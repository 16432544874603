import React, {
    RefObject, useEffect, useMemo, useRef, useState,
} from 'react';

import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SingleValue } from 'react-select';
import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import COLOR from '@CONSTANTS/COLOR.constant';

import { AdminSingleSectionData, AdminSingleSectionMeta } from '@INTERFACES/admin-api/sections';

import { useAddTagToSection, useGetAllTags } from '@SERVICES';
import useDocumentPermissions from '@HOOKS/useDocumentPermissions';
import useNewTagInSectionModal from '@HOOKS/store/modals/useNewTagInSectionModal';

import BasicSelect from '@COMPONENTS/COMMON/inputs/select/BasicSelect';
import ApplySelectButton from '@COMPONENTS/COMMON/buttons/ApplySelectButton';
import BasicButton from '@COMPONENTS/COMMON/buttons/BasicButton';

import styles from './SectionTagsControls.module.scss';

const cx: CX = classnames.bind(styles);

function SectionTagsControls(props: Props) {
    const { sectionData, sectionMeta } = props;

    const {
        canManageDocumentTags,
    } = useDocumentPermissions({
        documentOwnerId: sectionData.document.owner,
        permissions: sectionMeta.permissions,
    });

    const {
        data: tagsData = { data: [] },
        isLoading: isTagsLoading,
    } = useGetAllTags({
        documentId: sectionData.document.id,
    });

    const [
        addTag,
        {
            isLoading, isSuccess, isError, error,
        },
    ] = useAddTagToSection();

    const {
        actions: newTagInSectionModalActions,
    } = useNewTagInSectionModal(() => true);

    const selectWrapperRef: RefObject<HTMLDivElement> = useRef(null);

    const [selectedTagId, setSelectedTagId] = useState<number | null>(null);

    useEffect(() => {
        if (isSuccess) {
            notify.info('New tag has been added', {
                toastId: 'add-tag-to-section',
            });

            setSelectedTagId(null);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            if ((error as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'add-tag-to-section-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'add-tag-to-section-error',
                });
            }
        }
    }, [isError, error]);

    const options = useMemo(() => tagsData.data
        .filter((tag) => !sectionData.tags.find((t) => t.id === tag.id))
        .map((tag) => ({
            ...tag,
            value: tag.id,
            label: tag.name,
        })), [sectionData, tagsData.data]);

    const value = useMemo(
        () => options.find((opt) => opt.id === selectedTagId) || null,
        [options, selectedTagId],
    );

    return (
        <div className={cx('section-tags-controls')}>
            <div
                ref={selectWrapperRef}
                className={cx('select-wrapper')}
            >
                <BasicSelect
                    title="Add tag to section"
                    placeholder="Select tag"
                    isSearchable
                    options={options}
                    value={value}
                    menuPlacement="top"
                    isLoading={isTagsLoading}
                    onMenuOpen={() => {
                        if (selectWrapperRef.current) {
                            selectWrapperRef.current.style.zIndex = '5';
                        }
                    }}
                    onMenuClose={() => {
                        if (selectWrapperRef.current) {
                            selectWrapperRef.current.style.zIndex = 'unset';
                        }
                    }}
                    onChange={(val: SingleValue<any>) => {
                        setSelectedTagId(val?.id || null);
                    }}
                />
            </div>
            <div className={cx('buttons-wrapper')}>
                <div className={cx('button')}>
                    <ApplySelectButton
                        tooltip="Add tag"
                        locked={!canManageDocumentTags}
                        isProcessing={isLoading}
                        disabled={selectedTagId === null}
                        onClick={() => {
                            if (selectedTagId !== null) {
                                addTag({
                                    id: sectionData.id,
                                    chapterId: sectionData.chapter.id,
                                    tagId: selectedTagId,
                                });
                            }
                        }}
                    />
                </div>
                <div className={cx('button')}>
                    <BasicButton
                        tooltip="Create and add new tag"
                        locked={!canManageDocumentTags}
                        title="+"
                        style={{
                            height: 36,
                            width: 36,
                            fontSize: 22,
                            padding: '0',
                            backgroundColor: COLOR['app-green'],
                        }}
                        onClick={() => {
                            newTagInSectionModalActions.open({
                                documentId: sectionData.document.id,
                                chapterId: sectionData.chapter.id,
                                sectionId: sectionData.id,
                            });
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

type Props = {
    sectionData: AdminSingleSectionData,
    sectionMeta: AdminSingleSectionMeta,
};

export default SectionTagsControls;
