import React from 'react';
import { Outlet } from 'react-router-dom';

import classnames from 'classnames/bind';

import Header from '@COMPONENTS/HEADERS/Header';
import MainHeader from '@COMPONENTS/HEADERS/MainHeader';
import Subheader from '@COMPONENTS/HEADERS/Subheader';
import StepBreadcrumbs from '@COMPONENTS/HEADERS/breadcrumbs/StepBreadcrumbs';
import ScrollToTopButton from '@COMPONENTS/COMMON/buttons/ScrollToTopButton';

import styles from './GenerateDocument.module.scss';

const cx: CX = classnames.bind(styles);

function GenerateDocumentLayout() {
    return (
        <>
            <Header>
                <MainHeader />
                <Subheader title="Generate New Document" />
                <StepBreadcrumbs />
            </Header>

            <ScrollToTopButton />

            <main className={cx('main')}>
                <div className={cx('content-wrapper')}>
                    <Outlet />
                </div>
            </main>
        </>
    );
}

export default GenerateDocumentLayout;
