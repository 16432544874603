import React from 'react';

import classnames from 'classnames/bind';

import styles from './404.module.scss';

const cx: CX = classnames.bind(styles);

function Page404() {
    return (
        <div className={cx('page')}>
            <div className="container max-constraint center">
                <div className={cx('not-found')}>
                    404 - Page not found!
                </div>
            </div>
        </div>
    );
}

export default Page404;
