import React, { useState } from 'react';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';
import DESIGN from '@CONSTANTS/DESIGN.constant';

import { AdminSingleDocument } from '@SERVICES';

import useAuth from '@HOOKS/useAuth';
import useDocumentPermissions from '@HOOKS/useDocumentPermissions';
import useNewChapterModal from '@HOOKS/store/modals/useNewChapterModal';

import DocumentName from '@PAGES/admin/document/components/DocumentName';
import Chapter from '@PAGES/admin/document/components/Chapter';
import DocumentImage from '@PAGES/admin/document/components/DocumentImage';
import DocumentTags from '@PAGES/admin/document/components/DocumentTags';
import ChaptersStickyHeader from '@PAGES/admin/document/components/ChaptersStickyHeader';

import StickyHeader from '@COMPONENTS/HEADERS/StickyHeader';
import AddButton from '@COMPONENTS/COMMON/buttons/AddButton';
import OpenCloseButton from '@COMPONENTS/COMMON/buttons/OpenCloseButton';
import DocumentPermissions from '@PAGES/admin/document/components/DocumentPermissions';

import styles from './Content.module.scss';

const cx: CX = classnames.bind(styles);

const top = DESIGN['subheader-height'] + DESIGN['admin-breadcrumbs-height'];

function Content(props: Props) {
    const { documentData } = props;

    const { state: { user, permissions } } = useAuth();

    const {
        canEditDocument,
    } = useDocumentPermissions({
        documentOwnerId: documentData.data.owner.id,
        permissions: documentData.meta.permissions,
    });

    const {
        actions: newChapterModalActions,
    } = useNewChapterModal(() => true);

    const [isDocumentPermissionsOpen, setDocumentPermissionsOpen] = useState<boolean>(false);
    const [isDocumentImageOpen, setDocumentImageOpen] = useState<boolean>(false);
    const [isDocumentTagsOpen, setDocumentTagsOpen] = useState<boolean>(false);

    return (
        <div className={cx('content')}>
            <DocumentName
                documentData={documentData}
            />

            {
                (user!.id === documentData.data.owner.id || permissions.isSuperuser)
                && (
                    <div className={cx('document-permissions-wrapper')}>
                        <StickyHeader top={top}>
                            <div className={cx('document-permissions-subheader', 'container')}>
                                <div className={cx('title')}>
                                    Document permissions
                                </div>
                                <OpenCloseButton
                                    isOpen={isDocumentPermissionsOpen}
                                    backgroundColor={COLOR['marriott-light-grey']}
                                    iconColor={COLOR['app-white']}
                                    onClick={() => {
                                        setDocumentPermissionsOpen((val) => !val);
                                    }}
                                />
                            </div>
                        </StickyHeader>

                        {
                            isDocumentPermissionsOpen
                            && (
                                <DocumentPermissions
                                    documentData={documentData.data}
                                />
                            )
                        }
                    </div>
                )
            }

            <div className={cx('document-image-wrapper')}>
                <StickyHeader
                    top={top}
                >
                    <div className={cx('document-image-subheader', 'container')}>
                        <div className={cx('title')}>
                            Document image
                        </div>
                        <OpenCloseButton
                            isOpen={isDocumentImageOpen}
                            backgroundColor={COLOR['marriott-light-grey']}
                            iconColor={COLOR['app-white']}
                            onClick={() => {
                                setDocumentImageOpen((val) => !val);
                            }}
                        />
                    </div>
                </StickyHeader>

                {
                    isDocumentImageOpen
                    && (
                        <DocumentImage
                            documentData={documentData}
                        />
                    )
                }
            </div>

            <div className={cx('document-tags-wrapper')}>
                <StickyHeader
                    top={top}
                >
                    <div className={cx('document-tags-subheader', 'container')}>
                        <div className={cx('title')}>
                            Document tags
                        </div>
                        <OpenCloseButton
                            isOpen={isDocumentTagsOpen}
                            backgroundColor={COLOR['marriott-light-grey']}
                            iconColor={COLOR['app-white']}
                            onClick={() => {
                                setDocumentTagsOpen((val) => !val);
                            }}
                        />
                    </div>
                </StickyHeader>

                {
                    isDocumentTagsOpen
                    && (
                        <DocumentTags documentData={documentData} />
                    )
                }
            </div>

            <ChaptersStickyHeader
                top={top}
                documentData={documentData}
            />

            <div className="container max-constraint center">
                <div className={cx('grid')}>
                    <div className={cx('grid-item')}>
                        <div className={cx('add-chapter-button')}>
                            <AddButton
                                locked={!canEditDocument}
                                title="New chapter"
                                fontSize={36}
                                onClick={() => {
                                    newChapterModalActions.open({
                                        documentId: documentData.data.id,
                                    });
                                }}
                            />
                        </div>
                    </div>

                    {
                        documentData!.data.chapters.map((chapter, i) => {
                            const chapterKey = chapter.id;

                            return (
                                <div
                                    key={chapterKey}
                                    className={cx('grid-item')}
                                >
                                    <Chapter
                                        documentId={documentData.data.id}
                                        documentOwnerId={documentData.data.owner.id}
                                        permissions={documentData.meta.permissions}
                                        chapter={chapter}
                                        index={i}
                                    />
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
}

type Props = {
    documentData: AdminSingleDocument,
};

export default Content;
