/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminSingleSectionDataBaseText, AdminSingleSectionDataVariant } from '@INTERFACES/admin-api/sections';

export interface PreviewVariantModalState {
    isOpen: boolean;

    data: {
        documentId: number;

        variant: AdminSingleSectionDataVariant;

        baseText: AdminSingleSectionDataBaseText;

        isLandscape: boolean;

        canGenerateDocument: boolean;
    } | null;
}

export interface PreviewVariantModalDispatchParams {
    documentId: number;

    variant: AdminSingleSectionDataVariant;

    baseText: AdminSingleSectionDataBaseText;

    isLandscape: boolean;

    canGenerateDocument: boolean;
}

const initialState: PreviewVariantModalState = {
    isOpen: false,

    data: null,
};

export const previewVariantModalSlice = createSlice({
    name: 'preview-variant-modal',
    initialState,
    reducers: {
        open(state: PreviewVariantModalState, action: PayloadAction<PreviewVariantModalDispatchParams>) {
            state.isOpen = true;
            state.data = action.payload;
        },
        close(state: PreviewVariantModalState) {
            state.isOpen = false;
            state.data = null;
        },
    },
});
