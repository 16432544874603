import React, { RefObject, useRef, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useForm } from 'react-hook-form';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import useGlobalPermissions from '@HOOKS/useGlobalPermissions';

import BasicInputValidatable from '@COMPONENTS/COMMON/inputs/input/BasicInput/BasicInputValidatable.component';
import HiddenFormButton from '@COMPONENTS/COMMON/buttons/HiddenFormButton';
import BasicButton from '@COMPONENTS/COMMON/buttons/BasicButton';
import RoundedCheckbox from '@COMPONENTS/COMMON/inputs/checkbox/RoundedCheckbox';

import styles from './VendorForm.module.scss';

const cx: CX = classnames.bind(styles);

enum Form {
    NAME = 'name',
    COMPANY = 'company',
    ADDRESS = 'address',
    CONTACT = 'contact',
    EMAIL = 'email',
    PHONE = 'phone',
}

yup.setLocale({
    mixed: {
        required: () => 'This field is required',
    },
});

const schema = yup.object({
    [Form.NAME]: yup.string().required(),
    [Form.COMPANY]: yup.string().required(),
    [Form.ADDRESS]: yup.string().required(),
    [Form.CONTACT]: yup.string().required(),
    [Form.EMAIL]: yup.string().email().required(),
    [Form.PHONE]: yup.string().required(),
});

export type VendorFormValues = {
    [Form.NAME]: string;
    [Form.COMPANY]: string;
    [Form.ADDRESS]: string;
    [Form.CONTACT]: string;
    [Form.EMAIL]: string;
    [Form.PHONE]: string;
};

function VendorForm(props: Props) {
    const {
        buttonTitle,
        isLoading,
        hidden,
        defaultValues,
        onSave,
    } = props;

    const {
        canManageVendors,
    } = useGlobalPermissions();

    const [isHidden, setIsHidden] = useState<boolean>(hidden);

    const formButtonRef: RefObject<HTMLButtonElement> = useRef(null);

    const { handleSubmit, control } = useForm<VendorFormValues>({
        defaultValues: {
            [Form.NAME]: defaultValues[Form.NAME],
            [Form.COMPANY]: defaultValues[Form.COMPANY],
            [Form.ADDRESS]: defaultValues[Form.ADDRESS],
            [Form.CONTACT]: defaultValues[Form.CONTACT],
            [Form.EMAIL]: defaultValues[Form.EMAIL],
            [Form.PHONE]: defaultValues[Form.PHONE],
        },
        resolver: yupResolver(schema),
        mode: 'onChange',
    });

    return (
        <div className={cx('vendor-form')}>
            <div className={cx('visibility-wrapper')}>
                <div className={cx('checkbox-title')}>
                    Hidden
                </div>
                <RoundedCheckbox
                    color={COLOR['marriott-orange']}
                    checked={isHidden}
                    onChange={() => {
                        setIsHidden((val) => !val);
                    }}
                />
            </div>
            <form onSubmit={handleSubmit((formValues: VendorFormValues) => onSave(formValues, isHidden))}>
                <div className={cx('input-wrapper')}>
                    <BasicInputValidatable<VendorFormValues>
                        label="Title"
                        placeholder="Title"
                        validation={{
                            name: Form.NAME,
                            control,
                        }}
                    />
                </div>

                <div className={cx('input-wrapper')}>
                    <BasicInputValidatable<VendorFormValues>
                        label="Company"
                        placeholder="Company"
                        validation={{
                            name: Form.COMPANY,
                            control,
                        }}
                    />
                </div>

                <div className={cx('input-wrapper')}>
                    <BasicInputValidatable<VendorFormValues>
                        label="Address"
                        placeholder="Address"
                        validation={{
                            name: Form.ADDRESS,
                            control,
                        }}
                    />
                </div>

                <div className={cx('input-wrapper')}>
                    <BasicInputValidatable<VendorFormValues>
                        label="Contact"
                        placeholder="Contact"
                        validation={{
                            name: Form.CONTACT,
                            control,
                        }}
                    />
                </div>

                <div className={cx('input-wrapper')}>
                    <BasicInputValidatable<VendorFormValues>
                        label="Email"
                        placeholder="Email"
                        validation={{
                            name: Form.EMAIL,
                            control,
                        }}
                    />
                </div>

                <div className={cx('input-wrapper')}>
                    <BasicInputValidatable<VendorFormValues>
                        label="Phone"
                        placeholder="Phone"
                        validation={{
                            name: Form.PHONE,
                            control,
                        }}
                    />
                </div>
                <HiddenFormButton ref={formButtonRef} />
            </form>
            <BasicButton
                locked={!canManageVendors}
                title={buttonTitle}
                isProcessing={isLoading}
                style={{
                    height: 40,
                    width: '100%',
                    fontSize: 14,
                    backgroundColor: COLOR['app-green'],
                }}
                onClick={() => {
                    formButtonRef.current?.click();
                }}
            />
        </div>
    );
}

VendorForm.propTypes = {
    buttonTitle: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    hidden: PropTypes.bool.isRequired,
    defaultValues: PropTypes.exact({
        [Form.NAME]: PropTypes.string.isRequired,
        [Form.COMPANY]: PropTypes.string.isRequired,
        [Form.ADDRESS]: PropTypes.string.isRequired,
        [Form.CONTACT]: PropTypes.string.isRequired,
        [Form.EMAIL]: PropTypes.string.isRequired,
        [Form.PHONE]: PropTypes.string.isRequired,
    }).isRequired,
    onSave: PropTypes.func.isRequired,
};

type Props = InferProps<typeof VendorForm.propTypes>;

export default VendorForm;
