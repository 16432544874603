import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import usePreviewDocumentModal from '@HOOKS/store/modals/usePreviewDocumentModal';
import useDownloadDocumentPreviewWrapper from '@HOOKS/useDownloadDocumentPreviewWrapper';

import ViewMode from '@MODALS/Previews/components/ViewMode';
import GeneratePreviewPDFButton from '@COMPONENTS/COMMON/buttons/GeneratePreviewPDFButton';

import styles from './ModalHeader.module.scss';

const cx: CX = classnames.bind(styles);

function ModalHeader(props: Props) {
    const {
        isPublished,
        showUnpublished,
        setShowUnpublished,
        canGenerateDocument,
    } = props;

    const {
        state: { data: modalParams },
        actions: previewDocumentModalActions,
    } = usePreviewDocumentModal(() => true);

    const [
        downloadDocumentPreview,
        { isLoading },
    ] = useDownloadDocumentPreviewWrapper();

    const { documentData } = modalParams!;

    function handleClose() {
        previewDocumentModalActions.close();
    }

    return (
        <div className={cx('modal-header')}>
            <div className={cx('title-wrapper')}>
                <div className={cx('title')}>
                    Document preview
                </div>
                <ViewMode
                    disabled={isPublished}
                    showUnpublished={showUnpublished}
                    setShowUnpublished={setShowUnpublished}
                />
            </div>
            <div className={cx('document-name')}>
                {documentData.name}
            </div>
            <div className={cx('right-block')}>
                <div className={cx('pdf-button-wrapper')}>
                    <GeneratePreviewPDFButton
                        isProcessing={isLoading}
                        canGenerateDocument={canGenerateDocument}
                        onClick={() => {
                            const variants: number[] = [];
                            const sections: number[] = [];
                            const chapters: number[] = [];

                            documentData.chapters.forEach((c) => {
                                chapters.push(c.id);

                                c.sections.forEach((s) => {
                                    sections.push(s.id);

                                    s.variants.forEach((v) => {
                                        variants.push(v.id);
                                    });
                                });
                            });

                            downloadDocumentPreview({
                                document: documentData.id,
                                variants,
                                sections,
                                chapters,
                                isUnpublished: showUnpublished,
                            });
                        }}
                    />
                </div>
                <div className={cx('close-button-wrapper')}>
                    <button
                        type="button"
                        className={cx('clear-button', 'close-button')}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        &times;
                    </button>
                </div>
            </div>
        </div>
    );
}

ModalHeader.propTypes = {
    isPublished: PropTypes.bool.isRequired,
    showUnpublished: PropTypes.bool.isRequired,
    setShowUnpublished: PropTypes.func.isRequired,
    canGenerateDocument: PropTypes.bool.isRequired,
};

type Props = InferProps<typeof ModalHeader.propTypes>;

export default ModalHeader;
