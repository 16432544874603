import React, {
    RefObject,
    useRef,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import COLOR from '@CONSTANTS/COLOR.constant';

import useAuth from '@HOOKS/useAuth';

import BasicInputValidatable from '@COMPONENTS/COMMON/inputs/input/BasicInput/BasicInputValidatable.component';
import HiddenFormButton from '@COMPONENTS/COMMON/buttons/HiddenFormButton';
import BasicButton from '@COMPONENTS/COMMON/buttons/BasicButton';

import styles from './ForgotForm.module.scss';

const cx: CX = classnames.bind(styles);

enum Form {
    EMAIL = 'email',
}

yup.setLocale({
    mixed: {
        required: () => 'This field is required',
    },
});

const schema = yup.object({
    [Form.EMAIL]: yup.string().email().required(),
});

export type LoginFormValues = {
    [Form.EMAIL]: string;
};

interface LoginProps {
    setIsLogin: any,
}

const messages = {
    error: 'Could not be sent. Contact Administrator',
    success: 'Success. Check your email box.',
};

function ForgotForm({ setIsLogin }: LoginProps) {
    const {
        actions,
        state: {
            resetData: {
                isLoading,
            },
        },
    } = useAuth();

    const [search] = useSearchParams();

    const formButtonRef: RefObject<HTMLButtonElement> = useRef(null);

    const defaultValues = {
        [Form.EMAIL]: search.get('email') || '',
    };

    const { handleSubmit, control } = useForm<LoginFormValues>({
        defaultValues: {
            [Form.EMAIL]: defaultValues[Form.EMAIL],
        },
        resolver: yupResolver(schema),
        mode: 'onChange',
    });

    async function onReset(formValues: LoginFormValues) {
        const res = await actions.reset({
            email: formValues[Form.EMAIL],
        });

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (res.error) {
            notify.error(messages.error, {
                toastId: 'error-reset',
            });
        } else {
            notify.success(messages.success, {
                toastId: 'success-reset',
            });
        }
        setIsLogin(true);
    }

    return (
        <div className={cx('login-form')}>
            <form onSubmit={handleSubmit(onReset)}>
                <div className={cx('input-wrapper')}>
                    <BasicInputValidatable<LoginFormValues>
                        label="Enter your email address and click ”Reset Password” button. You will get an email with new password created."
                        placeholder="Email"
                        validation={{
                            name: Form.EMAIL,
                            control,
                        }}
                    />
                </div>
                <HiddenFormButton ref={formButtonRef} />
            </form>
            <BasicButton
                title="Reset Password"
                isProcessing={isLoading}
                style={{
                    height: 40,
                    width: '100%',
                    fontSize: 14,
                    backgroundColor: COLOR['app-green'],
                }}
                onClick={() => {
                    formButtonRef.current?.click();
                }}
            />
        </div>
    );
}

export default ForgotForm;
