import React from 'react';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import { AdminSingleSectionData, AdminSingleSectionMeta } from '@INTERFACES/admin-api/sections';

import useSectionNameModal from '@HOOKS/store/modals/useSectionNameModal';
import useDocumentPermissions from '@HOOKS/useDocumentPermissions';

import BasicIconButton from '@COMPONENTS/COMMON/buttons/BasicIconButton';
import Lock from '@COMPONENTS/SHARED/Lock';

import styles from './SectionName.module.scss';

const cx: CX = classnames.bind(styles);

function SectionName(props: Props) {
    const { sectionData, sectionMeta } = props;

    const {
        canEditDocument,
    } = useDocumentPermissions({
        documentOwnerId: sectionData.document.owner,
        permissions: sectionMeta.permissions,
    });

    const {
        actions,
    } = useSectionNameModal(() => true);

    return (
        <div className={cx('section-name', 'container')}>
            <div className={cx('name')}>
                {`Section - ${sectionData?.name}`}
            </div>
            {
                !canEditDocument
                    ? (
                        <Lock size="medium" />
                    ) : (
                        <div className={cx('edit-section-name')}>
                            <BasicIconButton
                                tooltip="Edit section name"
                                icon="edit"
                                width={18}
                                height={18}
                                color={COLOR['marriott-light-grey']}
                                onClick={() => {
                                    actions.open({
                                        sectionData,
                                    });
                                }}
                            />
                        </div>
                    )
            }
        </div>
    );
}

type Props = {
    sectionData: AdminSingleSectionData,
    sectionMeta: AdminSingleSectionMeta,
};

export default SectionName;
