import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ADMIN_API_PREFIX, BASE_URL } from '@CONSTANTS/API.constant';
import userRepository from '@REPOSITORIES/local-repository/user-repository';
import { GLOBAL_PERMISSIONS } from '@CONSTANTS/PERMISSIONS.constant';
import invalidateData from '../helpers/invalidateData';
import { documentsAdminApi } from './documents.admin-api';
import { chaptersAdminApi } from './chapters.admin-api';
import { sectionsAdminApi } from './sections.admin-api';
import { logsAdminApi } from './logs.admin-api';
import { vendorsAdminApi } from './vendors.admin-api';

export type AdminUsers = {
    data: AdminUsersData;
};

export type AdminUsersData = AdminUser[];

export type AdminUser = {
    id: number;

    isSuperuser: boolean;

    isActive: boolean;

    fullName: string;

    email: string;

    mobilePhone: string;

    officePhone: string;

    country: string;

    title: string;

    expirationDate: string | null;

    permissions: AdminUserPermission[];
};

export type AdminUserPermission = {
    id: number;

    name: string;

    codename: string;
};

type UserApiResponse = {
    id: number;

    is_superuser: boolean;

    is_active: boolean;

    full_name: string;

    email: string;

    mobile_phone: string;

    office_phone: string;

    country: string;

    title: string;

    expiration_date: string | null;

    token: string | undefined;

    permissions: {
        id: number;

        name: string;

        codename: string;
    }[];
};

type GetAllApiResponse = UserApiResponse[];

type UpdatePermissionsParams = {
    id: number;

    isSuperuser: boolean;

    isActive: boolean;

    permissions: GLOBAL_PERMISSIONS[],

    expirationDate: Date | null;
};

function transformGetAllResponse(raw: GetAllApiResponse): AdminUsers {
    return {
        data: raw.map((user) => ({
            id: user.id,
            isSuperuser: user.is_superuser,
            isActive: user.is_active,
            fullName: user.full_name,
            email: user.email,
            mobilePhone: user.mobile_phone,
            officePhone: user.office_phone,
            country: user.country,
            title: user.title,
            expirationDate: user.expiration_date,
            permissions: user.permissions.map((permission) => ({
                id: permission.id,
                name: permission.name,
                codename: permission.codename,
            })),
        })),
    };
}

export const usersAdminApi = createApi({
    reducerPath: 'users-admin-api',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + ADMIN_API_PREFIX,
        prepareHeaders: (headers) => {
            const token = userRepository.getData();

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getAll: builder.query<AdminUsers, void>({
            query: () => ({
                url: '/users/',
                method: 'GET',
            }),
            transformResponse(raw: GetAllApiResponse) {
                return transformGetAllResponse(raw);
            },
        }),
        updatePermissions: builder.mutation<UserApiResponse, UpdatePermissionsParams>({
            query: (params) => {
                const {
                    isActive,
                    expirationDate,
                } = params;

                const formData = JSON.stringify({
                    is_superuser: params.isSuperuser.toString(),
                    permissions_codenames: params.permissions.join(','),
                    is_active: isActive.toString(),
                    expiration_date: expirationDate?.toISOString() || '',
                });

                return {
                    url: `/users/${params.id}/permissions/`,
                    method: 'PATCH',
                    body: formData,
                };
            },
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data: queryData } = await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    dispatch(documentsAdminApi.util.resetApiState());
                    dispatch(chaptersAdminApi.util.resetApiState());
                    dispatch(sectionsAdminApi.util.resetApiState());
                    dispatch(vendorsAdminApi.util.resetApiState());
                    dispatch(logsAdminApi.util.resetApiState());

                    // UPDATE USER PERMISSIONS
                    dispatch(
                        usersAdminApi.util.updateQueryData('getAll', undefined, (draft) => ({
                            data: draft.data.map((user) => {
                                if (user.id === queryData.id) {
                                    return {
                                        ...user,
                                        isActive: queryData.is_active,
                                        isSuperuser: queryData.is_superuser,
                                        expirationDate: queryData.expiration_date,
                                        permissions: queryData.permissions.map((permission) => ({
                                            id: permission.id,
                                            name: permission.name,
                                            codename: permission.codename,
                                        })),
                                    };
                                }

                                return user;
                            }),
                        })),
                    );
                } catch (e) {
                    //
                }
            },
        }),
    }),
});

export const {
    useGetAllQuery: useGetAllUsers,
    useUpdatePermissionsMutation: useUpdateUserPermissions,
} = usersAdminApi;
