import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { AdminVendor } from '@SERVICES';

import VendorHeader from '@PAGES/admin/vendors/components/VendorHeader';
import VendorBody from '@PAGES/admin/vendors/components/VendorBody';

import styles from './Vendor.module.scss';

const cx: CX = classnames.bind(styles);

function Vendor(props: Props) {
    const {
        vendor,
        openVendorsIds,
        setOpenVendorsIds,
    } = props;

    const isOpen = openVendorsIds.has(vendor.id);

    return (
        <div className={cx('vendor')}>
            <div className={cx('vendor-wrapper')}>
                <VendorHeader
                    vendor={vendor}
                    isOpen={isOpen}
                    onOpenToggle={(val: boolean) => {
                        const set = new Set(openVendorsIds);

                        if (val) {
                            set.add(vendor.id);
                        } else {
                            set.delete(vendor.id);
                        }

                        setOpenVendorsIds(set);
                    }}
                />
                {
                    isOpen && <VendorBody vendor={vendor} />
                }
            </div>
        </div>
    );
}

Vendor.propTypes = {
    openVendorsIds: PropTypes.instanceOf(Set<number>).isRequired,
    setOpenVendorsIds: PropTypes.func.isRequired,
};

type Props = InferProps<typeof Vendor.propTypes> & {
    vendor: AdminVendor,
};

export default Vendor;
