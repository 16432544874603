import React, { useEffect } from 'react';

import classnames from 'classnames/bind';

import { useGetAllDocumentsAdmin } from '@SERVICES';

import useNewDocumentModal from '@HOOKS/store/modals/useNewDocumentModal';
import useDeleteDocumentModal from '@HOOKS/store/modals/useDeleteDocumentModal';

import DataLoader from '@COMPONENTS/SHARED/DataLoader';
import Content from '@PAGES/admin/documents-list/components/Content';

import styles from './DocumentsList.module.scss';

const cx: CX = classnames.bind(styles);

function DocumentsListPage() {
    const {
        data: documentsData = [], isLoading, isError, error,
    } = useGetAllDocumentsAdmin();

    const {
        actions: newDocumentModalActions,
    } = useNewDocumentModal(() => true);

    const {
        actions: deleteDocumentModalActions,
    } = useDeleteDocumentModal(() => true);

    useEffect(() => () => {
        newDocumentModalActions.close();
        deleteDocumentModalActions.close();
    }, [
        newDocumentModalActions,
        deleteDocumentModalActions,
    ]);

    return (
        <div className={cx('page')}>
            <div className="container max-constraint center">
                <h1>
                    Documents
                </h1>
                <DataLoader
                    isLoading={isLoading}
                    isError={isError}
                    error={error}
                >
                    <Content documentsData={documentsData} />
                </DataLoader>
            </div>
        </div>
    );
}

export default DocumentsListPage;
