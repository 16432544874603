import React, {
    RefObject, useEffect, useRef, useState,
} from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classNames from 'classnames/bind';
import { useInView } from 'react-intersection-observer';

import PDF from '@CONSTANTS/PDF.constant';
import COLOR from '@CONSTANTS/COLOR.constant';
import { BASE_URL } from '@CONSTANTS/API.constant';

import CircularProgressIndicator from '@COMPONENTS/SHARED/CircularProgressIndicator';

import styles from './DocumentPreviewPage.module.scss';

const cx: CX = classNames.bind(styles);

const EDITOR_MARGIN = PDF['pdf-editor-margin'];
const PAGE_HEIGHT = PDF['pdf-page-height'];
const PAGE_WIDTH = PDF['pdf-page-width'];

function getTransform() {
    return `rotate(-90deg) translateX(-${PAGE_HEIGHT - PAGE_WIDTH}px)`;
}

function DocumentPreviewPage(props: Props) {
    const {
        body,
        isLandscape,
        isPreview,
        lazy,
    } = props;

    const ref: RefObject<HTMLIFrameElement> = useRef(null);

    const [iframeHeight, setIframeHeight] = useState(0);
    const [isIframeLoaded, setIframeLoaded] = useState(false);

    const { ref: observerRef, inView } = useInView({
        triggerOnce: true,
        rootMargin: '200px 0px',
        skip: lazy === false,
    });

    const pageHeight = (isLandscape && !isPreview) ? PAGE_WIDTH : undefined;
    const pageWidth = (isLandscape && isPreview) ? PAGE_HEIGHT : PAGE_WIDTH;

    const iframeWidth = isLandscape ? PAGE_HEIGHT : PAGE_WIDTH;

    useEffect(() => {
        if (ref.current && isIframeLoaded) {
            const iframe = ref!.current!;

            const iFrameDocumentElement = iframe.contentWindow!.document.documentElement;

            setIframeHeight(Math.floor(iFrameDocumentElement.scrollHeight));

            const resizeObserver = new ResizeObserver(() => {
                setIframeHeight(Math.floor(iFrameDocumentElement.scrollHeight));
            });

            resizeObserver.observe(iFrameDocumentElement);

            return () => {
                resizeObserver.unobserve(iFrameDocumentElement);
            };
        }
    }, [isIframeLoaded]);

    const srcDoc = `
        <html lang="en">
            <head>
                <title>Page</title>
                <link rel="stylesheet" href="${BASE_URL}/static/pdf/document.css" />
            <style>
                html {
                  overflow: hidden;
                }

                body {
                  margin: ${EDITOR_MARGIN}px;
                }

                a {
                  pointer-events: none;
                }
            </style>
            </head>
            <body>
              ${body}
            </body>
        </html>
    `;

    return (
        <div
            className={cx('document-preview-page')}
            style={{
                height: (isLandscape && !isPreview) ? PAGE_HEIGHT : undefined,
                width: pageWidth,
            }}
        >
            <div
                className={cx('body', {
                    'is-loaded': isIframeLoaded,
                })}
                style={{
                    height: pageHeight,
                    width: pageWidth,
                    transform: (isLandscape && !isPreview) ? getTransform() : undefined,
                }}
            >
                <div
                    ref={observerRef}
                    className={cx('iframe-wrapper')}
                >
                    {
                        (inView || lazy === false)
                        && (
                            <iframe
                                ref={ref}
                                srcDoc={srcDoc}
                                title="0"
                                height={iframeHeight}
                                width={iframeWidth}
                                style={{
                                    position: (isLandscape && !isPreview) ? 'absolute' : undefined,
                                }}
                                onLoad={() => {
                                    setIframeLoaded(true);
                                }}
                            />
                        )
                    }
                </div>
            </div>
            {
                !isIframeLoaded
                && (
                    <div className={cx('document-page-preloader')}>
                        <CircularProgressIndicator
                            color={COLOR['marriott-primary']}
                            thickness={4}
                        />
                    </div>
                )
            }
        </div>
    );
}

DocumentPreviewPage.defaultProps = {
    isPreview: false,
    lazy: false,
};

DocumentPreviewPage.propTypes = {
    body: PropTypes.string.isRequired,
    isLandscape: PropTypes.bool.isRequired,
    isPreview: PropTypes.bool,
    lazy: PropTypes.bool,
};

type Props = InferProps<typeof DocumentPreviewPage.propTypes> & typeof DocumentPreviewPage.defaultProps;

export default DocumentPreviewPage;
