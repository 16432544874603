import React from 'react';

import useNewTagInSectionModal from '@HOOKS/store/modals/useNewTagInSectionModal';

import NameInputModal, { FormValues } from '@MODALS/common/NameInputModal';
import { useCreateAndAddTagToSection } from '@SERVICES';

function NewTagInSectionModal() {
    const {
        state: { data: modalParams },
        actions: newTagInSectionModalActions,
    } = useNewTagInSectionModal();

    const [
        createAndAddTag,
        {
            isLoading, isSuccess, isError, error,
        },
    ] = useCreateAndAddTagToSection();

    const onSave = (formValues: FormValues) => {
        createAndAddTag({
            documentId: modalParams!.documentId,
            chapterId: modalParams!.chapterId,
            id: modalParams!.sectionId,
            tagName: formValues.name,
        });
    };

    return (
        <NameInputModal
            modalTitle="Add new tag"
            itemName="Tag"
            inputPlaceholder="Tag name"
            successMessage="New tag has been created and added to section!"
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            error={error}
            onSave={onSave}
            onClose={() => {
                newTagInSectionModalActions.close();
            }}
        />
    );
}

export default NewTagInSectionModal;
