import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import rootReducer, { getInitialState, resetApiState } from '@STORE/rootReducer';
import rootMiddleware from '@STORE/rootMiddleware';
import { authSlice, decodeUserToken } from '@REDUCERS';

import stepsRepository from '@REPOSITORIES/local-repository/steps-repository';
import userRepository from '@REPOSITORIES/local-repository/user-repository';
import userPermissionsRepository from '@REPOSITORIES/local-repository/user-permissions-repository';

const steps = stepsRepository.getData();

type PreloadedState = {
    documentGenerationSteps?: any;
    auth?: any;
};

const preloadedState: PreloadedState = {
    auth: {
        user: decodeUserToken(userRepository.getData()).user,
        permissions: userPermissionsRepository.getData(),
    },
};

if (steps && steps.length > 0) {
    preloadedState.documentGenerationSteps = {
        steps,
    };
}

const combinedReducer = combineReducers(rootReducer);

export const store = configureStore({
    reducer: (state: any, action: AnyAction) => {
        if (action.type === authSlice.actions.logout.type) {
            const initialState = getInitialState(state);

            stepsRepository.clear();
            userRepository.clear();
            userPermissionsRepository.clear();

            setTimeout(() => {
                resetApiState(store.dispatch);
            }, 0);

            return combinedReducer(initialState, action);
        }

        return combinedReducer(state, action);
    },
    preloadedState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(rootMiddleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
