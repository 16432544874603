import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import { useNavigate } from 'react-router-dom';
import classnames from 'classnames/bind';

import ROUTES from '@CONSTANTS/ROUTES.constant';

import { AdminSearchInsideSection, useSearchInsideSectionLazy } from '@SERVICES';

import scrollToVariant from '@PAGES/admin/edit-section/helpers/scrollToVariant';

import ResultSetWrapper from '@COMPONENTS/HEADERS/breadcrumbs/AdminBreadclumbs/components/ResultSetWrapper';
import ResultItemTitle from '@COMPONENTS/HEADERS/breadcrumbs/AdminBreadclumbs/components/ResultItemTitle';
import DocumentPreviewPage from '@COMPONENTS/SHARED/DocumentPreviewPage';

import styles from './SectionResults.module.scss';

const cx: CX = classnames.bind(styles);

function SectionResults(props: Props) {
    const {
        id,
        q,
        setLoading,
        isFocused,
        setFocused,
    } = props;

    const [
        searchInsideSection,
        {
            data, isLoading, isError, error, requestId,
        },
    ] = useSearchInsideSectionLazy();

    const navigate = useNavigate();

    const [results, setResults] = useState<AdminSearchInsideSection | undefined>(undefined);

    const showResultSet = (
        q.length > 2
        && results !== undefined
        && isFocused
    );

    useLayoutEffect(() => {
        setLoading(isLoading);
    }, [setLoading, isLoading]);

    useLayoutEffect(() => {
        if (data) {
            setResults(data);
        }
    }, [data, requestId]);

    useLayoutEffect(() => {
        if (q.length <= 2) {
            setResults(undefined);
        }
    }, [q]);

    useEffect(() => {
        if (q.trim().length > 2) {
            const result = searchInsideSection({
                id,
                q,
            });

            return () => {
                result.abort();
            };
        }
    }, [id, q, searchInsideSection]);

    if (!showResultSet) return null;

    const count = results?.meta.count || 0;
    const resultsQuery = results?.meta.query || '';

    return (
        <ResultSetWrapper
            setFocused={setFocused}
            count={count}
            resultsQuery={resultsQuery}
        >
            {
                (results?.data.body || results?.data.variants.length > 0)
                && (

                    <div className={cx('section-results')}>
                        {
                            results?.data.body
                            && (
                                <div className={cx('section-base-text')}>
                                    <ResultItemTitle
                                        title="Base text"
                                        onClick={() => {
                                            setFocused(false);
                                            navigate(ROUTES.admin.sections.id.get(results.data.id));
                                        }}
                                    />

                                    <DocumentPreviewPage
                                        isLandscape={results.data.isLandscape}
                                        body={results.data.body}
                                        isPreview
                                    />
                                </div>
                            )
                        }

                        {
                            results?.data.variants.length > 0
                            && (
                                <div className={cx('variants-list')}>
                                    {
                                        results?.data.variants.map((variant) => {
                                            const key = variant.id;

                                            return (
                                                <div
                                                    key={key}
                                                    className={cx('variant')}
                                                >
                                                    <ResultItemTitle
                                                        title={variant.name}
                                                        onClick={() => {
                                                            setFocused(false);

                                                            navigate(ROUTES.admin.sections.id.get(results.data.id, variant.id));

                                                            setTimeout(() => {
                                                                scrollToVariant(variant.id);
                                                            }, 150);
                                                        }}
                                                    />

                                                    <DocumentPreviewPage
                                                        isLandscape={variant.isLandscape}
                                                        body={variant.body}
                                                        isPreview
                                                    />
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            )
                        }
                    </div>
                )
            }
        </ResultSetWrapper>
    );
}

SectionResults.propTypes = {
    id: PropTypes.number.isRequired,
    q: PropTypes.string.isRequired,
    setLoading: PropTypes.func.isRequired,
    isFocused: PropTypes.bool.isRequired,
    setFocused: PropTypes.func.isRequired,
};

type Props = InferProps<typeof SectionResults.propTypes>;

export default SectionResults;
