import React from 'react';

import { useDeleteChapter } from '@SERVICES';
import useDeleteChapterModal from '@HOOKS/store/modals/useDeleteChapterModal';

import DeleteItemModal from '@MODALS/common/DeleteItemModal';

function DeleteChapterModal() {
    const {
        state: { data: modalParams },
        actions,
    } = useDeleteChapterModal();

    const [
        deleteChapter,
        {
            isLoading, isSuccess, isError, error,
        },
    ] = useDeleteChapter();

    return (
        <DeleteItemModal
            modalTitle="Delete chapter"
            name={modalParams!.name}
            itemName="Chapter"
            description="All sections of this chapter will also be deleted!"
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            error={error}
            onDelete={() => {
                deleteChapter({
                    id: modalParams!.id,
                    documentId: modalParams!.documentId,
                });
            }}
            onClose={() => {
                actions.close();
            }}
        />
    );
}

export default DeleteChapterModal;
