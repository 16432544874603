import React from 'react';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import CircularProgressIndicator from '@COMPONENTS/SHARED/CircularProgressIndicator';

import styles from './PageDataLoading.module.scss';

const cx: CX = classnames.bind(styles);

function PageDataLoading() {
    return (
        <div className={cx('page-data-loading')}>
            <CircularProgressIndicator
                color={COLOR['marriott-primary']}
                size={50}
                thickness={5}
            />
        </div>
    );
}

export default PageDataLoading;
