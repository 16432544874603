import React, { ChangeEvent, useId } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useController, UseControllerProps } from 'react-hook-form';

import classnames from 'classnames/bind';

import styles from './BasicInput.module.scss';

const cx: CX = classnames.bind(styles);

function BasicInputValidatable<T>(props: Props<T>) {
    const {
        placeholder,
        onChange,
        label,
        type,
        validation,
    } = props;

    const inputId = useId();

    const { field, fieldState } = useController<T>(validation);

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        field.onChange(event.target.value);

        onChange(event.target.value);
    }

    function handleBlur() {
        field.onBlur();
    }

    return (
        <div
            className={cx('basic-input', 'validatable', {
                error: !!fieldState.error,
            })}
        >
            <label htmlFor={inputId}>
                {
                    label
                    && (
                        <div className={cx('input-label')}>
                            {label}
                        </div>
                    )
                }
                <input
                    ref={field.ref}
                    id={inputId}
                    type={type}
                    autoComplete="off"
                    placeholder={placeholder}
                    name={field.name}
                    value={field.value as string}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </label>
            <div className={cx('error-message')}>
                {fieldState.error && fieldState.error.message}
            </div>
        </div>
    );
}

BasicInputValidatable.defaultProps = {
    placeholder: '',
    onChange: (val: string) => {
    },
    label: '',
    type: 'text',
};

BasicInputValidatable.propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.string,
    type: PropTypes.string,
};

type Props<T> =
    InferProps<typeof BasicInputValidatable.propTypes>
    & typeof BasicInputValidatable.defaultProps
    & { validation: UseControllerProps<T> };

export default BasicInputValidatable;
