import { AdminSingleChapter } from '@INTERFACES/admin-api/chapters';

export default function getFilteredByDraftsOnlyData(
    chapterData: AdminSingleChapter,
    isOnlyDrafts: boolean,
): AdminSingleChapter {
    if (!isOnlyDrafts) return chapterData;

    const { data, meta } = chapterData;

    if (data) {
        const filtered = {
            ...data,
            sections: data.sections.map((section) => {
                const { variants } = section;

                return {
                    ...section,
                    variants: variants.filter((variant) => !variant.isPublished),
                };
            }).filter((section) => section.variants.length > 0),
        };

        return {
            data: filtered,
            meta,
        };
    }

    return chapterData;
}
