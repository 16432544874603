import { AdminPreviewChapterSectionVariant, AdminPreviewDocumentChapterSectionVariant } from '@SERVICES';
import { SelectedOptions } from '@MODALS/Previews/components/BasicFilters/BasicFilters.component';

type Variant = AdminPreviewChapterSectionVariant | AdminPreviewDocumentChapterSectionVariant;

export default function getFilteredByOptionsPreviewVariants(variants: Variant[], selectedOptions: SelectedOptions) {
    return variants.filter((variant) => {
        if (selectedOptions.brand !== null) {
            if (variant.brand === null) {
                return false;
            }

            if (variant.brand.id !== selectedOptions.brand.value) {
                return false;
            }
        }

        if (selectedOptions.region !== null) {
            if (variant.region === null) {
                return false;
            }

            if (variant.region.id !== selectedOptions.region.value) {
                return false;
            }
        }

        if (selectedOptions.area !== null) {
            if (variant.area === null) {
                return false;
            }

            if (variant.area.id !== selectedOptions.area.value) {
                return false;
            }
        }

        if (selectedOptions.country !== null) {
            if (variant.country === null) {
                return false;
            }

            if (variant.country.id !== selectedOptions.country.value) {
                return false;
            }
        }

        if (selectedOptions.propertyType !== null) {
            if (variant.propertyType === null) {
                return false;
            }

            if (variant.propertyType.id !== selectedOptions.propertyType.value) {
                return false;
            }
        }

        return true;
    });
}
