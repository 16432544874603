import React, {
    RefObject, useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes, { InferProps } from 'prop-types';

import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SingleValue } from 'react-select';
import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import COLOR from '@CONSTANTS/COLOR.constant';

import {
    AdminSingleSectionData,
    AdminSingleSectionDataMandatoryCondition,
    AdminSingleSectionMeta,
} from '@INTERFACES/admin-api/sections';
import { useAddSectionMandatoryCondition, useUpdateSection } from '@SERVICES';

import BasicSelect from '@COMPONENTS/COMMON/inputs/select/BasicSelect';
import BasicButton from '@COMPONENTS/COMMON/buttons/BasicButton';
import Warning from '@COMPONENTS/SHARED/Warning';
import RoundedCheckbox from '@COMPONENTS/COMMON/inputs/checkbox/RoundedCheckbox';

import styles from './MandatorySidebar.module.scss';

const cx: CX = classnames.bind(styles);

function toOptions(data: any[]) {
    return data.map((val) => ({
        value: val.id,
        label: val.name,
    }));
}

function MandatorySidebar(props: Props) {
    const {
        sectionData,
        sectionMeta,
        isAlwaysMandatory,
        setAlwaysMandatory,
        conditions,
        locked,
    } = props;

    const [
        updateSection,
        {
            isLoading,
            isSuccess,
            isError,
            error,
        },
    ] = useUpdateSection();

    const [
        addCondition,
        {
            isLoading: isAddConditionLoading,
            isSuccess: isAddConditionSuccess,
            isError: isAddConditionError,
            error: eddConditionError,
        },
    ] = useAddSectionMandatoryCondition();

    const sidebarRef: RefObject<HTMLDivElement> = useRef(null);

    const [brandId, setBrandId] = useState<number | null>(null);
    const [regionId, setRegionId] = useState<number | null>(null);
    const [areaId, setAreaId] = useState<number | null>(null);
    const [countryId, setCountryId] = useState<number | null>(null);
    const [propertyTypeId, setPropertyTypeId] = useState<number | null>(null);

    const [showWarning, setShowWarning] = useState<boolean>(false);

    const options = useMemo(() => {
        const {
            options: {
                brands,
                regions,
                areas,
                countries,
                propertyTypes,
            },
        } = sectionMeta;

        return {
            brands: toOptions(brands),
            regions: toOptions(regions),
            areas: toOptions(areas),
            countries: toOptions(countries),
            propertyTypes: toOptions(propertyTypes),
        };
    }, [sectionMeta]);

    const values = useMemo(
        () => ({
            brand: options.brands.find((brand) => brand.value === brandId) || null,
            region: options.regions.find((region) => region.value === regionId) || null,
            area: options.areas.find((area) => area.value === areaId) || null,
            country: options.countries.find((country) => country.value === countryId) || null,
            propertyType: options.propertyTypes.find((propertyType) => propertyType.value === propertyTypeId) || null,
        }),
        [
            options,
            brandId,
            regionId,
            areaId,
            countryId,
            propertyTypeId,
        ],
    );

    useEffect(() => {
        setShowWarning(false);
    }, [
        brandId,
        regionId,
        areaId,
        countryId,
        propertyTypeId,
    ]);

    useEffect(() => {
        if (isSuccess) {
            notify.info('Section mandatory options has been updated', {
                toastId: 'update-section-mandatory-option',
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            if ((error as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'update-section-mandatory-option-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'update-section-mandatory-option-error',
                });
            }
        }
    }, [isError, error]);

    useEffect(() => {
        if (isAddConditionSuccess) {
            notify.info('Condition has been added', {
                toastId: 'add-section-mandatory-condition',
            });

            setBrandId(null);
            setRegionId(null);
            setAreaId(null);
            setCountryId(null);
            setPropertyTypeId(null);
        }
    }, [isAddConditionSuccess]);

    useEffect(() => {
        if (isAddConditionError) {
            if ((eddConditionError as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'add-section-mandatory-condition-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'add-section-mandatory-condition-error',
                });
            }
        }
    }, [isAddConditionError, eddConditionError]);

    function onMenuOpen() {
        if (sidebarRef.current) {
            sidebarRef.current.style.zIndex = '6';
        }
    }

    function onMenuClose() {
        if (sidebarRef.current) {
            sidebarRef.current.style.zIndex = '2';
        }
    }

    function checkExistedCondition() {
        if (conditions.length === 0) return false;

        for (let i = 0; i < conditions.length; i++) {
            const condition = conditions[i];

            const brandExists = brandId === (condition?.brand?.id || null);
            const regionExists = regionId === (condition?.region?.id || null);
            const areaExists = areaId === (condition?.area?.id || null);
            const countryExists = countryId === (condition?.country?.id || null);
            const propertyTypeExists = propertyTypeId === (condition?.propertyType?.id || null);

            if (
                brandExists
                && regionExists
                && areaExists
                && countryExists
                && propertyTypeExists
            ) {
                return true;
            }
        }

        return false;
    }

    const shouldShowSaveButton = (
        sectionData.isMandatory !== isAlwaysMandatory
    );

    const disableConditionButton = (
        !brandId
        && !regionId
        && !areaId
        && !countryId
        && !propertyTypeId
    ) || locked || sectionData.isMandatory;

    return (
        <div
            ref={sidebarRef}
            className={cx('mandatory-sidebar')}
        >
            <div className={cx('always-mandatory-wrapper')}>
                <div className={cx('checkbox-wrapper')}>
                    <div className={cx('checkbox-title')}>
                        Always mandatory
                    </div>
                    <RoundedCheckbox
                        color={COLOR['app-green']}
                        checked={isAlwaysMandatory}
                        onChange={() => {
                            setAlwaysMandatory((val: boolean) => !val);
                        }}
                    />
                </div>

                {
                    shouldShowSaveButton
                    && (
                        <div className={cx('save-button-wrapper')}>
                            <BasicButton
                                locked={locked}
                                title="Save"
                                isProcessing={isLoading}
                                style={{
                                    height: 32,
                                    width: 110,
                                    fontSize: 12,
                                    backgroundColor: COLOR['app-green'],
                                }}
                                onClick={() => {
                                    updateSection({
                                        id: sectionData.id,
                                        chapterId: sectionData.chapter.id,
                                        documentId: sectionData.document.id,
                                        name: sectionData.name,
                                        body: sectionData.baseText.body,
                                        isPageBreak: sectionData.isPageBreak,
                                        isLandscape: sectionData.isLandscape,
                                        isMandatory: isAlwaysMandatory,
                                    });
                                }}
                            />
                        </div>
                    )
                }
            </div>

            <div
                className={cx('conditions', {
                    hide: sectionData.isMandatory,
                })}
            >
                <div className={cx('conditions-title')}>
                    Conditions
                </div>

                <div
                    className={cx('selects')}
                >
                    <div className={cx('select-wrapper')}>
                        <BasicSelect
                            title="Brand"
                            placeholder="Choose Brand"
                            options={options.brands}
                            value={values.brand}
                            isDisabled={sectionData.isMandatory}
                            onChange={(val: SingleValue<any>) => {
                                setBrandId(val?.value || null);
                            }}
                            onMenuOpen={() => onMenuOpen()}
                            onMenuClose={() => onMenuClose()}
                        />

                        <div className={cx('plus')}>+</div>
                    </div>
                    <div className={cx('select-wrapper')}>
                        <BasicSelect
                            title="Region"
                            placeholder="Choose Region"
                            options={options.regions}
                            value={values.region}
                            isDisabled={sectionData.isMandatory}
                            onChange={(val: SingleValue<any>) => {
                                setRegionId(val?.value || null);
                            }}
                            onMenuOpen={() => onMenuOpen()}
                            onMenuClose={() => onMenuClose()}
                        />
                        <div className={cx('plus')}>+</div>
                    </div>
                    <div className={cx('select-wrapper')}>
                        <BasicSelect
                            title="Area"
                            placeholder="Choose Area"
                            options={options.areas}
                            value={values.area}
                            isDisabled={sectionData.isMandatory}
                            onChange={(val: SingleValue<any>) => {
                                setAreaId(val?.value || null);
                            }}
                            onMenuOpen={() => onMenuOpen()}
                            onMenuClose={() => onMenuClose()}
                        />
                        <div className={cx('plus')}>+</div>
                    </div>
                    <div className={cx('select-wrapper')}>
                        <BasicSelect
                            title="Country"
                            placeholder="Choose Country"
                            isSearchable
                            options={options.countries}
                            isDisabled={sectionData.isMandatory}
                            value={values.country}
                            onChange={(val: SingleValue<any>) => {
                                setCountryId(val?.value || null);
                            }}
                            onMenuOpen={() => onMenuOpen()}
                            onMenuClose={() => onMenuClose()}
                        />
                        <div className={cx('plus')}>+</div>
                    </div>
                    <div className={cx('select-wrapper')}>
                        <BasicSelect
                            title="Property Type"
                            placeholder="Choose Property Type"
                            options={options.propertyTypes}
                            value={values.propertyType}
                            isDisabled={sectionData.isMandatory}
                            onChange={(val: SingleValue<any>) => {
                                setPropertyTypeId(val?.value || null);
                            }}
                            onMenuOpen={() => onMenuOpen()}
                            onMenuClose={() => onMenuClose()}
                        />
                    </div>
                </div>

                <div
                    className={cx('warning-wrapper', {
                        show: showWarning,
                    })}
                >
                    <Warning text="Current condition already exists" />
                </div>

                <BasicButton
                    locked={locked}
                    title="Add condition"
                    isProcessing={isAddConditionLoading}
                    style={{
                        pointerEvents: disableConditionButton ? 'none' : 'all',
                        height: 40,
                        width: '100%',
                        fontSize: 14,
                        backgroundColor: disableConditionButton ? COLOR['marriott-light-grey'] : COLOR['app-green'],
                    }}
                    onClick={() => {
                        if (!disableConditionButton && !sectionData.isMandatory) {
                            const alreadyExists = checkExistedCondition();

                            if (alreadyExists) {
                                setShowWarning(true);
                            } else {
                                addCondition({
                                    brandId,
                                    regionId,
                                    areaId,
                                    countryId,
                                    propertyTypeId,
                                    sectionId: sectionData.id,
                                });
                            }
                        }
                    }}
                />
            </div>
        </div>
    );
}

MandatorySidebar.propTypes = {
    isAlwaysMandatory: PropTypes.bool.isRequired,
    setAlwaysMandatory: PropTypes.func.isRequired,
    locked: PropTypes.bool.isRequired,
};

type Props = InferProps<typeof MandatorySidebar.propTypes> & {
    sectionData: AdminSingleSectionData,
    sectionMeta: AdminSingleSectionMeta,
    conditions: AdminSingleSectionDataMandatoryCondition[],
};

export default MandatorySidebar;
