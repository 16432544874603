/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface NewTagModalState {
    isOpen: boolean;

    data: {
        documentId: number;
    } | null;
}

export interface NewTagModalDispatchParams {
    documentId: number;
}

const initialState: NewTagModalState = {
    isOpen: false,
    data: null,
};

export const newTagModalSlice = createSlice({
    name: 'new-tag-modal',
    initialState,
    reducers: {
        open(state: NewTagModalState, action: PayloadAction<NewTagModalDispatchParams>) {
            state.isOpen = true;
            state.data = action.payload;
        },
        close(state: NewTagModalState) {
            state.isOpen = false;
            state.data = null;
        },
    },
});
