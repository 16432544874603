import React, { useEffect, useState } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import COLOR from '@CONSTANTS/COLOR.constant';

import { useUpdateSection } from '@SERVICES';
import { AdminSingleSectionData, AdminSingleSectionMeta } from '@INTERFACES/admin-api/sections';

import useDocumentPermissions from '@HOOKS/useDocumentPermissions';

import SectionName from '@PAGES/admin/edit-section/components/SectionName';

import RoundedCheckbox from '@COMPONENTS/COMMON/inputs/checkbox/RoundedCheckbox';
import BasicButton from '@COMPONENTS/COMMON/buttons/BasicButton';

import styles from './PageHeader.module.scss';

const cx: CX = classnames.bind(styles);

function PageHeader(props: Props) {
    const { sectionData, sectionMeta } = props;

    const {
        canEditDocument,
    } = useDocumentPermissions({
        documentOwnerId: sectionData.document.owner,
        permissions: sectionMeta.permissions,
    });

    const [
        updateSection,
        {
            isLoading, isSuccess, isError, error,
        },
    ] = useUpdateSection();

    const [isPageBreak, setPageBreak] = useState<boolean>(sectionData.isPageBreak);
    const [isLandscape, setLandscape] = useState<boolean>(sectionData.isLandscape);

    useEffect(() => {
        if (isSuccess) {
            notify.info('Section has been updated', {
                toastId: 'update-section-params',
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            if ((error as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'update-section-params-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'update-section-params-error',
                });
            }
        }
    }, [isError, error]);

    const shouldShowSaveButton = (
        sectionData.isLandscape !== isLandscape
        || sectionData.isPageBreak !== isPageBreak
    );

    return (
        <div className={cx('page-header')}>
            <SectionName
                sectionData={sectionData}
                sectionMeta={sectionMeta}
            />
            <div className={cx('section-options', 'container')}>
                <div className={cx('options')}>
                    <div className={cx('option')}>
                        <div className={cx('checkbox-title')}>
                            Page break
                        </div>
                        <RoundedCheckbox
                            color={COLOR['app-green']}
                            checked={isPageBreak}
                            onChange={() => {
                                setPageBreak((val) => !val);
                            }}
                        />
                    </div>

                    <div className={cx('option')}>
                        <div className={cx('checkbox-title')}>
                            Landscape
                        </div>
                        <RoundedCheckbox
                            color={COLOR['app-green']}
                            checked={isLandscape}
                            onChange={() => {
                                setLandscape((val) => !val);
                            }}
                        />
                    </div>
                </div>

                {
                    shouldShowSaveButton
                    && (
                        <div className={cx('save-button-wrapper')}>
                            <BasicButton
                                locked={!canEditDocument}
                                title="Save"
                                isProcessing={isLoading}
                                style={{
                                    height: 32,
                                    width: 110,
                                    fontSize: 12,
                                    backgroundColor: COLOR['app-green'],
                                }}
                                onClick={() => {
                                    updateSection({
                                        id: sectionData.id,
                                        chapterId: sectionData.chapter.id,
                                        documentId: sectionData.document.id,
                                        name: sectionData.name,
                                        body: sectionData.baseText.body,
                                        isMandatory: sectionData.isMandatory,
                                        isPageBreak,
                                        isLandscape,
                                    });
                                }}
                            />
                        </div>
                    )
                }
            </div>
        </div>
    );
}

type Props = {
    sectionData: AdminSingleSectionData,
    sectionMeta: AdminSingleSectionMeta,
};

export default PageHeader;
