/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export interface NewVendorModalState {
    isOpen: boolean;
}

const initialState: NewVendorModalState = {
    isOpen: false,
};

export const newVendorModalSlice = createSlice({
    name: 'new-vendor-modal',
    initialState,
    reducers: {
        open(state: NewVendorModalState) {
            state.isOpen = true;
        },
        close(state: NewVendorModalState) {
            state.isOpen = false;
        },
    },
});
