import React from 'react';

import useDocumentNameModal from '@HOOKS/store/modals/useDocumentNameModal';
import useChapterNameModal from '@HOOKS/store/modals/useChapterNameModal';
import useSectionNameModal from '@HOOKS/store/modals/useSectionNameModal';
import useTagNameModal from '@HOOKS/store/modals/useTagNameModal';

import useNewDocumentModal from '@HOOKS/store/modals/useNewDocumentModal';
import useNewChapterModal from '@HOOKS/store/modals/useNewChapterModal';
import useNewSectionModal from '@HOOKS/store/modals/useNewSectionModal';
import useNewVariantModal from '@HOOKS/store/modals/useNewVariantModal';
import useNewTagModal from '@HOOKS/store/modals/useNewTagModal';
import useNewTagInChapterModal from '@HOOKS/store/modals/useNewTagInChapterModal';
import useNewTagInSectionModal from '@HOOKS/store/modals/useNewTagInSectionModal';
import useNewTagInVariantModal from '@HOOKS/store/modals/useNewTagInVariantModal';
import useNewVendorModal from '@HOOKS/store/modals/useNewVendorModal';

import useDeleteDocumentModal from '@HOOKS/store/modals/useDeleteDocumentModal';
import useDeleteChapterModal from '@HOOKS/store/modals/useDeleteChapterModal';
import useDeleteSectionModal from '@HOOKS/store/modals/useDeleteSectionModal';
import useDeleteVariantModal from '@HOOKS/store/modals/useDeleteVariantModal';
import useDeleteVendorModal from '@HOOKS/store/modals/useDeleteVendorModal';
import useDeleteTagModal from '@HOOKS/store/modals/useDeleteTagModal';

import usePublishDocumentModal from '@HOOKS/store/modals/usePublishDocumentModal';
import usePublishChapterModal from '@HOOKS/store/modals/usePublishChapterModal';
import usePublishChapterIntroductionTextModal from '@HOOKS/store/modals/usePublishChapterIntroductionTextModal';
import usePublishSectionModal from '@HOOKS/store/modals/usePublishSectionModal';
import usePublishSectionBaseTextModal from '@HOOKS/store/modals/usePublishSectionBaseTextModal';
import usePublishVariantModal from '@HOOKS/store/modals/usePublishVariantModal';

import usePreviewDocumentModal from '@HOOKS/store/modals/usePreviewDocumentModal';
import usePreviewChapterModal from '@HOOKS/store/modals/usePreviewChapterModal';
import usePreviewChapterIntroductionTextModal from '@HOOKS/store/modals/usePreviewChapterIntroductionTextModal';
import usePreviewSectionModal from '@HOOKS/store/modals/usePreviewSectionModal';
import usePreviewSectionBaseTextModal from '@HOOKS/store/modals/usePreviewSectionBaseTextModal';
import usePreviewVariantModal from '@HOOKS/store/modals/usePreviewVariantModal';

import useChapterIntroductionTextHistoryModal from '@HOOKS/store/modals/useChapterIntroductionTextHistoryModal';
import useSectionHistoryModal from '@HOOKS/store/modals/useSectionHistoryModal';
import useSectionBaseTextHistoryModal from '@HOOKS/store/modals/useSectionBaseTextHistoryModal';
import useVariantHistoryModal from '@HOOKS/store/modals/useVariantHistoryModal';

import DocumentNameModal from '@MODALS/DocumentNameModal';
import ChapterNameModal from '@MODALS/ChapterNameModal';
import SectionNameModal from '@MODALS/SectionNameModal';
import TagNameModal from '@MODALS/Tags/TagNameModal';

import NewDocumentModal from '@MODALS/NewDocumentModal';
import NewChapterModal from '@MODALS/NewChapterModal';
import NewSectionModal from '@MODALS/NewSectionModal';
import NewVariantModal from '@MODALS/NewVariantModal';
import NewTagModal from '@MODALS/Tags/NewTagModal';
import NewTagInChapterModal from '@MODALS/Tags/NewTagInChapterModal';
import NewTagInSectionModal from '@MODALS/Tags/NewTagInSectionModal';
import NewTagInVariantModal from '@MODALS/Tags/NewTagInVariantModal';
import NewVendorModal from '@MODALS/NewVendorModal';

import DeleteDocumentModal from '@MODALS/DeleteDocumentModal';
import DeleteChapterModal from '@MODALS/DeleteChapterModal';
import DeleteSectionModal from '@MODALS/DeleteSectionModal';
import DeleteVariantModal from '@MODALS/DeleteVariantModal';
import DeleteVendorModal from '@MODALS/DeleteVendorModal';
import DeleteTagModal from '@MODALS/Tags/DeleteTagModal';

import PublishDocumentModal from '@MODALS/Publications/PublishDocumentModal';
import PublishChapterModal from '@MODALS/Publications/PublishChapterModal';
import PublishChapterIntroductionTextModal from '@MODALS/Publications/PublishChapterIntroductionTextModal';
import PublishSectionModal from '@MODALS/Publications/PublishSectionModal';
import PublishSectionBaseTextModal from '@MODALS/Publications/PublishSectionBaseTextModal';
import PublishVariantModal from '@MODALS/Publications/PublishVariantModal';

import ChapterIntroductionTextHistoryModal from '@MODALS/History/ChapterIntroductionTextHistoryModal';
import SectionHistoryModal from '@MODALS/History/SectionHistoryModal';
import SectionBaseTextHistoryModal from '@MODALS/History/SectionBaseTextHistoryModal';
import VariantHistoryModal from '@MODALS/History/VariantHistoryModal';

import PreviewDocumentModal from '@MODALS/Previews/PreviewDocumentModal';
import PreviewChapterModal from '@MODALS/Previews/PreviewChapterModal';
import PreviewChapterIntroductionTextModal from '@MODALS/Previews/PreviewChapterIntroductionTextModal';
import PreviewSectionModal from '@MODALS/Previews/PreviewSectionModal';
import PreviewSectionBaseTextModal from '@MODALS/Previews/PreviewSectionBaseTextModal';
import PreviewVariantModal from '@MODALS/Previews/PreviewVariantModal';

function ModalsContainer() {
    const { state: { isOpen: isDocumentNameOpen } } = useDocumentNameModal();
    const { state: { isOpen: isChapterNameOpen } } = useChapterNameModal();
    const { state: { isOpen: isSectionNameOpen } } = useSectionNameModal();
    const { state: { isOpen: isTagNameOpen } } = useTagNameModal();

    const { state: { isOpen: isNewDocumentOpen } } = useNewDocumentModal();
    const { state: { isOpen: isNewChapterOpen } } = useNewChapterModal();
    const { state: { isOpen: isNewSectionOpen } } = useNewSectionModal();
    const { state: { isOpen: isNewVariantOpen } } = useNewVariantModal();
    const { state: { isOpen: isNewTagOpen } } = useNewTagModal();
    const { state: { isOpen: isNewTagInChapterOpen } } = useNewTagInChapterModal();
    const { state: { isOpen: isNewTagInSectionOpen } } = useNewTagInSectionModal();
    const { state: { isOpen: isNewTagInVariantOpen } } = useNewTagInVariantModal();
    const { state: { isOpen: isNewVendorOpen } } = useNewVendorModal();

    const { state: { isOpen: isDeleteDocumentOpen } } = useDeleteDocumentModal();
    const { state: { isOpen: isDeleteChapterOpen } } = useDeleteChapterModal();
    const { state: { isOpen: isDeleteSectionOpen } } = useDeleteSectionModal();
    const { state: { isOpen: isDeleteVariantOpen } } = useDeleteVariantModal();
    const { state: { isOpen: isDeleteVendorOpen } } = useDeleteVendorModal();
    const { state: { isOpen: isDeleteTagOpen } } = useDeleteTagModal();

    const { state: { isOpen: isPublishDocumentOpen } } = usePublishDocumentModal();
    const { state: { isOpen: isPublishChapterOpen } } = usePublishChapterModal();
    const { state: { isOpen: isPublishChapterIntroductionTextOpen } } = usePublishChapterIntroductionTextModal();
    const { state: { isOpen: isPublishSectionOpen } } = usePublishSectionModal();
    const { state: { isOpen: isPublishSectionBaseTextOpen } } = usePublishSectionBaseTextModal();
    const { state: { isOpen: isPublishVariantOpen } } = usePublishVariantModal();

    const { state: { isOpen: isPreviewDocumentOpen } } = usePreviewDocumentModal();
    const { state: { isOpen: isPreviewChapterOpen } } = usePreviewChapterModal();
    const { state: { isOpen: isPreviewChapterIntroductionTextOpen } } = usePreviewChapterIntroductionTextModal();
    const { state: { isOpen: isPreviewSectionOpen } } = usePreviewSectionModal();
    const { state: { isOpen: isPreviewSectionBaseTextOpen } } = usePreviewSectionBaseTextModal();
    const { state: { isOpen: isPreviewVariantOpen } } = usePreviewVariantModal();

    const { state: { isOpen: isChapterIntroductionTextHistoryOpen } } = useChapterIntroductionTextHistoryModal();
    const { state: { isOpen: isSectionHistoryOpen } } = useSectionHistoryModal();
    const { state: { isOpen: isSectionBaseTextHistoryOpen } } = useSectionBaseTextHistoryModal();
    const { state: { isOpen: isVariantHistoryOpen } } = useVariantHistoryModal();

    return (
        <>
            {isDocumentNameOpen && <DocumentNameModal />}
            {isChapterNameOpen && <ChapterNameModal />}
            {isSectionNameOpen && <SectionNameModal />}
            {isTagNameOpen && <TagNameModal />}

            {isNewDocumentOpen && <NewDocumentModal />}
            {isNewChapterOpen && <NewChapterModal />}
            {isNewSectionOpen && <NewSectionModal />}
            {isNewVariantOpen && <NewVariantModal />}
            {isNewTagOpen && <NewTagModal />}
            {isNewTagInChapterOpen && <NewTagInChapterModal />}
            {isNewTagInSectionOpen && <NewTagInSectionModal />}
            {isNewTagInVariantOpen && <NewTagInVariantModal />}
            {isNewVendorOpen && <NewVendorModal />}

            {isDeleteDocumentOpen && <DeleteDocumentModal />}
            {isDeleteChapterOpen && <DeleteChapterModal />}
            {isDeleteSectionOpen && <DeleteSectionModal />}
            {isDeleteVariantOpen && <DeleteVariantModal />}
            {isDeleteVendorOpen && <DeleteVendorModal />}
            {isDeleteTagOpen && <DeleteTagModal />}

            {isPublishDocumentOpen && <PublishDocumentModal />}
            {isPublishChapterOpen && <PublishChapterModal />}
            {isPublishChapterIntroductionTextOpen && <PublishChapterIntroductionTextModal />}
            {isPublishSectionOpen && <PublishSectionModal />}
            {isPublishSectionBaseTextOpen && <PublishSectionBaseTextModal />}
            {isPublishVariantOpen && <PublishVariantModal />}

            {isPreviewDocumentOpen && <PreviewDocumentModal />}
            {isPreviewChapterOpen && <PreviewChapterModal />}
            {isPreviewChapterIntroductionTextOpen && <PreviewChapterIntroductionTextModal />}
            {isPreviewSectionOpen && <PreviewSectionModal />}
            {isPreviewSectionBaseTextOpen && <PreviewSectionBaseTextModal />}
            {isPreviewVariantOpen && <PreviewVariantModal />}

            {isChapterIntroductionTextHistoryOpen && <ChapterIntroductionTextHistoryModal />}
            {isSectionHistoryOpen && <SectionHistoryModal />}
            {isSectionBaseTextHistoryOpen && <SectionBaseTextHistoryModal />}
            {isVariantHistoryOpen && <VariantHistoryModal />}
        </>
    );
}

export default ModalsContainer;
