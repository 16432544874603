/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminSingleChapterData } from '@INTERFACES/admin-api/chapters';

export interface ChapterNameModalState {
    isOpen: boolean;

    data: {
        chapterData: AdminSingleChapterData;
    } | null;
}

export interface ChapterNameModalDispatchParams {
    chapterData: AdminSingleChapterData;
}

const initialState: ChapterNameModalState = {
    isOpen: false,
    data: null,
};

export const chapterNameModalSlice = createSlice({
    name: 'chapter-name-modal',
    initialState,
    reducers: {
        open(state: ChapterNameModalState, action: PayloadAction<ChapterNameModalDispatchParams>) {
            state.isOpen = true;
            state.data = action.payload;
        },
        close(state: ChapterNameModalState) {
            state.isOpen = false;
            state.data = null;
        },
    },
});
