/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DeleteDocumentModalState {
    isOpen: boolean;

    data: {
        id: number;

        name: string;
    } | null;
}

export interface DeleteDocumentModalDispatchParams {
    id: number;

    name: string;
}

const initialState: DeleteDocumentModalState = {
    isOpen: false,
    data: null,
};

export const deleteDocumentModalSlice = createSlice({
    name: 'delete-document-modal',
    initialState,
    reducers: {
        open(state: DeleteDocumentModalState, action: PayloadAction<DeleteDocumentModalDispatchParams>) {
            state.isOpen = true;
            state.data = action.payload;
        },
        close(state: DeleteDocumentModalState) {
            state.isOpen = false;
            state.data = null;
        },
    },
});
