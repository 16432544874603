import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ADMIN_API_PREFIX, BASE_URL } from '@CONSTANTS/API.constant';
import { DOCUMENT_PERMISSIONS } from '@CONSTANTS/PERMISSIONS.constant';

import userRepository from '@REPOSITORIES/local-repository/user-repository';

import { chaptersAdminApi } from './chapters.admin-api';
import invalidateData from '../helpers/invalidateData';
import { sectionsAdminApi } from './sections.admin-api';

interface GetAllAdminApiResponse {
    data: {
        id: number;

        name: string;

        owner: {
            id: number;

            full_name: string;

            email: string;
        };

        description: string;

        is_published: boolean;

        is_visible: boolean;

        cover: string | null;

        chapters: {
            id: number;

            name: string;

            order: number;
        }[];
    };

    meta: {
        permissions: DOCUMENT_PERMISSIONS[];
    };
}

interface GetByIdAdminApiResponse {
    data: {
        id: number;

        name: string;

        owner: {
            id: number;

            full_name: string;

            email: string;
        };

        description: string;

        is_published: boolean;

        is_visible: boolean;

        cover: string | null;

        chapters: {
            id: number;

            name: string;

            order: number;

            is_visible: boolean;

            introduction_text: {
                is_published: boolean;
            };

            sections: {
                id: number;

                name: string;

                base_text: {
                    is_published: boolean;
                };

                variants: {
                    id: number;

                    is_published: boolean;
                }[];
            }[];
        }[];

        users: {
            id: number;

            full_name: string;

            email: string;

            permissions: {
                id: number;

                name: string;

                codename: DOCUMENT_PERMISSIONS;
            }[];
        }[];
    };

    meta: {
        documents: {
            id: number;

            name: string;
        }[];

        permissions: DOCUMENT_PERMISSIONS[];
    };
}

type UpdatePermissionsApiResponse = {
    id: number;

    full_name: string;

    email: string;

    permissions: {
        id: number;

        name: string;

        codename: DOCUMENT_PERMISSIONS;
    }[]
};

export type AdminSingleDocumentData = {
    id: number,

    name: string,

    owner: AdminDocumentDataOwner;

    description: string,

    isPublished: boolean,

    isVisible: boolean,

    cover: string | null,

    chapters: AdminSingleDocumentChapter[];

    users: AdminSingleDocumentDataUser[];
};

export type AdminSingleDocumentMetaDocument = {
    id: number;

    name: string;
};

export type AdminSingleDocumentDataUser = {
    id: number;

    fullName: string;

    email: string;

    permissions: AdminSingleDocumentDataUserPermission[];
};

export type AdminSingleDocumentDataUserPermission = {
    id: number;

    name: string;

    codename: DOCUMENT_PERMISSIONS;
};

export type AdminSingleDocumentMeta = {
    documents: AdminSingleDocumentMetaDocument[];

    permissions: DOCUMENT_PERMISSIONS[];
};

export type AdminSingleDocument = {
    data: AdminSingleDocumentData,
    meta: AdminSingleDocumentMeta,
};

export type AdminDocumentListItem = {
    data: {
        id: number,

        name: string,

        owner: AdminDocumentDataOwner,

        isVisible: boolean,

        cover: string | null,

        chapters: AdminDocumentListItemChapter[];
    };

    meta: {
        permissions: DOCUMENT_PERMISSIONS[];
    }
};

type AdminDocumentDataOwner = {
    id: number;

    fullName: string;

    email: string;
};

export type AdminSingleDocumentChapter = {
    id: number;

    name: string;

    order: number;

    isVisible: boolean;

    introductionText: {
        isPublished: boolean;
    };

    sections: AdminSingleDocumentChapterSection[];
};

export type AdminDocumentListItemChapter = {
    id: number;

    name: string;
};

export type AdminSingleDocumentChapterSection = {
    id: number;

    name: string;

    baseText: {
        isPublished: boolean;
    };

    variants: AdminSingleDocumentChapterSectionVariant[];
};

export type AdminSingleDocumentChapterSectionVariant = {
    id: number;

    isPublished: boolean;
};

function transformGetAllResponse(raw: GetAllAdminApiResponse[]): AdminDocumentListItem[] {
    return raw
        .sort((a, b) => (a.data.name > b.data.name ? 1 : -1))
        .map((document) => ({
            data: {
                id: document.data.id,
                name: document.data.name,
                owner: {
                    id: document.data.owner.id,
                    fullName: document.data.owner.full_name,
                    email: document.data.owner.email,
                },
                description: document.data.description,
                isPublished: document.data.is_published,
                isVisible: document.data.is_visible,
                cover: document.data.cover,
                chapters: document.data.chapters.map((chapter) => ({
                    id: chapter.id,
                    name: chapter.name,
                    order: chapter.order,
                })),
            },
            meta: {
                permissions: document.meta.permissions,
            },
        }));
}

function transformGetByIdResponse(raw: GetByIdAdminApiResponse): AdminSingleDocument {
    const data = {
        id: raw.data.id,
        description: raw.data.description,
        name: raw.data.name,
        owner: {
            id: raw.data.owner.id,
            fullName: raw.data.owner.full_name,
            email: raw.data.owner.email,
        },
        isPublished: raw.data.is_published,
        isVisible: raw.data.is_visible,
        cover: raw.data.cover,
        chapters: raw.data.chapters.map((chapter) => ({
            id: chapter.id,
            name: chapter.name,
            order: chapter.order,
            isVisible: chapter.is_visible,
            introductionText: {
                isPublished: chapter.introduction_text.is_published,
            },
            sections: chapter.sections.map((section) => ({
                id: section.id,
                name: section.name,
                baseText: {
                    isPublished: section.base_text.is_published,
                },
                variants: section.variants.map((variant) => ({
                    id: variant.id,
                    isPublished: variant.is_published,
                })),
            })),
        })),
        users: raw.data.users.map((user) => ({
            id: user.id,
            fullName: user.full_name,
            email: user.email,
            permissions: user.permissions,
        })),
    };

    return {
        data,
        meta: {
            documents: raw.meta.documents.map((doc) => ({
                id: doc.id,
                name: doc.name,
            })),
            permissions: raw.meta.permissions,
        },
    };
}

type CreateParams = {
    userId: number;

    name: string;
};

interface CreateApiResponse {
    data: {
        id: number;

        name: string;

        owner: {
            id: number;

            full_name: string;

            email: string;
        };

        is_visible: boolean;

        cover: string | null;

        chapters: [];
    };

    meta: {
        permissions: DOCUMENT_PERMISSIONS[];
    };
}

type UpdateParams = {
    id: number;

    name: string;

    cover: Blob | string | null;
};

type DeleteParams = {
    id: number;
};

type CopyParams = {
    id: number;
};

type UpdatePermissionsParams = {
    id: number;

    usersPermissions: {
        userId: number;

        permissions: DOCUMENT_PERMISSIONS[];
    }[];
};

interface CopyApiResponse {
    id: number;

    name: string;

    owner: {
        id: number;

        full_name: string;

        email: string;
    };

    is_visible: boolean;

    cover: string | null;

    chapters: {
        id: number;

        name: string;
    }[];
}

export const documentsAdminApi = createApi({
    reducerPath: 'documents-admin-api',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + ADMIN_API_PREFIX,
        prepareHeaders: (headers) => {
            const token = userRepository.getData();

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    tagTypes: ['Admin-Document'],
    endpoints: (builder) => ({
        getAll: builder.query<AdminDocumentListItem[], void>({
            query: () => '/documents/',
            transformResponse(raw: GetAllAdminApiResponse[]) {
                return transformGetAllResponse(raw);
            },
        }),
        getById: builder.query<AdminSingleDocument, number>({
            query: (id: number) => `/documents/${id}/`,
            transformResponse(raw: GetByIdAdminApiResponse) {
                return transformGetByIdResponse(raw);
            },
            providesTags: (result) => (
                result ? [{
                    type: 'Admin-Document' as const,
                    id: result.data.id,
                }] : [{
                    type: 'Admin-Document' as const,
                    id: 'LIST',
                }]
            ),
        }),
        create: builder.mutation<CreateApiResponse, CreateParams>({
            query: (params) => {
                const formData = new FormData();

                formData.append('owner', params.userId.toString());
                formData.append('name', params.name);

                return {
                    url: '/documents/',
                    method: 'POST',
                    body: formData,
                };
            },
            async onQueryStarted({ name }, { dispatch, queryFulfilled }) {
                try {
                    const { data: { data: queryData } } = await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    // ADD NEW DOCUMENT TO DOCUMENTS LIST
                    const documentsListPatch = dispatch(
                        documentsAdminApi.util.updateQueryData('getAll', undefined, (draft) => [...draft, {
                            data: {
                                id: queryData.id,
                                name,
                                owner: {
                                    id: queryData.owner.id,
                                    fullName: queryData.owner.full_name,
                                    email: queryData.owner.email,
                                },
                                isVisible: queryData.is_visible,
                                cover: queryData.cover,
                                chapters: queryData.chapters,
                            },
                            meta: {
                                permissions: [],
                            },
                        }].sort((a, b) => (a.data.name > b.data.name ? 1 : -1))),
                    );

                    const data = documentsListPatch.patches[0]?.value;

                    if (data) {
                        // ADD NEW DOCUMENT TO ALL SINGLE DOCUMENTS META
                        data.forEach((doc: AdminDocumentListItem) => {
                            dispatch(
                                documentsAdminApi.util.updateQueryData('getById', doc.data.id, (draft) => ({
                                    data: draft.data,
                                    meta: {
                                        ...draft.meta,
                                        documents: [...draft.meta.documents, {
                                            id: queryData.id,
                                            name: queryData.name,
                                        }],
                                    },
                                })),
                            );
                        });
                    }
                } catch (e) {
                    //
                }
            },
        }),
        update: builder.mutation({
            query: (params: UpdateParams) => {
                const formData = new FormData();

                formData.append('name', params.name);

                if (params.cover || params.cover === null) {
                    formData.append('cover', params.cover || '');
                }

                return {
                    url: `/documents/${params.id}/`,
                    method: 'PATCH',
                    body: formData,
                };
            },
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                try {
                    const { data: queryData } = await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    // UPDATE DOCUMENT IN DOCUMENTS LIST
                    dispatch(
                        documentsAdminApi.util.updateQueryData('getAll', undefined, (draft) => draft.map((doc) => {
                            if (doc.data.id === id) {
                                return {
                                    data: {
                                        ...doc.data,
                                        name: queryData.data.name,
                                    },
                                    meta: doc.meta,
                                };
                            }

                            return doc;
                        }).sort((a, b) => (a.data.name > b.data.name ? 1 : -1))),
                    );

                    // UPDATE DOCUMENT IN CURRENT SINGLE DOCUMENT
                    const documentPatch = dispatch(
                        documentsAdminApi.util.updateQueryData('getById', id, (draft) => ({
                            data: {
                                ...draft.data,
                                name: queryData.data.name,
                                cover: queryData.data.cover,
                            },
                            meta: draft.meta,
                        })),
                    );

                    const data = documentPatch.patches[0]?.value;

                    if (data) {
                        // UPDATE DOCUMENT IN EVERY SINGLE DOCUMENT META
                        data.meta.documents.forEach((doc: AdminSingleDocumentMetaDocument) => {
                            dispatch(
                                documentsAdminApi.util.updateQueryData('getById', doc.id, (draft) => ({
                                    data: draft.data,
                                    meta: {
                                        ...draft.meta,
                                        documents: draft.meta.documents.map((d) => {
                                            if (d.id === id) {
                                                return {
                                                    ...d,
                                                    name: queryData.data.name,
                                                };
                                            }

                                            return d;
                                        }).sort((a, b) => a.id - b.id),
                                    },
                                })),
                            );
                        });

                        // UPDATE DOCUMENT IN ALL DOCUMENT CHAPTERS
                        data.data.chapters.forEach((chapter: AdminSingleDocumentChapter) => {
                            const { sections } = chapter;

                            dispatch(
                                chaptersAdminApi.util.updateQueryData('getById', chapter.id, (draft) => ({
                                    data: {
                                        ...draft.data,
                                        document: {
                                            ...draft.data.document,
                                            name: queryData.data.name,
                                        },
                                    },
                                    meta: draft.meta,
                                })),
                            );

                            // UPDATE DOCUMENT IN CHAPTER SECTIONS
                            sections.forEach((section: AdminSingleDocumentChapterSection) => {
                                dispatch(
                                    sectionsAdminApi.util.updateQueryData('getById', section.id, (draft) => ({
                                        data: {
                                            ...draft.data,
                                            document: {
                                                ...draft.data.document,
                                                name: queryData.data.name,
                                            },
                                        },
                                        meta: draft.meta,
                                    })),
                                );
                            });
                        });
                    }
                } catch (e) {
                    //
                }
            },
        }),
        delete: builder.mutation(({
            query: (params: DeleteParams) => ({
                url: `/documents/${params.id}/`,
                method: 'DELETE',
            }),
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    dispatch(
                        chaptersAdminApi.util.resetApiState(),
                    );

                    dispatch(
                        sectionsAdminApi.util.resetApiState(),
                    );

                    // DELETE DOCUMENT FROM DOCUMENTS LIST
                    const documentsPatch = dispatch(
                        documentsAdminApi.util.updateQueryData('getAll', undefined, (draft) => draft.filter((doc) => doc.data.id !== id)),
                    );

                    const data = documentsPatch.patches[0].value;

                    if (data) {
                        // DELETE DOCUMENT FROM EVERY SINGLE DOCUMENT META
                        data.forEach((doc: AdminDocumentListItem) => {
                            dispatch(
                                documentsAdminApi.util.updateQueryData('getById', doc.data.id, (draft) => ({
                                    data: draft.data,
                                    meta: {
                                        ...draft.meta,
                                        documents: draft.meta.documents.filter((d) => d.id !== id),
                                    },
                                })),
                            );
                        });
                    }
                } catch (e) {
                    //
                }
            },
            invalidatesTags: (result, error, arg) => [{
                type: 'Admin-Document' as const,
                id: arg.id,
            }],
        })),
        copy: builder.mutation<CopyApiResponse, CopyParams>({
            query: (params) => ({
                url: `/documents/${params.id}/copy/`,
                method: 'POST',
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data: queryData } = await queryFulfilled;

                    // COPY DOCUMENT IN DOCUMENTS LIST
                    const documentsPatch = dispatch(
                        documentsAdminApi.util.updateQueryData('getAll', undefined, (draft) => [...draft, {
                            data: {
                                id: queryData.id,
                                name: queryData.name,
                                owner: {
                                    id: queryData.owner.id,
                                    fullName: queryData.owner.full_name,
                                    email: queryData.owner.email,
                                },
                                isVisible: queryData.is_visible,
                                cover: queryData.cover,
                                chapters: queryData.chapters.map((chapter) => ({
                                    id: chapter.id,
                                    name: chapter.name,
                                })),
                            },
                            meta: {
                                // TODO get permissions
                                permissions: [],
                            },
                        }].sort((a, b) => (a.data.name > b.data.name ? 1 : -1))),
                    );

                    const data = documentsPatch.patches[0].value;

                    if (data) {
                        // COPY DOCUMENT IN EVERY SINGLE DOCUMENT META
                        data.forEach((doc: AdminDocumentListItem) => {
                            dispatch(
                                documentsAdminApi.util.updateQueryData('getById', doc.data.id, (draft) => ({
                                    data: draft.data,
                                    meta: {
                                        ...draft.meta,
                                        documents: [...draft.meta.documents, {
                                            id: queryData.id,
                                            name: queryData.name,
                                        }],
                                    },
                                })),
                            );
                        });
                    }
                } catch (e) {
                    //
                }
            },
        }),
        updateDocumentPermissions: builder.mutation<UpdatePermissionsApiResponse[], UpdatePermissionsParams>({
            query: (params) => {
                const formData = JSON.stringify({
                    users_permissions: params.usersPermissions.map((user) => ({
                        user_id: user.userId,
                        permissions: user.permissions,
                    })),
                });

                return ({
                    url: `/documents/${params.id}/permissions/`,
                    method: 'POST',
                    body: formData,
                });
            },
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                const { id } = args;

                try {
                    const { data: queryData } = await queryFulfilled;

                    // UPDATE PERMISSIONS IN SINGLE DOCUMENT
                    dispatch(
                        documentsAdminApi.util.updateQueryData('getById', id, (draft) => ({
                            data: {
                                ...draft.data,
                                users: queryData.map((user) => ({
                                    id: user.id,
                                    fullName: user.full_name,
                                    email: user.email,
                                    permissions: user.permissions,
                                })),
                            },
                            meta: draft.meta,
                        })),
                    );
                } catch (e) {
                    //
                }
            },
        }),
    }),
});

export const {
    useGetAllQuery: useGetAllDocumentsAdmin,
    useGetByIdQuery: useGetDocumentByIdAdmin,
    useLazyGetByIdQuery: useGetDocumentByIdAdminLazy,
    useCreateMutation: useCreateDocument,
    useUpdateMutation: useUpdateDocument,
    useDeleteMutation: useDeleteDocument,
    useCopyMutation: useCopyDocument,
    useUpdateDocumentPermissionsMutation: useUpdateDocumentPermissions,
} = documentsAdminApi;
