import React, { useState } from 'react';

import classnames from 'classnames/bind';

import usePreviewChapterIntroductionTextModal from '@HOOKS/store/modals/usePreviewChapterIntroductionTextModal';
import useDownloadDocumentPreviewWrapper from '@HOOKS/useDownloadDocumentPreviewWrapper';

import BaseModal from '@MODALS/BaseModal';
import ViewMode from '@MODALS/Previews/components/ViewMode';
import DocumentPreviewPage from '@COMPONENTS/SHARED/DocumentPreviewPage';
import GeneratePreviewPDFButton from '@COMPONENTS/COMMON/buttons/GeneratePreviewPDFButton';

import styles from './PreviewChapterIntroductionTextModal.module.scss';

const cx: CX = classnames.bind(styles);

function PreviewChapterIntroductionTextModal() {
    const {
        state: { data: modalParams },
        actions: previewChapterIntroductionTextModalActions,
    } = usePreviewChapterIntroductionTextModal(() => true);

    const [
        downloadDocumentPreview,
        { isLoading },
    ] = useDownloadDocumentPreviewWrapper();

    const { chapterData, canGenerateDocument } = modalParams!;
    const { introductionText } = chapterData!;

    const { isPublished } = introductionText;

    const [showUnpublished, setShowUnpublished] = useState<boolean>(false);

    const baseTextBody = showUnpublished
        ? (introductionText.body || '')
        : (introductionText.versions[introductionText.versions.length - 1]?.body || '');

    function handleClose() {
        previewChapterIntroductionTextModalActions.close();
    }

    return (
        <BaseModal
            style={{
                top: 30,
                height: '100%',
            }}
            onClose={() => handleClose()}
        >
            <div className={cx('preview-chapter-introduction-text-modal')}>
                <div className={cx('header')}>
                    <div className={cx('title-wrapper')}>
                        <div className={cx('title')}>
                            Introduction text preview
                        </div>
                        <ViewMode
                            disabled={isPublished}
                            showUnpublished={showUnpublished}
                            setShowUnpublished={setShowUnpublished}
                        />
                    </div>
                    <div className={cx('pdf-button-wrapper')}>
                        <GeneratePreviewPDFButton
                            isProcessing={isLoading}
                            canGenerateDocument={canGenerateDocument}
                            onClick={() => {
                                downloadDocumentPreview({
                                    document: chapterData.document.id,
                                    variants: [],
                                    sections: [],
                                    chapters: [chapterData.id],
                                    isUnpublished: showUnpublished,
                                });
                            }}
                        />
                    </div>
                    <div className={cx('close-button-wrapper')}>
                        <button
                            type="button"
                            className={cx('clear-button', 'close-button')}
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            &times;
                        </button>
                    </div>
                </div>
                <div className={cx('content')}>
                    <div className={cx('page-wrapper')}>
                        <DocumentPreviewPage
                            key={showUnpublished.toString()}
                            body={baseTextBody}
                            isLandscape={false}
                        />
                    </div>
                </div>
            </div>
        </BaseModal>
    );
}

export default PreviewChapterIntroductionTextModal;
