import React, {
    MutableRefObject, useRef, useState,
} from 'react';

import { SingleValue } from 'react-select';
import classnames from 'classnames/bind';

import {
    AdminSingleChapterData,
    AdminSingleChapterDataIntroductionTextVersion, AdminSingleChapterMeta,
} from '@INTERFACES/admin-api/chapters';

import { isContentEmptyCheck } from '@HELPERS/isContentEmptyCheck';

import IntroductionTextEditor from '@PAGES/admin/edit-chapter/components/IntroductionTextEditor';
import IntroductionTextSidebar from '@PAGES/admin/edit-chapter/components/IntroductionTextSidebar';

import styles from './IntroductionText.module.scss';

const cx: CX = classnames.bind(styles);

function IntroductionText(props: Props) {
    const { chapterData, chapterMeta } = props;

    const { introductionText } = chapterData;

    const editorRef: MutableRefObject<string> = useRef('');
    const [contentIsEmpty, setContentIsEmpty] = useState<boolean>(true);

    const [selectedVersion, setSelectedVersion] = useState<AdminSingleChapterDataIntroductionTextVersion | undefined>(undefined);

    function getEditorContent() {
        return editorRef.current;
    }

    function onVersionChange(val: SingleValue<any>) {
        const version = introductionText.versions.find((v) => v.id === val.value);

        setSelectedVersion(version);
    }

    return (
        <div className={cx('introduction-text', 'container')}>
            <div className={cx('body')}>
                <div className={cx('body-wrapper')}>
                    <div className={cx('editor')}>
                        <div className={cx('editor-wrapper')}>
                            <IntroductionTextEditor
                                initialValue={(selectedVersion ? selectedVersion.body : introductionText.body) || ''}
                                onEditorChange={(content: string) => {
                                    editorRef.current = content;

                                    setContentIsEmpty(isContentEmptyCheck(content));
                                }}
                            />
                        </div>
                    </div>
                    <div className={cx('sidebar-wrapper')}>
                        <IntroductionTextSidebar
                            contentIsEmpty={contentIsEmpty}
                            selectedVersion={selectedVersion}
                            chapterData={chapterData}
                            chapterMeta={chapterMeta}
                            getEditorContent={() => getEditorContent()}
                            onVersionChange={(val: any) => onVersionChange(val)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

type Props = {
    chapterData: AdminSingleChapterData,
    chapterMeta: AdminSingleChapterMeta,
};

export default IntroductionText;
