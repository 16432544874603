import React from 'react';

import { useCreateSection } from '@SERVICES';
import useNewSectionModal from '@HOOKS/store/modals/useNewSectionModal';

import NameInputModal, { FormValues } from '@MODALS/common/NameInputModal';

function NewSectionModal() {
    const {
        state: { data: modalParams },
        actions,
    } = useNewSectionModal();

    const [
        createSection,
        {
            isLoading, isSuccess, isError, error,
        },
    ] = useCreateSection();

    const onSave = (formValues: FormValues) => {
        createSection({
            documentId: modalParams!.documentId,
            chapterId: modalParams!.chapterId,
            name: formValues.name,
        });
    };

    return (
        <NameInputModal
            modalTitle="Create new section"
            itemName="Section"
            inputPlaceholder="Section name"
            successMessage="New section has been created!"
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            error={error}
            onSave={onSave}
            onClose={() => {
                actions.close();
            }}
        />
    );
}

export default NewSectionModal;
