import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_PREFIX, BASE_URL } from '@CONSTANTS/API.constant';
import userRepository from '@REPOSITORIES/local-repository/user-repository';

interface VariantsHistoryApiResponse {
    id: number;
}

export const variantHistoryApi = createApi({
    reducerPath: 'variant-history-api',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + API_PREFIX,
        prepareHeaders: (headers) => {
            const token = userRepository.getData();

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getAll: builder.query<VariantsHistoryApiResponse[], void>({
            query: () => '/variants_history/',
        }),
    }),
});

export const {
    useGetAllQuery: aku,
} = variantHistoryApi;
