import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import classnames from 'classnames/bind';

import GenerationSteps from '@CONSTANTS/GENERATION_STEPS.constant';
import ROUTES from '@CONSTANTS/ROUTES.constant';
import DESIGN from '@CONSTANTS/DESIGN.constant';

import { notify } from '@NOTIFICATION/Notificator';

import {
    AreaStep,
    BrandStep,
    CountryStep,
    DocumentStep,
    OptionsStep,
    RegionStep,
    SelectContentStep,
    TypeStep,
} from '@REDUCERS';

import { useGetContent } from '@SERVICES';
import useStepsValidation from '@VALIDATION/hooks/useStepsValidation';
import useDocumentGenerationSteps from '@HOOKS/store/useDocumentGenerationSteps';

import WideButton from '@COMPONENTS/COMMON/buttons/WideButton';
import SelectContentSidebar from '@COMPONENTS/SIDEBARS/SelectContentSidebar';
import DataLoader from '@COMPONENTS/SHARED/DataLoader';
import ContentWithFullHeightSidebar from '@COMPONENTS/SHARED/ContentWithFullHeightSidebar';

import { generationModes } from '@PAGES/manager/options/Options.page';
import Content from '@PAGES/manager/select-content/components/Content';

// import TEST_WINDOW from '@PAGES/manager/select-content/components/TEST_WINDOW/TEST_WINDOW';

import styles from './SelectContent.module.scss';

const cx: CX = classnames.bind(styles);

const top = DESIGN['subheader-height'] + DESIGN['step-breadcrumbs-height'];

const defaultData = {
    data: [],
    meta: {
        tags: [],
    },
};

function SelectContentPage() {
    const {
        services: documentGenerationStepsServices,
    } = useDocumentGenerationSteps();

    const documentStep: DocumentStep = documentGenerationStepsServices.getStepData(GenerationSteps.DOCUMENT);
    const brandStep: BrandStep = documentGenerationStepsServices.getStepData(GenerationSteps.BRAND);
    const regionStep: RegionStep = documentGenerationStepsServices.getStepData(GenerationSteps.REGION);
    const areaStep: AreaStep = documentGenerationStepsServices.getStepData(GenerationSteps.AREA);
    const countryStep: CountryStep = documentGenerationStepsServices.getStepData(GenerationSteps.COUNTRY);
    const optionsStep: OptionsStep = documentGenerationStepsServices.getStepData(GenerationSteps.OPTIONS);
    const typeStep: TypeStep = documentGenerationStepsServices.getStepData(GenerationSteps.TYPE);
    const selectContentStep: SelectContentStep = documentGenerationStepsServices.getStepData(GenerationSteps.SELECT_CONTENT);

    const {
        data: contentData = defaultData, isLoading, isError, error,
    } = useGetContent({
        document: documentStep.data?.id,
        brand: brandStep.data?.id,
        region: regionStep.data?.id,
        area: areaStep.data?.id,
        country: countryStep.data?.id,
        type: typeStep.data?.id,
    });

    const {
        data: chaptersData = [],
        meta: {
            tags,
        },
    } = contentData;

    const canActivateStep = useStepsValidation();

    const navigate = useNavigate();

    const tagsIds = selectContentStep.data?.tagsIds || [];

    const [isOnlyRequiredSections, setOnlyRequiredSections] = useState<boolean>(false);
    const [selectedTagsIds, setSelectedTagsIds] = useState<Set<number>>(new Set(tagsIds));

    const [searchValue, setSearchValue] = useState<string>('');

    const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');

    // DEBOUNCE INPUT
    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedSearchValue(searchValue);
        }, 250);

        return () => {
            clearTimeout(timer);
        };
    }, [searchValue]);

    return (
        <div className={cx('page')}>
            <div className="max-constraint center">
                <ContentWithFullHeightSidebar
                    sidebar={(
                        <SelectContentSidebar
                            isOnlyRequiredSections={isOnlyRequiredSections}
                            setOnlyRequiredSections={setOnlyRequiredSections}
                            tags={tags}
                            isTagsLoading={isLoading}
                            selectedTagsIds={selectedTagsIds}
                            setSelectedTagsIds={setSelectedTagsIds}
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                        />
                    )}
                    top={top}
                >
                    <DataLoader
                        isLoading={isLoading}
                        isError={isError}
                        error={error}
                    >
                        <h1 className="container">Select content</h1>
                        <div className={cx('document', 'container')}>
                            <div className={cx('document-title')}>
                                Document
                            </div>
                            <div className={cx('document-info')}>
                                <div className={cx('document-name')}>
                                    {documentStep.data?.name}
                                </div>
                                <div className={cx('document-extraction')}>
                                    {generationModes.find((m) => m.id === optionsStep.data.id)!.name}
                                </div>
                            </div>
                        </div>
                        <Content
                            chaptersData={chaptersData}
                            isOnlyRequiredSections={isOnlyRequiredSections}
                            selectedTagsIds={selectedTagsIds}
                            searchValue={debouncedSearchValue}
                        />
                    </DataLoader>
                </ContentWithFullHeightSidebar>
            </div>

            <WideButton
                withSidebar
                title="Next step"
                onClick={() => {
                    const { canActivate } = canActivateStep(GenerationSteps.PREVIEW);

                    const variantsIds = selectContentStep.data?.variantsIds || [];
                    const sectionsIds = selectContentStep.data?.sectionsIds || [];
                    const chaptersIds = selectContentStep.data?.chaptersIds || [];

                    const anyChapterHasBody = chaptersData.filter(
                        (chapter) => chaptersIds.includes(chapter.id),
                    ).some((chapter) => chapter.hasBody);

                    const anySectionHasBody = chaptersData.filter(
                        (chapter) => chaptersIds.includes(chapter.id),
                    ).some((chapter) => chapter.sections.filter(
                        (section) => sectionsIds.includes(section.id),
                    ).some(
                        (section) => section.hasBody,
                    ));

                    if (canActivate && (variantsIds.length > 0 || anySectionHasBody || anyChapterHasBody)) {
                        navigate(ROUTES.preview.path);
                    } else {
                        notify.error('No sections or chapters selected');
                    }
                }}
            />

            {/* <TEST_WINDOW /> */}
        </div>
    );
}

export default SelectContentPage;
