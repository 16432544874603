import React, { useEffect, useMemo, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { AdminSingleChapterData, AdminSingleChapterMeta } from '@INTERFACES/admin-api/chapters';

import useDocumentPermissions from '@HOOKS/useDocumentPermissions';
import usePublishChapterModal from '@HOOKS/store/modals/usePublishChapterModal';

import PublishAllButton from '@COMPONENTS/COMMON/buttons/PublishAllButton';

import styles from './FloatingPublishButton.module.scss';

const cx: CX = classnames.bind(styles);

function FloatingPublishButton(props: Props) {
    const {
        chapterData,
        chapterMeta,
        top,
        isIntroductionOpen,
    } = props;

    const {
        canPublishDocument,
    } = useDocumentPermissions({
        documentOwnerId: chapterData.document.owner,
        permissions: chapterMeta.permissions,
    });

    const {
        actions: publishChapterModalActions,
    } = usePublishChapterModal(() => true);

    const [toRight, setToRight] = useState<boolean>(false);

    const isPublished = useMemo(
        () => {
            const { introductionText, sections } = chapterData;

            return (
                introductionText.isPublished
                && sections.every((section) => {
                    const { baseText, variants } = section;

                    return (
                        baseText.isPublished
                        && variants.every((v) => v.isPublished)
                    );
                })
            );
        },
        [chapterData],
    );

    useEffect(() => {
        const element = document.getElementById('sections-sticky-header');

        function onScroll() {
            if (element) {
                const box = element.getBoundingClientRect();

                const passed = box.top <= top + 2;

                if (passed) {
                    setToRight(true);
                } else {
                    setToRight(false);
                }
            }
        }

        onScroll();

        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, [top, isIntroductionOpen]);

    useEffect(() => {
        const orderSectionsButtonElement = document.getElementById('order-sections-button-wrapper');

        if (!orderSectionsButtonElement) return;

        if (toRight) {
            orderSectionsButtonElement.style.marginRight = '140px';
        } else {
            orderSectionsButtonElement.style.marginRight = '0px';
        }
    }, [toRight]);

    return (
        <div
            className={cx('floating-publish-button', 'container', {
                'to-right': toRight,
            })}
            style={{
                top,
            }}
        >
            <div className={cx('publish-wrapper')}>
                <PublishAllButton
                    disabled={isPublished}
                    canPublishDocument={canPublishDocument}
                    onClick={() => {
                        if (!isPublished) {
                            publishChapterModalActions.open({
                                chapterData,
                            });
                        }
                    }}
                />
            </div>
        </div>
    );
}

FloatingPublishButton.propTypes = {
    top: PropTypes.number.isRequired,
    isIntroductionOpen: PropTypes.bool.isRequired,
};

type Props = InferProps<typeof FloatingPublishButton.propTypes> & {
    chapterData: AdminSingleChapterData,
    chapterMeta: AdminSingleChapterMeta,
};

export default FloatingPublishButton;
