import React, { useEffect } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { notify } from '@NOTIFICATION/Notificator';
import {
    AdminSingleSectionData,
    AdminSingleSectionDataBaseText,
    AdminSingleSectionDataVariant,
} from '@INTERFACES/admin-api/sections';

import { usePublishAll } from '@SERVICES';
import useAuth from '@HOOKS/useAuth';
import usePublishSectionModal from '@HOOKS/store/modals/usePublishSectionModal';

import { PublishFormValues } from '@COMPONENTS/SHARED/PublicationForm';
import BaseModalSidebar from '@MODALS/Publications/components/BaseModalSidebar';

function ModalSidebar(props: Props) {
    const {
        sectionData,
        baseText,
        variants,
        includeBaseText,
        selectedVariantsIds,
    } = props;

    const { state: { user } } = useAuth();

    const {
        actions: publishSectionModalActions,
    } = usePublishSectionModal(() => true);

    const [
        publishAll,
        {
            isLoading, isSuccess, isError, error,
        },
    ] = usePublishAll();

    useEffect(() => {
        if (isSuccess) {
            notify.info('Section has been published', {
                toastId: 'publish-section',
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            if ((error as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'publish-section-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'publish-section-error',
                });
            }
        }
    }, [isError, error]);

    useEffect(() => {
        if (isSuccess) {
            const isPublished = (
                sectionData.baseText.isPublished
                && sectionData.variants.every((v) => v.isPublished)
            );

            if (isPublished) {
                publishSectionModalActions.close();
            }
        }
    }, [isSuccess, sectionData, publishSectionModalActions]);

    const hasSelected = selectedVariantsIds.size > 0 || includeBaseText;

    function onSave(formValues: PublishFormValues, includeToVersionControl: boolean) {
        let description = null;

        if (includeToVersionControl) {
            description = formValues.description;
        }

        publishAll({
            type: 'section',
            documentId: sectionData.document.id,
            data: {
                description,
                userId: user!.id,
                chapters: [],
                sections: includeBaseText ? [{
                    id: sectionData.id,
                    chapterId: sectionData.chapter.id,
                    documentId: sectionData.document.id,
                    body: baseText?.body || '',
                }] : [],
                variants: variants
                    .filter((variant) => selectedVariantsIds.has(variant.id))
                    .map((variant) => ({
                        id: variant.id,
                        sectionId: sectionData.id,
                        chapterId: sectionData.chapter.id,
                        documentId: sectionData.document.id,
                        body: variant.body,
                    })),
            },
        });
    }

    return (
        <BaseModalSidebar
            isLoading={isLoading}
            hasSelected={hasSelected}
            onSave={(formValues: PublishFormValues, includeToVersionControl) => {
                onSave(formValues, includeToVersionControl);
            }}
        />
    );
}

ModalSidebar.propTypes = {
    includeBaseText: PropTypes.bool.isRequired,
    selectedVariantsIds: PropTypes.instanceOf(Set<number>).isRequired,
};

type Props = InferProps<typeof ModalSidebar.propTypes> & {
    sectionData: AdminSingleSectionData,
    baseText: AdminSingleSectionDataBaseText | null,
    variants: AdminSingleSectionDataVariant[],
};

export default ModalSidebar;
