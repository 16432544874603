/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminSingleChapterDataSection } from '@INTERFACES/admin-api/chapters';

export interface SectionHistoryModalState {
    isOpen: boolean;

    data: {
        sectionId: number,
        sections: AdminSingleChapterDataSection[],
    } | null;
}

export interface SectionHistoryModalDispatchParams {
    sectionId: number,
    sections: AdminSingleChapterDataSection[],
}

const initialState: SectionHistoryModalState = {
    isOpen: false,
    data: null,
};

export const sectionHistoryModalSlice = createSlice({
    name: 'section-history-modal',
    initialState,
    reducers: {
        open(state: SectionHistoryModalState, action: PayloadAction<SectionHistoryModalDispatchParams>) {
            state.isOpen = true;
            state.data = action.payload;
        },
        close(state: SectionHistoryModalState) {
            state.isOpen = false;
            state.data = null;
        },
    },
});
