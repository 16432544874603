import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import {
    AdminSingleSectionData,
    AdminSingleSectionDataBaseText,
    AdminSingleSectionDataVariant,
} from '@INTERFACES/admin-api/sections';

import ModalSidebar from '@MODALS/Publications/PublishSectionModal/components/ModalSidebar';
import OnlyTitleStickyHeader from '@MODALS/Publications/components/OnlyTitleStickyHeader';
import BaseText from '@MODALS/Publications/PublishSectionModal/components/BaseText';
import VariantsStickyHeader from '@MODALS/Publications/PublishSectionModal/components/VariantsStickyHeader';
import Variant from '@MODALS/Publications/PublishSectionModal/components/Variant';

import styles from './ModalBody.module.scss';

const cx: CX = classnames.bind(styles);

function ModalBody(props: Props) {
    const {
        sectionData,
        baseText,
        variants,
        isLandscape,
        includeBaseText,
        setIncludeBaseText,
        selectedVariantsIds,
        setSelectedVariantsIds,
    } = props;

    return (
        <div className={cx('modal-body')}>
            <div className={cx('sidebar')}>
                <ModalSidebar
                    sectionData={sectionData}
                    baseText={baseText}
                    variants={variants}
                    includeBaseText={includeBaseText}
                    selectedVariantsIds={selectedVariantsIds}
                />
            </div>

            <div className={cx('body')}>
                {
                    baseText !== null
                    && (
                        <div>
                            <OnlyTitleStickyHeader title="Base text" top={1} />

                            <div className={cx('base-text-wrapper')}>
                                <BaseText
                                    isLandscape={isLandscape}
                                    baseText={baseText}
                                    includeBaseText={includeBaseText}
                                    setIncludeBaseText={setIncludeBaseText}
                                />
                            </div>
                        </div>
                    )
                }

                {
                    variants.length > 0
                    && (
                        <>
                            <VariantsStickyHeader
                                variants={variants}
                                setSelectedVariantsIds={setSelectedVariantsIds}
                            />

                            <div className={cx('variants-list')}>
                                {
                                    variants.map((variant) => {
                                        const key = variant.id;

                                        return (
                                            <div
                                                key={key}
                                                className={cx('variant-wrapper')}
                                            >
                                                <Variant
                                                    isLandscape={isLandscape}
                                                    variant={variant}
                                                    selectedVariantsIds={selectedVariantsIds}
                                                    setSelectedVariantsIds={setSelectedVariantsIds}
                                                />
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    );
}

ModalBody.propTypes = {
    isLandscape: PropTypes.bool.isRequired,
    includeBaseText: PropTypes.bool.isRequired,
    setIncludeBaseText: PropTypes.func.isRequired,
    selectedVariantsIds: PropTypes.instanceOf(Set<number>).isRequired,
    setSelectedVariantsIds: PropTypes.func.isRequired,
};

type Props = InferProps<typeof ModalBody.propTypes> & {
    sectionData: AdminSingleSectionData,
    baseText: AdminSingleSectionDataBaseText | null,
    variants: AdminSingleSectionDataVariant[]
};

export default ModalBody;
