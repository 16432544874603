import React from 'react';

import classnames from 'classnames/bind';

import { AdminPreviewDocumentChapter } from '@SERVICES';

import styles from './ChapterStickyHeader.module.scss';

const cx: CX = classnames.bind(styles);

function ChapterStickyHeader(props: Props) {
    const { chapter } = props;

    return (
        <div className={cx('chapter-sticky-header')}>
            <div className={cx('header-wrapper')}>
                <div className={cx('title')}>
                    <span className={cx('title-prefix')}>
                        Chapter
                    </span>

                    {chapter.name}
                </div>
            </div>
        </div>
    );
}

type Props = {
    chapter: AdminPreviewDocumentChapter,
};

export default ChapterStickyHeader;
