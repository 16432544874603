import { AdminSingleChapter } from '@INTERFACES/admin-api/chapters';

export default function getFilteredByTagsData(
    chapterData: AdminSingleChapter,
    tagsSet: Set<number>,
): AdminSingleChapter {
    if (tagsSet.size === 0) return chapterData;

    if (chapterData.data) {
        const { data, meta } = chapterData;

        const { sections } = data;

        const filtered = {
            ...data,
            sections: sections.map((section) => {
                const { variants } = section;

                return {
                    ...section,
                    variants: variants.filter((variant) => {
                        const { tags } = variant;

                        return tags.some((tag) => tagsSet.has(tag.id));
                    }),
                };
            }).filter((section) => (
                section.tags.some((tag) => tagsSet.has(tag.id))
                || section.variants.length > 0
            )),
        };

        return {
            data: filtered,
            meta,
        };
    }

    return chapterData;
}
