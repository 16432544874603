import React, { RefObject, useLayoutEffect, useRef } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import DESIGN from '@CONSTANTS/DESIGN.constant';

import styles from './ContentWithFullHeightSidebar.module.scss';

const cx: CX = classnames.bind(styles);

const mainHeaderHeight = DESIGN['main-header-height'];

function ContentWithFullHeightSidebar(props: Props) {
    const {
        sidebar, sidebarHeader, top, bottom, children,
    } = props;

    const ref: RefObject<HTMLDivElement> = useRef(null);

    useLayoutEffect(() => {
        const bar = ref.current!;

        const fullTop = mainHeaderHeight + top + bottom;

        function onScroll() {
            // if modal open!
            if (document.body.style.position === 'fixed') return;

            const { scrollY } = window;

            if (scrollY <= mainHeaderHeight) {
                bar.style.height = `calc(100vh - ${fullTop - scrollY}px)`;
            } else {
                bar.style.height = `calc(100vh - ${top + bottom}px)`;
            }
        }

        window.addEventListener('scroll', onScroll);

        onScroll();

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, [top, bottom]);

    return (
        <div className={cx('content-with-full-height-sidebar')}>
            {
                sidebarHeader
                && (
                    <div className={cx('sidebar-header-wrapper')}>
                        <div className={cx('sidebar-sticky')}>
                            {sidebarHeader}
                        </div>
                    </div>
                )
            }
            <div
                ref={ref}
                className={cx('full-height-sidebar', {
                    'with-header': !!sidebarHeader,
                })}
                style={{
                    top,
                }}
            >
                {sidebar}
            </div>
            <div className={cx('content-wrapper')}>
                {children}
            </div>
        </div>
    );
}

ContentWithFullHeightSidebar.defaultProps = {
    bottom: 0,
    sidebarHeader: null,
};

ContentWithFullHeightSidebar.propTypes = {
    sidebar: PropTypes.element.isRequired,
    sidebarHeader: PropTypes.oneOfType([PropTypes.element, PropTypes.any]),
    top: PropTypes.number.isRequired,
    bottom: PropTypes.number,
};

type Props =
    InferProps<typeof ContentWithFullHeightSidebar.propTypes>
    & typeof ContentWithFullHeightSidebar.defaultProps
    & OnlyChildrenProps;

export default ContentWithFullHeightSidebar;
