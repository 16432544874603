import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { ReactComponent as ArrowIcon } from '@ICONS/arrow-forward-icon.svg';

import styles from './HistoryPagination.module.scss';

const cx: CX = classnames.bind(styles);

function HistoryPagination(props: Props) {
    const {
        page,
        limit,
        dataLength,
        onClickPrev,
        onClickNext,
    } = props;

    const pages = Math.ceil(dataLength / limit);

    const hasPrev = page > 1;
    const hasNext = page < pages;

    function onPrev() {
        if (hasPrev) {
            onClickPrev(page - 1);
        }
    }

    function onNext() {
        if (hasNext) {
            onClickNext(page + 1);
        }
    }

    return (
        <div className={cx('history-pagination', 'container')}>
            <div className={cx('prev-next')}>
                <button
                    type="button"
                    className={cx('prev-button', 'clear-button')}
                    disabled={!hasPrev}
                    onClick={onPrev}
                >
                    <div className={cx('icon')}>
                        <ArrowIcon
                            width={18}
                            height={18}
                        />
                    </div>
                </button>

                <div className={cx('pages')}>
                    <div className={cx('current-page')}>{page}</div>
                    <div className={cx('separator')}>of</div>
                    <div className={cx('all-pages')}>{pages}</div>
                </div>

                <button
                    type="button"
                    className={cx('next-button', 'clear-button')}
                    disabled={!hasNext}
                    onClick={onNext}
                >
                    <div className={cx('icon')}>
                        <ArrowIcon
                            width={18}
                            height={18}
                        />
                    </div>
                </button>
            </div>
        </div>
    );
}

HistoryPagination.propTypes = {
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    dataLength: PropTypes.number.isRequired,
    onClickPrev: PropTypes.func.isRequired,
    onClickNext: PropTypes.func.isRequired,
};

type Props = InferProps<typeof HistoryPagination.propTypes>;

export default HistoryPagination;
