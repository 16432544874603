import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import usePreviewChapterIntroductionTextModal from '@HOOKS/store/modals/usePreviewChapterIntroductionTextModal';
import useChapterIntroductionTextHistoryModal from '@HOOKS/store/modals/useChapterIntroductionTextHistoryModal';
import useDocumentPermissions from '@HOOKS/useDocumentPermissions';

import { AdminSingleChapterData, AdminSingleChapterMeta } from '@INTERFACES/admin-api/chapters';

import StickyHeader from '@COMPONENTS/HEADERS/StickyHeader';
import OpenCloseButton from '@COMPONENTS/COMMON/buttons/OpenCloseButton';
import BasicIconButton from '@COMPONENTS/COMMON/buttons/BasicIconButton';

import styles from './IntroductionTextStickyHeader.module.scss';

const cx: CX = classnames.bind(styles);

function IntroductionTextStickyHeader(props: Props) {
    const {
        top,
        isIntroductionOpen,
        setIntroductionOpen,
        chapterData,
        chapterMeta,
    } = props;

    const {
        canGenerateDocument,
    } = useDocumentPermissions({
        documentOwnerId: chapterData.document.owner,
        permissions: chapterMeta.permissions,
    });

    const {
        actions: previewChapterIntroductionTextModalActions,
    } = usePreviewChapterIntroductionTextModal(() => true);

    const {
        actions: chapterIntroductionTextHistoryModalActions,
    } = useChapterIntroductionTextHistoryModal(() => true);

    return (

        <StickyHeader
            top={top}
        >
            <div className={cx('introduction-text-sticky-header', 'container')}>
                <div className={cx('title-wrapper')}>
                    <div className={cx('title')}>
                        Introduction text
                    </div>

                    <div className={cx('buttons')}>
                        <div className={cx('button-wrapper')}>
                            <div className={cx('button')}>
                                <BasicIconButton
                                    tooltip="Introduction text preview"
                                    icon="preview"
                                    width={18}
                                    height={18}
                                    color={COLOR['marriott-light-grey']}
                                    onClick={() => {
                                        previewChapterIntroductionTextModalActions.open({
                                            chapterData,
                                            canGenerateDocument,
                                        });
                                    }}
                                />
                            </div>
                        </div>

                        <div className={cx('button-wrapper')}>
                            <div className={cx('button')}>
                                <BasicIconButton
                                    tooltip="Introduction text history"
                                    icon="clock"
                                    width={18}
                                    height={18}
                                    color={COLOR['marriott-light-grey']}
                                    onClick={() => {
                                        chapterIntroductionTextHistoryModalActions.open({
                                            chapterData,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <OpenCloseButton
                    isOpen={isIntroductionOpen}
                    onClick={() => {
                        setIntroductionOpen((val: boolean) => !val);
                    }}
                    backgroundColor={COLOR['marriott-light-grey']}
                    iconColor={COLOR['app-white']}
                />
            </div>
        </StickyHeader>
    );
}

IntroductionTextStickyHeader.propTypes = {
    top: PropTypes.number.isRequired,
    isIntroductionOpen: PropTypes.bool.isRequired,
    setIntroductionOpen: PropTypes.func.isRequired,
};

type Props = InferProps<typeof IntroductionTextStickyHeader.propTypes> & {
    chapterData: AdminSingleChapterData,
    chapterMeta: AdminSingleChapterMeta,
};

export default IntroductionTextStickyHeader;
