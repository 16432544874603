import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { GLOBAL_PERMISSIONS } from '@CONSTANTS/PERMISSIONS.constant';

import { AdminUser } from '@SERVICES';
import useAuth from '@HOOKS/useAuth';

import BasicCheckbox from '@COMPONENTS/COMMON/inputs/checkbox/BasicCheckbox';

import styles from './Permissions.module.scss';

const cx: CX = classnames.bind(styles);

const permissions = [
    {
        id: 1,
        name: 'Can create documents',
        codename: GLOBAL_PERMISSIONS.CAN_CREATE_DOCUMENTS,
    },
    {
        id: 2,
        name: 'Can manage vendors',
        codename: GLOBAL_PERMISSIONS.CAN_MANAGE_VENDORS,
    },
    {
        id: 3,
        name: 'Can view logs',
        codename: GLOBAL_PERMISSIONS.CAN_VIEW_LOGS,
    },
    {
        id: 4,
        name: 'Can manage users permissions',
        codename: GLOBAL_PERMISSIONS.CAN_MANAGE_PERMISSIONS,
    },
];

function Permissions(props: Props) {
    const {
        isSuperuser,
        setIsSuperuser,
        selectedPermissions,
        setSelectedPermissions,
        user,
    } = props;

    const { state: { user: currentUser } } = useAuth();

    return (
        <div className={cx('permissions')}>
            <div className={cx('permissions-title')}>
                Permissions
            </div>

            <div className={cx('is-admin')}>
                <BasicCheckbox
                    title="Is admin"
                    checked={isSuperuser}
                    fontWeight={700}
                    onChange={() => {
                        setIsSuperuser((val: boolean) => !val);
                    }}
                />
            </div>

            <div className={cx('permissions-list')}>
                {
                    permissions.map((permission) => {
                        const key = permission.id;

                        const checked = selectedPermissions.has(permission.codename);

                        const disabled = (
                            permission.codename === GLOBAL_PERMISSIONS.CAN_MANAGE_PERMISSIONS
                            && user.id === currentUser!.id
                        );

                        return (
                            <div
                                key={key}
                                className={cx('permission-wrapper')}
                            >
                                <BasicCheckbox
                                    title={permission.name}
                                    checked={isSuperuser || checked}
                                    disabled={isSuperuser || disabled}
                                    onChange={() => {
                                        const set = new Set(selectedPermissions);

                                        if (checked) {
                                            set.delete(permission.codename);
                                        } else {
                                            set.add(permission.codename);
                                        }

                                        setSelectedPermissions(set);
                                    }}
                                />
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

Permissions.propTypes = {
    isSuperuser: PropTypes.bool.isRequired,
    setIsSuperuser: PropTypes.func.isRequired,
    selectedPermissions: PropTypes.instanceOf(Set<GLOBAL_PERMISSIONS>).isRequired,
    setSelectedPermissions: PropTypes.func.isRequired,
};

type Props = InferProps<typeof Permissions.propTypes> & {
    user: AdminUser,
};

export default Permissions;
