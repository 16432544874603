import React, {
    RefObject, useEffect, useRef, useState,
} from 'react';

import classnames from 'classnames/bind';

import usePreviewSectionModal from '@HOOKS/store/modals/usePreviewSectionModal';

import BaseModal from '@MODALS/BaseModal';
import ModalHeader from '@MODALS/Previews/PreviewSectionModal/components/ModalHeader';
import ModalBody from '@MODALS/Previews/PreviewSectionModal/components/ModalBody';

import styles from './PreviewSectionModal.module.scss';

const cx: CX = classnames.bind(styles);

function PreviewSectionModal() {
    const {
        state: { data: modalParams },
        actions: previewSectionModalActions,
    } = usePreviewSectionModal();

    const { sectionId, sections, canGenerateDocument } = modalParams!;

    const ref: RefObject<HTMLDivElement> = useRef(null);

    const [currentSectionIndex, setCurrentSectionIndex] = useState<number>(sections.findIndex((s) => s.id === sectionId));

    const [showUnpublished, setShowUnpublished] = useState<boolean>(false);

    const section = sections[currentSectionIndex];

    useEffect(() => {
        const element = ref.current;

        if (element) {
            element.scroll(0, 0);
        }
    }, [currentSectionIndex]);

    return (
        <BaseModal
            style={{
                top: 30,
                height: '100%',
                width: 'calc(100% - 60px)',
            }}
            onClose={() => {
                previewSectionModalActions.close();
            }}
        >
            <div className={cx('preview-section-modal')}>
                <div className={cx('header-wrapper')}>
                    <ModalHeader
                        section={section}
                        currentSectionIndex={currentSectionIndex}
                        setCurrentSectionIndex={setCurrentSectionIndex}
                        showUnpublished={showUnpublished}
                        setShowUnpublished={setShowUnpublished}
                        canGenerateDocument={canGenerateDocument}
                    />
                </div>

                <div
                    ref={ref}
                    className={cx('content')}
                >
                    <ModalBody
                        section={section}
                        showUnpublished={showUnpublished}
                    />
                </div>
            </div>
        </BaseModal>
    );
}

export default PreviewSectionModal;
