import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { ContentChapter } from '@INTERFACES/api/content';

import ChapterStickyHeader from '@PAGES/manager/select-content/components/ChapterStickyHeader';
import GridView from '@PAGES/manager/select-content/components/GridView';
import onlyMandatorySections from '@PAGES/manager/select-content/helpers/onlyMandatorySections';

import styles from './Sections.module.scss';

const cx: CX = classnames.bind(styles);

function Sections(props: Props) {
    const {
        chaptersData,
        selectedChaptersIds,
        selectedSectionsIds,
        selectedVariantsIds,
        setSelectedSectionsIds,
        setSelectedVariantsIds,
        isPartial,
        isOnlyRequiredSections,
    } = props;

    let sectionsData = chaptersData.filter((chapter) => selectedChaptersIds.has(chapter.id));

    if (isOnlyRequiredSections) {
        if (isPartial) {
            sectionsData = sectionsData.map((chapter: ContentChapter) => ({
                id: chapter.id,
                name: chapter.name,
                order: chapter.order,
                hasBody: chapter.hasBody,
                isMandatory: chapter.isMandatory,
                tags: chapter.tags,
                sections: [],
            }));
        } else {
            sectionsData = onlyMandatorySections(sectionsData);
        }
    }

    return (
        <div className={cx('sections')}>
            <div className={cx('sections-title-wrapper', 'container')}>
                <div className={cx('sections-title')}>
                    Sections
                </div>
            </div>

            <div className={cx('sections-lists-wrapper')}>
                {
                    sectionsData.map((chapter) => {
                        const { sections } = chapter;

                        const chapterKey = chapter.id;

                        const foundChapterIndex = chaptersData.findIndex((c) => c.id === chapter.id);

                        return (
                            <div
                                key={chapterKey}
                                className={cx('sections-list')}
                            >
                                <ChapterStickyHeader
                                    chapter={chapter}
                                    index={foundChapterIndex}
                                    selectedSectionsIds={selectedSectionsIds}
                                    selectedVariantsIds={selectedVariantsIds}
                                    setSelectedSectionsIds={setSelectedSectionsIds}
                                    setSelectedVariantsIds={setSelectedVariantsIds}
                                    isPartial={isPartial}
                                />

                                {
                                    sections.length
                                        ? (
                                            <GridView
                                                sections={sections}
                                                selectedSectionsIds={selectedSectionsIds}
                                                selectedVariantsIds={selectedVariantsIds}
                                                setSelectedSectionsIds={setSelectedSectionsIds}
                                                setSelectedVariantsIds={setSelectedVariantsIds}
                                                isPartial={isPartial}
                                                isChapterMandatory={chapter.isMandatory}
                                            />
                                        ) : (
                                            <>
                                                {
                                                    chapter.hasBody
                                                    && (
                                                        <div className={cx('chapter-has-body', 'container')}>
                                                            Chapter has introduction text only
                                                        </div>
                                                    )
                                                }
                                                <div className={cx('sections-not-found', 'container')}>
                                                    {
                                                        isOnlyRequiredSections
                                                            ? 'Required sections not found!'
                                                            : 'Sections not found!'
                                                    }
                                                </div>
                                            </>
                                        )
                                }
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

Sections.propTypes = {
    selectedChaptersIds: PropTypes.instanceOf(Set<number>).isRequired,
    selectedSectionsIds: PropTypes.instanceOf(Set<number>).isRequired,
    selectedVariantsIds: PropTypes.instanceOf(Set<number>).isRequired,
    setSelectedSectionsIds: PropTypes.func.isRequired,
    setSelectedVariantsIds: PropTypes.func.isRequired,
    isPartial: PropTypes.bool.isRequired,
    isOnlyRequiredSections: PropTypes.bool.isRequired,
};

type Props = InferProps<typeof Sections.propTypes> & { chaptersData: ContentChapter[] };

export default Sections;
