import React from 'react';
import classnames from 'classnames/bind';

import {
    AdminSingleDocument, useGetAllTags,
} from '@SERVICES';

import useDocumentPermissions from '@HOOKS/useDocumentPermissions';
import useNewTagModal from '@HOOKS/store/modals/useNewTagModal';
import useTagNameModal from '@HOOKS/store/modals/useTagNameModal';
import useDeleteTagModal from '@HOOKS/store/modals/useDeleteTagModal';

import { ReactComponent as EditIcon } from '@ICONS/edit-icon-2.svg';

import DataLoader from '@COMPONENTS/SHARED/DataLoader';
import AddButton from '@COMPONENTS/COMMON/buttons/AddButton';
import Lock from '@COMPONENTS/SHARED/Lock';

import styles from './DocumentTags.module.scss';

const cx: CX = classnames.bind(styles);

function DocumentTags(props: Props) {
    const { documentData } = props;

    const {
        canManageDocumentTags,
    } = useDocumentPermissions({
        documentOwnerId: documentData.data.owner.id,
        permissions: documentData.meta.permissions,
    });

    const {
        data: tagsData = { data: [] },
        isLoading,
        isFetching,
        isError,
        error,
    } = useGetAllTags({
        documentId: documentData.data.id,
    });

    const {
        actions: newTagModalActions,
    } = useNewTagModal(() => true);

    const {
        actions: tagNameModalActions,
    } = useTagNameModal(() => true);

    const {
        actions: deleteTagModalActions,
    } = useDeleteTagModal(() => true);

    const locked = !canManageDocumentTags;

    return (
        <div className={cx('document-tags', 'container')}>
            <div className={cx('tags-wrapper')}>
                <DataLoader
                    isLoading={isLoading}
                    isFetching={isFetching}
                    isError={isError}
                    error={error}
                >
                    <div className={cx('tags-list')}>
                        <div className={cx('tag-item-wrapper')}>
                            <AddButton
                                locked={locked}
                                size="small"
                                title="New tag"
                                fontSize={36}
                                onClick={() => {
                                    newTagModalActions.open({
                                        documentId: documentData.data.id,
                                    });
                                }}
                            />
                        </div>

                        {
                            tagsData.data.length
                                ? tagsData.data.map((tag) => {
                                    const key = tag.id;

                                    return (
                                        <div
                                            key={key}
                                            className={cx('tag-item-wrapper')}
                                        >
                                            <div
                                                className={cx('tag-item', {
                                                    locked,
                                                })}
                                            >
                                                {
                                                    locked
                                                        ? (
                                                            <div className={cx('lock-wrapper')}>
                                                                <Lock size="small" />
                                                            </div>
                                                        ) : (

                                                            <button
                                                                type="button"
                                                                className={cx('clear-button', 'delete-button')}
                                                                onClick={() => {
                                                                    deleteTagModalActions.open({
                                                                        id: tag.id,
                                                                        name: tag.name,
                                                                        documentId: documentData.data.id,
                                                                    });
                                                                }}
                                                            >
                                                                &times;
                                                            </button>
                                                        )
                                                }
                                                <div className={cx('tag-name')}>
                                                    <button
                                                        type="button"
                                                        className="clear-button"
                                                        onClick={() => {
                                                            if (!locked) {
                                                                tagNameModalActions.open({
                                                                    id: tag.id,
                                                                    name: tag.name,
                                                                    documentId: documentData.data.id,
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        <div className={cx('edit-icon-wrapper')}>
                                                            <EditIcon
                                                                width={16}
                                                                height={16}
                                                            />
                                                        </div>
                                                        <div className={cx('name')}>
                                                            {tag.name}
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                                : (
                                    <div className={cx('no-tags')}>No tags</div>
                                )
                        }
                    </div>
                </DataLoader>
            </div>
        </div>
    );
}

type Props = {
    documentData: AdminSingleDocument,
};

export default DocumentTags;
