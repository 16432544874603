import { useMemo } from 'react';
import { EqualityFn } from 'react-redux';

import { useAppDispatch, useAppSelector } from '@STORE/index';

import { PublishVariantModalDispatchParams, publishVariantModalSlice, PublishVariantModalState } from '@REDUCERS';

function createPublishVariantModalHook() {
    return function usePublishVariantModal(equalityFn?: EqualityFn<PublishVariantModalState>) {
        const state = useAppSelector((store) => store.publishVariantModal, equalityFn);

        const dispatch = useAppDispatch();

        const actions = useMemo(() => ({
            open: (params: PublishVariantModalDispatchParams) => dispatch(publishVariantModalSlice.actions.open(params)),
            close: () => dispatch(publishVariantModalSlice.actions.close()),
        }), [dispatch]);

        return {
            state,
            actions,
        };
    };
}

export default createPublishVariantModalHook();
