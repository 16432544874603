import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_PREFIX, BASE_URL } from '@CONSTANTS/API.constant';
import userRepository from '@REPOSITORIES/local-repository/user-repository';

interface AreasApiResponse {
    id: number;

    name: string;
}

type Id = number | undefined;

type GetAllParams = {
    region: Id;
};

export const areasApi = createApi({
    reducerPath: 'areas-api',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + API_PREFIX,
        prepareHeaders: (headers) => {
            const token = userRepository.getData();

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getAll: builder.query<AreasApiResponse[], GetAllParams>({
            query: (params: GetAllParams) => ({
                url: '/areas/',
                params,
            }),
            transformResponse: (raw: AreasApiResponse[]) => raw.sort((a, b) => (a.name > b.name ? 1 : -1)),
            keepUnusedDataFor: 60 * 5, // 5 minutes
        }),
    }),
});

export const {
    useGetAllQuery: useGetAllAreas,
} = areasApi;
