import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import classnames from 'classnames/bind';

import ROUTES from '@CONSTANTS/ROUTES.constant';
import { AdminSingleChapterMetaChapter } from '@INTERFACES/admin-api/chapters';

import RouteButton from '@COMPONENTS/COMMON/buttons/RouteButton';

import styles from './ChaptersView.module.scss';

const cx: CX = classnames.bind(styles);

function ChaptersView(props: Props) {
    const { chapters } = props;

    const { id } = useParams();
    const navigate = useNavigate();

    return (
        <div className={cx('chapters-view')}>
            <div className={cx('chapters-title')}>
                Chapters
            </div>

            <div className={cx('chapters-list')}>
                {
                    chapters.map((chapter) => {
                        const key = chapter.id;

                        return (
                            <div
                                key={key}
                                className={cx('chapter-list-item')}
                            >
                                <RouteButton
                                    title={chapter.name}
                                    active={+id! === chapter.id}
                                    onClick={() => {
                                        navigate(ROUTES.admin.chapters.id.get(chapter.id));
                                    }}
                                />
                            </div>
                        );
                    })
                }
            </div>

        </div>
    );
}

type Props = {
    chapters: AdminSingleChapterMetaChapter[]
};

export default ChaptersView;
