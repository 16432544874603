import React, {
    RefObject, useEffect, useRef, useState,
} from 'react';

import classnames from 'classnames/bind';

import { useSectionHistory } from '@SERVICES';

import useSectionHistoryModal from '@HOOKS/store/modals/useSectionHistoryModal';

import BaseModal from '@MODALS/BaseModal';
import ModalHeader from '@MODALS/History/SectionHistoryModal/components/ModalHeader';
import ModalBody from '@MODALS/History/SectionHistoryModal/components/ModalBody';
import DataLoader from '@COMPONENTS/SHARED/DataLoader';

import styles from './SectionHistoryModal.module.scss';

const cx: CX = classnames.bind(styles);

const defaultData = {
    data: null,
};

function SectionHistoryModal() {
    const {
        state: { data: modalParams },
        actions: sectionHistoryModalActions,
    } = useSectionHistoryModal();

    const { sectionId, sections } = modalParams!;

    const ref: RefObject<HTMLDivElement> = useRef(null);

    const [currentSectionIndex, setCurrentSectionIndex] = useState<number>(sections.findIndex((s) => s.id === sectionId));

    const section = sections[currentSectionIndex];

    const {
        data: historyData = defaultData, isLoading, isFetching, isError, error,
    } = useSectionHistory(section.id);

    useEffect(() => {
        const element = ref.current;

        if (element) {
            element.scroll(0, 0);
        }
    }, [currentSectionIndex]);

    function handleClose() {
        sectionHistoryModalActions.close();
    }

    return (
        <BaseModal
            style={{
                top: 30,
                height: '100%',
                width: 'calc(100% - 60px)',
            }}
            onClose={() => handleClose()}
        >
            <div className={cx('section-history-modal')}>
                <div className={cx('header-wrapper')}>
                    <ModalHeader
                        section={section}
                        currentSectionIndex={currentSectionIndex}
                        setCurrentSectionIndex={setCurrentSectionIndex}
                    />
                </div>
                <div
                    ref={ref}
                    className={cx('content')}
                >
                    <DataLoader
                        isLoading={isLoading}
                        isFetching={isFetching}
                        isError={isError}
                        error={error}
                    >
                        <ModalBody
                            historyData={historyData.data!}
                            isLandscape={section.isLandscape}
                        />
                    </DataLoader>
                </div>
            </div>
        </BaseModal>
    );
}

export default SectionHistoryModal;
