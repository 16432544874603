import { useMemo } from 'react';
import { EqualityFn } from 'react-redux';

import { useAppDispatch, useAppSelector } from '@STORE/index';
import { newSectionModalSlice, NewSectionModalDispatchParams, NewSectionModalState } from '@REDUCERS';

function createNewSectionModalHook() {
    return function useNewSectionModal(equalityFn?: EqualityFn<NewSectionModalState>) {
        const state = useAppSelector((store) => store.newSectionModal, equalityFn);

        const dispatch = useAppDispatch();

        const actions = useMemo(() => ({
            open: (params: NewSectionModalDispatchParams) => dispatch(newSectionModalSlice.actions.open(params)),
            close: () => dispatch(newSectionModalSlice.actions.close()),
        }), [dispatch]);

        return {
            state,
            actions,
        };
    };
}

export default createNewSectionModalHook();
