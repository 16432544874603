import React from 'react';

import classnames from 'classnames/bind';

import useSectionBaseTextHistoryModal from '@HOOKS/store/modals/useSectionBaseTextHistoryModal';

import BaseModal from '@MODALS/BaseModal';
import SimpleLargeModalHeader from '@COMPONENTS/SHARED/SimpleLargeModalHeader';
import BasicHistoryList from '@MODALS/History/components/BasicHistoryList';

import styles from './SectionBaseTextHistoryModal.module.scss';

const cx: CX = classnames.bind(styles);

function SectionBaseTextHistoryModal() {
    const {
        state: { data: modalParams },
        actions: sectionBaseTextHistoryModalActions,
    } = useSectionBaseTextHistoryModal(() => true);

    const { sectionData } = modalParams!;

    function handleClose() {
        sectionBaseTextHistoryModalActions.close();
    }

    return (
        <BaseModal
            style={{
                top: 30,
                height: '100%',
                width: 'calc(100% - 60px)',
            }}
            onClose={() => handleClose()}
        >
            <div className={cx('section-base-text-history-modal')}>
                <div className={cx('header-wrapper')}>
                    <SimpleLargeModalHeader
                        modalTitle="Base text history"
                        title=""
                        onClose={() => handleClose()}
                    />
                </div>
                <div className={cx('content')}>
                    <BasicHistoryList
                        versions={sectionData.baseText.versions}
                        isLandscape={sectionData.isLandscape}
                        paginationLimit={5}
                    />
                </div>
            </div>
        </BaseModal>
    );
}

export default SectionBaseTextHistoryModal;
