import React, { RefObject, useEffect, useRef } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useForm } from 'react-hook-form';

import classnames from 'classnames/bind';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import COLOR from '@CONSTANTS/COLOR.constant';

import BaseModal from '@MODALS/BaseModal';
import BasicButton from '@COMPONENTS/COMMON/buttons/BasicButton';
import HiddenFormButton from '@COMPONENTS/COMMON/buttons/HiddenFormButton';
import BasicInputValidatable from '@COMPONENTS/COMMON/inputs/input/BasicInput/BasicInputValidatable.component';

import { notify } from '@NOTIFICATION/Notificator';

import styles from './NameInputModal.module.scss';

const cx: CX = classnames.bind(styles);

enum Form {
    NAME = 'name',
}

yup.setLocale({
    mixed: {
        required: () => 'Name field is required',
    },
});

const schema = yup.object({
    [Form.NAME]: yup.string().required(),
});

export type FormValues = {
    [Form.NAME]: string;
};

function NameInputModal(props: Props) {
    const {
        modalTitle,
        itemName,
        initialInputValue,
        inputPlaceholder,
        successMessage,
        isLoading,
        isSuccess,
        isError,
        error,
        onSave,
        onClose,
    } = props;

    const { handleSubmit, control } = useForm<FormValues>({
        defaultValues: {
            [Form.NAME]: initialInputValue,
        },
        resolver: yupResolver(schema),
        mode: 'onChange',
    });

    const formButtonRef: RefObject<HTMLButtonElement> = useRef(null);

    useEffect(() => {
        if (isSuccess) {
            notify.info(successMessage, {
                toastId: `add-new-${itemName.toLowerCase()}`,
            });

            onClose();
        }
    }, [itemName, successMessage, isSuccess, onClose]);

    useEffect(() => {
        if (isError && error) {
            if ((error as FetchBaseQueryError).status === 400) {
                notify.error(`${itemName} with this name already exists`, {
                    toastId: `add-new-${itemName.toLowerCase()}-error`,
                });
            } else if ((error as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: `add-new-${itemName.toLowerCase()}-permission-error`,
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'something-wrong',
                });
            }
        }
    }, [itemName, isError, error]);

    return (
        <BaseModal
            style={{
                top: '30%',
                transform: 'translate(-50%, -30%)',
            }}
            onClose={onClose}
        >
            <div className={cx('name-input-modal')}>
                <div className={cx('title')}>
                    {modalTitle}
                </div>
                <form onSubmit={handleSubmit(onSave)}>
                    <div className={cx('input-wrapper')}>
                        <BasicInputValidatable<FormValues>
                            placeholder={inputPlaceholder}
                            validation={{
                                name: Form.NAME,
                                control,
                            }}
                        />
                    </div>
                    <HiddenFormButton ref={formButtonRef} />
                </form>
                <BasicButton
                    title="Save"
                    isProcessing={isLoading}
                    style={{
                        height: 40,
                        width: '100%',
                        fontSize: 14,
                        backgroundColor: COLOR['app-green'],
                    }}
                    onClick={() => {
                        formButtonRef.current?.click();
                    }}
                />
            </div>
        </BaseModal>
    );
}

NameInputModal.defaultProps = {
    initialInputValue: '',
    inputPlaceholder: '',
};

NameInputModal.propTypes = {
    modalTitle: PropTypes.string.isRequired,
    itemName: PropTypes.string.isRequired,
    initialInputValue: PropTypes.string,
    inputPlaceholder: PropTypes.string,
    successMessage: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
};

type Props = InferProps<typeof NameInputModal.propTypes> & typeof NameInputModal.defaultProps & {
    error: FetchBaseQueryError | SerializedError | undefined,
};

export default NameInputModal;
