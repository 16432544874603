import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import CircularProgressIndicator from '@COMPONENTS/SHARED/CircularProgressIndicator';
import Lock from '@COMPONENTS/SHARED/Lock';

import styles from './TagItem.module.scss';

const cx: CX = classnames.bind(styles);

type Tag = {
    id: number;

    name: string;
};

function TagItem(props: Props) {
    const {
        tag,
        isProcessing,
        locked,
        onClick,
    } = props;

    return (
        <div
            className={cx('tag-item', {
                'is-processing': isProcessing,
                locked,
            })}
        >
            {
                locked
                    ? (
                        <div className={cx('lock-wrapper')}>
                            <Lock size="small" />
                        </div>
                    ) : (

                        <button
                            type="button"
                            className={cx('clear-button', 'delete-button')}
                            onClick={() => {
                                if (!isProcessing) {
                                    onClick();
                                }
                            }}
                        >
                            &times;
                        </button>
                    )
            }
            {
                isProcessing
                && (
                    <div className={cx('progress-indicator-wrapper')}>
                        <CircularProgressIndicator
                            color={COLOR['marriott-light-grey']}
                            size={24}
                            thickness={3}
                        />
                    </div>
                )

            }
            <div className={cx('tag-name')}>
                {tag.name}
            </div>
        </div>
    );
}

TagItem.propTypes = {
    isProcessing: PropTypes.bool.isRequired,
    locked: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

type Props = InferProps<typeof TagItem.propTypes> & {
    tag: Tag,
};

export default TagItem;
