import React from 'react';

import useNewDocumentModal from '@HOOKS/store/modals/useNewDocumentModal';
import { useCreateDocument } from '@SERVICES';

import NameInputModal, { FormValues } from '@MODALS/common/NameInputModal';
import useAuth from '@HOOKS/useAuth';

function NewDocumentModal() {
    const { state: { user } } = useAuth();

    const {
        actions,
    } = useNewDocumentModal(() => true);

    const [
        createDocument,
        {
            isLoading, isSuccess, isError, error,
        },
    ] = useCreateDocument();

    const onSave = (formValues: FormValues) => {
        createDocument({
            name: formValues.name,
            userId: user!.id,
        });
    };

    return (
        <NameInputModal
            modalTitle="Create new document"
            itemName="Document"
            inputPlaceholder="Document name"
            successMessage="New document has been created!"
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            error={error}
            onSave={onSave}
            onClose={() => {
                actions.close();
            }}
        />
    );
}

export default NewDocumentModal;
