import React from 'react';
import DeleteItemModal from '@MODALS/common/DeleteItemModal';
import { useDeleteVariant } from '@SERVICES';
import useDeleteVariantModal from '@HOOKS/store/modals/useDeleteVariantModal';

function DeleteVariantModal() {
    const {
        state: { data: modalParams },
        actions,
    } = useDeleteVariantModal();

    const [
        deleteVariant,
        {
            isLoading, isSuccess, isError, error,
        },
    ] = useDeleteVariant();

    return (
        <DeleteItemModal
            modalTitle="Delete variant"
            name={modalParams!.variant.name}
            itemName="Variant"
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            error={error}
            onDelete={() => {
                deleteVariant({
                    id: modalParams!.id,
                    documentId: modalParams!.documentId,
                    chapterId: modalParams!.chapterId,
                    sectionId: modalParams!.sectionId,
                });
            }}
            onClose={() => {
                actions.close();
            }}
        />
    );
}

export default DeleteVariantModal;
