import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { AdminSingleSectionDataVariant } from '@INTERFACES/admin-api/sections';

import { isContentEmptyCheck } from '@HELPERS/isContentEmptyCheck';

import CircleCheckbox from '@COMPONENTS/COMMON/inputs/checkbox/CircleCheckbox';
import VariantHeader from '@MODALS/Publications/components/VariantHeader';
import PublicationPair from '@COMPONENTS/SHARED/PublicationPair';

import styles from './Variant.module.scss';

const cx: CX = classnames.bind(styles);

function Variant(props: Props) {
    const {
        isLandscape,
        variant,
        selectedVariantsIds,
        setSelectedVariantsIds,
    } = props;

    const oldVersion = (
        variant.versions.length > 0
            ? variant.versions[variant.versions.length - 1].body
            : null
    );

    const newVersion = variant.body;

    const contentIsEmpty = isContentEmptyCheck(newVersion);

    return (
        <div className={cx('variant')}>
            <div className={cx('checkbox-wrapper')}>
                <div className={cx('checkbox')}>
                    <CircleCheckbox
                        disabled={contentIsEmpty}
                        checked={selectedVariantsIds.has(variant.id)}
                        onChange={(val: boolean) => {
                            const set = new Set(selectedVariantsIds);

                            if (val) {
                                set.add(variant.id);
                            } else {
                                set.delete(variant.id);
                            }

                            setSelectedVariantsIds(set);
                        }}
                    />
                </div>
            </div>
            <div className={cx('variant-body')}>
                <VariantHeader
                    variant={{
                        isDefault: variant.isDefault,
                        brand: variant.brand?.name || '',
                        region: variant.region?.name || '',
                        area: variant.area?.name || '',
                        country: variant.country?.name || '',
                        propertyType: variant.propertyType?.name || '',
                    }}
                />
                <PublicationPair
                    contentIsEmpty={contentIsEmpty}
                    isLandscape={isLandscape}
                    pair={{
                        oldVersion,
                        newVersion,
                    }}
                />
            </div>
        </div>
    );
}

Variant.propTypes = {
    isLandscape: PropTypes.bool.isRequired,
    selectedVariantsIds: PropTypes.instanceOf(Set<number>).isRequired,
    setSelectedVariantsIds: PropTypes.func.isRequired,
};

type Props = InferProps<typeof Variant.propTypes> & {
    variant: AdminSingleSectionDataVariant
};

export default Variant;
