import React, { MouseEvent } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import Lock from '@COMPONENTS/SHARED/Lock';

import styles from './AddButton.module.scss';

const cx: CX = classnames.bind(styles);

function AddButton(props: Props) {
    const {
        title, fontSize, onClick, locked, size,
    } = props;

    function handleClick(event: MouseEvent<HTMLButtonElement>) {
        if (!locked) {
            onClick(event);
        }
    }

    return (
        <div
            className={cx('add-button', {
                locked,
                small: size === 'small',
            })}
        >
            {
                locked
                && (
                    <div className={cx('lock-wrapper')}>
                        <Lock size={size === 'small' ? 'small' : 'large'} />
                    </div>
                )
            }

            <button
                type="button"
                className="clear-button"
                style={{
                    fontSize,
                }}
                onClick={(e) => handleClick(e)}
            >
                {
                    title && <div className={cx('title')}>{title}</div>
                }
                <div className={cx('plus')}>
                    +
                </div>
            </button>
        </div>
    );
}

AddButton.defaultProps = {
    title: '',
    size: 'large',
    fontSize: 60,
};

AddButton.propTypes = {
    locked: PropTypes.bool.isRequired,
    size: PropTypes.oneOf(['small', 'large']),
    title: PropTypes.string,
    fontSize: PropTypes.number,
    onClick: PropTypes.func.isRequired,
};

type Props = InferProps<typeof AddButton.propTypes> & typeof AddButton.defaultProps;

export default AddButton;
