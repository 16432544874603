import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { AdminPreviewDocumentChapter } from '@SERVICES';

import BasicCheckbox from '@COMPONENTS/COMMON/inputs/checkbox/BasicCheckbox';

import styles from './ChaptersFilters.module.scss';

const cx: CX = classnames.bind(styles);

function ChaptersFilters(props: Props) {
    const {
        chapters,
        selectedChaptersIds,
        setSelectedChaptersIds,
    } = props;

    return (
        <div className={cx('chapters-filters', 'container-left')}>
            <div className={cx('title')}>
                Chapters
            </div>

            <div className={cx('checkboxes')}>
                {
                    chapters.map((chapter) => {
                        const key = chapter.id;

                        const checked = selectedChaptersIds.has(chapter.id);

                        return (
                            <div
                                key={key}
                                className={cx('checkbox-wrapper')}
                            >
                                <BasicCheckbox
                                    title={chapter.name}
                                    fontSize={12}
                                    checked={checked}
                                    onChange={() => {
                                        const set = new Set(selectedChaptersIds);

                                        if (checked) {
                                            set.delete(chapter.id);
                                        } else {
                                            set.add(chapter.id);
                                        }

                                        setSelectedChaptersIds(set);
                                    }}
                                />
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

ChaptersFilters.propTypes = {
    selectedChaptersIds: PropTypes.instanceOf(Set<number>).isRequired,
    setSelectedChaptersIds: PropTypes.func.isRequired,
};

type Props = InferProps<typeof ChaptersFilters.propTypes> & {
    chapters: AdminPreviewDocumentChapter[],
};

export default ChaptersFilters;
