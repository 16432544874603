import React, {
    MutableRefObject, useEffect, useRef,
} from 'react';
import { Outlet } from 'react-router-dom';

import { notify } from '@NOTIFICATION/Notificator';
import userRepository from '@REPOSITORIES/local-repository/user-repository';

import ModalsContainer from '@APP/ModalsContainer';

import useAuth from '@HOOKS/useAuth';

import AppHelmet from './AppHelmet';

function AppWrapper() {
    const { state: { user }, actions: authActions } = useAuth();

    const interval: MutableRefObject<any> = useRef();

    useEffect(() => {
        clearInterval(interval.current);

        function check() {
            const data = userRepository.getData();
            const isSessionExpired = userRepository.isExpired();

            if (isSessionExpired) {
                clearInterval(interval.current);

                if (data) {
                    notify.info('Your session has expired', {
                        toastId: 'session-expired',
                    });
                }

                authActions.logout();
            }
        }

        if (user) {
            interval.current = setInterval(() => {
                check();
            }, 10 * 1000);

            check();
        }
    }, [user, authActions]);

    return (
        <>
            <AppHelmet>
                <Outlet />
            </AppHelmet>

            <ModalsContainer />
        </>
    );
}

export default AppWrapper;
