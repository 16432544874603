export default function extractCSSVars<T>(
    data: { [key: string]: string },
    handler: (a: string) => T,
) {
    const obj: {
        [key: string]: T,
    } = {};

    const keys = Object.keys(data);

    keys.forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
            obj[key] = handler(data[key]);
        }
    });

    return Object.freeze(obj);
}
