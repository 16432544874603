import React, {
    useEffect, useLayoutEffect, useMemo, useState,
} from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { SingleValue } from 'react-select';
import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import {
    AdminSingleSectionData,
    AdminSingleSectionDataBaseTextVersion,
    AdminSingleSectionMeta,
} from '@INTERFACES/admin-api/sections';
import { useUpdateSection } from '@SERVICES';

import usePublishSectionBaseTextModal from '@HOOKS/store/modals/usePublishSectionBaseTextModal';
import useDocumentPermissions from '@HOOKS/useDocumentPermissions';

import BasicSelect from '@COMPONENTS/COMMON/inputs/select/BasicSelect';

import VersionInfo from '@COMPONENTS/SHARED/VersionInfo';
import Warning from '@COMPONENTS/SHARED/Warning';
import SaveAndPublishPairButtons from '@COMPONENTS/COMMON/buttons/SaveAndPublishPairButtons';

import styles from './BaseTextSidebar.module.scss';

const cx: CX = classnames.bind(styles);

function getOption(options: any[]) {
    const len = options.length;

    if (len > 0) {
        return options[len - 1];
    }

    return null;
}

function BaseTextSidebar(props: Props) {
    const {
        sectionData,
        sectionMeta,
        selectedVersion,
        contentIsEmpty,
        getEditorContent,
        onVersionChange,
    } = props;

    const { baseText } = sectionData;

    const {
        canEditDocument,
        canPublishDocument,
    } = useDocumentPermissions({
        documentOwnerId: sectionData.document.owner,
        permissions: sectionMeta.permissions,
    });

    const {
        actions: publishSectionBaseTextModalActions,
    } = usePublishSectionBaseTextModal();

    const [
        updateSection,
        {
            isLoading, isSuccess, isError, error,
        },
    ] = useUpdateSection();

    const options = useMemo(() => {
        const { versions, body } = baseText;

        const opts = versions
            .map((version, i) => ({
                value: version.id,
                label: `Version ${i + 1}`,
            }));

        if (versions.length === 0 || body !== versions[versions.length - 1].body) {
            opts.push({
                value: 0,
                label: 'Draft',
            });
        }

        return opts;
    }, [baseText]);

    const [selectedOption, setSelectedOption] = useState<any>(getOption(options));

    useLayoutEffect(() => {
        setSelectedOption(getOption(options));
    }, [baseText, options]);

    useLayoutEffect(() => {
        onVersionChange(selectedOption);
    }, [selectedOption, onVersionChange]);

    useEffect(() => {
        if (isSuccess) {
            notify.info('Base text has been saved', {
                toastId: 'save-base-text',
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            if ((error as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'save-base-text-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'save-base-text-error',
                });
            }
        }
    }, [isError, error]);

    function getWarning() {
        if (baseText.versions.length === 0) {
            return 'Base text is not published';
        }

        return 'Base text has unpublished changes';
    }

    const includedToVersionControl = !!selectedVersion?.description;

    return (
        <div className={cx('base-text-sidebar')}>
            <div className={cx('sidebar-wrapper')}>
                <div className={cx('version-control')}>
                    <div className={cx('title')}>
                        Version Control
                    </div>
                    <div className={cx('versions')}>
                        <BasicSelect
                            title="Base text history"
                            isClearable={false}
                            menuPlacement="bottom"
                            options={options}
                            value={selectedOption}
                            onChange={(val: SingleValue<any>) => {
                                setSelectedOption(val);
                            }}
                        />
                    </div>

                    {
                        selectedVersion !== undefined
                        && (
                            <VersionInfo
                                includedToVersionControl={includedToVersionControl}
                                version={{
                                    description: selectedVersion!.description || '',
                                    createdAt: selectedVersion!.createdAt,
                                    author: {
                                        fullName: selectedVersion!.author.fullName,
                                        email: selectedVersion!.author.email,
                                    },
                                }}
                            />
                        )
                    }
                </div>

                <div className={cx('footer')}>
                    {
                        !baseText.isPublished
                        && (
                            <div className={cx('footer-top')}>
                                <Warning text={getWarning()} />
                            </div>
                        )
                    }

                    <SaveAndPublishPairButtons
                        isSaveProcessing={isLoading}
                        disablePublish={contentIsEmpty}
                        canEditDocument={canEditDocument}
                        canPublishDocument={canPublishDocument}
                        onSaveClick={() => {
                            const content = getEditorContent();

                            updateSection({
                                id: sectionData.id,
                                name: sectionData.name,
                                body: content,
                                isMandatory: sectionData.isMandatory,
                                isPageBreak: sectionData.isPageBreak,
                                isLandscape: sectionData.isLandscape,
                                chapterId: sectionData.chapter.id,
                                documentId: sectionData.document.id,
                            });
                        }}
                        onSaveAndPublishClick={() => {
                            publishSectionBaseTextModalActions.open({
                                id: sectionData.id,
                                body: getEditorContent(),
                                documentId: sectionData.document.id,
                                chapterId: sectionData.chapter.id,
                            });
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

BaseTextSidebar.propTypes = {
    contentIsEmpty: PropTypes.bool.isRequired,
    getEditorContent: PropTypes.func.isRequired,
    onVersionChange: PropTypes.func.isRequired,
};

type Props = InferProps<typeof BaseTextSidebar.propTypes> & {
    sectionData: AdminSingleSectionData,
    sectionMeta: AdminSingleSectionMeta,
    selectedVersion: SingleValue<AdminSingleSectionDataBaseTextVersion | undefined>,
};

export default BaseTextSidebar;
