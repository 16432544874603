import React from 'react';
import { useNavigate } from 'react-router-dom';

import classnames from 'classnames/bind';

import ROUTES from '@CONSTANTS/ROUTES.constant';

import useGlobalPermissions from '@HOOKS/useGlobalPermissions';

import Subheader from '@COMPONENTS/HEADERS/Subheader';
import IconSquareButton from '@COMPONENTS/COMMON/buttons/IconSquareButton';
import Grid from '@COMPONENTS/SHARED/Grid';

import styles from './Main.module.scss';

const cx: CX = classnames.bind(styles);

function MainPage() {
    const {
        canManageVendors,
        canViewLogs,
        canManagePermissions,
    } = useGlobalPermissions();

    const navigate = useNavigate();

    return (
        <div className={cx('page')}>
            <Subheader title="Dashboard" />

            <div className={cx('content-wrapper')}>
                <div className="container max-constraint center">
                    <Grid>
                        <IconSquareButton
                            // locked={!canGenerateDocuments}
                            locked={false}
                            title="Generate New Document"
                            icon="document-generation"
                            onClick={() => {
                                navigate(ROUTES.chooseDocument.path);
                            }}
                        />

                        <IconSquareButton
                            locked={false}
                            title="Manage Document Templates"
                            icon="document-admin"
                            onClick={() => {
                                navigate(ROUTES.admin.documents.path);
                            }}
                        />

                        <IconSquareButton
                            locked={!canManageVendors}
                            title="Manage Vendor Contact Information"
                            icon="vendors"
                            onClick={() => {
                                navigate(ROUTES.admin.manageVendors.path);
                            }}
                        />

                        <IconSquareButton
                            locked={!canManagePermissions}
                            title="Manage Users Permissions"
                            icon="user-permissions"
                            onClick={() => {
                                navigate(ROUTES.admin.manageUsersPermissions.path);
                            }}
                        />

                        <IconSquareButton
                            locked={!canViewLogs}
                            title="Logs"
                            icon="logs"
                            onClick={() => {
                                navigate(ROUTES.admin.logs.path);
                            }}
                        />
                    </Grid>
                </div>
            </div>
        </div>
    );
}

export default MainPage;
