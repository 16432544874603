export const RESULTS_PAGE_SIZE = 40;

export enum LogsActions {
    LOGIN = 1,
    DOWNLOAD_DOCUMENT = 2,
}

export const logsActionsMap = {
    [LogsActions.LOGIN]: 'Login',
    [LogsActions.DOWNLOAD_DOCUMENT]: 'Download document',
};
