import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import COLOR from '@CONSTANTS/COLOR.constant';

import BasicButton from '@COMPONENTS/COMMON/buttons/BasicButton';

function ApplySelectButton(props: Props) {
    const {
        tooltip,
        locked,
        color,
        disabled,
        isProcessing,
        onClick,
    } = props;

    const activeColor = disabled ? COLOR['marriott-light-grey'] : color;

    return (
        <BasicButton
            tooltip={tooltip}
            locked={locked}
            title="&raquo;"
            isProcessing={isProcessing}
            progressIndicatorColor={color}
            progressIndicatorSize={20}
            style={{
                pointerEvents: (disabled || locked) ? 'none' : 'all',
                height: 36,
                width: 36,
                fontSize: 22,
                padding: isProcessing ? '0' : '0 0 3px 1px',
                color: activeColor,
                border: `1px solid ${activeColor}`,
            }}
            onClick={onClick}
        />
    );
}

ApplySelectButton.defaultProps = {
    tooltip: '',
    color: COLOR['marriott-primary'],
    disabled: false,
    isProcessing: false,
};

ApplySelectButton.propTypes = {
    tooltip: PropTypes.string,
    locked: PropTypes.bool.isRequired,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    isProcessing: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};

type Props = InferProps<typeof ApplySelectButton.propTypes> & typeof ApplySelectButton.defaultProps;

export default ApplySelectButton;
