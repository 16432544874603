import React from 'react';
import { useNavigate } from 'react-router-dom';

import classnames from 'classnames/bind';

import ROUTES from '@CONSTANTS/ROUTES.constant';

import useDocumentGenerationSteps from '@HOOKS/store/useDocumentGenerationSteps';
import { useGetAllAreas } from '@SERVICES';

import { AreaStep, AreaStepData, RegionStep } from '@REDUCERS';
import GenerationSteps from '@CONSTANTS/GENERATION_STEPS.constant';

import ContentWithStickySidebar from '@COMPONENTS/SHARED/ContentWithStickySidebar';
import StepSidebar from '@COMPONENTS/SIDEBARS/StepSidebar';
import DataLoader from '@COMPONENTS/SHARED/DataLoader';
import SquareButton from '@COMPONENTS/COMMON/buttons/SquareButton';
import Grid from '@COMPONENTS/SHARED/Grid';

import styles from './Area.module.scss';

const cx: CX = classnames.bind(styles);

function AreaPage() {
    const {
        actions: documentGenerationStepsActions,
        services: documentGenerationStepsServices,
    } = useDocumentGenerationSteps(() => true);

    const regionStep: RegionStep = documentGenerationStepsServices.getStepData(GenerationSteps.REGION);

    const {
        data: areasData = [], isLoading, isError, error,
    } = useGetAllAreas({
        region: regionStep.data?.id,
    });

    const navigate = useNavigate();

    function handleClick(id: number, name: string) {
        const data: AreaStepData = {
            id,
            name,
        };

        documentGenerationStepsActions.apply({
            id: GenerationSteps.AREA,
            isComplete: true,
            data,
        });

        navigate(ROUTES.country.path);
    }

    function getHighlighted(id: number) {
        const step: AreaStep = documentGenerationStepsServices.getStepData(GenerationSteps.AREA);

        return step.data?.id === id;
    }

    return (
        <div className={cx('page')}>
            <div className="container max-constraint center">
                <ContentWithStickySidebar
                    sidebar={<StepSidebar />}
                    offsetTop={130}
                    offsetBottom={100}
                >
                    <h1>Area</h1>
                    <DataLoader
                        isLoading={isLoading}
                        isError={isError}
                        error={error}
                    >
                        <Grid
                            hasSidebar
                        >
                            {
                                areasData.map((area) => {
                                    const key = area.id;

                                    const highlighted = getHighlighted(area.id);

                                    return (
                                        <SquareButton
                                            key={key}
                                            highlighted={highlighted}
                                            title={area.name}
                                            onClick={() => handleClick(
                                                area.id,
                                                area.name,
                                            )}
                                        />
                                    );
                                })
                            }
                        </Grid>
                    </DataLoader>
                </ContentWithStickySidebar>
            </div>
        </div>
    );
}

export default AreaPage;
