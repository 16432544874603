import React from 'react';

import classnames from 'classnames/bind';

import { AdminVendor } from '@SERVICES';

import VendorData from '@PAGES/admin/vendors/components/VendorData';
// import Product from '@PAGES/admin/vendors/components/Product';

import styles from './VendorBody.module.scss';

const cx: CX = classnames.bind(styles);

function VendorBody(props: Props) {
    const { vendor } = props;

    return (
        <div className={cx('vendor-body')}>
            <div className={cx('body-wrapper')}>
                <div className={cx('vendor-data-wrapper')}>
                    <VendorData vendor={vendor} />
                </div>
                {/* <div className={cx('products')}> */}
                {/*    <div className={cx('products-title')}> */}
                {/*        Products */}
                {/*    </div> */}
                {/*    <div className={cx('products-list')}> */}
                {/*        { */}
                {/*            vendor.products.map((product) => { */}
                {/*                const key = product.id; */}

                {/*                return ( */}
                {/*                    <div */}
                {/*                        key={key} */}
                {/*                        className={cx('product-wrapper')} */}
                {/*                    > */}
                {/*                        <Product product={product} /> */}
                {/*                    </div> */}
                {/*                ); */}
                {/*            }) */}
                {/*        } */}
                {/*    </div> */}
                {/* </div> */}
            </div>
        </div>
    );
}

type Props = {
    vendor: AdminVendor,
};

export default VendorBody;
