import React, { useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import { ReactComponent as PDFIcon } from '@ICONS/document-generation-page-icon.svg';

import Tooltip from '@COMPONENTS/SHARED/Tooltip';
import Lock from '@COMPONENTS/SHARED/Lock';
import CircularProgressIndicator from '@COMPONENTS/SHARED/CircularProgressIndicator';

import styles from './GeneratePreviewPDFButton.module.scss';

const cx: CX = classnames.bind(styles);

function GeneratePreviewPDFButton(props: Props) {
    const {
        tooltip,
        isProcessing,
        onClick,
        canGenerateDocument,
    } = props;

    const [tooltipId] = useState(Tooltip.getId());

    const locked = !canGenerateDocument;

    return (
        <div className={cx('generate-preview-pdf-button')}>
            <button
                id={tooltipId}
                type="button"
                className={cx('button', 'clear-button', {
                    'is-processing': isProcessing,
                    locked,
                })}
                onClick={() => {
                    if (!isProcessing && !locked) {
                        onClick();
                    }
                }}
            >
                {
                    locked
                    && (
                        <div className={cx('lock-wrapper')}>
                            <Lock size="small" />
                        </div>
                    )
                }

                {
                    !isProcessing
                        ? (
                            <PDFIcon />
                        )
                        : (
                            <div className={cx('processing')}>
                                <CircularProgressIndicator
                                    color={COLOR['marriott-light-grey']}
                                    size={22}
                                />
                            </div>
                        )
                }

            </button>

            <Tooltip
                id={tooltipId}
                content={tooltip}
                place="left"
                disabled={isProcessing}
            />
        </div>
    );
}

GeneratePreviewPDFButton.defaultProps = {
    tooltip: 'Generate PDF',
};

GeneratePreviewPDFButton.propTypes = {
    tooltip: PropTypes.string,
    isProcessing: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    canGenerateDocument: PropTypes.bool.isRequired,
};

type Props = InferProps<typeof GeneratePreviewPDFButton.propTypes> & typeof GeneratePreviewPDFButton.defaultProps;

export default GeneratePreviewPDFButton;
