import React, { ChangeEvent, useId } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useController, UseControllerProps } from 'react-hook-form';

import classnames from 'classnames/bind';

import styles from './BasicTextarea.module.scss';

const cx: CX = classnames.bind(styles);

function BasicTextareaValidatable<T>(props: Props<T>) {
    const {
        title,
        placeholder,
        onChange,
        validation,
    } = props;

    const inputId = useId();

    const { field, fieldState } = useController<T>(validation);

    function handleChange(event: ChangeEvent<HTMLTextAreaElement>) {
        field.onChange(event.target.value);

        onChange(event.target.value);
    }

    function handleBlur() {
        field.onBlur();
    }

    return (
        <div
            className={cx('basic-textarea', 'validatable', {
                error: !!fieldState.error,
            })}
        >
            <label htmlFor={inputId}>
                <div className={cx('title')}>
                    {title}
                </div>
            </label>
            <textarea
                ref={field.ref}
                id={inputId}
                placeholder={placeholder}
                name={field.name}
                value={field.value as string}
                onChange={handleChange}
                onBlur={handleBlur}
            />
            <div className={cx('error-message')}>
                {fieldState.error && fieldState.error.message}
            </div>
        </div>
    );
}

BasicTextareaValidatable.defaultProps = {
    placeholder: '',
    onChange: (val: string) => {
    },
};

BasicTextareaValidatable.propTypes = {
    title: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
};

type Props<T> =
    InferProps<typeof BasicTextareaValidatable.propTypes>
    & typeof BasicTextareaValidatable.defaultProps
    & { validation: UseControllerProps<T> };

export default BasicTextareaValidatable;
