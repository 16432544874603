import GenerationSteps from '@CONSTANTS/GENERATION_STEPS.constant';

interface IsStepDataEqual {
    prev: any;

    next: any;

    stepId: GenerationSteps;
}

export default function isStepDataEqual({ prev, next, stepId }: IsStepDataEqual): boolean {
    if (!prev && !next) return true;
    if (!prev || !next) return false;

    if (stepId === GenerationSteps.OPTIONS) {
        return (
            prev.id === next.id
            && prev.propertyName === next.propertyName
        );
    }

    if (stepId === GenerationSteps.SELECT_CONTENT) {
        if (prev.variantsIds.length !== next.variantsIds.length) {
            return false;
        }

        if (prev.chaptersIds.length !== next.chaptersIds.length) {
            return false;
        }

        const sortedPrevVariants = [...prev.variantsIds].sort((a, b) => a - b);
        const sortedNextVariants = [...next.variantsIds].sort((a, b) => a - b);

        const sortedPrevChapters = [...prev.chaptersIds].sort((a, b) => a - b);
        const sortedNextChapters = [...next.chaptersIds].sort((a, b) => a - b);

        for (let i = 0; i < sortedPrevVariants.length; i++) {
            if (sortedPrevVariants[i] !== sortedNextVariants[i]) return false;
        }

        for (let i = 0; i < sortedPrevChapters.length; i++) {
            if (sortedPrevChapters[i] !== sortedNextChapters[i]) return false;
        }

        return true;
    }

    if (stepId === GenerationSteps.PREVIEW) {
        return prev.approved === next.approved;
    }

    if (stepId === GenerationSteps.DOWNLOAD_DOCUMENT) {
        return (
            prev.expirationTime === next.expirationTime
            && prev.watermarkText === next.watermarkText
            && prev.link === next.link
        );
    }

    return prev.id === next.id;
}
