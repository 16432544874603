import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import classNames from 'classnames/bind';

import styles from './Tooltip.module.scss';

const cx: CX = classNames.bind(styles);

function Tooltip(props: Props) {
    const {
        id,
        content,
        disabled,
        place,
    } = props;

    if (disabled) return null;

    return (
        <ReactTooltip
            anchorId={id}
            className={cx('tooltip')}
            classNameArrow={cx('arrow')}
            place={place}
        >
            {content}
        </ReactTooltip>
    );
}

Tooltip.getId = function getId() {
    return `tooltip-${(Math.random() * 1000000000).toFixed(0)}`;
};

Tooltip.defaultProps = {
    place: 'top',
};

Tooltip.propTypes = {
    id: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    place: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
};

type Props = InferProps<typeof Tooltip.propTypes> & typeof Tooltip.defaultProps;

export default Tooltip;
