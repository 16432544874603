import React, { useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import {
    DragDropContext, Draggable, DragStart, Droppable, DropResult,
} from 'react-beautiful-dnd';

function reorder<T>(
    list: T[],
    startIndex: number,
    endIndex: number,
): T[] {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    return result;
}

type Item = {
    id: number;
};

function DragAndDropList<T extends Item>(props: Props<T>) {
    const {
        list,
        droppableId,
        onDragStart,
        onDragEnd,
        children,
    } = props;

    const [dragId, setDragId] = useState<number | null>(null);

    function handleDragStart(result: DragStart) {
        setDragId(Number(result.draggableId));

        onDragStart(result);
    }

    function handleDragEnd(result: DropResult) {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const items: T[] = reorder(
            list,
            result.source.index,
            result.destination.index,
        );

        setDragId(null);

        onDragEnd(items);
    }

    return (
        <DragDropContext
            onDragStart={(result: DragStart) => {
                handleDragStart(result);
            }}
            onDragEnd={(result: DropResult) => {
                handleDragEnd(result);
            }}
        >
            <Droppable
                droppableId={droppableId}
            >
                {(dropProvided) => (
                    <div
                        ref={dropProvided.innerRef}
                        {...dropProvided.droppableProps}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                        }}
                    >
                        {list.map((item, index) => {
                            const itemId = item.id;

                            return (
                                <Draggable
                                    key={itemId}
                                    draggableId={itemId.toString()}
                                    index={index}
                                >
                                    {(dragProvided) => (
                                        <div
                                            ref={dragProvided.innerRef}
                                            {...dragProvided.draggableProps}
                                            {...dragProvided.dragHandleProps}
                                            style={{
                                                ...dragProvided.draggableProps.style,
                                                marginBottom: 20,
                                            }}
                                        >
                                            {
                                                children(item, index, dragId)
                                            }
                                        </div>
                                    )}
                                </Draggable>
                            );
                        })}

                        {dropProvided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}

DragAndDropList.defaultProps = {
    onDragStart: (result: DragStart) => {
        //
    },
};

DragAndDropList.propTypes = {
    droppableId: PropTypes.string.isRequired,
    onDragStart: PropTypes.func,
    onDragEnd: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired,
};

type Props<T> = InferProps<typeof DragAndDropList.propTypes> & typeof DragAndDropList.defaultProps & {
    list: T[],
};

export default DragAndDropList;
