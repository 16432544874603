import { ContentChapter } from '@INTERFACES/api/content';

interface Data {
    chaptersData: ContentChapter[];

    initialChaptersIds: Set<number>;

    initialSectionsIds: Set<number>;

    initialVariantsIds: Set<number>;

    selectedTagsIds: Set<number>;

    isPartial: boolean;
}

export default function getDataByTags(
    {
        chaptersData,
        initialChaptersIds,
        initialSectionsIds,
        initialVariantsIds,
        selectedTagsIds,
        isPartial,
    }: Data,
) {
    if (selectedTagsIds.size === 0) {
        return {
            chaptersIds: new Set<number>(initialChaptersIds),
            sectionsIds: new Set<number>(initialSectionsIds),
            variantsIds: new Set<number>(initialVariantsIds),
        };
    }

    const filteredIds = {
        chaptersIds: new Set<number>(),
        sectionsIds: new Set<number>(),
        variantsIds: new Set<number>(),
    };

    chaptersData.forEach((chapter) => {
        const { sections } = chapter;

        let chapterHasTags = false;

        if (initialChaptersIds.has(chapter.id)) {
            chapterHasTags = chapter.tags.some((tag) => selectedTagsIds.has(tag.id));

            if (chapterHasTags) {
                filteredIds.chaptersIds.add(chapter.id);
            }
        }

        sections.forEach((section) => {
            const { variants } = section;

            let sectionHasTags = false;
            const isMandatorySection = (chapter.isMandatory || section.isMandatory) && !isPartial;

            if (initialSectionsIds.has(section.id)) {
                if (chapterHasTags) { // Add all sections if chapter has own tags
                    filteredIds.sectionsIds.add(section.id);
                } else {
                    sectionHasTags = section.tags.some((tag) => selectedTagsIds.has(tag.id));

                    if (sectionHasTags || isMandatorySection) {
                        filteredIds.chaptersIds.add(chapter.id);
                        filteredIds.sectionsIds.add(section.id);
                    }
                }
            }

            variants.forEach((variant) => {
                if (initialVariantsIds.has(variant.id)) {
                    if (chapterHasTags || sectionHasTags) { // Add all variants if chapter or section has own tags
                        filteredIds.variantsIds.add(variant.id);
                    } else {
                        const variantHasTags = variant.tags.some((tag) => selectedTagsIds.has(tag.id));

                        if (variantHasTags || isMandatorySection) {
                            filteredIds.chaptersIds.add(chapter.id);
                            filteredIds.sectionsIds.add(section.id);
                            filteredIds.variantsIds.add(variant.id);
                        }
                    }
                }
            });
        });
    });

    return filteredIds;
}
