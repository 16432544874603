import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { AdminPreviewChapterData } from '@SERVICES';

import SectionStickyHeader from '@MODALS/Previews/components/SectionStickyHeader';
import VariantInfo from '@MODALS/Previews/components/VariantInfo';
import IntroductionTextInfo from '@MODALS/Previews/components/IntroductionTextInfo';
import DocumentPreviewPage from '@COMPONENTS/SHARED/DocumentPreviewPage';
import BaseTextInfo from '@MODALS/Previews/components/BaseTextInfo';

import styles from './Content.module.scss';

const cx: CX = classnames.bind(styles);

function Content(props: Props) {
    const {
        previewData,
        showUnpublished,
        view,
    } = props;

    const { introductionText, sections } = previewData;

    const hideInfo = view === 'filters';

    const introductionTextBody = showUnpublished
        ? (introductionText.body || '')
        : (introductionText.latestVersion?.body || '');

    return (
        <div
            className={cx('content', {
                'hide-info': hideInfo,
            })}
        >
            <div className={cx('item-wrapper')}>
                <div className={cx('info-wrapper', 'container-left')}>
                    <IntroductionTextInfo
                        top={84}
                        chapter={previewData}
                    />
                </div>
                <div className={cx('page-wrapper')}>
                    {
                        introductionTextBody
                            ? (
                                <DocumentPreviewPage
                                    key={previewData.id + showUnpublished.toString()}
                                    body={introductionTextBody}
                                    isLandscape={false}
                                />
                            ) : (
                                <div className={cx('page-placeholder')} />
                            )
                    }
                </div>
            </div>

            {
                sections.length > 0
                    ? sections.map((section) => {
                        const sectionKey = section.id;

                        const { baseText } = section;

                        const baseTextBody = showUnpublished
                            ? (baseText.body || '')
                            : (baseText.latestVersion?.body || '');

                        return (
                            <div
                                key={sectionKey}
                                className={cx('section-wrapper')}
                            >
                                <div className={cx('pages')}>
                                    <SectionStickyHeader
                                        section={section}
                                    />

                                    <div className={cx('item-wrapper')}>
                                        <div className={cx('info-wrapper', 'container-left')}>
                                            <BaseTextInfo
                                                top={84}
                                                section={section}
                                            />
                                        </div>

                                        <div className={cx('page-wrapper')}>
                                            {
                                                baseTextBody
                                                    ? (
                                                        <DocumentPreviewPage
                                                            key={section.id + showUnpublished.toString()}
                                                            body={baseTextBody}
                                                            isLandscape={section.isLandscape}
                                                        />
                                                    ) : (
                                                        <div className={cx('page-placeholder')} />
                                                    )
                                            }
                                        </div>
                                    </div>

                                    {
                                        section.variants.map((variant) => {
                                            const variantKey = variant.id;

                                            const variantBody = showUnpublished
                                                ? (
                                                    baseTextBody + (variant.body || '')
                                                ) : (
                                                    baseTextBody + (variant.latestVersion?.body || '')
                                                );

                                            return (
                                                <div
                                                    key={variantKey}
                                                    className={cx('item-wrapper')}
                                                >
                                                    <div className={cx('info-wrapper', 'container-left')}>
                                                        <VariantInfo
                                                            top={84}
                                                            sectionId={section.id}
                                                            variant={variant}
                                                        />
                                                    </div>
                                                    <div className={cx('page-wrapper')}>
                                                        {
                                                            variantBody
                                                                ? (

                                                                    <DocumentPreviewPage
                                                                        key={showUnpublished.toString()}
                                                                        body={variantBody}
                                                                        isLandscape={section.isLandscape}
                                                                    />
                                                                ) : (
                                                                    <div className={cx('page-placeholder')} />
                                                                )
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        );
                    }) : (
                        <div className={cx('no-sections')}>
                            No sections
                        </div>
                    )
            }
        </div>
    );
}

Content.propTypes = {
    showUnpublished: PropTypes.bool.isRequired,
    view: PropTypes.oneOf(['info', 'filters']).isRequired,
};

type Props = InferProps<typeof Content.propTypes> & {
    previewData: AdminPreviewChapterData,
};

export default Content;
