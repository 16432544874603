import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { ADMIN_API_PREFIX, BASE_URL } from '@CONSTANTS/API.constant';
import {
    AdminSingleSectionData,
    AdminSingleSectionMeta,
    AdminSingleSectionMetaSection,
} from '@INTERFACES/admin-api/sections';
import { AdminSingleChapterDataSection } from '@INTERFACES/admin-api/chapters';

import userRepository from '@REPOSITORIES/local-repository/user-repository';

import {
    chaptersAdminApi,
    documentsAdminApi,
    extractSectionsFilters,
    extractSectionsTags,
    formatVersion, tagsAdminApi,
    unpublishedAdminApi,
} from '@SERVICES';
import { DOCUMENT_PERMISSIONS } from '@CONSTANTS/PERMISSIONS.constant';
import invalidateData from '../helpers/invalidateData';

interface GetByIdApiResponse {
    data: {

        id: number;

        name: string;

        order: number;

        cover: string | null;

        document: {
            id: number;

            name: string;

            owner: number;
        };

        chapter: {
            id: number;

            name: string;
        };

        is_mandatory: boolean;

        is_page_break: boolean;

        is_landscape: boolean;

        mandatory_conditions: {
            id: number;

            brand: {
                id: number;

                name: string;
            } | null;

            region: {
                id: number;

                name: string;
            } | null;

            area: {
                id: number;

                name: string;
            } | null;

            country: {
                id: number;

                name: string;
            } | null;

            property_type: {
                id: number;

                name: string;
            } | null;
        }[];

        base_text: {
            body: string;

            is_published: boolean;

            versions: {
                id: number;

                description: string;

                body: string;

                created_at: string;

                author: {
                    id: number;

                    full_name: string;

                    email: string;
                };
            }[];
        }

        tags: {
            id: number;

            name: string;
        }[];

        vendors: {
            id: number;

            name: string;

            company: string;

            address: string;

            contact: string;

            email: string;

            phone: string;

            is_visible: boolean;
        }[];

        variants: {
            id: number;

            name: string;

            is_default: boolean;

            is_visible: boolean;

            is_published: boolean;

            brand: {
                id: number;

                name: string;
            } | null;

            region: {
                id: number;

                name: string;
            } | null;

            area: {
                id: number;

                name: string;
            } | null;

            country: {
                id: number;

                name: string;
            } | null;

            property_type: {
                id: number;

                name: string;
            } | null;

            body: string;

            tags: {
                id: number;

                name: string;
            }[];

            vendors: {
                id: number;

                name: string;

                company: string;

                address: string;

                contact: string;

                email: string;

                phone: string;

                is_visible: boolean;
            }[];

            versions: {
                id: number;

                description: string;

                body: string;

                created_at: string;

                author: {
                    id: number;

                    full_name: string;

                    email: string;
                };
            }[];
        }[];
    },

    meta: {
        sections: {
            id: number;

            name: string;

            variants: {
                id: number;

                name: string;
            }[];
        }[];

        options: {
            brands: {
                id: number;

                name: string;
            }[];

            regions: {
                id: number;

                name: string;
            }[];

            areas: {
                id: number;

                name: string;

                regions: {
                    id: number;

                    name: string;
                }[];
            }[];

            countries: {
                id: number;

                name: string;

                region: {
                    id: number;

                    name: string;
                } | null;

                areas: {
                    id: number;

                    name: string;
                }[];
            }[];

            property_types: {
                id: number;

                name: string;
            }[];
        };

        permissions: DOCUMENT_PERMISSIONS[];
    },
}

export type SectionHistory = {
    data: SectionHistoryData;
};

export type SectionHistoryData = {
    id: number;

    name: string;

    isLandscape: boolean;

    baseText: SectionHistoryBaseText;

    variants: SectionHistoryVariant[];
};

export type SectionHistoryBaseText = {
    isPublished: boolean;

    versions: SectionHistoryVersion[];
};

export type SectionHistoryVariant = {
    id: number;

    name: string;

    isDefault: boolean;

    isPublished: boolean;

    brand: {
        id: number;

        name: string;
    } | null;

    region: {
        id: number;

        name: string;
    } | null;

    area: {
        id: number;

        name: string;
    } | null;

    country: {
        id: number;

        name: string;
    } | null;

    propertyType: {
        id: number;

        name: string;
    } | null;

    versions: SectionHistoryVersion[];
};

export type SectionHistoryVersion = {
    id: number;

    body: string;

    description: string;

    createdAt: string;

    author: {
        id: number;

        fullName: string;

        email: string;
    }
};

type SectionHistoryApiResponse = {
    id: number;

    name: string;

    is_landscape: boolean;

    base_text: {
        is_published: boolean;

        versions: {
            id: number;

            description: string;

            body: string;

            created_at: string;

            author: {
                id: number;

                full_name: string;

                email: string;
            };
        }[];
    }

    variants: {
        id: number;

        name: string;

        is_default: boolean;

        is_published: boolean;

        brand: {
            id: number;

            name: string;
        } | null;

        region: {
            id: number;

            name: string;
        } | null;

        area: {
            id: number;

            name: string;
        } | null;

        country: {
            id: number;

            name: string;
        } | null;

        property_type: {
            id: number;

            name: string;
        } | null;

        versions: {
            id: number;

            body: string;

            description: string;

            created_at: string;

            author: {
                id: number;

                full_name: string;

                email: string;
            }
        }[];
    }[];
};

type CreateParams = {
    documentId: number;

    chapterId: number;

    name: string;
};

interface CreateApiResponse {
    id: number;

    name: string;

    base_text: {
        body: string;
    };

    is_mandatory: boolean;

    is_page_break: boolean;

    is_landscape: boolean;

    has_mandatory_conditions: boolean;

    cover: string | null;

    order: number;
}

type UpdateParams = {
    id: number;

    name: string;

    body: string;

    isMandatory: boolean;

    isPageBreak: boolean;

    isLandscape: boolean;

    chapterId: number;

    documentId: number;
};

type DeleteParams = {
    id: number;

    documentId: number;

    chapterId: number;
};

interface CopyApiResponse {
    id: number;

    name: string;

    order: number;

    is_mandatory: boolean;

    is_page_break: boolean;

    is_landscape: boolean;

    has_mandatory_conditions: boolean;

    base_text: {
        body: string;

        is_published: boolean;

        version: {
            id: number;

            description: string;

            body: string;

            created_at: string;

            author: {
                id: number;

                full_name: string;

                email: string;
            };
        } | null;
    };

    tags: {
        id: number;

        name: string;
    }[];

    variants: {
        id: number;

        name: string;

        body: string;

        is_default: boolean;

        is_published: boolean;

        version: {
            id: number;

            description: string;

            body: string;

            created_at: string;

            author: {
                id: number;

                full_name: string;

                email: string;
            };
        } | null;

        brand: {
            id: number;

            name: string;
        } | null;

        region: {
            id: number;

            name: string;
        } | null;

        area: {
            id: number;

            name: string;
        } | null;

        country: {
            id: number;

            name: string;
        } | null;

        property_type: {
            id: number;

            name: string;
        } | null;

        tags: {
            id: number;

            name: string;
        }[]
    }[];
}

type CopyParams = {
    documentId: number;

    chapterId: number;

    id: number;
};

type AddVendorApiResponse = {
    vendor_id: number;

    vendor_name: string;

    vendor_company: string;

    vendor_address: string;

    vendor_contact: string;

    vendor_email: string;

    vendor_phone: string;

    vendor_is_visible: boolean;
};

type AddVendorParams = {
    id: number;

    vendorId: number;
};

type DeleteVendorParams = {
    id: number;

    vendorId: number;
};

type CreateAndAddTagParams = {
    documentId: number;

    chapterId: number;

    id: number;

    tagName: string;
};

type AddTagApiResponse = {
    tag_id: number;

    tag_name: string;
};

type AddTagParams = {
    chapterId: number;

    id: number;

    tagId: number;
};

type DeleteTagParams = {
    chapterId: number;

    id: number;

    tagId: number;
};

const SortById = (a: any, b: any) => a.id - b.id;
const SortByOrder = (a: any, b: any) => a.order - b.order;

function updateSingleSectionTags(
    {
        dispatch, id, data,
    }: {
        dispatch: ThunkDispatch<any, any, AnyAction>;
        id: number;
        data: {
            tagId: number;
            tagName: string;
        }
    },
) {
    dispatch(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        sectionsAdminApi.util.updateQueryData('getById', id, (draft) => {
            const tags = [...draft.data.tags, {
                id: data.tagId,
                name: data.tagName,
            }];

            tags.sort(SortById);

            return ({
                data: {
                    ...draft.data,
                    tags,
                },
                meta: draft.meta,
            });
        }),
    );
}

function updateSingleChapterTags(
    {
        dispatch, id, chapterId, data,
    }: {
        dispatch: ThunkDispatch<any, any, AnyAction>;
        chapterId: number;
        id: number;
        data: {
            tagId: number;
            tagName: string;
        }
    },
) {
    dispatch(
        chaptersAdminApi.util.updateQueryData('getById', chapterId, (draft) => ({
            data: {
                ...draft.data,
                sections: draft.data.sections.map((section) => {
                    if (section.id === id) {
                        const tags = [...section.tags, {
                            id: data.tagId,
                            name: data.tagName,
                        }];

                        tags.sort(SortById);

                        return {
                            ...section,
                            tags,
                        };
                    }

                    return section;
                }),
            },
            meta: {
                ...draft.meta,
                tags: extractSectionsTags(draft.data.sections.reduce((acc: any, section: any) => {
                    const { variants } = section;

                    return [
                        ...acc,
                        ...[...section.tags, {
                            id: data.tagId,
                            name: data.tagName,
                        }],
                        ...variants.reduce((innerAcc: any, variant: any) => {
                            const { tags } = variant;

                            return [...innerAcc, ...tags];
                        }, []),
                    ];
                }, [])),
            },
        })),
    );
}

type ReturnType = {
    data: AdminSingleSectionData;
    meta: AdminSingleSectionMeta,
};

function transformGetByIdResponse(raw: GetByIdApiResponse): ReturnType {
    const data: AdminSingleSectionData = {
        id: raw.data.id,
        name: raw.data.name,
        order: raw.data.order,
        cover: raw.data.cover,
        document: raw.data.document,
        chapter: raw.data.chapter,
        isMandatory: raw.data.is_mandatory,
        isPageBreak: raw.data.is_page_break,
        isLandscape: raw.data.is_landscape,
        mandatoryConditions: raw.data.mandatory_conditions.map((con) => ({
            id: con.id,
            brand: con.brand,
            region: con.region,
            area: con.area,
            country: con.country,
            propertyType: con.property_type,
        })),
        baseText: {
            body: raw.data.base_text.body || '',
            isPublished: raw.data.base_text.is_published,
            versions: raw.data.base_text.versions.map((version) => formatVersion(version)),
        },
        tags: raw.data.tags.map((tag) => ({
            id: tag.id,
            name: tag.name,
        })).sort(SortById),
        vendors: raw.data.vendors.map((vendor) => ({
            id: vendor.id,
            name: vendor.name,
            company: vendor.company,
            address: vendor.address,
            contact: vendor.contact || '',
            email: vendor.email,
            phone: vendor.phone,
            isVisible: vendor.is_visible,
        })),
        variants: raw.data.variants.map((variant) => ({
            id: variant.id,
            name: variant.name,
            isDefault: variant.is_default,
            isVisible: variant.is_visible,
            isPublished: variant.is_published,
            brand: variant.brand,
            region: variant.region,
            area: variant.area,
            country: variant.country,
            propertyType: variant.property_type,
            body: variant.body || '',
            tags: variant.tags.map((tag) => ({
                id: tag.id,
                name: tag.name,
            })).sort(SortById),
            vendors: variant.vendors.map((vendor) => ({
                id: vendor.id,
                name: vendor.name,
                company: vendor.company,
                address: vendor.address,
                contact: vendor.contact || '',
                email: vendor.email,
                phone: vendor.phone,
                isVisible: vendor.is_visible,
            })),
            versions: variant.versions.map((version) => formatVersion(version)),
        })),
    };

    const meta = {
        sections: raw.meta.sections.map((section) => ({
            id: section.id,
            name: section.name,
            variants: section.variants.map((variant) => ({
                id: variant.id,
                name: variant.name,
            })),
        })),
        options: {
            brands: raw.meta.options.brands.map((brand) => ({
                id: brand.id,
                name: brand.name,
            })),
            regions: raw.meta.options.regions.map((region) => ({
                id: region.id,
                name: region.name,
            })),
            areas: raw.meta.options.areas.map((area) => ({
                id: area.id,
                name: area.name,
                regions: area.regions.map((region) => ({
                    id: region.id,
                    name: region.name,
                })),
            })),
            countries: raw.meta.options.countries.map((country) => ({
                id: country.id,
                name: country.name,
                region: country.region,
                areas: country.areas.map((area) => ({
                    id: area.id,
                    name: area.name,
                })),
            })),
            propertyTypes: raw.meta.options.property_types.map((propertyType) => ({
                id: propertyType.id,
                name: propertyType.name,
            })),
        },
        permissions: raw.meta.permissions,
    };

    return {
        data,
        meta,
    };
}

function transformHistoryResponse(raw: SectionHistoryApiResponse): SectionHistory {
    return {
        data: {
            id: raw.id,
            name: raw.name,
            isLandscape: raw.is_landscape,
            baseText: {
                isPublished: raw.base_text.is_published,
                versions: raw.base_text.versions.map((version) => formatVersion(version)),
            },
            variants: raw.variants.map((variant) => ({
                id: variant.id,
                name: variant.name,
                isDefault: variant.is_default,
                isPublished: variant.is_published,
                brand: variant.brand,
                region: variant.region,
                area: variant.area,
                country: variant.country,
                propertyType: variant.property_type,
                versions: variant.versions.map((version) => formatVersion(version)),
            })),
        },
    };
}

export const sectionsAdminApi = createApi({
    reducerPath: 'sections-admin-api',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + ADMIN_API_PREFIX,
        prepareHeaders: (headers) => {
            const token = userRepository.getData();

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    tagTypes: ['Section-History'],
    endpoints: (builder) => ({
        getById: builder.query<ReturnType, number>({
            query: (id: number) => `/sections/${id}/`,
            transformResponse(raw: GetByIdApiResponse) {
                return transformGetByIdResponse(raw);
            },
        }),
        history: builder.query<SectionHistory, number>({
            query: (id: number) => ({
                url: `/sections/${id}/history/`,
            }),
            transformResponse(raw: SectionHistoryApiResponse) {
                return transformHistoryResponse(raw);
            },
            providesTags: (result) => (result ? [{
                type: 'Section-History' as const,
                id: result.data.id,
            }] : [{ type: 'Section-History' as const, id: 'history' }]),
        }),
        create: builder.mutation<CreateApiResponse, CreateParams>({
            query: (params) => {
                const formData = new FormData();

                formData.append('chapter', params.chapterId.toString());
                formData.append('name', params.name);

                return {
                    url: '/sections/',
                    method: 'POST',
                    body: formData,
                };
            },
            async onQueryStarted({ documentId, chapterId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: queryData }: { data: CreateApiResponse } = await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    dispatch(
                        unpublishedAdminApi.util.resetApiState(),
                    );

                    // ADD SECTION TO SINGLE DOCUMENT
                    dispatch(
                        documentsAdminApi.util.updateQueryData('getById', documentId, (draft) => ({
                            data: {
                                ...draft.data,
                                chapters: draft.data.chapters.map((chapter) => {
                                    if (chapter.id === chapterId) {
                                        return {
                                            ...chapter,
                                            sections: [
                                                ...chapter.sections,
                                                {
                                                    id: queryData.id,
                                                    name: queryData.name,
                                                    baseText: {
                                                        isPublished: false,
                                                    },
                                                    variants: [],
                                                },
                                            ],
                                        };
                                    }

                                    return chapter;
                                }),
                            },
                            meta: draft.meta,
                        })),
                    );

                    // ADD SECTION TO SINGLE CHAPTER
                    const chapterPatch = dispatch(
                        chaptersAdminApi.util.updateQueryData('getById', chapterId, (draft) => ({
                            data: {
                                ...draft.data,
                                sections: draft.data.sections.concat({
                                    id: queryData.id,
                                    name: queryData.name,
                                    order: queryData.order,
                                    isMandatory: queryData.is_mandatory,
                                    isPageBreak: queryData.is_page_break,
                                    isLandscape: queryData.is_landscape,
                                    hasMandatoryConditions: queryData.has_mandatory_conditions,
                                    baseText: {
                                        body: '',
                                        isPublished: false,
                                        latestVersion: null,
                                    },
                                    tags: [],
                                    variants: [],
                                }),
                            },
                            meta: draft.meta,
                        })),
                    );

                    const data = chapterPatch.patches[0]?.value;

                    if (data) {
                        // ADD SECTION TO EVERY SINGLE SECTION META
                        data.data.sections.forEach((section: AdminSingleSectionMetaSection) => {
                            dispatch(
                                sectionsAdminApi.util.updateQueryData('getById', section.id, (draft) => ({
                                    data: draft.data,
                                    meta: {
                                        ...draft.meta,
                                        sections: [...draft.meta.sections, {
                                            id: queryData.id,
                                            name: queryData.name,
                                            variants: [],
                                        }],
                                    },
                                })),
                            );
                        });
                    }
                } catch (e) {
                    //
                }
            },
        }),
        update: builder.mutation({
            query: (params: UpdateParams) => {
                const formData = new FormData();

                formData.append('name', params.name);
                formData.append('body', params.body);
                formData.append('is_mandatory', params.isMandatory.toString() || '');
                formData.append('is_page_break', params.isPageBreak.toString() || '');
                formData.append('is_landscape', params.isLandscape.toString() || '');

                return {
                    url: `/sections/${params.id}/`,
                    method: 'PATCH',
                    body: formData,
                };
            },
            async onQueryStarted(
                {
                    id, chapterId, documentId,
                },
                { dispatch, queryFulfilled },
            ) {
                try {
                    const { data: queryData } = await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    dispatch(
                        unpublishedAdminApi.util.resetApiState(),
                    );

                    // UPDATE SECTION IN SINGLE DOCUMENT
                    dispatch(
                        documentsAdminApi.util.updateQueryData('getById', documentId, (draft) => ({
                            data: {
                                ...draft.data,
                                chapters: draft.data.chapters.map((chapter) => {
                                    if (chapter.id === chapterId) {
                                        return {
                                            ...chapter,
                                            sections: chapter.sections.map((section) => {
                                                if (section.id === id) {
                                                    return {
                                                        ...section,
                                                        name: queryData.name,
                                                        baseText: {
                                                            ...section.baseText,
                                                            isPublished: queryData.is_published,
                                                        },
                                                    };
                                                }

                                                return section;
                                            }),
                                        };
                                    }

                                    return chapter;
                                }),
                            },
                            meta: draft.meta,
                        })),
                    );

                    // UPDATE SECTION IN SINGLE CHAPTER
                    dispatch(
                        chaptersAdminApi.util.updateQueryData('getById', chapterId, (draft) => ({
                            data: {
                                ...draft.data,
                                sections: draft.data.sections.map((section) => {
                                    if (section.id === id) {
                                        return {
                                            ...section,
                                            name: queryData.name,
                                            isMandatory: queryData.is_mandatory,
                                            isPageBreak: queryData.is_page_break,
                                            isLandscape: queryData.is_landscape,
                                            baseText: {
                                                ...section.baseText,
                                                body: queryData.body || '',
                                                isPublished: queryData.is_published,
                                            },
                                        };
                                    }

                                    return section;
                                }),
                            },
                            meta: draft.meta,
                        })),
                    );

                    // UPDATE SECTION IN CURRENT SINGLE SECTION
                    const sectionPatch = dispatch(
                        sectionsAdminApi.util.updateQueryData('getById', id, (draft) => ({
                            data: {
                                ...draft.data,
                                name: queryData.name,
                                isMandatory: queryData.is_mandatory,
                                isPageBreak: queryData.is_page_break,
                                isLandscape: queryData.is_landscape,
                                baseText: {
                                    ...draft.data.baseText,
                                    body: queryData.body || '',
                                    isPublished: queryData.is_published,
                                },
                            },
                            meta: draft.meta,
                        })),
                    );

                    const data = sectionPatch.patches[0]?.value;

                    if (data) {
                        // UPDATE SECTION IN EVERY SINGLE SECTION META
                        data.meta.sections.forEach((section: AdminSingleSectionMetaSection) => {
                            dispatch(
                                sectionsAdminApi.util.updateQueryData('getById', section.id, (draft) => ({
                                    data: draft.data,
                                    meta: {
                                        ...draft.meta,
                                        sections: draft.meta.sections.map((s) => {
                                            if (s.id === id) {
                                                return {
                                                    ...s,
                                                    name: queryData.name,
                                                };
                                            }

                                            return s;
                                        }),
                                    },
                                })),
                            );
                        });
                    }
                } catch (e) {
                    //
                }
            },
        }),
        delete: builder.mutation(({
            query: (params: DeleteParams) => ({
                url: `/sections/${params.id}/`,
                method: 'DELETE',
            }),
            async onQueryStarted({ id, documentId, chapterId }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    dispatch(
                        unpublishedAdminApi.util.resetApiState(),
                    );

                    dispatch(
                        sectionsAdminApi.util.resetApiState(),
                    );

                    // DELETE SECTION FROM SINGLE DOCUMENT
                    dispatch(
                        documentsAdminApi.util.updateQueryData('getById', documentId, (draft) => ({
                            data: {
                                ...draft.data,
                                chapters: draft.data.chapters.map((chapter) => {
                                    if (chapter.id === chapterId) {
                                        return {
                                            ...chapter,
                                            sections: chapter.sections.filter((s) => s.id !== id),
                                        };
                                    }

                                    return chapter;
                                }),
                            },
                            meta: draft.meta,
                        })),
                    );

                    // DELETE SECTION FROM SINGLE CHAPTER
                    const chapterPatch = dispatch(
                        chaptersAdminApi.util.updateQueryData('getById', chapterId, (draft) => ({
                            data: {
                                ...draft.data,
                                sections: draft.data.sections.filter((s) => s.id !== id),
                            },
                            meta: {
                                ...draft.meta,
                                tags: extractSectionsTags(draft.data.sections
                                    .filter((s) => s.id !== id)
                                    .reduce((acc: any, section: any) => {
                                        const { variants } = section;

                                        return [
                                            ...acc,
                                            ...section.tags,
                                            ...variants.reduce((innerAcc: any, variant: any) => {
                                                const { tags } = variant;

                                                return [...innerAcc, ...tags];
                                            }, []),
                                        ];
                                    }, [])),
                                filters: extractSectionsFilters(draft.data.sections
                                    .filter((s) => s.id !== id)
                                    .reduce((acc: any, section: any) => {
                                        const { variants } = section;

                                        return [...acc, ...variants.reduce((innerAcc: any, variant: any) => [...innerAcc, {
                                            brand: variant.brand,
                                            region: variant.region,
                                            area: variant.area,
                                            country: variant.country,
                                            propertyType: variant.property_type,
                                        }], [])];
                                    }, [])),
                            },
                        })),
                    );

                    const data = chapterPatch.patches[0]?.value;

                    if (data) {
                        // DELETE SECTION FROM EVERY SINGLE SECTION META
                        data.data.sections.forEach((section: AdminSingleSectionMetaSection) => {
                            dispatch(
                                sectionsAdminApi.util.updateQueryData('getById', section.id, (draft) => ({
                                    data: draft.data,
                                    meta: {
                                        ...draft.meta,
                                        sections: draft.meta.sections.filter((s) => s.id !== id),
                                    },
                                })),
                            );
                        });
                    }
                } catch (e) { /**/
                }
            },
            invalidatesTags: (result, error, arg) => [{ type: 'Section-History', id: arg.id }],
        })),
        copy: builder.mutation<CopyApiResponse, CopyParams>({
            query: (params) => ({
                url: `/sections/${params.id}/copy/`,
                method: 'POST',
            }),
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                const {
                    documentId,
                    chapterId,
                } = args;

                try {
                    const { data: queryData } = await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    dispatch(
                        unpublishedAdminApi.util.resetApiState(),
                    );

                    // COPY SECTION IN SINGLE DOCUMENT
                    dispatch(
                        documentsAdminApi.util.updateQueryData('getById', documentId, (draft) => ({
                            data: {
                                ...draft.data,
                                chapters: draft.data.chapters.map((chapter) => {
                                    if (chapter.id === chapterId) {
                                        return {
                                            ...chapter,
                                            sections: [
                                                ...chapter.sections,
                                                {
                                                    id: queryData.id,
                                                    name: queryData.name,
                                                    baseText: {
                                                        isPublished: queryData.base_text.is_published,
                                                    },
                                                    variants: queryData.variants.map((variant) => ({
                                                        id: variant.id,
                                                        isPublished: variant.is_published,
                                                    })),
                                                },
                                            ].sort(SortByOrder),
                                        };
                                    }

                                    return chapter;
                                }),
                            },
                            meta: draft.meta,
                        })),
                    );

                    // COPY SECTION IN SINGLE CHAPTER
                    const sectionPatch = dispatch(
                        chaptersAdminApi.util.updateQueryData('getById', chapterId, (draft) => ({
                            data: {
                                ...draft.data,
                                sections: [
                                    ...draft.data.sections,
                                    {
                                        id: queryData.id,
                                        name: queryData.name,
                                        order: queryData.order,
                                        isMandatory: queryData.is_mandatory,
                                        isPageBreak: queryData.is_page_break,
                                        isLandscape: queryData.is_landscape,
                                        hasMandatoryConditions: queryData.has_mandatory_conditions,
                                        baseText: {
                                            body: queryData.base_text.body || '',
                                            isPublished: queryData.base_text.is_published,
                                            latestVersion: queryData.base_text.version ? formatVersion(queryData.base_text.version) : null,
                                        },
                                        tags: queryData.tags.map((tag) => ({
                                            id: tag.id,
                                            name: tag.name,
                                        })).sort(SortById),
                                        variants: queryData.variants.map((variant) => ({
                                            id: variant.id,
                                            name: variant.name,
                                            body: variant.body || '',
                                            isDefault: variant.is_default,
                                            isPublished: variant.is_published,
                                            latestVersion: variant.version ? formatVersion(variant.version) : null,
                                            brand: variant.brand,
                                            region: variant.region,
                                            area: variant.area,
                                            country: variant.country,
                                            propertyType: variant.property_type,
                                            tags: variant.tags.map((tag) => ({
                                                id: tag.id,
                                                name: tag.name,
                                            })).sort(SortById),
                                        })),
                                    },
                                ].sort(SortByOrder),
                            },
                            meta: draft.meta,
                        })),
                    );

                    const data = sectionPatch.patches[0]?.value;

                    if (data) {
                        // COPY SECTION IN EVERY SINGLE SECTION META
                        data.data.sections.forEach((section: AdminSingleChapterDataSection) => {
                            dispatch(
                                sectionsAdminApi.util.updateQueryData('getById', section.id, (draft) => ({
                                    data: draft.data,
                                    meta: {
                                        ...draft.meta,
                                        sections: [
                                            ...draft.meta.sections,
                                            {
                                                id: queryData.id,
                                                name: queryData.name,
                                                variants: queryData.variants.map((variant) => ({
                                                    id: variant.id,
                                                    name: variant.name,
                                                })),
                                            },
                                        ].sort(SortByOrder),
                                    },
                                })),
                            );
                        });
                    }
                } catch (e) {
                    //
                }
            },
        }),
        createAndAddTag: builder.mutation<AddTagApiResponse, CreateAndAddTagParams>({
            query: (params) => {
                const formData = JSON.stringify({
                    tag_name: params.tagName,
                });

                return {
                    url: `/sections/${params.id}/tags/`,
                    method: 'POST',
                    body: formData,
                };
            },
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                const { id, documentId, chapterId } = args;

                try {
                    const { data: queryData } = await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    // ADD TAG TO SINGLE SECTION
                    updateSingleSectionTags({
                        dispatch,
                        id,
                        data: {
                            tagId: queryData.tag_id,
                            tagName: queryData.tag_name,
                        },
                    });

                    // ADD TAG TO SINGLE CHAPTER
                    updateSingleChapterTags({
                        dispatch,
                        chapterId,
                        id,
                        data: {
                            tagId: queryData.tag_id,
                            tagName: queryData.tag_name,
                        },
                    });

                    // ADD TAG TO DOCUMENT
                    dispatch(
                        tagsAdminApi.util.updateQueryData('getAll', { documentId }, (draft) => ({
                            data: [...draft.data, {
                                id: queryData.tag_id,
                                name: queryData.tag_name,
                            }],
                        })),
                    );
                } catch (e) {
                    //
                }
            },
        }),
        addTag: builder.mutation<AddTagApiResponse, AddTagParams>({
            query: (params) => {
                const formData = JSON.stringify({
                    tag_id: params.tagId,
                });

                return {
                    url: `/sections/${params.id}/tags/`,
                    method: 'PATCH',
                    body: formData,
                };
            },
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                const { id, chapterId } = args;

                try {
                    const { data: queryData } = await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    // ADD TAG TO SINGLE SECTION
                    updateSingleSectionTags({
                        dispatch,
                        id,
                        data: {
                            tagId: queryData.tag_id,
                            tagName: queryData.tag_name,
                        },
                    });

                    // ADD TAG TO SINGLE CHAPTER
                    updateSingleChapterTags({
                        dispatch,
                        chapterId,
                        id,
                        data: {
                            tagId: queryData.tag_id,
                            tagName: queryData.tag_name,
                        },
                    });
                } catch (e) {
                    //
                }
            },
        }),
        deleteTag: builder.mutation<void, DeleteTagParams>({
            query: (params) => {
                const formData = JSON.stringify({
                    tag_id: params.tagId,
                });

                return {
                    url: `/sections/${params.id}/tags/`,
                    method: 'DELETE',
                    body: formData,
                };
            },
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                const { id, chapterId, tagId } = args;

                try {
                    await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    // DELETE TAG FROM SINGLE SECTION
                    dispatch(
                        sectionsAdminApi.util.updateQueryData('getById', id, (draft) => ({
                            data: {
                                ...draft.data,
                                tags: draft.data.tags.filter((tag) => tag.id !== tagId),
                            },
                            meta: draft.meta,
                        })),
                    );

                    // DELETE TAG FROM SINGLE CHAPTER
                    dispatch(
                        chaptersAdminApi.util.updateQueryData('getById', chapterId, (draft) => ({
                            data: {
                                ...draft.data,
                                sections: draft.data.sections.map((section) => {
                                    if (section.id === id) {
                                        return {
                                            ...section,
                                            tags: section.tags.filter((tag) => tag.id !== tagId),
                                        };
                                    }

                                    return section;
                                }),
                            },
                            meta: {
                                ...draft.meta,
                                tags: extractSectionsTags(draft.data.sections.reduce((acc: any, section: any) => {
                                    const { variants } = section;

                                    return [
                                        ...acc,
                                        ...section.tags.filter((tag: any) => tag.id !== tagId),
                                        ...variants.reduce((innerAcc: any, variant: any) => {
                                            const { tags } = variant;

                                            return [...innerAcc, ...tags];
                                        }, []),
                                    ];
                                }, [])),
                            },
                        })),
                    );
                } catch (e) {
                    //
                }
            },
        }),
        addVendor: builder.mutation<AddVendorApiResponse, AddVendorParams>({
            query: (params) => {
                const formData = JSON.stringify({
                    vendor_id: params.vendorId,
                });

                return {
                    url: `/sections/${params.id}/vendors/`,
                    method: 'PATCH',
                    body: formData,
                };
            },
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                try {
                    const { data: queryData } = await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    // ADD VENDOR TO SINGLE SECTION
                    dispatch(
                        sectionsAdminApi.util.updateQueryData('getById', id, (draft) => ({
                            data: {
                                ...draft.data,
                                vendors: [...draft.data.vendors, {
                                    id: queryData.vendor_id,
                                    name: queryData.vendor_name,
                                    company: queryData.vendor_company,
                                    address: queryData.vendor_address,
                                    contact: queryData.vendor_contact || '',
                                    email: queryData.vendor_email,
                                    phone: queryData.vendor_phone,
                                    isVisible: queryData.vendor_is_visible,
                                }].sort(SortById),
                            },
                            meta: draft.meta,
                        })),
                    );
                } catch (e) {
                    //
                }
            },
        }),
        deleteVendor: builder.mutation<void, DeleteVendorParams>({
            query: (params) => {
                const formData = JSON.stringify({
                    vendor_id: params.vendorId,
                });

                return {
                    url: `/sections/${params.id}/vendors/`,
                    method: 'DELETE',
                    body: formData,
                };
            },
            async onQueryStarted({ id, vendorId }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;

                    // INVALIDATE DATA
                    invalidateData(dispatch);

                    // DELETE VENDOR FROM SINGLE SECTION
                    dispatch(
                        sectionsAdminApi.util.updateQueryData('getById', id, (draft) => ({
                            data: {
                                ...draft.data,
                                vendors: draft.data.vendors.filter((vendor) => vendor.id !== vendorId),
                            },
                            meta: draft.meta,
                        })),
                    );
                } catch (e) {
                    //
                }
            },
        }),
    }),
});

export const {
    useGetByIdQuery: useGetSectionByIdAdmin,
    useLazyGetByIdQuery: useGetSectionByIdAdminLazy,
    useHistoryQuery: useSectionHistory,
    useCreateMutation: useCreateSection,
    useUpdateMutation: useUpdateSection,
    useDeleteMutation: useDeleteSection,
    useCopyMutation: useCopySection,
    useCreateAndAddTagMutation: useCreateAndAddTagToSection,
    useAddTagMutation: useAddTagToSection,
    useDeleteTagMutation: useDeleteTagFromSection,
    useAddVendorMutation: useAddVendorToSection,
    useDeleteVendorMutation: useDeleteVendorFromSection,
} = sectionsAdminApi;
