import React, { MouseEvent, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import { ReactComponent as PreviewIcon } from '@ICONS/preview-icon.svg';
import { ReactComponent as SettingsIcon } from '@ICONS/settings-icon.svg';
import { ReactComponent as ClockIcon } from '@ICONS/clock-icon.svg';
import { ReactComponent as GridIcon } from '@ICONS/grid-icon.svg';
import { ReactComponent as ListIcon } from '@ICONS/list-icon.svg';
import { ReactComponent as EditIcon } from '@ICONS/edit-icon-2.svg';

import Tooltip from '@COMPONENTS/SHARED/Tooltip';
import styles from './BasicIconButton.module.scss';

const cx: CX = classnames.bind(styles);

const icons: { [key: string]: any } = {
    preview: (props: Partial<Props>) => <PreviewIcon {...props} />,
    settings: (props: Partial<Props>) => <SettingsIcon {...props} />,
    clock: (props: Partial<Props>) => <ClockIcon {...props} />,
    grid: (props: Partial<Props>) => <GridIcon {...props} />,
    list: (props: Partial<Props>) => <ListIcon {...props} />,
    edit: (props: Partial<Props>) => <EditIcon {...props} />,
};

function BasicIconButton(props: Props) {
    const {
        tooltip,
        icon,
        color,
        width,
        height,
        className,
        style,
        onClick,
    } = props;

    const [tooltipId] = useState(Tooltip.getId());

    function handleClick(event: MouseEvent<HTMLButtonElement>) {
        event.preventDefault();

        onClick(event);
    }

    const Icon = icons[icon];

    return (
        <>
            <div className={cx('basic-icon-button')}>
                <button
                    id={tooltipId}
                    type="button"
                    className={['clear-button', className].join(' ')}
                    style={style}
                    onClick={handleClick}
                >
                    <div className={cx('icon-wrapper')}>
                        <Icon fill={color} width={width} height={height} />
                    </div>
                </button>
            </div>
            <Tooltip
                id={tooltipId}
                content={tooltip}
                disabled={false}
            />
        </>
    );
}

BasicIconButton.defaultProps = {
    color: COLOR['marriott-dark-grey'],
    className: '',
    style: {},
};

BasicIconButton.propTypes = {
    tooltip: PropTypes.string.isRequired,
    icon: PropTypes.oneOf(
        ['preview', 'settings', 'clock', 'grid', 'list', 'edit'],
    ).isRequired,
    color: PropTypes.string,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    className: PropTypes.string,
    style: PropTypes.shape({}),
    onClick: PropTypes.func.isRequired,
};

type Props = InferProps<typeof BasicIconButton.propTypes> & typeof BasicIconButton.defaultProps;

export default BasicIconButton;
