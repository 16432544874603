import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classNames from 'classnames/bind';

import styles from './SimpleLargeModalHeader.module.scss';

const cx: CX = classNames.bind(styles);

function SimpleLargeModalHeader(props: Props) {
    const { modalTitle, title, onClose } = props;

    return (
        <div className={cx('simple-large-modal-header')}>
            <div className={cx('title')}>
                {modalTitle}
            </div>
            <div className={cx('variant-title')}>
                {title}
            </div>
            <div className={cx('close-button-wrapper')}>
                <button
                    type="button"
                    className={cx('clear-button', 'close-button')}
                    onClick={onClose}
                >
                    &times;
                </button>
            </div>
        </div>
    );
}

SimpleLargeModalHeader.propTypes = {
    modalTitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

type Props = InferProps<typeof SimpleLargeModalHeader.propTypes>;

export default SimpleLargeModalHeader;
