import { useMemo } from 'react';
import { EqualityFn } from 'react-redux';

import { useAppDispatch, useAppSelector } from '@STORE/index';

import { deleteVariantModalSlice, DeleteVariantModalDispatchParams, DeleteVariantModalState } from '@REDUCERS';

function createDeleteVariantModalHook() {
    return function useDeleteVariantModal(equalityFn?: EqualityFn<DeleteVariantModalState>) {
        const state = useAppSelector((store) => store.deleteVariantModal, equalityFn);

        const dispatch = useAppDispatch();

        const actions = useMemo(() => ({
            open: (params: DeleteVariantModalDispatchParams) => dispatch(deleteVariantModalSlice.actions.open(params)),
            close: () => dispatch(deleteVariantModalSlice.actions.close()),
        }), [dispatch]);

        return {
            state,
            actions,
        };
    };
}

export default createDeleteVariantModalHook();
