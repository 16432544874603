import { useMemo } from 'react';
import { EqualityFn } from 'react-redux';

import { useAppDispatch, useAppSelector } from '@STORE/index';

import { previewDocumentModalSlice, PreviewDocumentModalState, PreviewDocumentModalDispatchParams } from '@REDUCERS';

function createPreviewDocumentModalHook() {
    return function usePreviewDocumentModal(equalityFn?: EqualityFn<PreviewDocumentModalState>) {
        const state = useAppSelector((store) => store.previewDocumentModal, equalityFn);

        const dispatch = useAppDispatch();

        const actions = useMemo(() => ({
            open: (params: PreviewDocumentModalDispatchParams) => dispatch(previewDocumentModalSlice.actions.open(params)),
            close: () => dispatch(previewDocumentModalSlice.actions.close()),
        }), [dispatch]);

        return {
            state,
            actions,
        };
    };
}

export default createPreviewDocumentModalHook();
