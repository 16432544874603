import React from 'react';

import classnames from 'classnames/bind';

import usePublishDocumentModal from '@HOOKS/store/modals/usePublishDocumentModal';

import BaseModal from '@MODALS/BaseModal';
import SimpleLargeModalHeader from '@COMPONENTS/SHARED/SimpleLargeModalHeader';
import ModalBody from '@MODALS/Publications/PublishDocumentModal/components/ModalBody';

import styles from './PublishDocumentModal.module.scss';

const cx: CX = classnames.bind(styles);

function PublishDocumentModal() {
    const {
        state: { data: modalParams },
        actions: publishDocumentModalActions,
    } = usePublishDocumentModal(() => true);

    const { documentData } = modalParams!;

    function handleClose() {
        publishDocumentModalActions.close();
    }

    return (
        <BaseModal
            style={{
                top: 30,
                height: '100%',
                width: 'calc(100% - 60px)',
            }}
            onClose={() => handleClose()}
        >
            <div className={cx('publish-document-modal')}>
                <div className={cx('header-wrapper')}>
                    <SimpleLargeModalHeader
                        modalTitle="Publish document"
                        title={documentData.name}
                        onClose={() => handleClose()}
                    />
                </div>
                <div className={cx('content')}>
                    <ModalBody
                        documentData={documentData}
                    />
                </div>
            </div>
        </BaseModal>
    );
}

export default PublishDocumentModal;
