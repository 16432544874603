import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classNames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import { AdminSingleSectionData, AdminSingleSectionMeta } from '@INTERFACES/admin-api/sections';

import usePreviewSectionBaseTextModal from '@HOOKS/store/modals/usePreviewSectionBaseTextModal';
import useSectionBaseTextHistoryModal from '@HOOKS/store/modals/useSectionBaseTextHistoryModal';
import useDocumentPermissions from '@HOOKS/useDocumentPermissions';

import StickyHeader from '@COMPONENTS/HEADERS/StickyHeader';
import OpenCloseButton from '@COMPONENTS/COMMON/buttons/OpenCloseButton';

import BasicIconButton from '@COMPONENTS/COMMON/buttons/BasicIconButton';
import styles from './BaseTextStickyHeader.module.scss';

const cx: CX = classNames.bind(styles);

function BaseTextStickyHeader(props: Props) {
    const {
        sectionData,
        sectionMeta,
        top,
        isBaseTextOpen,
        setBaseTextOpen,
    } = props;

    const {
        canGenerateDocument,
    } = useDocumentPermissions({
        documentOwnerId: sectionData.document.owner,
        permissions: sectionMeta.permissions,
    });

    const {
        actions: previewSectionBaseTextModalActions,
    } = usePreviewSectionBaseTextModal(() => true);

    const {
        actions: sectionBaseTextHistoryModalActions,
    } = useSectionBaseTextHistoryModal(() => true);

    return (
        <StickyHeader
            top={top}
        >
            <div className={cx('base-text-sticky-header', 'container')}>
                <div className={cx('title-wrapper')}>
                    <div className={cx('title')}>
                        Base text
                    </div>

                    <div className={cx('buttons')}>
                        <div className={cx('button-wrapper')}>
                            <div className={cx('button')}>
                                <BasicIconButton
                                    tooltip="Base text preview"
                                    icon="preview"
                                    width={18}
                                    height={18}
                                    color={COLOR['marriott-light-grey']}
                                    onClick={() => {
                                        previewSectionBaseTextModalActions.open({
                                            sectionData,
                                            canGenerateDocument,
                                        });
                                    }}
                                />
                            </div>
                        </div>

                        <div className={cx('button-wrapper')}>
                            <div className={cx('button')}>
                                <BasicIconButton
                                    tooltip="Base text history"
                                    icon="clock"
                                    width={18}
                                    height={18}
                                    color={COLOR['marriott-light-grey']}
                                    onClick={() => {
                                        sectionBaseTextHistoryModalActions.open({
                                            sectionData,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <OpenCloseButton
                    isOpen={isBaseTextOpen}
                    backgroundColor={COLOR['marriott-light-grey']}
                    iconColor={COLOR['app-white']}
                    onClick={() => {
                        setBaseTextOpen((val: boolean) => !val);
                    }}
                />
            </div>
        </StickyHeader>
    );
}

BaseTextStickyHeader.propTypes = {
    top: PropTypes.number.isRequired,
    isBaseTextOpen: PropTypes.bool.isRequired,
    setBaseTextOpen: PropTypes.func.isRequired,
};

type Props = InferProps<typeof BaseTextStickyHeader.propTypes> & {
    sectionData: AdminSingleSectionData,
    sectionMeta: AdminSingleSectionMeta,
};

export default BaseTextStickyHeader;
