import React, { ForwardedRef } from 'react';

const HiddenFormButton = React.forwardRef((props, ref: ForwardedRef<HTMLButtonElement>) => (
    <button
        ref={ref}
        type="submit"
        className="visually-hidden"
    >
        _
    </button>
));

HiddenFormButton.displayName = 'HiddenFormButton';

export default HiddenFormButton;
