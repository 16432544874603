import React from 'react';

import classnames from 'classnames/bind';

import styles from './Header.module.scss';

const cx: CX = classnames.bind(styles);

function Header(props: OnlyChildrenProps) {
    const { children } = props;

    return (
        <div className={cx('header')}>
            {children}
        </div>
    );
}

export default Header;
