export const ApplicationInventoryTemplate = `
<table style="width: 612px; border-collapse: collapse; height: 830.907px; border-width: 0px; border-spacing: 2px; border-style: none;" border="1">
  <colgroup>
    <col style="width: 182px;">
    <col style="width: 15px;">
    <col style="width: 415px;">
  </colgroup>
  <tbody>
  <tr style="height: 22px;">
    <td style="text-align: right; border-width: 0px; height: 22px; padding: 2px;"><strong>&nbsp;</strong></td>
    <td style="border-width: 0px; height: 22px; padding: 2px;">&nbsp;</td>
    <td style="border-width: 0px; height: 22px; padding: 2px;"><strong>&nbsp;</strong></td>
  </tr>
  <tr style="height: 23.8438px;">
    <td style="text-align: right; height: 23.8438px; border-width: 0px; background-color: rgb(167, 181, 190); padding: 2px;">
      <strong>Application</strong></td>
    <td style="height: 23.8438px; border-width: 0px; background-color: rgb(167, 181, 190); padding: 2px;">&nbsp;</td>
    <td style="height: 23.8438px; border-width: 0px; background-color: rgb(167, 181, 190); padding: 2px;"><strong>Property
      Management System</strong></td>
  </tr>
  <tr style="height: 23.8438px;">
    <td style="text-align: right; height: 23.8438px; border-width: 0px; padding: 2px; vertical-align: top;">&nbsp;</td>
    <td style="height: 23.8438px; border-width: 0px; padding: 2px; vertical-align: top;">&nbsp;</td>
    <td style="height: 23.8438px; border-width: 0px; padding: 2px; vertical-align: top;">&nbsp;</td>
  </tr>
  <tr style="height: 23.8438px;">
    <td style="text-align: right; height: 23.8438px; border-width: 0px; padding: 2px; vertical-align: top;">
      <em>Product</em></td>
    <td style="height: 23.8438px; border-width: 0px; padding: 2px; vertical-align: top;">&nbsp;</td>
    <td style="height: 23.8438px; border-width: 0px; padding: 2px; vertical-align: top; text-align: justify;">Opera
      Property Management System
    </td>
  </tr>
  <tr style="height: 23.8438px;">
    <td style="text-align: right; height: 23.8438px; border-width: 0px; padding: 2px; vertical-align: top;">
      <em>Vendor</em></td>
    <td style="height: 23.8438px; border-width: 0px; padding: 2px; vertical-align: top;">&nbsp;</td>
    <td style="height: 23.8438px; border-width: 0px; padding: 2px; vertical-align: top; text-align: justify;">Oracle
      Hospitality
    </td>
  </tr>
  <tr style="height: 23.8438px;">
    <td style="text-align: right; height: 23.8438px; border-width: 0px; padding: 2px; vertical-align: top;">&nbsp;</td>
    <td style="height: 23.8438px; border-width: 0px; padding: 2px; vertical-align: top;">&nbsp;</td>
    <td style="height: 23.8438px; border-width: 0px; padding: 2px; vertical-align: top; text-align: justify;">&nbsp;
    </td>
  </tr>
  <tr style="height: 23.8438px;">
    <td style="text-align: right; height: 23.8438px; border-width: 0px; padding: 2px; vertical-align: top;"><em>Hosted/on-premises</em>
    </td>
    <td style="height: 23.8438px; border-width: 0px; padding: 2px; vertical-align: top;">&nbsp;</td>
    <td style="height: 23.8438px; border-width: 0px; padding: 2px; vertical-align: top; text-align: justify;">Opera PMS
      can be installed locally on premises or hosted
    </td>
  </tr>
  <tr style="height: 23.8438px;">
    <td style="text-align: right; height: 23.8438px; border-width: 0px; padding: 2px; vertical-align: top;">&nbsp;</td>
    <td style="height: 23.8438px; border-width: 0px; padding: 2px; vertical-align: top;">&nbsp;</td>
    <td style="height: 23.8438px; border-width: 0px; padding: 2px; vertical-align: top; text-align: justify;">&nbsp;
    </td>
  </tr>
  <tr style="height: 112px;">
    <td style="text-align: right; height: 112px; border-width: 0px; padding: 2px; vertical-align: top;"><em>Application
      Details</em></td>
    <td style="height: 112px; border-width: 0px; padding: 2px; vertical-align: top;">&nbsp;</td>
    <td style="height: 112px; border-width: 0px; padding: 2px; vertical-align: top; text-align: justify;">The Property
      Management System (PMS) manages reservations, checking guests in and out, assigning rooms and managing room
      inventory, accommodating in-house guest needs, and handling accounting and billing. It is the central point of
      integration into a number of other hotel systems and booking channels.
    </td>
  </tr>
  <tr style="height: 40px;">
    <td style="text-align: right; height: 40px; border-width: 0px; padding: 2px; vertical-align: top;"><em>Application
      Modules</em></td>
    <td style="height: 40px; border-width: 0px; padding: 2px; vertical-align: top;">&nbsp;</td>
    <td style="height: 40px; border-width: 0px; padding: 2px; vertical-align: top; text-align: justify;">Front Office,
      Housekeeping, Reservations, Accounts Receivable, Travel Agent Processing
    </td>
  </tr>
  <tr style="height: 328px;">
    <td style="text-align: right; height: 328px; border-width: 0px; padding: 2px; vertical-align: top;">
      <em>Interfaces</em></td>
    <td style="height: 328px; border-width: 0px; padding: 2px; vertical-align: top;">&nbsp;</td>
    <td style="height: 328px; border-width: 0px; padding: 2px; vertical-align: top; text-align: justify;">Interfaces
      including but not limited to<br>PeopleSoft Back Office<br>PABX<br>Interactive TV System<br>Guest Internet
      Access<br>Telephone Call Accounting System<br>Voice Mail System<br>Simphony Point of Sale<br>Spasoft<br>Door
      Locking System<br>Digital Signage<br>Marsha Two Way Interface<br>SFA Web Interface (when Opera S&amp;C is
      purchased)<br>GXP Interface<br>Third party Credit Card Handling System<br>Guest Room Management System (If
      Required)<br>Building Management System (If Required)<br>Book4Time
    </td>
  </tr>
  <tr style="height: 58px;">
    <td style="text-align: right; height: 58px; border-width: 0px; padding: 2px; vertical-align: top;">
      <em>Licensing</em></td>
    <td style="height: 58px; border-width: 0px; padding: 2px; vertical-align: top;">&nbsp;</td>
    <td style="height: 58px; border-width: 0px; padding: 2px; vertical-align: top; text-align: justify;">Opera: Based on
      Inventory of Rooms,<br>Interfaces: One License per interface capped at 4 per property.
    </td>
  </tr>
  <tr style="height: 40px;">
    <td style="text-align: right; height: 40px; border-width: 0px; padding: 2px; vertical-align: top;"><em>Scope</em>
    </td>
    <td style="height: 40px; border-width: 0px; padding: 2px; vertical-align: top;">&nbsp;</td>
    <td style="height: 40px; border-width: 0px; padding: 2px; vertical-align: top; text-align: justify;">Delivery,
      Installation, Configuration, Training and Live Coverage Post Installation.
    </td>
  </tr>
  <tr style="height: 40px;">
    <td style="text-align: right; height: 40px; border-width: 0px; padding: 2px; vertical-align: top;"><em>Service Level
      Agreement</em></td>
    <td style="height: 40px; border-width: 0px; padding: 2px; vertical-align: top;">&nbsp;</td>
    <td style="height: 40px; border-width: 0px; padding: 2px; vertical-align: top; text-align: justify;">24x7: 4 Hour
      extended support with software assurance
    </td>
  </tr>
  <tr style="height: 24px;">
    <td style="text-align: right; height: 24px; border-width: 0px; padding: 2px; vertical-align: top;">&nbsp;</td>
    <td style="height: 24px; border-width: 0px; padding: 2px; vertical-align: top;">&nbsp;</td>
    <td style="height: 24px; border-width: 0px; padding: 2px; vertical-align: top; text-align: justify;">&nbsp;</td>
  </tr>
  </tbody>
</table>
<p>&nbsp;</p>
`;
