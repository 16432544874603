import React, { useEffect, useState } from 'react';

import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import {
    AdminSingleSectionData,
    AdminSingleSectionMeta,
} from '@INTERFACES/admin-api/sections';
import useDocumentPermissions from '@HOOKS/useDocumentPermissions';
import { useDeleteSectionMandatoryCondition } from '@SERVICES';

import MandatorySidebar from '@PAGES/admin/edit-section/components/MandatorySidebar';
import ConditionItem from '@PAGES/admin/edit-section/components/ConditionItem';

import styles from './Mandatory.module.scss';

const cx: CX = classnames.bind(styles);

function Mandatory(props: Props) {
    const {
        sectionData,
        sectionMeta,
    } = props;

    const { mandatoryConditions } = sectionData;

    const {
        canEditDocument,
    } = useDocumentPermissions({
        documentOwnerId: sectionData.document.owner,
        permissions: sectionMeta.permissions,
    });

    const [
        deleteCondition,
        {
            isLoading, isSuccess, isError, error, originalArgs,
        },
    ] = useDeleteSectionMandatoryCondition();

    const [isAlwaysMandatory, setAlwaysMandatory] = useState<boolean>(sectionData.isMandatory);

    useEffect(() => {
        if (isSuccess) {
            notify.info('Condition has been deleted', {
                toastId: 'delete-mandatory-condition-from-section',
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            if ((error as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'delete-mandatory-condition-from-section-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'delete-mandatory-condition-from-section-error',
                });
            }
        }
    }, [isError, error]);

    return (
        <div className={cx('mandatory', 'container')}>
            <div className={cx('body')}>
                <div className={cx('body-wrapper')}>
                    <div className={cx('conditions')}>
                        <div
                            className={cx('conditions-wrapper', {
                                hide: sectionData.isMandatory,
                            })}
                        >
                            {
                                mandatoryConditions.length > 0
                                    ? (
                                        <div className={cx('conditions-list')}>
                                            {
                                                mandatoryConditions.map((condition) => {
                                                    const key = condition.id;

                                                    const isProcessing = originalArgs?.id === condition.id && isLoading;

                                                    return (
                                                        <div
                                                            key={key}
                                                            className={cx('condition-item-wrapper')}
                                                        >
                                                            <ConditionItem
                                                                condition={condition}
                                                                locked={!canEditDocument}
                                                                isProcessing={isProcessing}
                                                                onDeleteClick={() => {
                                                                    if (!sectionData.isMandatory) {
                                                                        deleteCondition({
                                                                            id: condition.id,
                                                                            sectionId: sectionData.id,
                                                                        });
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    ) : (
                                        <div className={cx('no-conditions')}>
                                            No conditions
                                        </div>
                                    )
                            }
                        </div>
                    </div>
                    <div className={cx('sidebar-wrapper')}>
                        <MandatorySidebar
                            sectionData={sectionData}
                            sectionMeta={sectionMeta}
                            isAlwaysMandatory={isAlwaysMandatory}
                            setAlwaysMandatory={setAlwaysMandatory}
                            conditions={mandatoryConditions}
                            locked={!canEditDocument}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

type Props = {
    sectionData: AdminSingleSectionData,
    sectionMeta: AdminSingleSectionMeta,
};

export default Mandatory;
