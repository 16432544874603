type Option = {
    id: number;

    name: string;
} | null;

type Options = {
    brand: Option,
    region: Option,
    area: Option,
    country: Option,
    propertyType: Option,
};

export function extractSectionsFilters(items: Options[]) {
    const brandsSet = new Set<number>();
    const regionsSet = new Set<number>();
    const areasSet = new Set<number>();
    const countriesSet = new Set<number>();
    const propertyTypesSet = new Set<number>();

    const filters: {
        brands: Option[];
        regions: Option[];
        areas: Option[];
        countries: Option[];
        propertyTypes: Option[];
    } = {
        brands: [],
        regions: [],
        areas: [],
        countries: [],
        propertyTypes: [],
    };

    items.forEach((item: any) => {
        const {
            brand, region, area, country, propertyType,
        } = item;

        if (brand && !brandsSet.has(brand.id)) {
            filters.brands.push(brand);

            brandsSet.add(brand.id);
        }

        if (region && !regionsSet.has(region.id)) {
            filters.regions.push(region);

            regionsSet.add(region.id);
        }

        if (area && !areasSet.has(area.id)) {
            filters.areas.push(area);

            areasSet.add(area.id);
        }

        if (country && !countriesSet.has(country.id)) {
            filters.countries.push(country);

            countriesSet.add(country.id);
        }

        if (propertyType && !propertyTypesSet.has(propertyType.id)) {
            filters.propertyTypes.push(propertyType);

            propertyTypesSet.add(propertyType.id);
        }
    });

    return filters;
}
