import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import GenerationSteps from '@CONSTANTS/GENERATION_STEPS.constant';

import { notify } from '@NOTIFICATION/Notificator';

import useStepsValidation from '@VALIDATION/hooks/useStepsValidation';

export const notificationMap = {
    [GenerationSteps.DOCUMENT]: 'Choose Document',
    [GenerationSteps.BRAND]: 'Brand',
    [GenerationSteps.REGION]: 'Region',
    [GenerationSteps.AREA]: 'Area',
    [GenerationSteps.COUNTRY]: 'Country',
    [GenerationSteps.TYPE]: 'Type',
    [GenerationSteps.OPTIONS]: 'Options',
    [GenerationSteps.SELECT_CONTENT]: 'Select Content',
    [GenerationSteps.PREVIEW]: 'Preview',
    [GenerationSteps.DOWNLOAD_DOCUMENT]: 'Download Document',
};

function StepsValidator() {
    const canActivateStep = useStepsValidation();

    const location = useLocation();

    const { canActivate, redirect } = canActivateStep(location.pathname as GenerationSteps);

    useEffect(() => {
        if (!canActivate) {
            notify.error(`"${notificationMap[redirect]}" step is required!`, {
                toastId: `steps-validation-${redirect}`,
            });
        }
    }, [canActivate, redirect]);

    if (!canActivate) {
        return <Navigate to={redirect} state={{ from: location }} replace />;
    }

    return (
        <Outlet />
    );
}

export default StepsValidator;
