import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import { useNavigate } from 'react-router-dom';
import classnames from 'classnames/bind';

import ROUTES from '@CONSTANTS/ROUTES.constant';

import { AdminSearchInsideChapter, useSearchInsideChapterLazy } from '@SERVICES';

import ResultSetWrapper from '@COMPONENTS/HEADERS/breadcrumbs/AdminBreadclumbs/components/ResultSetWrapper';

import ResultItemTitle from '@COMPONENTS/HEADERS/breadcrumbs/AdminBreadclumbs/components/ResultItemTitle';
import DocumentPreviewPage from '@COMPONENTS/SHARED/DocumentPreviewPage';
import SectionStickyHeader from '@COMPONENTS/HEADERS/breadcrumbs/AdminBreadclumbs/components/SectionStickyHeader';

import styles from './ChapterResults.module.scss';

const cx: CX = classnames.bind(styles);

function ChapterResults(props: Props) {
    const {
        id,
        q,
        setLoading,
        isFocused,
        setFocused,
    } = props;

    const [
        searchInsideChapter,
        {
            data, isLoading, isError, error, requestId,
        },
    ] = useSearchInsideChapterLazy();

    const navigate = useNavigate();

    const [results, setResults] = useState<AdminSearchInsideChapter | undefined>(undefined);

    const showResultSet = (
        q.length > 2
        && results !== undefined
        && isFocused
    );

    useLayoutEffect(() => {
        setLoading(isLoading);
    }, [setLoading, isLoading]);

    useLayoutEffect(() => {
        if (data) {
            setResults(data);
        }
    }, [data, requestId]);

    useLayoutEffect(() => {
        if (q.length <= 2) {
            setResults(undefined);
        }
    }, [q]);

    useEffect(() => {
        if (q.trim().length > 2) {
            const result = searchInsideChapter({
                id,
                q,
            });

            return () => {
                result.abort();
            };
        }
    }, [id, q, searchInsideChapter]);

    if (!showResultSet) return null;

    const count = results?.meta.count || 0;
    const resultsQuery = results?.meta.query || '';

    return (
        <ResultSetWrapper
            setFocused={setFocused}
            count={count}
            resultsQuery={resultsQuery}
        >
            <div className={cx('chapter-results')}>
                {
                    results?.data.body
                    && (
                        <div className={cx('chapter-introduction-text')}>
                            <ResultItemTitle
                                title="Introduction text"
                                onClick={() => {
                                    setFocused(false);
                                    navigate(ROUTES.admin.chapters.id.get(results?.data.id));
                                }}
                            />
                            <DocumentPreviewPage
                                isLandscape={false}
                                body={results.data.body}
                                isPreview
                            />
                        </div>
                    )

                }

                {
                    results?.data.sections.length > 0
                    && (
                        <div className={cx('sections-list')}>
                            {
                                results?.data.sections.map((section) => {
                                    const sectionKey = section.id;

                                    return (
                                        <div
                                            key={sectionKey}
                                            className={cx('section')}
                                        >

                                            <SectionStickyHeader
                                                name={section.name}
                                                top={1}
                                                zIndex={5}
                                            />

                                            {
                                                section.body
                                                && (
                                                    <div className={cx('section-base-text')}>
                                                        <ResultItemTitle
                                                            title="Base text"
                                                            onClick={() => {
                                                                navigate(ROUTES.admin.sections.id.get(section.id));
                                                            }}
                                                        />

                                                        <DocumentPreviewPage
                                                            isLandscape={section.isLandscape}
                                                            body={section.body}
                                                            isPreview
                                                        />
                                                    </div>
                                                )
                                            }

                                            {
                                                section.variants.length > 0
                                                && (
                                                    <div className={cx('variants-list')}>
                                                        {
                                                            section.variants.map((variant) => {
                                                                const variantKey = variant.id;

                                                                return (
                                                                    <div
                                                                        key={variantKey}
                                                                        className={cx('variant')}
                                                                    >
                                                                        <ResultItemTitle
                                                                            title={variant.name}
                                                                            onClick={() => {
                                                                                navigate(ROUTES.admin.sections.id.get(section.id, variant.id));
                                                                            }}
                                                                        />

                                                                        <DocumentPreviewPage
                                                                            isLandscape={variant.isLandscape}
                                                                            body={variant.body}
                                                                            isPreview
                                                                        />
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                    )
                }
            </div>
        </ResultSetWrapper>
    );
}

ChapterResults.propTypes = {
    id: PropTypes.number.isRequired,
    q: PropTypes.string.isRequired,
    setLoading: PropTypes.func.isRequired,
    isFocused: PropTypes.bool.isRequired,
    setFocused: PropTypes.func.isRequired,
};

type Props = InferProps<typeof ChapterResults.propTypes>;

export default ChapterResults;
