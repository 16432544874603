import React, { useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useForm } from 'react-hook-form';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import classnames from 'classnames/bind';

import TabRadio from '@COMPONENTS/COMMON/inputs/radio/TabRadio';
import BasicTextareaValidatable
    from '@COMPONENTS/COMMON/inputs/textarea/BasicTextarea/BasicTextareaValidatable.component';

import styles from './PublicationForm.module.scss';

const cx: CX = classnames.bind(styles);

const radios = [
    {
        id: 1,
        name: 'Yes',
    },
    {
        id: 2,
        name: 'No',
    },
];

enum Form {
    DESCRIPTION = 'description',
}

yup.setLocale({
    mixed: {
        required: () => 'Description field is required',
    },
});

const schema = yup.object({
    [Form.DESCRIPTION]: yup.string().required(),
});

export type PublishFormValues = {
    [Form.DESCRIPTION]: string;
};

function PublicationForm(props: Props) {
    const { children, onSave } = props;

    const [activeId, setActiveId] = useState(radios[1].id);

    const includeToVersionControl = activeId === 1;

    const {
        handleSubmit, control,
    } = useForm<PublishFormValues>({
        defaultValues: {
            [Form.DESCRIPTION]: '',
        },
        resolver: includeToVersionControl ? yupResolver(schema) : undefined,
        mode: 'onChange',
    });

    function handleSave(formValues: PublishFormValues) {
        onSave(formValues, includeToVersionControl);
    }

    return (
        <div className={cx('publication-form')}>
            <div className={cx('radios-wrapper')}>
                <TabRadio
                    title="Include changes to version control?"
                    items={radios}
                    activeId={activeId}
                    onChange={(id: number) => {
                        setActiveId(id);
                    }}
                />
            </div>

            <form onSubmit={handleSubmit(handleSave)}>
                {
                    includeToVersionControl
                    && (
                        <div className={cx('textarea-wrapper')}>
                            <BasicTextareaValidatable<PublishFormValues>
                                title="Description of changes"
                                placeholder="Description"
                                validation={{
                                    name: Form.DESCRIPTION,
                                    control,
                                    shouldUnregister: true,
                                }}
                            />
                        </div>
                    )
                }
                {children}
            </form>
        </div>
    );
}

PublicationForm.propTypes = {
    onSave: PropTypes.func.isRequired,
};

type Props = InferProps<typeof PublicationForm.propTypes> & OnlyChildrenProps;

export default PublicationForm;
