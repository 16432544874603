import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import {
    contentApi, documentApi, previewAdminApi, previewApi, searchAdminApi,
} from '@SERVICES';
import { documentGenerationStepsSlice } from '@REDUCERS';

import stepsRepository from '@REPOSITORIES/local-repository/steps-repository';

export default function invalidateData(dispatch: ThunkDispatch<any, any, AnyAction>) {
    dispatch(
        documentApi.util.resetApiState(),
    );
    dispatch(
        contentApi.util.resetApiState(),
    );
    dispatch(
        previewApi.util.resetApiState(),
    );
    dispatch(
        previewAdminApi.util.resetApiState(),
    );
    dispatch(
        searchAdminApi.util.resetApiState(),
    );

    dispatch(documentGenerationStepsSlice.actions.reset());

    stepsRepository.clear();
}
