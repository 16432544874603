import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import DESIGN from '@CONSTANTS/DESIGN.constant';

import { PreviewData } from '@INTERFACES/api/preview';

import Chapter from '@PAGES/manager/preview/components/Chapter';

import styles from './Content.module.scss';

const cx: CX = classnames.bind(styles);

const top = DESIGN['subheader-height'] + DESIGN['step-breadcrumbs-height'];

function Content(props: Props) {
    const {
        previewData,
        isPartial,
    } = props;

    return (
        <div className={cx('content')}>
            <div className={cx('chapters')}>
                {
                    previewData.map((chapter) => {
                        const key = chapter.id;

                        return (
                            <div
                                key={key}
                                className={cx('chapter-wrapper')}
                            >
                                <Chapter
                                    top={top}
                                    chapter={chapter}
                                    isPartial={isPartial}
                                />
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

Content.propTypes = {
    isPartial: PropTypes.bool.isRequired,
};

type Props = InferProps<typeof Content.propTypes> & {
    previewData: PreviewData,
};

export default Content;
