import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import getDate from '@HELPERS/getDate';

import styles from './LatestUpdates.module.scss';

const cx: CX = classnames.bind(styles);

function LatestUpdates(props: Props) {
    const {
        includedToVersionControl,
        version,
    } = props;

    return (
        <div className={cx('version-info')}>
            <div className={cx('latest-updates-title')}>
                Last updates
            </div>

            {
                includedToVersionControl
                    ? (
                        <div className={cx('included-to-version-control')}>
                            Included to version control
                        </div>
                    ) : (
                        <div className={cx('included-to-version-control', 'not-in-control')}>
                            Not included to version control
                        </div>
                    )
            }

            <div className={cx('version-info-item')}>
                <div className={cx('version-info-title')}>
                    Author
                </div>

                <div className={cx('version-info-data')}>
                    {version.author.fullName}
                </div>

                <div className={cx('version-info-data')}>
                    {version.author.email}
                </div>
            </div>

            {
                includedToVersionControl
                && (
                    <div className={cx('version-info-item')}>
                        <div className={cx('version-info-title')}>
                            Description
                        </div>

                        <div className={cx('version-info-data')}>
                            {version.description || '-'}
                        </div>
                    </div>
                )
            }

            <div className={cx('version-info-item')}>
                <div className={cx('version-info-title')}>
                    Date
                </div>

                <div className={cx('version-info-data')}>
                    {`Published: ${getDate(version.createdAt)}`}
                </div>
            </div>
        </div>
    );
}

LatestUpdates.propTypes = {
    includedToVersionControl: PropTypes.bool.isRequired,
    version: PropTypes.exact({
        description: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired,
        author: PropTypes.exact({
            fullName: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

type Props = InferProps<typeof LatestUpdates.propTypes>;

export default LatestUpdates;
