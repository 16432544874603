import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { ReactComponent as ArrowForwardIcon } from '@ICONS/arrow-forward-icon.svg';

import COLOR from '@CONSTANTS/COLOR.constant';

import styles from './Crumb.module.scss';

const cx: CX = classnames.bind(styles);

function Crumb(props: Props) {
    const {
        name, isActive, isLast, onClick,
    } = props;

    return (
        <div className={cx('crumb')}>
            <div
                className={cx({
                    content: true,
                    'is-active': isActive,
                })}
            >
                <div className={cx('content-wrapper')}>
                    <button
                        type="button"
                        className="clear-button"
                        onClick={onClick}
                    >
                        <span className={cx('title')}>{name}</span>
                    </button>
                    {
                        !isLast
                        && (
                            <div className={cx('arrow')}>
                                <ArrowForwardIcon
                                    width={14}
                                    height={14}
                                    fill={COLOR['marriott-light-grey']}
                                />
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

Crumb.propTypes = {
    name: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    isLast: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

type Props = InferProps<typeof Crumb.propTypes>;

export default Crumb;
