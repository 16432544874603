enum GenerationSteps {
    DOCUMENT = '/document',
    BRAND = '/brand',
    REGION = '/region',
    AREA = '/area',
    COUNTRY = '/country',
    TYPE = '/type',
    OPTIONS = '/options',
    SELECT_CONTENT = '/select-content',
    PREVIEW = '/preview',
    DOWNLOAD_DOCUMENT = '/download-document',
}

export default GenerationSteps;
