import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import BasicInput from '@COMPONENTS/COMMON/inputs/input/BasicInput';

import styles from './UsersPermissionsSidebar.module.scss';

const cx: CX = classnames.bind(styles);

function UsersPermissionsSidebar(props: Props) {
    const { searchValue, setSearchValue } = props;

    function handleInputChange(val: string) {
        setSearchValue(val);
    }

    return (
        <div className={cx('users-permissions-sidebar')}>
            <div className="container-left">
                <div className={cx('sidebar-title')}>
                    Filter users
                </div>
                <div className={cx('input-wrapper')}>
                    <BasicInput
                        value={searchValue}
                        label="Search"
                        placeholder="Search by user name"
                        onChange={(val: string) => {
                            handleInputChange(val);
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

UsersPermissionsSidebar.propTypes = {
    searchValue: PropTypes.string.isRequired,
    setSearchValue: PropTypes.func.isRequired,
};

type Props = InferProps<typeof UsersPermissionsSidebar.propTypes>;

export default UsersPermissionsSidebar;
