import { useMemo } from 'react';
import { EqualityFn } from 'react-redux';

import { useAppDispatch, useAppSelector } from '@STORE/index';

import {
    PublishChapterIntroductionTextModalDispatchParams,
    publishChapterIntroductionTextModalSlice,
    PublishChapterIntroductionTextModalState,
} from '@REDUCERS';

function createPublishChapterIntroductionTextModalHook() {
    return function usePublishChapterIntroductionText(equalityFn?: EqualityFn<PublishChapterIntroductionTextModalState>) {
        const state = useAppSelector((store) => store.publishChapterIntroductionTextModal, equalityFn);

        const dispatch = useAppDispatch();

        const actions = useMemo(() => ({
            open: (params: PublishChapterIntroductionTextModalDispatchParams) => dispatch(publishChapterIntroductionTextModalSlice.actions.open(params)),
            close: () => dispatch(publishChapterIntroductionTextModalSlice.actions.close()),
        }), [dispatch]);

        return {
            state,
            actions,
        };
    };
}

export default createPublishChapterIntroductionTextModalHook();
