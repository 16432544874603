import React, { useEffect } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import ROUTES from '@CONSTANTS/ROUTES.constant';

import {
    AdminSingleChapterData,
    AdminSingleChapterDataSection,
    AdminSingleChapterMeta,
} from '@INTERFACES/admin-api/chapters';

import { useCopySection } from '@SERVICES';
import useDeleteSectionModal from '@HOOKS/store/modals/useDeleteSectionModal';
import usePreviewSectionModal from '@HOOKS/store/modals/usePreviewSectionModal';
import useDocumentPermissions from '@HOOKS/useDocumentPermissions';
import useSectionHistoryModal from '@HOOKS/store/modals/useSectionHistoryModal';

import TripleCardButtons from '@COMPONENTS/COMMON/buttons/TripleCardButtons';
import RoundIconButton from '@COMPONENTS/COMMON/buttons/RoundIconButton';
import OpenCloseButton from '@COMPONENTS/COMMON/buttons/OpenCloseButton';

import styles from './SectionHeader.module.scss';

const cx: CX = classnames.bind(styles);

function SectionHeader(props: Props) {
    const {
        section,
        chapterData,
        chapterMeta,
        index,
        isOpen,
        onOpenToggle,
    } = props;

    const {
        canEditDocument,
        canGenerateDocument,
    } = useDocumentPermissions({
        documentOwnerId: chapterData.document.owner,
        permissions: chapterMeta.permissions,
    });

    const [
        copySection,
        {
            isLoading: isCopyLoading,
            isSuccess: isCopySuccess,
            isError: isCopyError,
            error: copyError,
        },
    ] = useCopySection();

    const {
        actions: deleteSectionActions,
    } = useDeleteSectionModal(() => true);

    const {
        actions: previewSectionModalActions,
    } = usePreviewSectionModal(() => true);

    const {
        actions: sectionHistoryModalActions,
    } = useSectionHistoryModal(() => true);

    const navigate = useNavigate();

    const title = `Section ${index + 1} - ${section.name}`;

    const isPublished = (
        section.variants.every((variant) => variant.isPublished)
        && section.baseText.isPublished
    );

    useEffect(() => {
        if (isCopySuccess) {
            notify.info('Section has been copied', {
                toastId: 'copy-section',
            });
        }
    }, [isCopySuccess]);

    useEffect(() => {
        if (isCopyError) {
            if ((copyError as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'copy-section-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'copy-section-error',
                });
            }
        }
    }, [isCopyError, copyError]);

    return (
        <div
            className={cx('section-header', {
                'is-close': !isOpen,
            })}
        >
            {
                !isPublished && <div className={cx('accent')} />
            }

            <div className={cx('title-wrapper')}>
                <div className={cx('buttons')}>
                    <div className={cx('triple-buttons-wrapper')}>
                        <TripleCardButtons
                            locked={{
                                edit: false,
                                copy: !canEditDocument,
                                delete: !canEditDocument,
                            }}
                            processing={{
                                edit: false,
                                copy: isCopyLoading,
                                delete: false,
                            }}
                            tooltips={{
                                edit: 'Edit section',
                                copy: 'Copy section',
                                delete: 'Delete section',
                            }}
                            onEdit={() => {
                                navigate(ROUTES.admin.sections.id.get(section.id));
                            }}
                            onCopy={() => {
                                copySection({
                                    documentId: chapterData.document.id,
                                    chapterId: chapterData.id,
                                    id: section.id,
                                });
                            }}
                            onDelete={() => {
                                deleteSectionActions.open({
                                    id: section.id,
                                    name: section.name,
                                    documentId: chapterData.document.id,
                                    chapterId: chapterData.id,
                                });
                            }}
                        />
                    </div>
                    <div className={cx('button-wrapper')}>
                        <RoundIconButton
                            tooltipTitle="Section preview"
                            icon="preview"
                            onClick={() => {
                                previewSectionModalActions.open({
                                    documentId: chapterData.document.id,
                                    sectionId: section.id,
                                    sections: chapterData.sections,
                                    canGenerateDocument,
                                });
                            }}
                        />
                    </div>
                    <div className={cx('button-wrapper')}>
                        <RoundIconButton
                            tooltipTitle="Section history"
                            icon="history"
                            onClick={() => {
                                sectionHistoryModalActions.open({
                                    sectionId: section.id,
                                    sections: chapterData.sections,
                                });
                            }}
                        />
                    </div>
                </div>

                <div className={cx('title')}>
                    {title}
                </div>
            </div>
            <div className={cx('header-buttons')}>
                <OpenCloseButton
                    isOpen={isOpen}
                    onClick={() => {
                        onOpenToggle(!isOpen);
                    }}
                />
            </div>
        </div>
    );
}

SectionHeader.propTypes = {
    index: PropTypes.number.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onOpenToggle: PropTypes.func.isRequired,
};

type Props = InferProps<typeof SectionHeader.propTypes> & {
    section: AdminSingleChapterDataSection,
    chapterData: AdminSingleChapterData,
    chapterMeta: AdminSingleChapterMeta,
};

export default SectionHeader;
