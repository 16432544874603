/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminSingleDocumentData } from '@SERVICES';

export interface DocumentNameModalState {
    isOpen: boolean;

    data: {
        documentData: AdminSingleDocumentData;
    } | null;
}

export interface DocumentNameModalDispatchParams {
    documentData: AdminSingleDocumentData,
}

const initialState: DocumentNameModalState = {
    isOpen: false,
    data: null,
};

export const documentNameModalSlice = createSlice({
    name: 'document-name-modal',
    initialState,
    reducers: {
        open(state: DocumentNameModalState, action: PayloadAction<DocumentNameModalDispatchParams>) {
            state.isOpen = true;
            state.data = action.payload;
        },
        close(state: DocumentNameModalState) {
            state.isOpen = false;
            state.data = null;
        },
    },
});
