import React, { useState } from 'react';

import classnames from 'classnames/bind';

import usePreviewDocumentModal from '@HOOKS/store/modals/usePreviewDocumentModal';

import BaseModal from '@MODALS/BaseModal';
import ModalHeader from '@MODALS/Previews/PreviewDocumentModal/compontents/ModalHeader';
import ModalBody from '@MODALS/Previews/PreviewDocumentModal/compontents/ModalBody';

import styles from './PreviewDocumentModal.module.scss';

const cx: CX = classnames.bind(styles);

function PreviewDocumentModal() {
    const {
        state: { data: modalParams },
        actions: previewDocumentModalActions,
    } = usePreviewDocumentModal();

    const { documentData, canGenerateDocument } = modalParams!;

    const [showUnpublished, setShowUnpublished] = useState<boolean>(false);

    const isPublished = documentData.chapters.every(
        (chapter) => (
            chapter.introductionText.isPublished
            && chapter.sections.every((section) => (
                section.baseText.isPublished
                && section.variants.every((v) => v.isPublished)
            ))
        ),
    );

    return (
        <BaseModal
            style={{
                top: 30,
                height: '100%',
                width: 'calc(100% - 60px)',
            }}
            onClose={() => {
                previewDocumentModalActions.close();
            }}
        >
            <div className={cx('preview-document-modal')}>
                <div className={cx('header-wrapper')}>
                    <ModalHeader
                        isPublished={isPublished}
                        showUnpublished={showUnpublished}
                        setShowUnpublished={setShowUnpublished}
                        canGenerateDocument={canGenerateDocument}
                    />
                </div>
                <div className={cx('content')}>
                    <ModalBody
                        documentData={documentData}
                        showUnpublished={showUnpublished}
                    />
                </div>
            </div>
        </BaseModal>
    );
}

export default PreviewDocumentModal;
