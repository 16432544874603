import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import useScrollLock from '@HOOKS/useScrollLock';

import styles from './BaseModal.module.scss';

const cx: CX = classnames.bind(styles);

const portal = document.getElementById('modals');

function BaseModal(props: Props) {
    const {
        style, onClose, children,
    } = props;

    const { lock, unlock } = useScrollLock();

    useEffect(() => {
        lock();

        return () => {
            unlock();
        };
    }, [lock, unlock]);

    function handleClose() {
        onClose();
    }

    return ReactDOM.createPortal(
        <div className={cx('base-modal')}>
            <div
                role="button"
                tabIndex={0}
                className={cx('bg')}
                onClick={handleClose}
            />
            <div
                style={style}
                className={cx('content')}
            >
                {children}
            </div>
        </div>,
        portal!,
    );
}

BaseModal.defaultProps = {
    style: {},
};

BaseModal.propTypes = {
    style: PropTypes.shape({}),
    onClose: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]).isRequired,
};

type Props = InferProps<typeof BaseModal.propTypes> & typeof BaseModal.defaultProps;

export default BaseModal;
