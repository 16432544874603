/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { stepsRules } from '@VALIDATION/helpers/validateSteps';

import GenerationSteps from '@CONSTANTS/GENERATION_STEPS.constant';

import { GenerationMode } from '@PAGES/manager/options/Options.page';
import { Expiration } from '@PAGES/manager/download-document/DownloadDocument.page';

import isStepDataEqual from './helpers/isStepDataEqual';

interface BaseStep {
    id: GenerationSteps;

    isComplete: boolean;
}

//
export type DocumentStepData = {
    id: number;
    name: string;
} | null;

export interface DocumentStep extends BaseStep {
    data: DocumentStepData;
}

//
export type BrandStepData = {
    id: number;
    name: string;
} | null;

export interface BrandStep extends BaseStep {
    data: BrandStepData;
}

//
export type RegionStepData = {
    id: number;
    name: string;
} | null;

export interface RegionStep extends BaseStep {
    data: RegionStepData;
}

//
export type AreaStepData = {
    id: number;
    name: string;
} | null;

export interface AreaStep extends BaseStep {
    data: AreaStepData;
}

//
export type CountryStepData = {
    id: number;
    name: string;
} | null;

export interface CountryStep extends BaseStep {
    data: CountryStepData;
}

//
export type TypeStepData = {
    id: number;
    name: string;
} | null;

export interface TypeStep extends BaseStep {
    data: TypeStepData;
}

//
export type OptionsStepData = {
    id: number;
    propertyName: string;
};

export interface OptionsStep extends BaseStep {
    data: OptionsStepData;
}

//
export type SelectContentStepData = {
    chaptersIds: number[];
    sectionsIds: number[];
    variantsIds: number[];
    tagsIds: number[];
} | null;

export interface SelectContentStep extends BaseStep {
    data: SelectContentStepData;
}

//
export type PreviewStepData = {
    approved: boolean;
} | null;

export interface PreviewStep extends BaseStep {
    data: PreviewStepData;
}

//
export type DownloadDocumentStepData = {
    expirationTime: Expiration;
    watermarkText: string;
    link: string;
} | null;

export interface DownloadDocumentStep extends BaseStep {
    data: DownloadDocumentStepData;
}

export type DocumentGenerationStepsStep =
    DocumentStep
    | BrandStep
    | RegionStep
    | AreaStep
    | CountryStep
    | TypeStep
    | OptionsStep
    | SelectContentStep
    | PreviewStep
    | DownloadDocumentStep;

export interface DocumentGenerationStepsState {
    steps: DocumentGenerationStepsStep[];
}

export type DocumentGenerationStepsDispatchParamsApply = DocumentGenerationStepsStep;

const initialState: DocumentGenerationStepsState = {
    steps: [
        {
            id: GenerationSteps.DOCUMENT,
            isComplete: false,
            data: null,
        } as DocumentStep,
        {
            id: GenerationSteps.BRAND,
            isComplete: false,
            data: null,
        } as BrandStep,
        {
            id: GenerationSteps.REGION,
            isComplete: false,
            data: null,
        } as RegionStep,
        {
            id: GenerationSteps.AREA,
            isComplete: false,
            data: null,
        } as AreaStep,
        {
            id: GenerationSteps.COUNTRY,
            isComplete: false,
            data: null,
        } as CountryStep,
        {
            id: GenerationSteps.TYPE,
            isComplete: false,
            data: null,
        } as TypeStep,
        {
            id: GenerationSteps.OPTIONS,
            isComplete: false,
            data: {
                id: GenerationMode.FULL,
                propertyName: '',
            },
        } as OptionsStep,
        {
            id: GenerationSteps.SELECT_CONTENT,
            isComplete: false,
            data: null,
        } as SelectContentStep,
        {
            id: GenerationSteps.PREVIEW,
            isComplete: false,
            data: null,
        } as PreviewStep,
        {
            id: GenerationSteps.DOWNLOAD_DOCUMENT,
            isComplete: false,
            data: null,
        } as DownloadDocumentStep,
    ],
};

export const documentGenerationStepsSlice = createSlice({
    name: 'document-generation-steps',
    initialState,
    reducers: {
        apply(state: DocumentGenerationStepsState, action: PayloadAction<DocumentGenerationStepsDispatchParamsApply>) {
            const currentStep = action.payload;

            const foundStepIndex = state.steps.findIndex((step) => step.id === currentStep.id);

            if (foundStepIndex !== -1) {
                const isEqual = isStepDataEqual({
                    prev: state.steps[foundStepIndex].data,
                    next: currentStep.data,
                    stepId: currentStep.id,
                });

                if (!isEqual) {
                    // clear all next
                    for (let i = foundStepIndex + 1; i < state.steps.length; i++) {
                        const step = state.steps[i];

                        const initialStep = initialState.steps[i];

                        state.steps[i] = <DocumentGenerationStepsStep>{
                            id: step.id,
                            isComplete: initialStep.isComplete,
                            data: initialStep.data,
                        };
                    }

                    // skip all not required prev
                    for (let i = 0; i < foundStepIndex; i++) {
                        const step = state.steps[i];
                        const stepRule = stepsRules[step.id];

                        const initialStep = initialState.steps[i];

                        if (!stepRule.isRequired && !step.data) {
                            state.steps[i] = <DocumentGenerationStepsStep>{
                                id: step.id,
                                isComplete: true,
                                data: initialStep.data,
                            };
                        }
                    }
                }

                const prevData = state.steps[foundStepIndex].data;

                // replace with new data
                state.steps.splice(foundStepIndex, 1, <DocumentGenerationStepsStep>{
                    id: currentStep.id,
                    isComplete: currentStep.isComplete,
                    data: currentStep.data ? {
                        ...prevData,
                        ...currentStep.data,
                    } : null,
                });
            }
        },
        reset() {
            return { ...initialState };
        },
    },
});
