import React from 'react';

import classnames from 'classnames/bind';

import { AdminUser } from '@SERVICES';

import UserBody from '@PAGES/admin/users-permissions/components/UserBody';

import styles from './User.module.scss';

const cx: CX = classnames.bind(styles);

function User(props: Props) {
    const { user } = props;

    return (
        <div className={cx('user')}>
            <UserBody user={user} />
        </div>
    );
}

type Props = {
    user: AdminUser
};

export default User;
