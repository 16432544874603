import React, { useMemo } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import { AdminSingleSectionDataMandatoryCondition } from '@INTERFACES/admin-api/sections';

import Lock from '@COMPONENTS/SHARED/Lock';
import CircularProgressIndicator from '@COMPONENTS/SHARED/CircularProgressIndicator';

import styles from './ConditionItem.module.scss';

const cx: CX = classnames.bind(styles);

function ConditionItem(props: Props) {
    const {
        condition,
        locked,
        isProcessing,
        onDeleteClick,
    } = props;

    const values = useMemo(() => {
        const {
            brand,
            region,
            area,
            country,
            propertyType,
        } = condition;

        return [
            {
                title: 'Brand',
                value: brand !== null ? {
                    id: brand.id,
                    name: brand.name,
                } : null,
            },
            {
                title: 'Region',
                value: region !== null ? {
                    id: region.id,
                    name: region.name,
                } : null,
            },
            {
                title: 'Area',
                value: area !== null ? {
                    id: area.id,
                    name: area.name,
                } : null,
            },
            {
                title: 'Country',
                value: country !== null ? {
                    id: country.id,
                    name: country.name,
                } : null,
            },
            {
                title: 'Property Type',
                value: propertyType !== null ? {
                    id: propertyType.id,
                    name: propertyType.name,
                } : null,
            },

        ].filter((con) => con.value !== null);
    }, [condition]);

    return (
        <div
            className={cx('condition-item', {
                locked,
                'is-processing': isProcessing,
            })}
        >
            {
                locked ? (
                    <div className={cx('lock-wrapper')}>
                        <Lock size="small" />
                    </div>
                ) : (
                    <button
                        type="button"
                        className={cx('clear-button', 'delete-button')}
                        onClick={() => {
                            onDeleteClick();
                        }}
                    >
                        &times;
                    </button>
                )
            }

            {
                isProcessing
                && (
                    <div className={cx('progress-indicator-wrapper')}>
                        <CircularProgressIndicator
                            color={COLOR['marriott-light-grey']}
                            size={24}
                            thickness={3}
                        />
                    </div>
                )

            }

            <div>
                {
                    values.map((con) => {
                        const key = con.title;

                        return (
                            <div
                                key={key}
                                className={cx('item-wrapper')}
                            >
                                <div
                                    className={cx('value-wrapper')}
                                >
                                    <div className={cx('title')}>
                                        {con.title}
                                    </div>
                                    <div className={cx('value')}>
                                        {con.value!.name}
                                    </div>
                                </div>

                                <div className={cx('plus')}>+</div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

ConditionItem.propTypes = {
    onDeleteClick: PropTypes.func.isRequired,
    locked: PropTypes.bool.isRequired,
    isProcessing: PropTypes.bool.isRequired,
};

type Props = InferProps<typeof ConditionItem.propTypes> & {
    condition: AdminSingleSectionDataMandatoryCondition,
};

export default ConditionItem;
