import React from 'react';
import { Provider } from 'react-redux';

import { store } from '@STORE/index';

import AppRouter from '@APP/AppRouter';
import { HelmetProvider } from 'react-helmet-async';
import Notificator from '@NOTIFICATION/Notificator';

function App() {
    return (
        <Provider store={store}>
            <HelmetProvider>
                <AppRouter />
            </HelmetProvider>

            <Notificator />
        </Provider>
    );
}

export default App;
