import { useMemo } from 'react';
import { EqualityFn } from 'react-redux';

import { useAppDispatch, useAppSelector } from '@STORE/index';

import { previewVariantModalSlice, PreviewVariantModalState, PreviewVariantModalDispatchParams } from '@REDUCERS';

function createPreviewVariantModalHook() {
    return function usePreviewVariantModal(equalityFn?: EqualityFn<PreviewVariantModalState>) {
        const state = useAppSelector((store) => store.previewVariantModal, equalityFn);

        const dispatch = useAppDispatch();

        const actions = useMemo(() => ({
            open: (params: PreviewVariantModalDispatchParams) => dispatch(previewVariantModalSlice.actions.open(params)),
            close: () => dispatch(previewVariantModalSlice.actions.close()),
        }), [dispatch]);

        return {
            state,
            actions,
        };
    };
}

export default createPreviewVariantModalHook();
