import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import { AdminVendor } from '@SERVICES';

import useGlobalPermissions from '@HOOKS/useGlobalPermissions';
import useDeleteVendorModal from '@HOOKS/store/modals/useDeleteVendorModal';

import { ReactComponent as HiddenIcon } from '@ICONS/hidden-icon.svg';

import RoundIconButton from '@COMPONENTS/COMMON/buttons/RoundIconButton';
import OpenCloseButton from '@COMPONENTS/COMMON/buttons/OpenCloseButton';

import styles from './VendorHeader.module.scss';

const cx: CX = classnames.bind(styles);

function VendorHeader(props: Props) {
    const {
        vendor,
        isOpen,
        onOpenToggle,
    } = props;

    const {
        canManageVendors,
    } = useGlobalPermissions();

    const {
        actions: deleteVendorActions,
    } = useDeleteVendorModal(() => true);

    return (
        <div className={cx('vendor-header')}>
            {
                !vendor.isVisible
                && (
                    <div className={cx('hidden-icon')}>
                        <HiddenIcon
                            width={20}
                            height={20}
                            fill={COLOR['marriott-light-grey']}
                        />
                    </div>
                )
            }

            <div className={cx('title-wrapper')}>
                <div className={cx('delete-button-wrapper')}>
                    <RoundIconButton
                        tooltipTitle="Delete vendor"
                        locked={!canManageVendors}
                        icon="delete"
                        onClick={() => {
                            deleteVendorActions.open({
                                id: vendor.id,
                                name: vendor.name,
                            });
                        }}
                    />
                </div>
                <div className={cx('title')}>
                    {vendor.name}
                </div>
            </div>
            <div className={cx('header-buttons')}>
                <OpenCloseButton
                    isOpen={isOpen}
                    onClick={() => {
                        onOpenToggle(!isOpen);
                    }}
                />
            </div>
        </div>
    );
}

VendorHeader.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onOpenToggle: PropTypes.func.isRequired,
};

type Props = InferProps<typeof VendorHeader.propTypes> & {
    vendor: AdminVendor,
};

export default VendorHeader;
