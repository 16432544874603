import React from 'react';
import { Outlet } from 'react-router-dom';

import Header from '@COMPONENTS/HEADERS/Header';
import MainHeader from '@COMPONENTS/HEADERS/MainHeader';
import AdminSubheader from '@COMPONENTS/HEADERS/AdminSubheader';
import ScrollToTopButton from '@COMPONENTS/COMMON/buttons/ScrollToTopButton';

function BasicAdminLayout() {
    return (
        <>
            <Header>
                <MainHeader />
                <AdminSubheader />
            </Header>

            <ScrollToTopButton />

            <main>
                <Outlet />
            </main>
        </>
    );
}

export default BasicAdminLayout;
