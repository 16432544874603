import React from 'react';
import DeleteItemModal from '@MODALS/common/DeleteItemModal';
import { useDeleteVendor } from '@SERVICES';
import useDeleteVendorModal from '@HOOKS/store/modals/useDeleteVendorModal';

function DeleteVendorModal() {
    const {
        state: { data: modalParams },
        actions,
    } = useDeleteVendorModal();

    const [
        deleteVendor,
        {
            isLoading, isSuccess, isError, error,
        },
    ] = useDeleteVendor();

    return (
        <DeleteItemModal
            modalTitle="Delete vendor"
            name={modalParams!.name}
            itemName="Vendor"
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            error={error}
            onDelete={() => {
                deleteVendor({
                    id: modalParams!.id,
                });
            }}
            onClose={() => {
                actions.close();
            }}
        />
    );
}

export default DeleteVendorModal;
