import React, { MutableRefObject, useRef, useState } from 'react';
import { InferProps } from 'prop-types';

import { SingleValue } from 'react-select';
import classnames from 'classnames/bind';

import {
    AdminSingleSectionData,
    AdminSingleSectionDataBaseTextVersion,
    AdminSingleSectionMeta,
} from '@INTERFACES/admin-api/sections';

import { isContentEmptyCheck } from '@HELPERS/isContentEmptyCheck';

import BaseTextEditor from '@PAGES/admin/edit-section/components/editors/BaseTextEditor';
import BaseTextSidebar from '@PAGES/admin/edit-section/components/BaseTextSidebar';

import styles from './BaseText.module.scss';

const cx: CX = classnames.bind(styles);

function BaseText(props: Props) {
    const {
        sectionData,
        sectionMeta,
    } = props;

    const { baseText } = sectionData;

    const editorRef: MutableRefObject<string> = useRef('');
    const [contentIsEmpty, setContentIsEmpty] = useState<boolean>(true);

    const [selectedVersion, setSelectedVersion] = useState<AdminSingleSectionDataBaseTextVersion | undefined>(undefined);

    function getEditorContent() {
        return editorRef.current;
    }

    function onVersionChange(val: SingleValue<any>) {
        const version = baseText.versions.find((v) => v.id === val.value);

        setSelectedVersion(version);
    }

    return (
        <div className={cx('base-text', 'container')}>
            <div className={cx('body')}>
                <div className={cx('body-wrapper')}>
                    <div className={cx('editor')}>
                        <div className={cx('editor-wrapper')}>
                            <BaseTextEditor
                                sectionData={sectionData}
                                selectedVersion={selectedVersion}
                                onEditorChange={(content: string) => {
                                    editorRef.current = content;

                                    setContentIsEmpty(isContentEmptyCheck(content));
                                }}
                            />
                        </div>
                    </div>
                    <div className={cx('sidebar-wrapper')}>
                        <BaseTextSidebar
                            contentIsEmpty={contentIsEmpty}
                            selectedVersion={selectedVersion}
                            sectionData={sectionData}
                            sectionMeta={sectionMeta}
                            getEditorContent={() => getEditorContent()}
                            onVersionChange={(val: any) => onVersionChange(val)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

BaseText.propTypes = {
    //
};

type Props = InferProps<typeof BaseText.propTypes> & {
    sectionData: AdminSingleSectionData,
    sectionMeta: AdminSingleSectionMeta,
};

export default BaseText;
