import React, { useEffect } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import { useCreateVendor } from '@SERVICES';

import useNewVendorModal from '@HOOKS/store/modals/useNewVendorModal';

import BaseModal from '@MODALS/BaseModal';

import VendorForm from '@PAGES/admin/vendors/components/VendorForm';
import { VendorFormValues } from '@PAGES/admin/vendors/components/VendorForm/VendorForm.component';

import styles from './NewVendorModal.module.scss';

const cx: CX = classnames.bind(styles);

function NewVendorModal() {
    const {
        actions: newVendorModalActions,
    } = useNewVendorModal(() => true);

    const [
        createVendor,
        {
            isLoading, isSuccess, isError, error,
        },
    ] = useCreateVendor();

    useEffect(() => {
        if (isSuccess) {
            notify.info('New vendor has been created', {
                toastId: 'add-new-vendor',
            });

            newVendorModalActions.close();
        }
    }, [isSuccess, newVendorModalActions]);

    useEffect(() => {
        if (isError) {
            if ((error as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'add-new-vendor-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'add-new-vendor-error',
                });
            }
        }
    }, [isError, error]);

    function handleClose() {
        newVendorModalActions.close();
    }

    const onSave = (formValues: VendorFormValues, isHidden: boolean) => {
        createVendor({
            name: formValues.name,
            company: formValues.company,
            address: formValues.address,
            contact: formValues.contact,
            email: formValues.email,
            phone: formValues.phone,
            isVisible: !isHidden,
        });
    };

    return (
        <BaseModal
            style={{ top: 140 }}
            onClose={() => handleClose()}
        >
            <div className={cx('new-vendor-modal')}>
                <div className={cx('title')}>
                    Create new vendor
                </div>

                <div className={cx('form')}>
                    <VendorForm
                        buttonTitle="Create"
                        isLoading={isLoading}
                        hidden
                        defaultValues={{
                            name: '',
                            company: '',
                            address: '',
                            contact: '',
                            email: '',
                            phone: '',
                        }}
                        onSave={(formValues, isHidden) => onSave(formValues, isHidden)}
                    />
                </div>
            </div>
        </BaseModal>
    );
}

export default NewVendorModal;
