import React, { useEffect, useMemo, useState } from 'react';

import classnames from 'classnames/bind';

import DESIGN from '@CONSTANTS/DESIGN.constant';

import { useGetAllUsers } from '@SERVICES';

import Content from '@PAGES/admin/users-permissions/components/Content';

import getFilteredBySearchData from '@PAGES/admin/users-permissions/helpers/getFilteredBySearchData';

import DataLoader from '@COMPONENTS/SHARED/DataLoader';
import UsersPermissionsSidebar from '@COMPONENTS/SIDEBARS/UsersPermissionsSidebar';
import ContentWithFullHeightSidebar from '@COMPONENTS/SHARED/ContentWithFullHeightSidebar';

import styles from './UsersPermissions.module.scss';

const cx: CX = classnames.bind(styles);

const top = DESIGN['subheader-height'];

const defaultData = {
    data: [],
};

function UsersPermissionsPage() {
    const {
        data: usersData = defaultData, isLoading, isError, error,
    } = useGetAllUsers();

    const [searchValue, setSearchValue] = useState<string>('');

    const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedSearchValue(searchValue);
        }, 250);

        return () => {
            clearTimeout(timer);
        };
    }, [searchValue]);

    // SEARCH
    const filteredUsersData = useMemo(
        () => getFilteredBySearchData(usersData, debouncedSearchValue),
        [usersData, debouncedSearchValue],
    );

    return (
        <div className={cx('page')}>
            <div className="max-constraint center">
                <ContentWithFullHeightSidebar
                    top={top}
                    sidebar={(
                        <UsersPermissionsSidebar
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                        />
                    )}
                >
                    <div className="container">
                        <DataLoader
                            isLoading={isLoading}
                            isError={isError}
                            error={error}
                        >
                            <Content
                                usersData={filteredUsersData.data!}
                            />
                        </DataLoader>
                    </div>
                </ContentWithFullHeightSidebar>
            </div>
        </div>
    );
}

export default UsersPermissionsPage;
