import Mandatory from '@PAGES/manager/select-content/helpers/mandatoryPredicate';
import { ContentChapter } from '@INTERFACES/api/content';

export default function getInitialSectionsIds(data: ContentChapter[], isPartial: boolean) {
    return data.reduce((acc: Set<number>, chapter: ContentChapter) => {
        const { sections } = chapter;

        if (!isPartial) {
            const mandatorySections = (
                chapter.isMandatory
                    ? sections
                    : sections.filter(Mandatory)
            );

            mandatorySections.forEach((section) => {
                acc.add(section.id);
            });
        }

        return acc;
    }, new Set());
}
