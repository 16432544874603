import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classNames from 'classnames/bind';

import RoundedCheckbox from '@COMPONENTS/COMMON/inputs/checkbox/RoundedCheckbox';

import styles from './ViewMode.module.scss';

const cx: CX = classNames.bind(styles);

function ViewMode(props: Props) {
    const {
        disabled,
        showUnpublished,
        setShowUnpublished,
    } = props;

    return (
        <div
            className={cx('view-mode', {
                disabled,
            })}
        >
            <RoundedCheckbox
                checked={showUnpublished}
                disabled={disabled}
                onChange={() => {
                    setShowUnpublished((val: boolean) => !val);
                }}
            />
            <div className={cx('view-mode-title')}>
                Unpublished
            </div>
        </div>
    );
}

ViewMode.propTypes = {
    disabled: PropTypes.bool.isRequired,
    showUnpublished: PropTypes.bool.isRequired,
    setShowUnpublished: PropTypes.func.isRequired,
};

type Props = InferProps<typeof ViewMode.propTypes>;

export default ViewMode;
