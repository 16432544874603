import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import BasicButton from '@COMPONENTS/COMMON/buttons/BasicButton';

import styles from './SaveAndPublishPairButtons.module.scss';

const cx: CX = classnames.bind(styles);

function SaveAndPublishPairButtons(props: Props) {
    const {
        isSaveProcessing,
        disablePublish,
        onSaveClick,
        onSaveAndPublishClick,
        canEditDocument,
        canPublishDocument,
    } = props;

    return (
        <div className={cx('save-and-publish-pair-buttons')}>
            <div className={cx('button-wrapper', 'save')}>
                <BasicButton
                    locked={!canEditDocument}
                    title="Save"
                    isProcessing={isSaveProcessing}
                    style={{
                        height: 32,
                        width: '100%',
                        fontSize: 12,
                        backgroundColor: COLOR['app-green'],
                    }}
                    onClick={() => {
                        onSaveClick();
                    }}
                />
            </div>

            <div className={cx('button-wrapper', 'save-and-publish')}>
                <BasicButton
                    locked={!canPublishDocument}
                    title="Save & Publish"
                    progressIndicatorColor={COLOR['marriott-primary']}
                    style={{
                        pointerEvents: disablePublish ? 'none' : 'all',
                        height: 32,
                        width: '100%',
                        fontSize: 12,
                        backgroundColor: COLOR['app-white'],
                        border: `1px solid ${disablePublish ? COLOR['marriott-light-grey-2'] : COLOR['marriott-primary']}`,
                        color: disablePublish ? COLOR['marriott-light-grey-2'] : COLOR['marriott-primary'],
                    }}
                    onClick={() => {
                        if (!disablePublish) {
                            onSaveAndPublishClick();
                        }
                    }}
                />
            </div>
        </div>
    );
}

SaveAndPublishPairButtons.propTypes = {
    isSaveProcessing: PropTypes.bool.isRequired,
    disablePublish: PropTypes.bool.isRequired,
    onSaveClick: PropTypes.func.isRequired,
    onSaveAndPublishClick: PropTypes.func.isRequired,
    canEditDocument: PropTypes.bool.isRequired,
    canPublishDocument: PropTypes.bool.isRequired,
};

type Props = InferProps<typeof SaveAndPublishPairButtons.propTypes>;

export default SaveAndPublishPairButtons;
