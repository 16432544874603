import getOrigin from '@HELPERS/getOrigin';

type Params = {
    url: string;

    name: string;
};

export default function createDocumentLinkAndClick({ url, name }: Params) {
    const a = document.createElement('a');

    const origin = getOrigin();

    a.href = origin + url;
    a.target = '_blank';
    a.download = `${name}.pdf`;

    a.click();
    a.remove();
}
