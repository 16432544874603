import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import styles from './StickyHeader.module.scss';

const cx: CX = classnames.bind(styles);

function StickyHeader(props: Props) {
    const { top, zIndex, children } = props;

    return (
        <div
            className={cx('sticky-header')}
            style={{
                top: top - 1,
                zIndex,
            }}
        >
            {children}
        </div>
    );
}

StickyHeader.defaultProps = {
    zIndex: 5,
};

StickyHeader.propTypes = {
    top: PropTypes.number.isRequired,
    zIndex: PropTypes.number,
};

type Props = InferProps<typeof StickyHeader.propTypes> & typeof StickyHeader.defaultProps & OnlyChildrenProps;

export default StickyHeader;
