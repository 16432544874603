import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';

import {
    documentGenerationStepsSlice,

    documentNameModalSlice,
    chapterNameModalSlice,
    sectionNameModalSlice,
    tagNameModalSlice,

    newDocumentModalSlice,
    newChapterModalSlice,
    newSectionModalSlice,
    newVariantModalSlice,
    newTagModalSlice,
    newTagInChapterModalSlice,
    newTagInSectionModalSlice,
    newTagInVariantModalSlice,
    newVendorModalSlice,

    deleteDocumentModalSlice,
    deleteChapterModalSlice,
    deleteSectionModalSlice,
    deleteVariantModalSlice,
    deleteVendorModalSlice,
    deleteTagModalSlice,

    publishDocumentModalSlice,
    publishChapterModalSlice,
    publishChapterIntroductionTextModalSlice,
    publishSectionModalSlice,
    publishSectionBaseTextModalSlice,
    publishVariantModalSlice,

    orderChaptersDrawerSlice,
    orderSectionsDrawerSlice,

    previewDocumentModalSlice,
    previewChapterModalSlice,
    previewChapterIntroductionTextModalSlice,
    previewSectionModalSlice,
    previewSectionBaseTextModalSlice,
    previewVariantModalSlice,

    chapterIntroductionTextHistoryModalSlice,
    sectionHistoryModalSlice,
    sectionBaseTextHistoryModalSlice,
    variantHistoryModalSlice,

    authSlice,
} from '@REDUCERS';

import {
    documentApi,
    brandApi,
    regionApi,
    areasApi,
    countryApi,
    propertyApi,
    contentApi,
    previewApi,
    variantApi,
    variantHistoryApi,
    downloadDocumentApi,
    authApi,
    documentsAdminApi,
    chaptersAdminApi,
    sectionsAdminApi,
    sectionsMandatoryConditionsAdminApi,
    variantsAdminApi,
    tagsAdminApi,
    vendorsAdminApi,
    productsAdminApi,
    usersAdminApi,
    reorderAdminApi,
    publishAdminApi,
    unpublishedAdminApi,
    previewAdminApi,
    downloadDocumentPreviewAdminApi,
    searchAdminApi,
    logsAdminApi,
} from '@SERVICES';

export default {
    // REDUCERS
    documentGenerationSteps: documentGenerationStepsSlice.reducer,

    documentNameModal: documentNameModalSlice.reducer,
    chapterNameModal: chapterNameModalSlice.reducer,
    sectionNameModal: sectionNameModalSlice.reducer,
    tagNameModal: tagNameModalSlice.reducer,

    newDocumentModal: newDocumentModalSlice.reducer,
    newChapterModal: newChapterModalSlice.reducer,
    newSectionModal: newSectionModalSlice.reducer,
    newVariantModal: newVariantModalSlice.reducer,
    newTagModal: newTagModalSlice.reducer,
    newTagInChapterModal: newTagInChapterModalSlice.reducer,
    newTagInSectionModal: newTagInSectionModalSlice.reducer,
    newTagInVariantModal: newTagInVariantModalSlice.reducer,
    newVendorModal: newVendorModalSlice.reducer,

    deleteDocumentModal: deleteDocumentModalSlice.reducer,
    deleteChapterModal: deleteChapterModalSlice.reducer,
    deleteSectionModal: deleteSectionModalSlice.reducer,
    deleteVariantModal: deleteVariantModalSlice.reducer,
    deleteVendorModal: deleteVendorModalSlice.reducer,
    deleteTagModal: deleteTagModalSlice.reducer,

    publishDocumentModal: publishDocumentModalSlice.reducer,
    publishChapterModal: publishChapterModalSlice.reducer,
    publishChapterIntroductionTextModal: publishChapterIntroductionTextModalSlice.reducer,
    publishSectionModal: publishSectionModalSlice.reducer,
    publishSectionBaseTextModal: publishSectionBaseTextModalSlice.reducer,
    publishVariantModal: publishVariantModalSlice.reducer,

    orderChaptersDrawer: orderChaptersDrawerSlice.reducer,
    orderSectionsDrawer: orderSectionsDrawerSlice.reducer,

    previewDocumentModal: previewDocumentModalSlice.reducer,
    previewChapterModal: previewChapterModalSlice.reducer,
    previewChapterIntroductionTextModal: previewChapterIntroductionTextModalSlice.reducer,
    previewSectionModal: previewSectionModalSlice.reducer,
    previewSectionBaseTextModal: previewSectionBaseTextModalSlice.reducer,
    previewVariantModal: previewVariantModalSlice.reducer,

    chapterIntroductionTextHistoryModal: chapterIntroductionTextHistoryModalSlice.reducer,
    sectionHistoryModal: sectionHistoryModalSlice.reducer,
    sectionBaseTextHistoryModal: sectionBaseTextHistoryModalSlice.reducer,
    variantHistoryModal: variantHistoryModalSlice.reducer,

    auth: authSlice.reducer,

    // API
    [documentApi.reducerPath]: documentApi.reducer,
    [brandApi.reducerPath]: brandApi.reducer,
    [regionApi.reducerPath]: regionApi.reducer,
    [areasApi.reducerPath]: areasApi.reducer,
    [countryApi.reducerPath]: countryApi.reducer,
    [propertyApi.reducerPath]: propertyApi.reducer,
    [contentApi.reducerPath]: contentApi.reducer,
    [previewApi.reducerPath]: previewApi.reducer,
    [variantApi.reducerPath]: variantApi.reducer,
    [variantHistoryApi.reducerPath]: variantHistoryApi.reducer,
    [downloadDocumentApi.reducerPath]: downloadDocumentApi.reducer,
    [authApi.reducerPath]: authApi.reducer,

    // ADMIN API
    [documentsAdminApi.reducerPath]: documentsAdminApi.reducer,
    [chaptersAdminApi.reducerPath]: chaptersAdminApi.reducer,
    [sectionsAdminApi.reducerPath]: sectionsAdminApi.reducer,
    [sectionsMandatoryConditionsAdminApi.reducerPath]: sectionsMandatoryConditionsAdminApi.reducer,
    [variantsAdminApi.reducerPath]: variantsAdminApi.reducer,
    [tagsAdminApi.reducerPath]: tagsAdminApi.reducer,
    [vendorsAdminApi.reducerPath]: vendorsAdminApi.reducer,
    [productsAdminApi.reducerPath]: productsAdminApi.reducer,
    [usersAdminApi.reducerPath]: usersAdminApi.reducer,
    [reorderAdminApi.reducerPath]: reorderAdminApi.reducer,
    [publishAdminApi.reducerPath]: publishAdminApi.reducer,
    [unpublishedAdminApi.reducerPath]: unpublishedAdminApi.reducer,
    [previewAdminApi.reducerPath]: previewAdminApi.reducer,
    [downloadDocumentPreviewAdminApi.reducerPath]: downloadDocumentPreviewAdminApi.reducer,
    [searchAdminApi.reducerPath]: searchAdminApi.reducer,
    [logsAdminApi.reducerPath]: logsAdminApi.reducer,
};

export function getInitialState(state: any) {
    return {
        ...state,
        documentGenerationSteps: documentGenerationStepsSlice.getInitialState(),
    };
}

export function resetApiState(dispatch: ThunkDispatch<any, any, AnyAction>) {
    // API
    dispatch(documentApi.util.resetApiState());
    dispatch(brandApi.util.resetApiState());
    dispatch(regionApi.util.resetApiState());
    dispatch(areasApi.util.resetApiState());
    dispatch(countryApi.util.resetApiState());
    dispatch(propertyApi.util.resetApiState());
    dispatch(contentApi.util.resetApiState());
    dispatch(previewApi.util.resetApiState());
    dispatch(variantApi.util.resetApiState());
    dispatch(variantHistoryApi.util.resetApiState());
    dispatch(downloadDocumentApi.util.resetApiState());
    dispatch(authApi.util.resetApiState());

    // ADMIN API
    dispatch(documentsAdminApi.util.resetApiState());
    dispatch(chaptersAdminApi.util.resetApiState());
    dispatch(sectionsAdminApi.util.resetApiState());
    dispatch(sectionsMandatoryConditionsAdminApi.util.resetApiState());
    dispatch(variantsAdminApi.util.resetApiState());
    dispatch(tagsAdminApi.util.resetApiState());
    dispatch(vendorsAdminApi.util.resetApiState());
    dispatch(productsAdminApi.util.resetApiState());
    dispatch(usersAdminApi.util.resetApiState());
    dispatch(reorderAdminApi.util.resetApiState());
    dispatch(publishAdminApi.util.resetApiState());
    dispatch(unpublishedAdminApi.util.resetApiState());
    dispatch(previewAdminApi.util.resetApiState());
    dispatch(downloadDocumentPreviewAdminApi.util.resetApiState());
    dispatch(searchAdminApi.util.resetApiState());
    dispatch(logsAdminApi.util.resetApiState());
}
