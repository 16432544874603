/* eslint-disable no-param-reassign,max-len */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type OrderSectionsDrawerSection = {
    id: number;

    name: string;

    order: number;
};

export interface OrderSectionsDrawerState {
    isOpen: boolean;

    data: {
        documentId: number;

        chapterId: number;

        canEditDocument: boolean;

        sections: OrderSectionsDrawerSection[];
    } | null;
}

export interface OrderSectionsDrawerDispatchParams {
    documentId: number;

    chapterId: number;

    canEditDocument: boolean;

    sections: OrderSectionsDrawerSection[];
}

const initialState: OrderSectionsDrawerState = {
    isOpen: false,
    data: null,
};

export const orderSectionsDrawerSlice = createSlice({
    name: 'order-sections-drawer',
    initialState,
    reducers: {
        open(state: OrderSectionsDrawerState, action: PayloadAction<OrderSectionsDrawerDispatchParams>) {
            state.isOpen = true;
            state.data = action.payload;
        },
        close(state: OrderSectionsDrawerState) {
            state.isOpen = false;
            state.data = null;
        },
    },
});
