import React from 'react';

import {
    BrowserRouter, Route, Routes,
} from 'react-router-dom';

import ROUTES from '@CONSTANTS/ROUTES.constant';

import StepsValidator from '@VALIDATION/components/StepsValidator';

import BasicLayout from '@LAYOUTS/BasicLayout';
import BasicAdminLayout from '@LAYOUTS/BasicAdminLayout';
import GenerateDocumentLayout from '@LAYOUTS/GenerateDocument';
import EditDocumentLayout from '@LAYOUTS/EditDocument';

import MainPage from '@PAGES/common/main';
import LoginPage from '@PAGES/common/login';
import Page404 from '@PAGES/common/404';

import ProtectedRoute from '@COMPONENTS/SHARED/ProtectedRoute/ProtectedRoute.component';

// MANAGER
import ChooseDocumentPage from '@PAGES/manager/choose-documement';
import BrandPage from '@PAGES/manager/brand';
import RegionPage from '@PAGES/manager/region';
import AreaPage from '@PAGES/manager/area';
import CountryPage from '@PAGES/manager/country';
import PropertyPage from '@PAGES/manager/property';
import OptionsPage from '@PAGES/manager/options';
import SelectContentPage from '@PAGES/manager/select-content';
import PreviewPage from '@PAGES/manager/preview';
import DownloadDocumentPage from '@PAGES/manager/download-document';

// ADMIN
import VendorsPage from '@PAGES/admin/vendors';
import LogsPage from '@PAGES/admin/logs';
import UsersPermissionsPage from '@PAGES/admin/users-permissions';
import DocumentsListPage from '@PAGES/admin/documents-list';
import DocumentPage from '@PAGES/admin/document';
import EditChapterPage from '@PAGES/admin/edit-chapter';
import EditSectionPage from '@PAGES/admin/edit-section';

import AppWrapper from './AppWrapper';

function AppRouter() {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<ProtectedRoute />}>
                    <Route element={<AppWrapper />}>
                        <Route path={ROUTES.login.path} element={<LoginPage />} />

                        {/* COMMON */}
                        <Route element={<BasicLayout />}>
                            <Route path={ROUTES.main.path} element={<MainPage />} />
                        </Route>

                        {/* MANAGER */}
                        <Route element={<StepsValidator />}>
                            <Route element={<GenerateDocumentLayout />}>
                                <Route
                                    path={ROUTES.chooseDocument.path}
                                    element={<ChooseDocumentPage />}
                                />
                                <Route path={ROUTES.brand.path} element={<BrandPage />} />
                                <Route path={ROUTES.region.path} element={<RegionPage />} />
                                <Route path={ROUTES.area.path} element={<AreaPage />} />
                                <Route path={ROUTES.country.path} element={<CountryPage />} />
                                <Route path={ROUTES.type.path} element={<PropertyPage />} />
                                <Route path={ROUTES.options.path} element={<OptionsPage />} />
                                <Route
                                    path={ROUTES.selectContent.path}
                                    element={<SelectContentPage />}
                                />
                                <Route path={ROUTES.preview.path} element={<PreviewPage />} />
                                <Route
                                    path={ROUTES.downloadDocument.path}
                                    element={<DownloadDocumentPage />}
                                />
                            </Route>
                        </Route>

                        {/* ADMIN */}
                        <Route element={<BasicAdminLayout />}>
                            <Route
                                path={ROUTES.admin.manageVendors.path}
                                element={<VendorsPage />}
                            />
                            <Route
                                path={ROUTES.admin.logs.path}
                                element={<LogsPage />}
                            />

                            <Route
                                path={ROUTES.admin.manageUsersPermissions.path}
                                element={<UsersPermissionsPage />}
                            />
                        </Route>

                        <Route path={ROUTES.admin.path} element={<EditDocumentLayout />}>
                            <Route
                                path={ROUTES.admin.documents.path}
                                element={<DocumentsListPage />}
                            />
                            <Route
                                path={ROUTES.admin.documents.id.path}
                                element={<DocumentPage />}
                            />
                            <Route
                                path={ROUTES.admin.chapters.id.path}
                                element={<EditChapterPage />}
                            />
                            <Route
                                path={ROUTES.admin.sections.id.path}
                                element={<EditSectionPage />}
                            />
                        </Route>

                        {/* 404 */}
                        <Route element={<BasicLayout />}>
                            <Route path="*" element={<Page404 />} />
                        </Route>
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default AppRouter;
