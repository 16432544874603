import React, { MouseEvent, ReactElement, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import { ReactComponent as CopyIcon } from '@ICONS/copy-icon.svg';
import { ReactComponent as EditIcon } from '@ICONS/edit-icon-2.svg';
import { ReactComponent as DeleteIcon } from '@ICONS/delete-icon.svg';
import { ReactComponent as PreviewIcon } from '@ICONS/preview-icon.svg';
import { ReactComponent as HistoryIcon } from '@ICONS/clock-icon.svg';

import CircularProgressIndicator from '@COMPONENTS/SHARED/CircularProgressIndicator';
import Lock from '@COMPONENTS/SHARED/Lock';
import Tooltip from '@COMPONENTS/SHARED/Tooltip';

import styles from './RoundIconButton.module.scss';

const cx: CX = classnames.bind(styles);

const icons: { [key: string]: ReactElement } = {
    edit: <EditIcon />,
    copy: <CopyIcon />,
    delete: <DeleteIcon />,
    preview: <PreviewIcon />,
    history: <HistoryIcon />,
};

function RoundIconButton(props: Props) {
    const {
        tooltipTitle,
        locked,
        icon,
        onClick,
        isProcessing,
        disabled,
    } = props;

    const [tooltipId] = useState(Tooltip.getId());

    function handleClick(event: MouseEvent<HTMLButtonElement>) {
        if (!locked) {
            event.preventDefault();

            onClick(event);
        }
    }

    const Icon = icons[icon];

    return (
        <>
            <button
                id={tooltipId}
                type="button"
                className={cx('clear-button', 'round-button', {
                    locked,
                })}
                style={{
                    pointerEvents: (disabled || locked) ? 'none' : undefined,
                }}
                onClick={handleClick}
            >
                {
                    locked
                    && (
                        <div className={cx('lock-wrapper')}>
                            <Lock size="free" />
                        </div>
                    )
                }

                {
                    !isProcessing
                        ? (
                            <div className={cx('icon', icon)}>{Icon}</div>
                        ) : (
                            <CircularProgressIndicator
                                size={20}
                                color={COLOR['marriott-primary']}
                                thickness={2}
                            />
                        )
                }
            </button>
            <Tooltip
                id={tooltipId}
                content={tooltipTitle}
                disabled={locked}
            />
        </>
    );
}

RoundIconButton.defaultProps = {
    locked: false,
    isProcessing: false,
    disabled: false,
};

RoundIconButton.propTypes = {
    tooltipTitle: PropTypes.string.isRequired,
    locked: PropTypes.bool,
    icon: PropTypes.oneOf(['copy', 'edit', 'delete', 'preview', 'history']).isRequired,
    onClick: PropTypes.func.isRequired,
    isProcessing: PropTypes.bool,
    disabled: PropTypes.bool,
};

type Props = InferProps<typeof RoundIconButton.propTypes> & typeof RoundIconButton.defaultProps;

export default RoundIconButton;
