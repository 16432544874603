import React from 'react';

import classnames from 'classnames/bind';

import { AdminUser } from '@SERVICES';

import styles from './UserInfo.module.scss';

const cx: CX = classnames.bind(styles);

function UserInfo(props: Props) {
    const { user } = props;

    return (
        <div className={cx('user-info')}>
            <div className={cx('user-info-title')}>
                User Info
            </div>

            <div className={cx('data')}>
                {
                    [
                        {
                            title: 'Name',
                            value: user.fullName,
                        },
                        {
                            title: 'Email',
                            value: user.email,
                        },
                        {
                            title: 'Country',
                            value: user.country,
                        },
                        {
                            title: 'Mobile phone',
                            value: user.mobilePhone,
                        },
                        {
                            title: 'Office phone',
                            value: user.officePhone,
                        },
                    ].map((item) => {
                        const key = item.title;

                        return (
                            <div
                                key={key}
                                className={cx('data-item')}
                            >
                                <div className={cx('data-title')}>
                                    {item.title}
                                </div>
                                <div className={cx('data-value')}>
                                    {item.value || '-'}
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

type Props = {
    user: AdminUser,
};

export default UserInfo;
