import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classNames from 'classnames/bind';

import StickyHeader from '@COMPONENTS/HEADERS/StickyHeader';

import styles from './PairStickyHeader.module.scss';

const cx: CX = classNames.bind(styles);

function PairStickyHeader(props: Props) {
    const {
        sectionName,
        chapterName,
    } = props;

    return (
        <StickyHeader
            top={1}
            zIndex={6}
        >
            <div className={cx('pair-sticky-header')}>
                <div className={cx('left')}>
                    <div className={cx('title-prefix')}>
                        Section
                    </div>
                    <div className={cx('title')}>
                        {sectionName}
                    </div>
                    <div />
                </div>

                <div className={cx('delimiter')} />

                <div className={cx('right')}>
                    <div className={cx('title-prefix')}>
                        Chapter
                    </div>
                    <div className={cx('title')}>
                        {chapterName}
                    </div>
                    <div />
                </div>
            </div>
        </StickyHeader>
    );
}

PairStickyHeader.propTypes = {
    sectionName: PropTypes.string.isRequired,
    chapterName: PropTypes.string.isRequired,
};

type Props = InferProps<typeof PairStickyHeader.propTypes>;

export default PairStickyHeader;
