import React, { useState } from 'react';

import classnames from 'classnames/bind';

import DESIGN from '@CONSTANTS/DESIGN.constant';
import COLOR from '@CONSTANTS/COLOR.constant';

import { AdminSingleChapterData, AdminSingleChapterMeta } from '@INTERFACES/admin-api/chapters';
import { OrderSectionsDrawerSection } from '@REDUCERS';

import useNewSectionModal from '@HOOKS/store/modals/useNewSectionModal';
import useDocumentPermissions from '@HOOKS/useDocumentPermissions';

import ChapterImage from '@PAGES/admin/edit-chapter/components/ChapterImage';
import IntroductionText from '@PAGES/admin/edit-chapter/components/IntroductionText';
import Section from '@PAGES/admin/edit-chapter/components/Section';
import SectionsStickyHeader from '@PAGES/admin/edit-chapter/components/SectionsStickyHeader';
import FloatingPublishButton from '@PAGES/admin/edit-chapter/components/FloatingPublishButton';
import IntroductionTextStickyHeader from '@PAGES/admin/edit-chapter/components/IntroductionTextStickyHeader';

import AddButton from '@COMPONENTS/COMMON/buttons/AddButton';
import StickyHeader from '@COMPONENTS/HEADERS/StickyHeader';
import OpenCloseButton from '@COMPONENTS/COMMON/buttons/OpenCloseButton';
import ChapterTags from '@PAGES/admin/edit-chapter/components/ChapterTags';
import PageHeader from '@PAGES/admin/edit-chapter/components/PageHeader';

import styles from './Content.module.scss';

const cx: CX = classnames.bind(styles);

const top = DESIGN['subheader-height'] + DESIGN['admin-breadcrumbs-height'];

function Content(props: Props) {
    const {
        chapterData,
        chapterMeta,
        filteredChapterData,
        sectionsOrder,
    } = props;

    const { sections } = filteredChapterData || { sections: [] };

    const {
        canEditDocument,
    } = useDocumentPermissions({
        documentOwnerId: chapterData.document.owner,
        permissions: chapterMeta.permissions,
    });

    const {
        actions: newSectionModalActions,
    } = useNewSectionModal(() => true);

    const [isChapterTagsOpen, setChapterTagsOpen] = useState<boolean>(false);
    const [isChapterImageOpen, setChapterImageOpen] = useState<boolean>(false);
    const [isIntroductionOpen, setIntroductionOpen] = useState<boolean>(false);
    const [openSectionsIds, setOpenSectionsIds] = useState<Set<number>>(new Set());

    return (
        <div className={cx('content')}>
            <PageHeader
                chapterData={chapterData}
                chapterMeta={chapterMeta}
            />

            <div>
                <StickyHeader
                    top={top}
                >
                    <div className={cx('chapter-tags-subheader', 'container')}>
                        <div className={cx('title')}>
                            Chapter tags
                        </div>
                        <OpenCloseButton
                            isOpen={isChapterTagsOpen}
                            backgroundColor={COLOR['marriott-light-grey']}
                            iconColor={COLOR['app-white']}
                            onClick={() => {
                                setChapterTagsOpen((val) => !val);
                            }}
                        />
                    </div>
                </StickyHeader>

                {
                    isChapterTagsOpen
                    && (
                        <ChapterTags
                            chapterData={chapterData}
                            chapterMeta={chapterMeta}
                        />
                    )
                }
            </div>

            <div>
                <StickyHeader
                    top={top}
                >
                    <div className={cx('chapter-image-subheader', 'container')}>
                        <div className={cx('title')}>
                            Chapter image
                        </div>
                        <OpenCloseButton
                            isOpen={isChapterImageOpen}
                            onClick={() => {
                                setChapterImageOpen((val) => !val);
                            }}
                            backgroundColor={COLOR['marriott-light-grey']}
                            iconColor={COLOR['app-white']}
                        />
                    </div>
                </StickyHeader>

                {
                    isChapterImageOpen
                    && (
                        <ChapterImage
                            chapterData={filteredChapterData}
                            chapterMeta={chapterMeta}
                        />
                    )
                }
            </div>

            <FloatingPublishButton
                chapterData={chapterData}
                chapterMeta={chapterMeta}
                isIntroductionOpen={isIntroductionOpen}
                top={top}
            />

            <div>
                <IntroductionTextStickyHeader
                    top={top}
                    isIntroductionOpen={isIntroductionOpen}
                    setIntroductionOpen={setIntroductionOpen}
                    chapterData={chapterData}
                    chapterMeta={chapterMeta}
                />

                {
                    isIntroductionOpen
                    && (
                        <IntroductionText
                            chapterData={filteredChapterData}
                            chapterMeta={chapterMeta}
                        />
                    )
                }
            </div>

            <div className={cx('sections-wrapper')}>
                <SectionsStickyHeader
                    top={top}
                    chapterData={filteredChapterData}
                    chapterMeta={chapterMeta}
                    sectionsOrder={sectionsOrder}
                    setOpenSectionsIds={setOpenSectionsIds}
                />
                <div className={cx('add-section-button', 'container')}>
                    <AddButton
                        locked={!canEditDocument}
                        title="New section"
                        fontSize={36}
                        onClick={() => {
                            newSectionModalActions.open({
                                documentId: filteredChapterData.document.id,
                                chapterId: filteredChapterData.id,
                            });
                        }}
                    />
                </div>
                {
                    sections.length > 0
                        ? (
                            <div className={cx('sections', 'container')}>
                                {
                                    sections.map((section) => {
                                        const key = section.id;

                                        return (
                                            <div
                                                key={key}
                                                className={cx('section-wrapper', {
                                                    collapsed: !openSectionsIds.has(section.id),
                                                })}
                                            >
                                                <Section
                                                    index={
                                                        sectionsOrder.findIndex(
                                                            (s) => s.id === section.id,
                                                        )
                                                    }
                                                    chapterData={filteredChapterData}
                                                    chapterMeta={chapterMeta}
                                                    section={section}
                                                    openSectionsIds={openSectionsIds}
                                                    setOpenSectionsIds={setOpenSectionsIds}
                                                />
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        )
                        : (
                            <div className={cx('sections-not-found', 'container')}>
                                No sections
                            </div>
                        )
                }
            </div>
        </div>
    );
}

type Props = {
    chapterData: AdminSingleChapterData,
    chapterMeta: AdminSingleChapterMeta,
    filteredChapterData: AdminSingleChapterData,
    sectionsOrder: OrderSectionsDrawerSection[],
};

export default Content;
