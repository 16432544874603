import React, { useEffect, useState } from 'react';

import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import COLOR from '@CONSTANTS/COLOR.constant';
import { GLOBAL_PERMISSIONS } from '@CONSTANTS/PERMISSIONS.constant';

import { AdminUser, useUpdateUserPermissions } from '@SERVICES';

import useGlobalPermissions from '@HOOKS/useGlobalPermissions';

import UserInfo from '@PAGES/admin/users-permissions/components/UserInfo';
import Permissions from '@PAGES/admin/users-permissions/components/Permissions';
import Properties from '@PAGES/admin/users-permissions/components/Properties';
import BasicButton from '@COMPONENTS/COMMON/buttons/BasicButton';

import styles from './UserBody.module.scss';

const cx: CX = classnames.bind(styles);

function UserBody(props: Props) {
    const { user } = props;

    const {
        canManagePermissions,
    } = useGlobalPermissions();

    const [
        updatePermissions,
        {
            isLoading, isSuccess, isError, error,
        },
    ] = useUpdateUserPermissions();

    const [isSuperuser, setIsSuperuser] = useState<boolean>(user.isSuperuser);

    const [selectedPermissions, setSelectedPermissions] = useState<Set<GLOBAL_PERMISSIONS>>(
        new Set(user.permissions.map((p) => p.codename as GLOBAL_PERMISSIONS)),
    );

    const [isUserActive, setUserActive] = useState<boolean>(user.isActive);
    const [userExpirationDate, setUserExpirationDate] = useState<Date | null>(
        user.expirationDate ? new Date(user.expirationDate) : null,
    );

    useEffect(() => {
        if (isSuccess) {
            notify.info('Permissions has been updated', {
                toastId: 'update-user-permissions',
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            if ((error as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'update-user-permissions-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'update-user-permissions-error',
                });
            }
        }
    }, [isError, error]);

    return (
        <div className={cx('user-body')}>
            <div className={cx('body-wrapper')}>
                <div className={cx('user-info-wrapper')}>
                    <UserInfo user={user} />
                </div>

                <div className={cx('user-options')}>
                    <div className={cx('permissions-wrapper')}>
                        <Permissions
                            selectedPermissions={selectedPermissions}
                            setSelectedPermissions={setSelectedPermissions}
                            isSuperuser={isSuperuser}
                            setIsSuperuser={setIsSuperuser}
                            user={user}
                        />
                    </div>

                    <div className={cx('properties-wrapper')}>
                        <Properties
                            isUserActive={isUserActive}
                            setUserActive={setUserActive}
                            userExpirationDate={userExpirationDate}
                            setUserExpirationDate={setUserExpirationDate}
                            user={user}
                        />
                    </div>

                    <div className={cx('button-wrapper')}>
                        <BasicButton
                            locked={!canManagePermissions}
                            title="Save"
                            isProcessing={isLoading}
                            style={{
                                height: 32,
                                width: '100%',
                                fontSize: 12,
                                backgroundColor: COLOR['app-green'],
                            }}
                            onClick={() => {
                                updatePermissions({
                                    id: user.id,
                                    isSuperuser,
                                    isActive: isUserActive,
                                    permissions: Array.from(selectedPermissions),
                                    expirationDate: userExpirationDate,
                                });
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

type Props = {
    user: AdminUser,
};

export default UserBody;
