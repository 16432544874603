import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import styles from './PairToggleButtons.module.scss';

const cx: CX = classnames.bind(styles);

function PairToggleButtons(props: Props) {
    const {
        titleOn,
        titleOff,
        onClickOn,
        onClickOff,
    } = props;

    function handleClickOn() {
        onClickOn();
    }

    function handleClickOff() {
        onClickOff();
    }

    return (
        <div className={cx('pair-toggle-buttons')}>
            <button
                type="button"
                className="clear-button"
                onClick={handleClickOn}
            >
                <div className={cx('on-button')}>
                    {titleOn}
                </div>
            </button>
            <button
                type="button"
                className="clear-button"
                onClick={handleClickOff}
            >
                <div className={cx('off-button')}>
                    {titleOff}
                </div>
            </button>
        </div>
    );
}

PairToggleButtons.propTypes = {
    titleOn: PropTypes.string.isRequired,
    titleOff: PropTypes.string.isRequired,
    onClickOn: PropTypes.func.isRequired,
    onClickOff: PropTypes.func.isRequired,
};

type Props = InferProps<typeof PairToggleButtons.propTypes>;

export default PairToggleButtons;
