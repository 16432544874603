import { useMemo } from 'react';
import { EqualityFn } from 'react-redux';

import { useAppDispatch, useAppSelector } from '@STORE/index';
import { newDocumentModalSlice, NewDocumentModalState } from '@REDUCERS';

function createNewDocumentModalHook() {
    return function useNewDocumentModal(equalityFn?: EqualityFn<NewDocumentModalState>) {
        const state = useAppSelector((store) => store.newDocumentModal, equalityFn);

        const dispatch = useAppDispatch();

        const actions = useMemo(() => ({
            open: () => dispatch(newDocumentModalSlice.actions.open()),
            close: () => dispatch(newDocumentModalSlice.actions.close()),
        }), [dispatch]);

        return {
            state,
            actions,
        };
    };
}

export default createNewDocumentModalHook();
