import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { ReactComponent as ArrowIcon } from '@ICONS/arrow-forward-icon.svg';

import DocumentPreviewPage from '@COMPONENTS/SHARED/DocumentPreviewPage';

import styles from './PublicationPair.module.scss';

const cx: CX = classnames.bind(styles);

function PublicationPair(props: Props) {
    const {
        pair,
        contentIsEmpty,
        isLandscape,
    } = props;

    const { oldVersion, newVersion } = pair;

    return (
        <div className={cx('publication-pair')}>
            <div className={cx('body-wrapper')}>
                <div className={cx('body')}>
                    <div className={cx('old')}>
                        <div className={cx('pair-body-header')}>
                            Current version
                        </div>
                        <div className={cx('page')}>
                            {
                                oldVersion
                                    ? (
                                        <DocumentPreviewPage
                                            body={oldVersion || ''}
                                            isLandscape={isLandscape}
                                            isPreview
                                        />
                                    ) : (
                                        <div
                                            className={cx('page-placeholder', {
                                                'is-landscape': isLandscape,
                                            })}
                                        />
                                    )
                            }
                        </div>
                    </div>
                    <div className={cx('arrow-wrapper')}>
                        <div className={cx('arrow')}>
                            <ArrowIcon
                                width={18}
                                height={18}
                            />
                        </div>
                    </div>
                    <div className={cx('new')}>
                        {
                            contentIsEmpty
                            && (
                                <div className={cx('empty-warning')}>
                                    Content is empty!
                                </div>
                            )
                        }
                        <div className={cx('pair-body-header')}>
                            New version
                        </div>
                        <div className={cx('page')}>
                            <DocumentPreviewPage
                                body={newVersion || ''}
                                isLandscape={isLandscape}
                                isPreview
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

PublicationPair.propTypes = {
    contentIsEmpty: PropTypes.bool.isRequired,
    isLandscape: PropTypes.bool.isRequired,
};

type Props = InferProps<typeof PublicationPair.propTypes> & {
    pair: {
        oldVersion: string | null,
        newVersion: string,
    },
};

export default PublicationPair;
