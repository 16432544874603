import React, { useState } from 'react';

import classnames from 'classnames/bind';

import BaseModal from '@MODALS/BaseModal';

import usePreviewVariantModal from '@HOOKS/store/modals/usePreviewVariantModal';
import useDownloadDocumentPreviewWrapper from '@HOOKS/useDownloadDocumentPreviewWrapper';

import DocumentPreviewPage from '@COMPONENTS/SHARED/DocumentPreviewPage';
import ViewMode from '@MODALS/Previews/components/ViewMode';
import GeneratePreviewPDFButton from '@COMPONENTS/COMMON/buttons/GeneratePreviewPDFButton';

import styles from './PreviewVariantModal.module.scss';

const cx: CX = classnames.bind(styles);

function PreviewVariantModal() {
    const {
        state: { data: modalParams },
        actions: previewVariantModalActions,
    } = usePreviewVariantModal();

    const [
        downloadDocumentPreview,
        { isLoading },
    ] = useDownloadDocumentPreviewWrapper();

    const {
        documentId,
        variant,
        baseText,
        isLandscape,
        canGenerateDocument,
    } = modalParams!;

    const isPublished = (
        baseText.isPublished
        && variant.isPublished
    );

    const [showUnpublished, setShowUnpublished] = useState<boolean>(false);

    const baseTextBody = showUnpublished
        ? (baseText.body || '')
        : (baseText.versions[baseText.versions.length - 1]?.body || '');

    const variantBody = showUnpublished
        ? (
            baseTextBody + (variant.body || '')
        ) : (
            baseTextBody + (variant.versions[variant.versions.length - 1]?.body || '')
        );

    function handleClose() {
        previewVariantModalActions.close();
    }

    return (
        <BaseModal
            style={{
                top: 30,
                height: '100%',
            }}
            onClose={() => handleClose()}
        >
            <div className={cx('preview-variant-modal')}>
                <div className={cx('header')}>
                    <div className={cx('title-wrapper')}>
                        <div className={cx('title')}>
                            Variant preview
                        </div>
                        <ViewMode
                            disabled={isPublished}
                            showUnpublished={showUnpublished}
                            setShowUnpublished={setShowUnpublished}
                        />
                    </div>
                    <div className={cx('pdf-button-wrapper')}>
                        <GeneratePreviewPDFButton
                            isProcessing={isLoading}
                            canGenerateDocument={canGenerateDocument}
                            onClick={() => {
                                downloadDocumentPreview({
                                    document: documentId,
                                    variants: [variant.id],
                                    sections: [],
                                    chapters: [],
                                    isUnpublished: showUnpublished,
                                });
                            }}
                        />
                    </div>
                    <div className={cx('close-button-wrapper')}>
                        <button
                            type="button"
                            className={cx('clear-button', 'close-button')}
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            &times;
                        </button>
                    </div>
                </div>
                <div className={cx('content')}>
                    <div className={cx('page-wrapper')}>
                        <DocumentPreviewPage
                            key={showUnpublished.toString()}
                            body={variantBody}
                            isLandscape={isLandscape}
                        />
                    </div>
                </div>
            </div>
        </BaseModal>
    );
}

export default PreviewVariantModal;
