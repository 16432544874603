import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { ReactComponent as OrderIcon } from '@ICONS/order-icon.svg';

import styles from './ChangeOrderButton.module.scss';

const cx: CX = classnames.bind(styles);

function ChangeOrderButton(props: Props) {
    const { onClick } = props;

    return (
        <button
            type="button"
            className={cx('change-order-button', 'clear-button')}
            onClick={onClick}
        >
            <div className={cx('order-icon')}>
                <OrderIcon
                    width={24}
                    height={24}
                />
            </div>
            <div className={cx('button-title')}>
                Change order
            </div>
        </button>
    );
}

ChangeOrderButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

type Props = InferProps<typeof ChangeOrderButton.propTypes>;

export default ChangeOrderButton;
