export default function getDate(val: string | undefined) {
    if (!val) return;

    return new Date(val).toLocaleString('en-US', {
        timeZone: 'Europe/Berlin',
        timeZoneName: 'long',
        hourCycle: 'h12',
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
    }).replace('Central European Time', 'CET')
        .replace('Central European Standard Time', 'CET')
        .replace('Central European Summer Time', 'CET');
}
