/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PublishVariantModalState {
    isOpen: boolean;

    data: {
        id: number;

        body: string;

        documentId: number;

        chapterId: number;

        sectionId: number;
    } | null;
}

export interface PublishVariantModalDispatchParams {
    id: number;

    body: string;

    documentId: number;

    chapterId: number;

    sectionId: number;
}

const initialState: PublishVariantModalState = {
    isOpen: false,
    data: null,
};

export const publishVariantModalSlice = createSlice({
    name: 'publish-variant-modal',
    initialState,
    reducers: {
        open(state: PublishVariantModalState, action: PayloadAction<PublishVariantModalDispatchParams>) {
            state.isOpen = true;
            state.data = action.payload;
        },
        close(state: PublishVariantModalState) {
            state.isOpen = false;
            state.data = null;
        },
    },
});
