import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { AdminSingleSectionDataVariant } from '@INTERFACES/admin-api/sections';

import { isContentEmptyCheck } from '@HELPERS/isContentEmptyCheck';

import StickyHeader from '@COMPONENTS/HEADERS/StickyHeader';
import PairToggleButtons from '@COMPONENTS/COMMON/buttons/PairToggleButtons';

import styles from './VariantsStickyHeader.module.scss';

const cx: CX = classnames.bind(styles);

function VariantsStickyHeader(props: Props) {
    const {
        variants,
        setSelectedVariantsIds,
    } = props;

    return (
        <StickyHeader
            top={1}
        >
            <div className={cx('variants-sticky-header')}>
                <div className={cx('variants-title-wrapper')}>
                    <div className={cx('title')}>
                        Variants
                    </div>
                    <PairToggleButtons
                        titleOn="Select all"
                        titleOff="Deselect all"
                        onClickOn={() => {
                            const variantsIds = variants
                                .filter((v) => !isContentEmptyCheck(v.body))
                                .map((s) => s.id);

                            const set = new Set<number>(variantsIds);

                            setSelectedVariantsIds(set);
                        }}
                        onClickOff={() => {
                            setSelectedVariantsIds(new Set());
                        }}
                    />
                </div>
            </div>
        </StickyHeader>
    );
}

VariantsStickyHeader.propTypes = {
    setSelectedVariantsIds: PropTypes.func.isRequired,
};

type Props = InferProps<typeof VariantsStickyHeader.propTypes> & {
    variants: AdminSingleSectionDataVariant[]
};

export default VariantsStickyHeader;
