import React from 'react';

import classnames from 'classnames/bind';

import { PreviewData } from '@INTERFACES/api/preview';

import styles from './PreviewSidebar.module.scss';

const cx: CX = classnames.bind(styles);

function PreviewSidebar(props: Props) {
    const { previewData } = props;

    function handleChapterClick(id: number) {
        const anchor = `chapter-${id}`;

        const anchorEl = document.getElementById(anchor);

        if (anchorEl) {
            anchorEl.scrollIntoView();
        }
    }

    function handleIntroductionTextClick(id: number) {
        const anchor = `introduction-text-chapter-${id}`;

        const anchorEl = document.getElementById(anchor);

        if (anchorEl) {
            anchorEl.scrollIntoView();
        }
    }

    function handleSectionClick(id: number) {
        const anchor = `section-${id}`;

        const anchorEl = document.getElementById(anchor);

        if (anchorEl) {
            anchorEl.scrollIntoView();
        }
    }

    return (
        <div className={cx('preview-sidebar')}>
            <div className="container-left">
                <div className={cx('chapters-title')}>
                    Chapters
                </div>
                <div className={cx('chapters-list')}>
                    {
                        previewData.map((chapter) => {
                            const chapterKey = chapter.id;

                            return (
                                <div
                                    key={chapterKey}
                                    className={cx('chapter-list-item')}
                                >
                                    <div className={cx('chapter')}>
                                        <button
                                            type="button"
                                            className={cx('link', 'clear-button')}
                                            onClick={() => {
                                                handleChapterClick(chapter.id);
                                            }}
                                        >
                                            {chapter.name}
                                        </button>
                                    </div>
                                    {
                                        chapter.hasBody
                                        && (
                                            <div className={cx('introduction-text')}>
                                                <button
                                                    type="button"
                                                    className={cx('link', 'clear-button')}
                                                    onClick={() => {
                                                        handleIntroductionTextClick(chapter.id);
                                                    }}
                                                >
                                                    Introduction text
                                                </button>
                                            </div>
                                        )
                                    }
                                    {
                                        chapter.sections.length > 0
                                        && (
                                            <>
                                                <div className={cx('sections-title')}>
                                                    Sections
                                                </div>
                                                <div className={cx('sections-list')}>
                                                    {
                                                        chapter.sections.map((section) => {
                                                            const sectionKey = section.id;

                                                            return (
                                                                <div
                                                                    key={sectionKey}
                                                                    className={cx('section')}
                                                                >
                                                                    <button
                                                                        type="button"
                                                                        className={cx('link', 'clear-button')}
                                                                        onClick={() => {
                                                                            handleSectionClick(section.id);
                                                                        }}
                                                                    >
                                                                        {section.name}
                                                                    </button>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
}

type Props = {
    previewData: PreviewData,
};

export default PreviewSidebar;
