import { ContentChapter } from '@INTERFACES/api/content';
import getDataByTags from '@PAGES/manager/select-content/helpers/getDataByTags';

interface Data {
    chaptersData: ContentChapter[];

    selectedTagsIds: Set<number>;

    isPartial: boolean;
}

export default function filterDataByTags(
    {
        chaptersData,
        selectedTagsIds,
        isPartial,
    }: Data,
) {
    const CHAPTERS: number[] = [];
    const SECTIONS: number[] = [];
    const VARIANTS: number[] = [];

    chaptersData.forEach((chapter) => {
        CHAPTERS.push(chapter.id);

        const { sections } = chapter;

        sections.forEach((section) => {
            SECTIONS.push(section.id);

            const { variants } = section;

            variants.forEach((variant) => {
                VARIANTS.push(variant.id);
            });
        });
    });

    const initialChaptersIds = new Set<number>(CHAPTERS);
    const initialSectionsIds = new Set<number>(SECTIONS);
    const initialVariantsIds = new Set<number>(VARIANTS);

    const {
        chaptersIds,
        sectionsIds,
        variantsIds,
    } = getDataByTags({
        chaptersData,
        initialChaptersIds,
        initialSectionsIds,
        initialVariantsIds,
        selectedTagsIds,
        isPartial,
    });

    return chaptersData.filter((chapter) => chaptersIds.has(chapter.id)).map((chapter) => {
        const { sections } = chapter;

        return {
            ...chapter,
            sections: sections.filter((section) => sectionsIds.has(section.id)).map((section) => {
                const { variants } = section;

                return {
                    ...section,
                    variants: variants.filter((variant) => variantsIds.has(variant.id)),
                };
            }),
        };
    });
}
