import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classNames from 'classnames/bind';

import { AdminSingleChapterDataSection } from '@INTERFACES/admin-api/chapters';
import useSectionHistoryModal from '@HOOKS/store/modals/useSectionHistoryModal';

import { ReactComponent as ArrowIcon } from '@ICONS/arrow-forward-icon.svg';

import styles from './ModalHeader.module.scss';

const cx: CX = classNames.bind(styles);

function ModalHeader(props: Props) {
    const {
        section,
        currentSectionIndex,
        setCurrentSectionIndex,
    } = props;

    const {
        state: { data: modalParams },
        actions: sectionHistoryModalActions,
    } = useSectionHistoryModal();

    const { sections } = modalParams!;

    const hasPrev = currentSectionIndex > 0;

    const hasNext = currentSectionIndex < (sections.length - 1);

    function onPrev() {
        if (hasPrev) {
            setCurrentSectionIndex(currentSectionIndex - 1);
        }
    }

    function onNext() {
        if (hasNext) {
            setCurrentSectionIndex(currentSectionIndex + 1);
        }
    }

    return (
        <div className={cx('modal-header')}>
            <div className={cx('title-wrapper')}>
                <div className={cx('title')}>
                    Section history
                </div>
                <div className={cx('prev-next')}>
                    <button
                        type="button"
                        disabled={!hasPrev}
                        className={cx('clear-button', 'prev-button')}
                        onClick={() => {
                            onPrev();
                        }}
                    >
                        <div className={cx('icon')}>
                            <ArrowIcon
                                width={24}
                                height={24}
                            />
                        </div>
                    </button>
                    <button
                        type="button"
                        disabled={!hasNext}
                        className={cx('clear-button', 'next-button')}
                        onClick={() => {
                            onNext();
                        }}
                    >
                        <div className={cx('icon')}>
                            <ArrowIcon
                                width={24}
                                height={24}
                            />
                        </div>
                    </button>
                </div>
            </div>
            <div className={cx('section-title')}>
                {section.name}
            </div>
            <div className={cx('close-button-wrapper')}>
                <button
                    type="button"
                    className={cx('clear-button', 'close-button')}
                    onClick={() => {
                        sectionHistoryModalActions.close();
                    }}
                >
                    &times;
                </button>
            </div>
        </div>
    );
}

ModalHeader.propTypes = {
    currentSectionIndex: PropTypes.number.isRequired,
    setCurrentSectionIndex: PropTypes.func.isRequired,
};

type Props = InferProps<typeof ModalHeader.propTypes> & {
    section: AdminSingleChapterDataSection,
};

export default ModalHeader;
