import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { AdminSingleChapterDataSection } from '@INTERFACES/admin-api/chapters';

import usePreviewSectionModal from '@HOOKS/store/modals/usePreviewSectionModal';
import useDownloadDocumentPreviewWrapper from '@HOOKS/useDownloadDocumentPreviewWrapper';

import { ReactComponent as ArrowIcon } from '@ICONS/arrow-forward-icon.svg';

import ViewMode from '@MODALS/Previews/components/ViewMode';
import GeneratePreviewPDFButton from '@COMPONENTS/COMMON/buttons/GeneratePreviewPDFButton';

import styles from './ModalHeader.module.scss';

const cx: CX = classnames.bind(styles);

function ModalHeader(props: Props) {
    const {
        section,
        currentSectionIndex,
        setCurrentSectionIndex,
        showUnpublished,
        setShowUnpublished,
        canGenerateDocument,
    } = props;

    const {
        state: { data: modalParams },
        actions: previewSectionModalActions,
    } = usePreviewSectionModal();

    const [
        downloadDocumentPreview,
        { isLoading },
    ] = useDownloadDocumentPreviewWrapper();

    const { documentId, sections } = modalParams!;

    const hasPrev = currentSectionIndex > 0;

    const hasNext = currentSectionIndex < (sections.length - 1);

    const isPublished = (
        section.baseText.isPublished
        && section.variants.every((v) => v.isPublished)
    );

    function onPrev() {
        if (hasPrev) {
            setCurrentSectionIndex(currentSectionIndex - 1);
        }
    }

    function onNext() {
        if (hasNext) {
            setCurrentSectionIndex(currentSectionIndex + 1);
        }
    }

    return (
        <div className={cx('modal-header')}>
            <div className={cx('title-wrapper')}>
                <div className={cx('title')}>
                    Section preview
                </div>
                <div className={cx('prev-next')}>
                    <button
                        type="button"
                        disabled={!hasPrev}
                        className={cx('clear-button', 'prev-button')}
                        onClick={() => {
                            onPrev();
                        }}
                    >
                        <div className={cx('icon')}>
                            <ArrowIcon
                                width={24}
                                height={24}
                            />
                        </div>
                    </button>
                    <button
                        type="button"
                        disabled={!hasNext}
                        className={cx('clear-button', 'next-button')}
                        onClick={() => {
                            onNext();
                        }}
                    >
                        <div className={cx('icon')}>
                            <ArrowIcon
                                width={24}
                                height={24}
                            />
                        </div>
                    </button>

                </div>
                <ViewMode
                    disabled={isPublished}
                    showUnpublished={showUnpublished}
                    setShowUnpublished={setShowUnpublished}
                />
            </div>
            <div className={cx('section-name')}>
                {section.name}
            </div>
            <div className={cx('right-block')}>
                <div className={cx('pdf-button-wrapper')}>
                    <GeneratePreviewPDFButton
                        isProcessing={isLoading}
                        canGenerateDocument={canGenerateDocument}
                        onClick={() => {
                            downloadDocumentPreview({
                                document: documentId,
                                variants: [...section.variants.map((v) => v.id)],
                                sections: [section.id],
                                chapters: [],
                                isUnpublished: showUnpublished,
                            });
                        }}
                    />
                </div>

                <div className={cx('close-button-wrapper')}>
                    <button
                        type="button"
                        className={cx('clear-button', 'close-button')}
                        onClick={() => {
                            previewSectionModalActions.close();
                        }}
                    >
                        &times;
                    </button>
                </div>
            </div>
        </div>
    );
}

ModalHeader.propTypes = {
    currentSectionIndex: PropTypes.number.isRequired,
    setCurrentSectionIndex: PropTypes.func.isRequired,
    showUnpublished: PropTypes.bool.isRequired,
    setShowUnpublished: PropTypes.func.isRequired,
    canGenerateDocument: PropTypes.bool.isRequired,
};

type Props = InferProps<typeof ModalHeader.propTypes> & {
    section: AdminSingleChapterDataSection,
};

export default ModalHeader;
