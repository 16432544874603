import { ContentChapter } from '@INTERFACES/api/content';

interface Data {
    chaptersData: ContentChapter[];

    searchValue: string;
}

export default function filterDataBySearch({ chaptersData, searchValue }: Data) {
    const val = searchValue.toLowerCase().trim();

    if (!val) return chaptersData;

    return chaptersData.map((chapter) => {
        const { sections } = chapter;

        return {
            ...chapter,
            sections: sections.filter((section) => {
                try {
                    return section.name.toLowerCase().match(
                        val,
                    );
                } catch (e) {
                    return false;
                }
            }),
        };
    }).filter((chapter) => chapter.sections.length > 0);
}
