import { useMemo } from 'react';
import { EqualityFn } from 'react-redux';

import { useAppDispatch, useAppSelector } from '@STORE/index';

import { previewSectionModalSlice, PreviewSectionModalState, PreviewSectionModalDispatchParams } from '@REDUCERS';

function createPreviewSectionModalHook() {
    return function usePreviewSectionModal(equalityFn?: EqualityFn<PreviewSectionModalState>) {
        const state = useAppSelector((store) => store.previewSectionModal, equalityFn);

        const dispatch = useAppDispatch();

        const actions = useMemo(() => ({
            open: (params: PreviewSectionModalDispatchParams) => dispatch(previewSectionModalSlice.actions.open(params)),
            close: () => dispatch(previewSectionModalSlice.actions.close()),
        }), [dispatch]);

        return {
            state,
            actions,
        };
    };
}

export default createPreviewSectionModalHook();
