import React, { useId } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import styles from './TabRadio.module.scss';

const cx: CX = classnames.bind(styles);

function TabRadio(props: Props) {
    const {
        title, items, activeId, onChange,
    } = props;

    const name = useId();

    function handleChange(id: number) {
        onChange(id);
    }

    return (
        <div className={cx('tab-radio')}>
            {
                title && (
                    <div className={cx('title')}>
                        {title}
                    </div>
                )
            }
            <div className={cx('radios')}>
                {
                    items.map((radio) => {
                        const key = radio.id;

                        return (
                            <label key={key}>
                                <input
                                    type="radio"
                                    name={name}
                                    checked={radio.id === activeId}
                                    className="visually-hidden"
                                    onChange={() => handleChange(radio.id)}
                                />
                                <div className={cx('content')}>
                                    {radio.name}
                                </div>
                            </label>
                        );
                    })
                }
            </div>
        </div>
    );
}

TabRadio.defaultProps = {
    title: '',
};

TabRadio.propTypes = {
    title: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.exact({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
    activeId: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
};

type Props = InferProps<typeof TabRadio.propTypes>;

export default TabRadio;
