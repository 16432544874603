import { ADMIN_API_PREFIX, BASE_URL } from '@CONSTANTS/API.constant';
import userRepository from '@REPOSITORIES/local-repository/user-repository';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface DownloadDocumentPreviewApiResponse {
    document_url: string;

    document_name: string;
}

type DownloadDocumentPreview = {
    documentUrl: string;

    documentName: string;
};

type DownloadParams = {
    document: number;

    variants: number[];

    sections: number[];

    chapters: number[];

    isUnpublished: boolean;
};

export const downloadDocumentPreviewAdminApi = createApi({
    reducerPath: 'download-document-preview-admin-api',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + ADMIN_API_PREFIX,
        prepareHeaders: (headers) => {
            const token = userRepository.getData();

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        download: builder.mutation<DownloadDocumentPreview, DownloadParams>({
            query: (params: DownloadParams) => {
                const formData = new FormData();

                formData.append('document', params.document.toString());
                formData.append('variant', params.variants.join(','));
                formData.append('section', params.sections.join(','));
                formData.append('chapter', params.chapters.join(','));
                formData.append('is_unpublished', params.isUnpublished.toString());

                return ({
                    url: '/download-preview/',
                    method: 'POST',
                    body: formData,
                });
            },
            transformResponse(raw: DownloadDocumentPreviewApiResponse) {
                return {
                    documentUrl: raw.document_url,
                    documentName: raw.document_name,
                };
            },
        }),
    }),
});

export const {
    useDownloadMutation: useDownloadDocumentPreview,
} = downloadDocumentPreviewAdminApi;
