import React, { MouseEvent, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import CircularProgressIndicator from '@COMPONENTS/SHARED/CircularProgressIndicator';
import Tooltip from '@COMPONENTS/SHARED/Tooltip';
import Lock from '@COMPONENTS/SHARED/Lock';

import styles from './BasicButton.module.scss';

const cx: CX = classnames.bind(styles);

function BasicButton(props: Props) {
    const {
        tooltip,
        locked,
        title,
        style,
        progressIndicatorColor,
        progressIndicatorSize,
        isProcessing,
        onClick,
    } = props;

    const [tooltipId] = useState(Tooltip.getId());

    function handleClick(event: MouseEvent<HTMLButtonElement>) {
        if (!isProcessing && !locked) {
            onClick(event);
        }
    }

    return (
        <>
            <button
                id={tooltipId}
                type="button"
                style={style}
                className={cx('basic-button', 'clear-button', {
                    'is-processing': isProcessing,
                    locked,
                })}
                onClick={handleClick}
            >
                {
                    locked
                    && (
                        <div className={cx('lock-wrapper')}>
                            <Lock size="small" />
                        </div>
                    )
                }

                {
                    !isProcessing
                        ? title
                        : (
                            <div className={cx('processing')}>
                                <CircularProgressIndicator
                                    color={progressIndicatorColor}
                                    size={progressIndicatorSize}
                                />
                            </div>
                        )
                }
            </button>
            {
                tooltip
                && (
                    <Tooltip
                        id={tooltipId}
                        content={tooltip}
                        disabled={false}
                    />
                )
            }
        </>
    );
}

BasicButton.defaultProps = {
    tooltip: '',
    locked: false,
    isProcessing: false,
    style: {},
    progressIndicatorColor: COLOR['app-white'],
    progressIndicatorSize: 22,
};

BasicButton.propTypes = {
    tooltip: PropTypes.string,
    locked: PropTypes.bool,
    title: PropTypes.string.isRequired,
    style: PropTypes.shape({}),
    isProcessing: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    progressIndicatorColor: PropTypes.string,
    progressIndicatorSize: PropTypes.number,
};

type Props = InferProps<typeof BasicButton.propTypes> & typeof BasicButton.defaultProps;

export default BasicButton;
