import React, { useEffect, useMemo, useState } from 'react';

import classnames from 'classnames/bind';

import DESIGN from '@CONSTANTS/DESIGN.constant';

import { useGetAllVendors } from '@SERVICES';

import useDeleteVendorModal from '@HOOKS/store/modals/useDeleteVendorModal';
import useNewVendorModal from '@HOOKS/store/modals/useNewVendorModal';

import Content from '@PAGES/admin/vendors/components/Content';
import getFilteredBySearchData from '@PAGES/admin/vendors/helpers/getFilteredBySearchData';

import VendorsSidebar from '@COMPONENTS/SIDEBARS/VendorsSidebar';
import DataLoader from '@COMPONENTS/SHARED/DataLoader';
import ContentWithFullHeightSidebar from '@COMPONENTS/SHARED/ContentWithFullHeightSidebar';

import styles from './Vendors.module.scss';

const cx: CX = classnames.bind(styles);

const top = DESIGN['subheader-height'];

const defaultData = {
    data: [],
};

function VendorsPage() {
    const {
        data: vendorsData = defaultData, isLoading, isError, error,
    } = useGetAllVendors();

    const {
        actions: newVendorModalActions,
    } = useNewVendorModal(() => true);

    const {
        actions: deleteVendorModalActions,
    } = useDeleteVendorModal(() => true);

    const [searchValue, setSearchValue] = useState<string>('');

    const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');

    useEffect(() => () => {
        newVendorModalActions.close();
        deleteVendorModalActions.close();
    }, [newVendorModalActions, deleteVendorModalActions]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedSearchValue(searchValue);
        }, 250);

        return () => {
            clearTimeout(timer);
        };
    }, [searchValue]);

    // SEARCH
    const filteredVendorsData = useMemo(
        () => getFilteredBySearchData(vendorsData, debouncedSearchValue),
        [vendorsData, debouncedSearchValue],
    );

    return (
        <div className={cx('page')}>
            <div className="max-constraint center">
                <ContentWithFullHeightSidebar
                    top={top}
                    sidebar={(
                        <VendorsSidebar
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                        />
                    )}
                >
                    <DataLoader
                        isLoading={isLoading}
                        isError={isError}
                        error={error}
                    >
                        <Content
                            top={top}
                            vendorsData={filteredVendorsData.data!}
                        />
                    </DataLoader>
                </ContentWithFullHeightSidebar>
            </div>
        </div>
    );
}

export default VendorsPage;
