import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { AdminLogs } from '@SERVICES';

import Table from '@PAGES/admin/logs/components/Table';
import Pagination from '@PAGES/admin/logs/components/Pagination';

import styles from './Content.module.scss';

const cx: CX = classnames.bind(styles);

function Content(props: Props) {
    const {
        page,
        logs,
        setPage,
    } = props;

    return (
        <div className={cx('content')}>
            <div className={cx('table-wrapper')}>
                <Table
                    page={page !== null ? +page : 1}
                    logs={logs}
                />
            </div>

            {
                logs.totalPages > 0
                && (
                    <div className={cx('pagination-wrapper')}>
                        <Pagination
                            pageCount={logs.totalPages}
                            forcePage={page !== null ? +page - 1 : 0}
                            onPageChange={(selected) => {
                                setPage(selected);
                            }}
                        />
                    </div>
                )
            }
        </div>
    );
}

Content.propTypes = {
    setPage: PropTypes.func.isRequired,
};

type Props = InferProps<typeof Content.propTypes> & {
    page: string | null,
    logs: AdminLogs,
};

export default Content;
