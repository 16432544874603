import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import { ReactComponent as CheckboxIcon } from '@ICONS/checkbox-icon.svg';

import styles from './CircleCheckbox.module.scss';

const cx: CX = classnames.bind(styles);

function CircleCheckbox(props: Props) {
    const {
        disabled,
        checked,
        onChange,
    } = props;

    function handleChange() {
        onChange(!checked);
    }

    return (
        <div
            className={cx('circle-checkbox')}
        >
            <label className={cx({ disabled })}>
                <input
                    className="visually-hidden"
                    type="checkbox"
                    checked={checked}
                    onChange={handleChange}
                />
                <div className={cx('icon-wrapper')}>
                    <CheckboxIcon
                        width={20}
                        height={20}
                        fill={disabled ? COLOR['marriott-light-grey-2'] : COLOR['marriott-dark-grey']}
                    />
                </div>
            </label>
        </div>
    );
}

CircleCheckbox.propTypes = {
    disabled: PropTypes.bool.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

type Props = InferProps<typeof CircleCheckbox.propTypes>;

export default CircleCheckbox;
