import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classNames from 'classnames/bind';

import { AdminUnpublishedDocumentChapterSectionVariant } from '@SERVICES';

import { isContentEmptyCheck } from '@HELPERS/isContentEmptyCheck';

import StickyHeader from '@COMPONENTS/HEADERS/StickyHeader';
import PairToggleButtons from '@COMPONENTS/COMMON/buttons/PairToggleButtons';

import styles from './VariantsStickyHeader.module.scss';

const cx: CX = classNames.bind(styles);

function VariantsStickyHeader(props: Props) {
    const {
        variants,
        selectedVariantsIds,
        setSelectedVariantsIds,
    } = props;

    return (
        <StickyHeader
            top={35}
        >
            <div className={cx('variants-sticky-header')}>
                <div className={cx('variants-title-wrapper')}>
                    <div className={cx('title')}>
                        Variants
                    </div>
                    <PairToggleButtons
                        titleOn="Select all"
                        titleOff="Deselect all"
                        onClickOn={() => {
                            const variantsIds = variants
                                .filter((v) => !isContentEmptyCheck(v.body))
                                .map((s) => s.id);

                            const set = new Set<number>(selectedVariantsIds);

                            variantsIds.forEach((id) => {
                                set.add(id);
                            });

                            setSelectedVariantsIds(set);
                        }}
                        onClickOff={() => {
                            const variantsIds = variants
                                .filter((v) => !isContentEmptyCheck(v.body))
                                .map((s) => s.id);

                            const set = new Set<number>(selectedVariantsIds);

                            variantsIds.forEach((id) => {
                                set.delete(id);
                            });

                            setSelectedVariantsIds(set);
                        }}
                    />
                </div>
            </div>
        </StickyHeader>
    );
}

VariantsStickyHeader.propTypes = {
    selectedVariantsIds: PropTypes.instanceOf(Set<number>).isRequired,
    setSelectedVariantsIds: PropTypes.func.isRequired,
};

type Props = InferProps<typeof VariantsStickyHeader.propTypes> & {
    variants: AdminUnpublishedDocumentChapterSectionVariant[],
};

export default VariantsStickyHeader;
