import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classNames from 'classnames/bind';

import { ContentVariant } from '@INTERFACES/api/content';

import styles from './VariantRadio.module.scss';

const cx: CX = classNames.bind(styles);

function VariantRadio(props: Props) {
    const {
        variant, name, checked, onChange,
    } = props;

    const { isDefault } = variant;

    return (
        <div className={cx('variant-radio')}>
            <label>
                <input
                    type="radio"
                    name={name}
                    className="visually-hidden"
                    checked={checked}
                    onChange={() => onChange(variant.id)}
                />
                <div
                    className={cx('wrapper', {
                        'is-default': isDefault,
                    })}
                >
                    <div className={cx('header')}>
                        <div className={cx('dot')}>
                            <div className={cx('dot-wrapper')}>
                                <div className={cx('inner-dot')} />
                            </div>
                        </div>
                        {
                            isDefault
                            && (
                                <div className={cx('default')}>
                                    Default
                                </div>
                            )
                        }
                        <div className={cx('title')}>
                            {variant.name}
                        </div>
                    </div>

                    {
                        !isDefault
                        && (

                            <div className={cx('content')}>
                                <div className={cx('info')}>
                                    <div className={cx('info-item')}>
                                        <div className={cx('info-title')}>
                                            Brand
                                        </div>
                                        <div className={cx('info-value')}>
                                            {variant.brand?.name || '-'}
                                        </div>
                                    </div>
                                    <div className={cx('info-item')}>
                                        <div className={cx('info-title')}>
                                            Region
                                        </div>
                                        <div className={cx('info-value')}>
                                            {variant.region?.name || '-'}
                                        </div>
                                    </div>
                                    <div className={cx('info-item')}>
                                        <div className={cx('info-title')}>
                                            Area
                                        </div>
                                        <div className={cx('info-value')}>
                                            {variant.area?.name || '-'}
                                        </div>
                                    </div>
                                    <div className={cx('info-item')}>
                                        <div className={cx('info-title')}>
                                            Country
                                        </div>
                                        <div className={cx('info-value')}>
                                            {variant.country?.name || '-'}
                                        </div>
                                    </div>
                                    <div className={cx('info-item')}>
                                        <div className={cx('info-title')}>
                                            Type
                                        </div>
                                        <div className={cx('info-value')}>
                                            {variant.propertyType?.name || '-'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </label>
        </div>
    );
}

VariantRadio.propTypes = {
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

type Props = InferProps<typeof VariantRadio.propTypes> & { variant: ContentVariant };

export default VariantRadio;
