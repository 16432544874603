import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { LogsActions, logsActionsMap } from '@CONSTANTS/LOGS.constant';

import BasicCheckbox from '@COMPONENTS/COMMON/inputs/checkbox/BasicCheckbox';

import styles from './LogsSidebar.module.scss';

const cx: CX = classnames.bind(styles);

function LogsSidebar(props: Props) {
    const {
        actions,
        setActions,
    } = props;

    return (
        <div className={cx('logs-sidebar')}>
            <div className="container-left">
                <div className={cx('sidebar-title')}>
                    Filter logs
                </div>

                <div className={cx('actions-filter')}>
                    <div className={cx('filter-title')}>
                        Actions
                    </div>

                    <div className={cx('actions-list')}>
                        <div className={cx('action')}>
                            <BasicCheckbox
                                checked={actions.has(LogsActions.LOGIN)}
                                title={logsActionsMap[LogsActions.LOGIN]}
                                onChange={() => {
                                    const set = new Set(actions);

                                    if (set.has(LogsActions.LOGIN)) {
                                        set.delete(LogsActions.LOGIN);
                                    } else {
                                        set.add(LogsActions.LOGIN);
                                    }

                                    setActions(set);
                                }}
                            />
                        </div>

                        <div className={cx('action')}>
                            <BasicCheckbox
                                checked={actions.has(LogsActions.DOWNLOAD_DOCUMENT)}
                                title={logsActionsMap[LogsActions.DOWNLOAD_DOCUMENT]}
                                onChange={() => {
                                    const set = new Set(actions);

                                    if (set.has(LogsActions.DOWNLOAD_DOCUMENT)) {
                                        set.delete(LogsActions.DOWNLOAD_DOCUMENT);
                                    } else {
                                        set.add(LogsActions.DOWNLOAD_DOCUMENT);
                                    }

                                    setActions(set);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

LogsSidebar.propTypes = {
    actions: PropTypes.instanceOf(Set<LogsActions>).isRequired,
    setActions: PropTypes.func.isRequired,
};

type Props = InferProps<typeof LogsSidebar.propTypes>;

export default LogsSidebar;
