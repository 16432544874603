import { useDownloadDocumentPreview } from '@SERVICES';
import { useEffect } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { notify } from '@NOTIFICATION/Notificator';
import createDocumentLinkAndClick from '@HELPERS/createDocumentLinkAndClick';

function createDownloadDocumentPreviewWrapperHook() {
    return function useDownloadDocumentPreviewWrapper() {
        const hookResponse = useDownloadDocumentPreview();

        const [
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            downloadDocumentPreview,
            {
                data: downloadDocumentPreviewData,
                isSuccess,
                isError,
                error,
            },
        ] = hookResponse;

        useEffect(() => {
            if (isSuccess) {
                const { documentUrl, documentName } = downloadDocumentPreviewData!;

                createDocumentLinkAndClick({
                    url: documentUrl,
                    name: `${documentName} (Preview)`,
                });
            }
        }, [downloadDocumentPreviewData, isSuccess]);

        useEffect(() => {
            if (isError) {
                if ((error as FetchBaseQueryError).status === 403) {
                    notify.error('Permission denied', {
                        toastId: 'download-document-preview-error-permission-error',
                    });
                } else {
                    notify.error('Something wrong!', {
                        toastId: 'download-document-preview-error',
                    });
                }
            }
        }, [isError, error]);

        return hookResponse;
    };
}

export default createDownloadDocumentPreviewWrapperHook();
