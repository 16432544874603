import { useMemo } from 'react';
import { EqualityFn } from 'react-redux';

import { useAppDispatch, useAppSelector } from '@STORE/index';
import { newTagInSectionModalSlice, NewTagInSectionModalDispatchParams, NewTagInSectionModalState } from '@REDUCERS';

function createNewTagInSectionModalHook() {
    return function useNewTagInSectionModal(equalityFn?: EqualityFn<NewTagInSectionModalState>) {
        const state = useAppSelector((store) => store.newTagInSectionModal, equalityFn);

        const dispatch = useAppDispatch();

        const actions = useMemo(() => ({
            open: (params: NewTagInSectionModalDispatchParams) => dispatch(newTagInSectionModalSlice.actions.open(params)),
            close: () => dispatch(newTagInSectionModalSlice.actions.close()),
        }), [dispatch]);

        return {
            state,
            actions,
        };
    };
}

export default createNewTagInSectionModalHook();
