import React, { MouseEvent, ReactElement } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classNames from 'classnames/bind';

import { ReactComponent as DocumentGenerationIcon } from '@ICONS/document-generation-page-icon.svg';
import { ReactComponent as DocumentAdminIcon } from '@ICONS/document-admin-page-icon.svg';
import { ReactComponent as UserPermissionsIcon } from '@ICONS/user-permissions-page-icon.svg';
import { ReactComponent as VendorsIcon } from '@ICONS/vendors-page-icon.svg';
import { ReactComponent as LogsIcon } from '@ICONS/logs-page-icon.svg';

import Lock from '@COMPONENTS/SHARED/Lock';

import styles from './IconSquareButton.module.scss';

const cx: CX = classNames.bind(styles);

const icons: { [key: string]: ReactElement } = {
    'document-generation': <DocumentGenerationIcon width={50} height={50} />,
    'document-admin': <DocumentAdminIcon width={50} height={50} />,
    'user-permissions': <UserPermissionsIcon width={50} height={50} />,
    vendors: <VendorsIcon width={50} height={50} />,
    logs: <LogsIcon width={44} height={44} />,
};

function IconSquareButton(props: Props) {
    const {
        title,
        icon,
        onClick,
        locked,
    } = props;

    function handleClick(event: MouseEvent<HTMLButtonElement>) {
        if (!locked) {
            onClick(event);
        }
    }

    const Icon = icons[icon];

    return (
        <div
            className={cx('icon-square-button', {
                locked,
            })}
        >
            {
                locked
                && (
                    <div className={cx('lock-wrapper')}>
                        <Lock size="large" />
                    </div>
                )
            }

            <button
                className="clear-button"
                type="button"
                onClick={(e) => handleClick(e)}
            >
                <div className={cx('content')}>
                    <div className={cx('icon', {
                        logs: icon === 'logs',
                    })}
                    >
                        {Icon}
                    </div>
                    <div className={cx('title')}>
                        {title}
                    </div>
                </div>
            </button>
        </div>
    );
}

IconSquareButton.propTypes = {
    locked: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    icon: PropTypes.oneOf([
        'document-generation',
        'document-admin',
        'user-permissions',
        'vendors',
        'logs',
    ]).isRequired,
    onClick: PropTypes.func.isRequired,
};

type Props = InferProps<typeof IconSquareButton.propTypes>;

export default IconSquareButton;
