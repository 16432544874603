import React from 'react';
import { matchRoutes, RouteObject, useLocation } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';
import ROUTES from '@CONSTANTS/ROUTES.constant';

const titlesMap = {
    [ROUTES.login.path]: 'Login',

    [ROUTES.main.path]: 'Dashboard',
    [ROUTES.chooseDocument.path]: 'Document',
    [ROUTES.brand.path]: 'Brand',
    [ROUTES.region.path]: 'Region',
    [ROUTES.area.path]: 'Area',
    [ROUTES.country.path]: 'Country',
    [ROUTES.type.path]: 'Type',
    [ROUTES.options.path]: 'Options',
    [ROUTES.selectContent.path]: 'Select Content',
    [ROUTES.preview.path]: 'Preview',
    [ROUTES.downloadDocument.path]: 'Download Document',

    [ROUTES.admin.documents.path]: 'Admin - Documents List',
    [ROUTES.admin.documents.id.path]: 'Admin - Manage Document',
    [ROUTES.admin.chapters.id.path]: 'Admin - Manage Chapter',
    [ROUTES.admin.sections.id.path]: 'Admin - Manage Section',
    [ROUTES.admin.manageVendors.path]: 'Admin - Manage Vendors',
    [ROUTES.admin.logs.path]: 'Admin - Logs',
    [ROUTES.admin.manageUsersPermissions.path]: 'Admin - Manage User Permissions',
};

const routes: RouteObject[] = [
    { path: ROUTES.login.path },

    { path: ROUTES.main.path },
    { path: ROUTES.chooseDocument.path },
    { path: ROUTES.brand.path },
    { path: ROUTES.region.path },
    { path: ROUTES.area.path },
    { path: ROUTES.country.path },
    { path: ROUTES.type.path },
    { path: ROUTES.options.path },
    { path: ROUTES.selectContent.path },
    { path: ROUTES.preview.path },
    { path: ROUTES.downloadDocument.path },

    { path: ROUTES.admin.documents.path },
    { path: ROUTES.admin.documents.id.path },
    { path: ROUTES.admin.chapters.id.path },
    { path: ROUTES.admin.sections.id.path },
    { path: ROUTES.admin.manageVendors.path },
    { path: ROUTES.admin.logs.path },
    { path: ROUTES.admin.manageUsersPermissions.path },
];

function AppHelmet(props: OnlyChildrenProps) {
    const { children } = props;

    const location = useLocation();

    const [{ route }] = matchRoutes(routes, location) || [{ route: { path: null } }];

    const title = `DMT - ${titlesMap[route.path!] || '404 Not found'}`;

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            {children}
        </>
    );
}

export default AppHelmet;
