import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { PreviewSection } from '@INTERFACES/api/preview';

import DocumentPreviewPage from '@COMPONENTS/SHARED/DocumentPreviewPage';

import styles from './Section.module.scss';

const cx: CX = classnames.bind(styles);

function Section(props: Props) {
    const {
        isPartial,
        isChapterMandatory,
        section,
    } = props;

    const {
        name,
        body,
        hasBody,
        variant,
        isLandscape,
    } = section;

    const fullBody = (body || '') + (variant?.body || '');

    if (!fullBody || (!hasBody && !variant?.hasBody)) return null;

    const sectionIsMandatory = !isPartial && (isChapterMandatory || section.isMandatory);

    return (
        <div className={cx('section')}>
            <div
                id={`section-${section.id}`}
                className={cx('anchor')}
            />
            <div className={cx('header')}>
                <div className={cx('title')}>
                    {name}
                </div>

            </div>
            <div className={cx('page')}>
                <div className={cx('body-wrapper')}>
                    {
                        sectionIsMandatory
                        && (
                            <div className={cx('mandatory-label')}>
                                Mandatory
                            </div>
                        )
                    }

                    <DocumentPreviewPage
                        body={fullBody}
                        isLandscape={isLandscape}
                    />
                </div>
            </div>
        </div>
    );
}

Section.propTypes = {
    isPartial: PropTypes.bool.isRequired,
    isChapterMandatory: PropTypes.bool.isRequired,
};

type Props = InferProps<typeof Section.propTypes> & {
    section: PreviewSection,
};

export default Section;
