import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { AdminSingleSectionData } from '@INTERFACES/admin-api/sections';

import StickyHeader from '@COMPONENTS/HEADERS/StickyHeader';
import PairToggleButtons from '@COMPONENTS/COMMON/buttons/PairToggleButtons';

import styles from './VariantsStickyHeader.module.scss';

const cx: CX = classnames.bind(styles);

function VariantsStickyHeader(props: Props) {
    const { top, setOpenVariantsIds, sectionData } = props;

    const { variants } = sectionData;

    return (
        <StickyHeader
            top={top}
        >
            <div
                id="variants-sticky-header"
                className={cx('variants-sticky-header', 'container')}
            >
                <div className={cx('variants-title-wrapper')}>
                    <div className={cx('title')}>
                        Variants
                    </div>
                    <PairToggleButtons
                        titleOn="Show all"
                        titleOff="Hide all"
                        onClickOn={() => {
                            const variantsIds = variants.map((s) => s.id);

                            const set = new Set<number>(variantsIds);

                            setOpenVariantsIds(set);
                        }}
                        onClickOff={() => {
                            setOpenVariantsIds(new Set());
                        }}
                    />
                </div>
            </div>
        </StickyHeader>
    );
}

VariantsStickyHeader.propTypes = {
    top: PropTypes.number.isRequired,
    setOpenVariantsIds: PropTypes.func.isRequired,
};

type Props = InferProps<typeof VariantsStickyHeader.propTypes> & {
    sectionData: AdminSingleSectionData,
};

export default VariantsStickyHeader;
