import { useMemo } from 'react';
import { EqualityFn } from 'react-redux';

import { useAppDispatch, useAppSelector } from '@STORE/index';

import {
    PublishSectionBaseTextModalDispatchParams,
    publishSectionBaseTextModalSlice,
    PublishSectionBaseTextModalState,
} from '@REDUCERS';

function createPublishSectionBaseTextModalHook() {
    return function usePublishSectionBaseTextModal(equalityFn?: EqualityFn<PublishSectionBaseTextModalState>) {
        const state = useAppSelector((store) => store.publishSectionBaseTextModal, equalityFn);

        const dispatch = useAppDispatch();

        const actions = useMemo(() => ({
            open: (params: PublishSectionBaseTextModalDispatchParams) => dispatch(publishSectionBaseTextModalSlice.actions.open(params)),
            close: () => dispatch(publishSectionBaseTextModalSlice.actions.close()),
        }), [dispatch]);

        return {
            state,
            actions,
        };
    };
}

export default createPublishSectionBaseTextModalHook();
