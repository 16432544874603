import { useMemo } from 'react';
import { EqualityFn } from 'react-redux';

import { useAppDispatch, useAppSelector } from '@STORE/index';
import { newTagInVariantModalSlice, NewTagInVariantModalDispatchParams, NewTagInVariantModalState } from '@REDUCERS';

function createNewTagInVariantModalHook() {
    return function useNewTagInVariantModal(equalityFn?: EqualityFn<NewTagInVariantModalState>) {
        const state = useAppSelector((store) => store.newTagInVariantModal, equalityFn);

        const dispatch = useAppDispatch();

        const actions = useMemo(() => ({
            open: (params: NewTagInVariantModalDispatchParams) => dispatch(newTagInVariantModalSlice.actions.open(params)),
            close: () => dispatch(newTagInVariantModalSlice.actions.close()),
        }), [dispatch]);

        return {
            state,
            actions,
        };
    };
}

export default createNewTagInVariantModalHook();
