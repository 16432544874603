import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import {
    AdminSingleChapterData,
    AdminSingleChapterDataSection,
    AdminSingleChapterMeta,
} from '@INTERFACES/admin-api/chapters';

import SectionHeader from '@PAGES/admin/edit-chapter/components/SectionHeader';
import SectionBody from '@PAGES/admin/edit-chapter/components/SectionBody';

import styles from './Section.module.scss';

const cx: CX = classnames.bind(styles);

function Section(props: Props) {
    const {
        section,
        chapterData,
        chapterMeta,
        index,
        openSectionsIds,
        setOpenSectionsIds,
    } = props;

    const isOpen = openSectionsIds.has(section.id);

    return (
        <>
            <div className={cx('hidden-title')}>
                {section.name}
            </div>
            <div className={cx('section')}>
                <SectionHeader
                    section={section}
                    chapterData={chapterData}
                    chapterMeta={chapterMeta}
                    index={index}
                    isOpen={isOpen}
                    onOpenToggle={(val: boolean) => {
                        const set = new Set(openSectionsIds);

                        if (val) {
                            set.add(section.id);
                        } else {
                            set.delete(section.id);
                        }

                        setOpenSectionsIds(set);
                    }}
                />

                {
                    isOpen && <SectionBody section={section} />
                }
            </div>
        </>
    );
}

Section.propTypes = {
    index: PropTypes.number.isRequired,
    openSectionsIds: PropTypes.instanceOf(Set<number>).isRequired,
    setOpenSectionsIds: PropTypes.func.isRequired,
};

type Props = InferProps<typeof Section.propTypes> & {
    section: AdminSingleChapterDataSection,
    chapterData: AdminSingleChapterData,
    chapterMeta: AdminSingleChapterMeta,
};

export default Section;
