import React, { useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import useAuth from '@HOOKS/useAuth';

import { AdminUser } from '@SERVICES';

import BasicCheckbox from '@COMPONENTS/COMMON/inputs/checkbox/BasicCheckbox';
import DatePicker from '@COMPONENTS/SHARED/DatePicker';

import styles from './Properties.module.scss';
import COLOR from '@CONSTANTS/COLOR.constant';

const cx: CX = classnames.bind(styles);

function Properties(props: Props) {
    const {
        isUserActive,
        setUserActive,
        userExpirationDate,
        setUserExpirationDate,
        user,
    } = props;

    const { state: { user: currentUser } } = useAuth();

    const disabled = user.id === currentUser!.id;

    const activeCheckboxColor = (
        isUserActive ? COLOR['app-green'] : COLOR['marriott-orange']
    );

    return (
        <div className={cx('properties')}>
            <div className={cx('properties-title')}>
                Properties
            </div>

            <div className={cx('active-user-checkbox')}>
                <BasicCheckbox
                    title="Active user"
                    checked={isUserActive}
                    color={disabled ? COLOR['marriott-light-grey'] : activeCheckboxColor}
                    disabled={disabled}
                    fontWeight={700}
                    onChange={() => {
                        setUserActive((val: boolean) => !val);
                    }}
                />
            </div>

            <div className={cx('expiration-date')}>
                <div
                    className={cx('title', {
                        disabled,
                    })}
                >
                    Expiration date
                </div>
                <div className={cx('date-picker-wrapper')}>
                    <DatePicker
                        date={userExpirationDate}
                        setDate={(d) => {
                            setUserExpirationDate(d);
                        }}
                        disabled={disabled}
                    />
                </div>
            </div>
        </div>
    );
}

Properties.propTypes = {
    isUserActive: PropTypes.bool.isRequired,
    setUserActive: PropTypes.func.isRequired,
    setUserExpirationDate: PropTypes.func.isRequired,
};

type Props = InferProps<typeof Properties.propTypes> & {
    userExpirationDate: Date | null,
    user: AdminUser,
};

export default Properties;
