import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import StickyHeader from '@COMPONENTS/HEADERS/StickyHeader';

import styles from './OnlyTitleStickyHeader.module.scss';

const cx: CX = classnames.bind(styles);

function OnlyTitleStickyHeader(props: Props) {
    const { title, top } = props;

    return (
        <StickyHeader
            top={top}
        >
            <div className={cx('only-title-sticky-header')}>
                <div className={cx('title')}>
                    {title}
                </div>
            </div>
        </StickyHeader>
    );
}

OnlyTitleStickyHeader.propTypes = {
    title: PropTypes.string.isRequired,
    top: PropTypes.number.isRequired,
};

type Props = InferProps<typeof OnlyTitleStickyHeader.propTypes>;

export default OnlyTitleStickyHeader;
