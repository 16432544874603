import React, { useEffect, useState } from 'react';

import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import COLOR from '@CONSTANTS/COLOR.constant';

import { AdminSingleChapterData, AdminSingleChapterMeta } from '@INTERFACES/admin-api/chapters';

import useDocumentPermissions from '@HOOKS/useDocumentPermissions';
import { useUpdateChapter } from '@SERVICES';

import ChapterName from '@PAGES/admin/edit-chapter/components/ChapterName';
import RoundedCheckbox from '@COMPONENTS/COMMON/inputs/checkbox/RoundedCheckbox';
import BasicButton from '@COMPONENTS/COMMON/buttons/BasicButton';

import styles from './PageHeader.module.scss';

const cx: CX = classnames.bind(styles);

function PageHeader(props: Props) {
    const { chapterData, chapterMeta } = props;

    const {
        canEditDocument,
    } = useDocumentPermissions({
        documentOwnerId: chapterData.document.owner,
        permissions: chapterMeta.permissions,
    });

    const [
        updateChapter,
        {
            isLoading, isSuccess, isError, error,
        },
    ] = useUpdateChapter();

    const [isMandatory, setMandatory] = useState<boolean>(chapterData.isMandatory);

    useEffect(() => {
        if (isSuccess) {
            notify.info('Chapter has been updated', {
                toastId: 'update-chapter-params',
            });
        }
    }, [isSuccess]);

    const shouldShowSaveButton = (
        chapterData.isMandatory !== isMandatory
    );

    useEffect(() => {
        if (isError) {
            if ((error as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'update-chapter-params-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'update-chapter-params-error',
                });
            }
        }
    }, [isError, error]);

    return (
        <div className={cx('page-header')}>
            <ChapterName
                chapterData={chapterData}
                chapterMeta={chapterMeta}
            />

            <div className={cx('chapter-options', 'container')}>
                <div className={cx('options')}>
                    <div className={cx('option')}>
                        <div className={cx('checkbox-title')}>
                            Mandatory
                        </div>
                        <RoundedCheckbox
                            color={COLOR['app-green']}
                            checked={isMandatory}
                            onChange={() => {
                                setMandatory((val) => !val);
                            }}
                        />
                    </div>
                </div>

                {
                    shouldShowSaveButton
                    && (
                        <div className={cx('save-button-wrapper')}>
                            <BasicButton
                                locked={!canEditDocument}
                                title="Save"
                                isProcessing={isLoading}
                                style={{
                                    height: 32,
                                    width: 110,
                                    fontSize: 12,
                                    backgroundColor: COLOR['app-green'],
                                }}
                                onClick={() => {
                                    updateChapter({
                                        id: chapterData.id,
                                        documentId: chapterData.document.id,
                                        name: chapterData.name,
                                        body: chapterData.introductionText.body,
                                        isMandatory,
                                        image: '',
                                    });
                                }}
                            />
                        </div>
                    )
                }
            </div>
        </div>
    );
}

type Props = {
    chapterData: AdminSingleChapterData,
    chapterMeta: AdminSingleChapterMeta,
};

export default PageHeader;
