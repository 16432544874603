import {
    documentApi,
    brandApi,
    regionApi,
    areasApi,
    countryApi,
    propertyApi,
    contentApi,
    previewApi,
    variantApi,
    variantHistoryApi,
    downloadDocumentApi,
    authApi,
    documentsAdminApi,
    chaptersAdminApi,
    sectionsAdminApi,
    sectionsMandatoryConditionsAdminApi,
    variantsAdminApi,
    tagsAdminApi,
    vendorsAdminApi,
    productsAdminApi,
    usersAdminApi,
    reorderAdminApi,
    publishAdminApi,
    unpublishedAdminApi,
    previewAdminApi,
    downloadDocumentPreviewAdminApi,
    searchAdminApi,
    logsAdminApi,
} from '@SERVICES';

import stepsPersistMiddleware from '@MIDDLEWARES/stepsPersistMiddleware';

export default [
    stepsPersistMiddleware,

    documentApi.middleware,
    brandApi.middleware,
    regionApi.middleware,
    areasApi.middleware,
    countryApi.middleware,
    propertyApi.middleware,
    contentApi.middleware,
    previewApi.middleware,
    variantApi.middleware,
    variantHistoryApi.middleware,
    downloadDocumentApi.middleware,
    authApi.middleware,

    // ADMIN
    documentsAdminApi.middleware,
    chaptersAdminApi.middleware,
    sectionsAdminApi.middleware,
    sectionsMandatoryConditionsAdminApi.middleware,
    variantsAdminApi.middleware,
    tagsAdminApi.middleware,
    vendorsAdminApi.middleware,
    productsAdminApi.middleware,
    usersAdminApi.middleware,
    reorderAdminApi.middleware,
    publishAdminApi.middleware,
    unpublishedAdminApi.middleware,
    previewAdminApi.middleware,
    downloadDocumentPreviewAdminApi.middleware,
    searchAdminApi.middleware,
    logsAdminApi.middleware,
];
