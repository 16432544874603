import React, { useLayoutEffect, useMemo, useState } from 'react';

import classnames from 'classnames/bind';

import { AdminSingleDocumentData, useGetDocumentUnpublished } from '@SERVICES';

import { isContentEmptyCheck } from '@HELPERS/isContentEmptyCheck';

import ModalSidebar from '@MODALS/Publications/PublishDocumentModal/components/ModalSidebar';
import DataLoader from '@COMPONENTS/SHARED/DataLoader';
import Content from '@MODALS/Publications/PublishDocumentModal/components/Content';

import styles from './ModalBody.module.scss';

const cx: CX = classnames.bind(styles);

function ModalBody(props: Props) {
    const { documentData } = props;

    const {
        data, isLoading, isSuccess, isError, error,
    } = useGetDocumentUnpublished(documentData.id);

    const [selectedChaptersIds, setSelectedChaptersIds] = useState<Set<number>>(new Set());
    const [selectedSectionsIds, setSelectedSectionsIds] = useState<Set<number>>(new Set());
    const [selectedVariantsIds, setSelectedVariantsIds] = useState<Set<number>>(new Set());

    const unpublishedDocumentData = useMemo(() => {
        if (!data) return;

        return {
            ...data,
            chapters: data.chapters.map((chapter) => ({
                ...chapter,
                sections: chapter.sections.map((section) => ({
                    ...section,
                    variants: section.variants.filter((v) => !v.isPublished),
                })).filter((s) => (
                    !s.baseText.isPublished
                    || s.variants.length > 0
                )),
            })).filter((ch) => (
                !ch.introductionText.isPublished
                || ch.sections.length > 0
            )),
        };
    }, [data]);

    useLayoutEffect(() => {
        if (isSuccess && unpublishedDocumentData) {
            setSelectedChaptersIds(
                new Set(
                    unpublishedDocumentData.chapters
                        .filter((chapter) => !chapter.introductionText.isPublished)
                        .filter((chapter) => !isContentEmptyCheck(chapter.introductionText.body))
                        .map((chapter) => chapter.id),
                ),
            );

            setSelectedSectionsIds(
                new Set(
                    unpublishedDocumentData.chapters.reduce<number[]>((cAcc, chapter) => {
                        const { sections } = chapter;

                        return [
                            ...cAcc,
                            ...sections
                                .filter((section) => !section.baseText.isPublished)
                                .filter((section) => !isContentEmptyCheck(section.baseText.body))
                                .map((section) => section.id),
                        ];
                    }, []),
                ),
            );

            setSelectedVariantsIds(
                new Set(
                    unpublishedDocumentData.chapters.reduce<number[]>((cAcc, chapter) => {
                        const { sections } = chapter;

                        return [...cAcc, ...sections.reduce<number[]>((sAcc, section) => {
                            const { variants } = section;

                            return [
                                ...sAcc,
                                ...variants
                                    .filter((v) => !isContentEmptyCheck(v.body))
                                    .map((v) => v.id),
                            ];
                        }, [])];
                    }, []),
                ),
            );
        }
    }, [unpublishedDocumentData, isSuccess]);

    return (
        <div className={cx('modal-body')}>
            <div className={cx('sidebar')}>
                <ModalSidebar
                    documentData={unpublishedDocumentData}
                    selectedChaptersIds={selectedChaptersIds}
                    selectedSectionsIds={selectedSectionsIds}
                    selectedVariantsIds={selectedVariantsIds}
                />
            </div>

            <div className={cx('body')}>
                <DataLoader
                    isLoading={isLoading}
                    isError={isError}
                    error={error}
                >
                    <Content
                        documentData={unpublishedDocumentData!}
                        selectedChaptersIds={selectedChaptersIds}
                        setSelectedChaptersIds={setSelectedChaptersIds}
                        selectedSectionsIds={selectedSectionsIds}
                        setSelectedSectionsIds={setSelectedSectionsIds}
                        selectedVariantsIds={selectedVariantsIds}
                        setSelectedVariantsIds={setSelectedVariantsIds}
                    />
                </DataLoader>
            </div>
        </div>
    );
}

type Props = {
    documentData: AdminSingleDocumentData,
};

export default ModalBody;
