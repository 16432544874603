/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminSingleSectionDataVariant } from '@INTERFACES/admin-api/sections';

export interface VariantHistoryModalState {
    isOpen: boolean;

    data: {
        variant: AdminSingleSectionDataVariant,
        isLandscape: boolean,
    } | null;
}

export interface VariantHistoryModalDispatchParams {
    variant: AdminSingleSectionDataVariant,
    isLandscape: boolean,
}

const initialState: VariantHistoryModalState = {
    isOpen: false,
    data: null,
};

export const variantHistoryModalSlice = createSlice({
    name: 'variant-history-modal',
    initialState,
    reducers: {
        open(state: VariantHistoryModalState, action: PayloadAction<VariantHistoryModalDispatchParams>) {
            state.isOpen = true;
            state.data = action.payload;
        },
        close(state: VariantHistoryModalState) {
            state.isOpen = false;
            state.data = null;
        },
    },
});
