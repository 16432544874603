import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import { AdminUnpublishedDocumentChapter } from '@SERVICES';

import { isContentEmptyCheck } from '@HELPERS/isContentEmptyCheck';

import PublicationPair from '@COMPONENTS/SHARED/PublicationPair';
import CircleCheckbox from '@COMPONENTS/COMMON/inputs/checkbox/CircleCheckbox';

import styles from './IntroductionText.module.scss';

const cx: CX = classnames.bind(styles);

function IntroductionText(props: Props) {
    const {
        chapter,
        selectedChaptersIds,
        setSelectedChaptersIds,
    } = props;

    const { introductionText } = chapter;

    const oldVersion = introductionText.version?.body || null;
    const newVersion = introductionText.body;

    const contentIsEmpty = isContentEmptyCheck(newVersion);

    return (
        <div className={cx('introduction-text')}>
            <div className={cx('checkbox-wrapper')}>
                <div className={cx('checkbox')}>
                    <CircleCheckbox
                        disabled={contentIsEmpty}
                        checked={selectedChaptersIds.has(chapter.id)}
                        onChange={(val: boolean) => {
                            const set = new Set(selectedChaptersIds);

                            if (val) {
                                set.add(chapter.id);
                            } else {
                                set.delete(chapter.id);
                            }

                            setSelectedChaptersIds(set);
                        }}
                    />
                </div>
            </div>
            <div className={cx('introduction-text-body')}>
                <PublicationPair
                    contentIsEmpty={contentIsEmpty}
                    isLandscape={false}
                    pair={{
                        oldVersion,
                        newVersion,
                    }}
                />
            </div>
        </div>
    );
}

IntroductionText.propTypes = {
    selectedChaptersIds: PropTypes.instanceOf(Set<number>).isRequired,
    setSelectedChaptersIds: PropTypes.func.isRequired,
};

type Props = InferProps<typeof IntroductionText.propTypes> & {
    chapter: AdminUnpublishedDocumentChapter;
};

export default IntroductionText;
