import React from 'react';
import DeleteItemModal from '@MODALS/common/DeleteItemModal';
import { useDeleteTag } from '@SERVICES';
import useDeleteTagModal from '@HOOKS/store/modals/useDeleteTagModal';

function DeleteTagModal() {
    const {
        state: { data: modalParams },
        actions,
    } = useDeleteTagModal();

    const [
        deleteTag,
        {
            isLoading, isSuccess, isError, error,
        },
    ] = useDeleteTag();

    return (
        <DeleteItemModal
            modalTitle="Delete tag"
            name={modalParams!.name}
            itemName="Tag"
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            error={error}
            onDelete={() => {
                deleteTag({
                    id: modalParams!.id,
                    documentId: modalParams!.documentId,
                });
            }}
            onClose={() => {
                actions.close();
            }}
        />
    );
}

export default DeleteTagModal;
