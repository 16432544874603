import React, { useEffect, useState } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { FileWithPath } from 'react-dropzone';

import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import COLOR from '@CONSTANTS/COLOR.constant';

import { AdminSingleDocument, useUpdateDocument } from '@SERVICES';
import useDocumentPermissions from '@HOOKS/useDocumentPermissions';

import DropFileInput from '@COMPONENTS/COMMON/inputs/file/DropFileInput';
import BasicButton from '@COMPONENTS/COMMON/buttons/BasicButton';

import styles from './DocumentImage.module.scss';

const cx: CX = classnames.bind(styles);

function DocumentImage(props: Props) {
    const { documentData } = props;

    const {
        canEditDocument,
    } = useDocumentPermissions({
        documentOwnerId: documentData.data.owner.id,
        permissions: documentData.meta.permissions,
    });

    const [
        updateDocument,
        {
            isLoading, isSuccess, isError, error,
        },
    ] = useUpdateDocument();

    const [image, setImage] = useState<string | null>(documentData.data.cover);
    const [blob, setBlob] = useState<Blob | string | null>('');

    useEffect(() => {
        if (isSuccess) {
            notify.info('Document image has been updated', {
                toastId: 'update-document-image',
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            if ((error as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'update-document-image-permission-error',
                });
            } else {
                notify.error('Something wrong', {
                    toastId: 'update-document-image-error',
                });
            }
        }
    }, [isError, error]);

    function readFile(file: FileWithPath) {
        const img = URL.createObjectURL(file);

        setImage(img);
        setBlob(file);
    }

    return (
        <div className={cx('document-image', 'container')}>
            <DropFileInput
                title="Document image (PNG/JPG)"
                onDrop={(acceptedFiles) => {
                    acceptedFiles.forEach((file: FileWithPath) => {
                        readFile(file);
                    });
                }}
            />
            {
                image
                    ? (
                        <div className={cx('image-container')}>
                            <button
                                type="button"
                                className={cx('clear-button', 'delete-button')}
                                onClick={() => {
                                    setImage(null);
                                    setBlob(null);
                                }}
                            >
                                &times;
                            </button>
                            <div className={cx('image-wrapper')}>
                                <img src={image} alt="" />
                            </div>
                        </div>
                    ) : (
                        <div className={cx('no-image')}>
                            No image
                        </div>
                    )
            }
            <div className={cx('save-button-wrapper')}>
                <BasicButton
                    locked={!canEditDocument}
                    title="Save"
                    isProcessing={isLoading}
                    style={{
                        height: 40,
                        width: '100%',
                        fontSize: 14,
                        backgroundColor: COLOR['app-green'],
                    }}
                    onClick={() => {
                        updateDocument({
                            id: documentData.data.id,
                            name: documentData.data.name,
                            cover: blob,
                        });
                    }}
                />
            </div>
        </div>
    );
}

type Props = {
    documentData: AdminSingleDocument,
};

export default DocumentImage;
