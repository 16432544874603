import React, { useEffect } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { notify } from '@NOTIFICATION/Notificator';

import { AdminSingleChapterData, AdminSingleChapterDataIntroductionText } from '@INTERFACES/admin-api/chapters';

import { usePublishAll } from '@SERVICES';
import usePublishChapterModal from '@HOOKS/store/modals/usePublishChapterModal';
import useAuth from '@HOOKS/useAuth';

import { PublishFormValues } from '@COMPONENTS/SHARED/PublicationForm';
import BaseModalSidebar from '@MODALS/Publications/components/BaseModalSidebar';

function ModalSidebar(props: Props) {
    const {
        chapterData,
        introductionText,
        includeIntroductionText,
        selectedSectionsIds,
        selectedVariantsIds,
    } = props;

    const { state: { user } } = useAuth();

    const {
        actions: publishChapterModalActions,
    } = usePublishChapterModal(() => true);

    const [
        publishAll,
        {
            isLoading, isSuccess, isError, error,
        },
    ] = usePublishAll();

    useEffect(() => {
        if (isSuccess) {
            notify.info('Chapter has been published', {
                toastId: 'publish-chapter',
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            if ((error as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'publish-chapter-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'publish-chapter-error',
                });
            }
        }
    }, [isError, error]);

    useEffect(() => {
        if (isSuccess) {
            const isPublished = (
                chapterData.introductionText.isPublished
                && chapterData.sections.every((section) => (
                    section.baseText.isPublished
                    && section.variants.every((v) => v.isPublished)
                ))
            );

            if (isPublished) {
                publishChapterModalActions.close();
            }
        }
    }, [isSuccess, chapterData, publishChapterModalActions]);

    const hasSelected = includeIntroductionText || selectedSectionsIds.size > 0 || selectedVariantsIds.size > 0;

    function onSave(formValues: PublishFormValues, includeToVersionControl: boolean) {
        let description = null;

        if (includeToVersionControl) {
            description = formValues.description;
        }

        const chapters: any[] = introductionText ? [{
            id: chapterData.id,
            documentId: chapterData.document.id,
            body: introductionText?.body || '',
        }] : [];

        const sections: any[] = [];

        const variants: any[] = [];

        chapterData.sections.forEach((section) => {
            if (selectedSectionsIds.has(section.id)) {
                sections.push({
                    id: section.id,
                    chapterId: chapterData.id,
                    documentId: chapterData.document.id,
                    body: section.baseText.body,
                });
            }
        });

        chapterData.sections.forEach((section) => {
            const { variants: v } = section;

            v.forEach((variant) => {
                if (selectedVariantsIds.has(variant.id)) {
                    variants.push({
                        id: variant.id,
                        sectionId: section.id,
                        chapterId: chapterData.id,
                        documentId: chapterData.document.id,
                        body: variant.body,
                    });
                }
            });
        });

        publishAll({
            type: 'chapter',
            documentId: chapterData.document.id,
            data: {
                description,
                userId: user!.id,
                chapters,
                sections,
                variants,
            },
        });
    }

    return (
        <BaseModalSidebar
            isLoading={isLoading}
            hasSelected={hasSelected}
            onSave={(formValues: PublishFormValues, includeToVersionControl) => {
                onSave(formValues, includeToVersionControl);
            }}
        />
    );
}

ModalSidebar.propTypes = {
    includeIntroductionText: PropTypes.bool.isRequired,
    selectedSectionsIds: PropTypes.instanceOf(Set<number>).isRequired,
    selectedVariantsIds: PropTypes.instanceOf(Set<number>).isRequired,
};

type Props = InferProps<typeof ModalSidebar.propTypes> & {
    chapterData: AdminSingleChapterData,
    introductionText: AdminSingleChapterDataIntroductionText | null,
};

export default ModalSidebar;
