import React, {
    RefObject, useEffect, useRef,
} from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import { notify } from '@NOTIFICATION/Notificator';

import { usePublishVariant } from '@SERVICES';

import useAuth from '@HOOKS/useAuth';
import usePublishVariantModal from '@HOOKS/store/modals/usePublishVariantModal';

import BaseModal from '@MODALS/BaseModal';
import BasicButton from '@COMPONENTS/COMMON/buttons/BasicButton';
import HiddenFormButton from '@COMPONENTS/COMMON/buttons/HiddenFormButton';
import PublicationForm, { PublishFormValues } from '@COMPONENTS/SHARED/PublicationForm';

import styles from './PublishVariantModal.module.scss';

const cx: CX = classnames.bind(styles);

function PublishVariantModal() {
    const { state: { user } } = useAuth();

    const {
        state: { data: modalParams },
        actions: publishVariantModalActions,
    } = usePublishVariantModal(() => true);

    const [
        publishVariant,
        {
            isLoading, isSuccess, isError, error,
        },
    ] = usePublishVariant();

    const {
        id: variantId, body, documentId, chapterId, sectionId,
    } = modalParams!;

    const formButtonRef: RefObject<HTMLButtonElement> = useRef(null);

    useEffect(() => {
        if (isSuccess) {
            notify.info('Variant has been published', {
                toastId: 'publish-variant',
            });

            publishVariantModalActions.close();
        }
    }, [isSuccess, publishVariantModalActions]);

    useEffect(() => {
        if (isError) {
            if ((error as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'publish-variant-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'publish-variant-error',
                });
            }
        }
    }, [isError, error]);

    function onSave(formValues: PublishFormValues, includeToVersionControl: boolean) {
        let description = null;

        if (includeToVersionControl) {
            description = formValues.description;
        }

        publishVariant({
            id: variantId,
            body,
            description,
            userId: user!.id,
            documentId,
            chapterId,
            sectionId,
        });
    }

    function handleClose() {
        publishVariantModalActions.close();
    }

    return (
        <BaseModal
            style={{ top: 160 }}
            onClose={() => handleClose()}
        >
            <div className={cx('publish-variant-modal')}>
                <div className={cx('title')}>
                    Publish variant
                </div>

                <PublicationForm
                    onSave={(formValues: PublishFormValues, includeToVersionControl) => {
                        onSave(formValues, includeToVersionControl);
                    }}
                >
                    <HiddenFormButton ref={formButtonRef} />
                </PublicationForm>

                <div className={cx('footer')}>
                    <BasicButton
                        title="Publish"
                        isProcessing={isLoading}
                        style={{
                            height: 40,
                            width: '100%',
                            fontSize: 14,
                            backgroundColor: COLOR['app-green'],
                        }}
                        onClick={() => {
                            formButtonRef.current?.click();
                        }}
                    />
                </div>
            </div>
        </BaseModal>
    );
}

export default PublishVariantModal;
