type ApiVersion = {
    id: number;

    description: string | null;

    body: string;

    created_at: string;

    author: {
        id: number;

        full_name: string;

        email: string;
    };
};

type Version = {
    id: number;

    description: string;

    body: string;

    createdAt: string;

    author: {
        id: number;

        fullName: string;

        email: string;
    };
};

export function formatVersion(apiVersion: ApiVersion): Version {
    return {
        id: apiVersion.id,
        body: apiVersion.body || '',
        description: apiVersion.description || '',
        createdAt: apiVersion.created_at,
        author: {
            id: apiVersion.author.id,
            fullName: apiVersion.author.full_name,
            email: apiVersion.author.email,
        },
    };
}
