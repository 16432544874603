import React, { useLayoutEffect, useMemo } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import classnames from 'classnames/bind';

import { LogsActions } from '@CONSTANTS/LOGS.constant';
import DESIGN from '@CONSTANTS/DESIGN.constant';
import ROUTES from '@CONSTANTS/ROUTES.constant';

import { useLogsLazy } from '@SERVICES';

import ContentWithFullHeightSidebar from '@COMPONENTS/SHARED/ContentWithFullHeightSidebar';
import Content from '@PAGES/admin/logs/components/Content';
import DataLoader from '@COMPONENTS/SHARED/DataLoader';
import LogsSidebar from '@COMPONENTS/SIDEBARS/LogsSidebar';

import styles from './Logs.module.scss';

const cx: CX = classnames.bind(styles);

const top = DESIGN['subheader-height'];

const actionsList = [
    LogsActions.LOGIN,
    LogsActions.DOWNLOAD_DOCUMENT,
];

function parseActionsFromQuery(queryActions: string | null) {
    if (queryActions === null) {
        return new Set(actionsList);
    }

    const res = queryActions.split(',').map((a) => +a) as LogsActions[];

    const hasNaN = res.some((num) => Number.isNaN(num));

    if (hasNaN) {
        return new Set(actionsList);
    }

    return new Set(res);
}

function getActionsList(actionsSet: Set<LogsActions>) {
    const newActionsList = Array.from(actionsSet);

    let list: LogsActions[];

    if (newActionsList.length === actionsList.length) {
        list = [];
    } else {
        list = newActionsList;
    }

    list.sort();

    return list;
}

function LogsPage() {
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const page = params.get('page');
    const queryActions = params.get('actions');

    const [
        getLogs,
        {
            data, isLoading, isFetching, isError, error,
        },
    ] = useLogsLazy();

    const actions = useMemo(
        () => parseActionsFromQuery(queryActions),
        [queryActions],
    );

    const setPage = (selected: number) => {
        navigate(ROUTES.admin.logs.get({
            page: selected + 1,
            actions: getActionsList(actions),
        }));
    };

    const setActions = (newActions: Set<LogsActions>) => {
        navigate(ROUTES.admin.logs.get({
            page: 1,
            actions: getActionsList(newActions),
        }));
    };

    useLayoutEffect(() => {
        const results = getLogs(
            {
                page: page !== null ? +page : 1,
                actions: Array.from(actions),
            },
            true,
        );

        return () => {
            results.abort();
        };
    }, [getLogs, page, actions]);

    if (!data && !isLoading) return null;

    return (
        <div className={cx('page')}>
            <div className="max-constraint center">
                <ContentWithFullHeightSidebar
                    top={top}
                    sidebar={(
                        <LogsSidebar
                            actions={actions}
                            setActions={setActions}
                        />
                    )}
                >
                    <DataLoader
                        isLoading={isLoading}
                        isFetching={isFetching}
                        isError={isError}
                        error={error}
                    >
                        <Content
                            page={page}
                            logs={data!}
                            setPage={setPage}
                        />
                    </DataLoader>
                </ContentWithFullHeightSidebar>
            </div>
        </div>
    );
}

export default LogsPage;
