import { useMemo } from 'react';
import { EqualityFn } from 'react-redux';

import { useAppDispatch, useAppSelector } from '@STORE/index';

import { PublishChapterModalDispatchParams, publishChapterModalSlice, PublishChapterModalState } from '@REDUCERS';

function createPublishChapterModalHook() {
    return function usePublishChapterModal(equalityFn?: EqualityFn<PublishChapterModalState>) {
        const state = useAppSelector((store) => store.publishChapterModal, equalityFn);

        const dispatch = useAppDispatch();

        const actions = useMemo(() => ({
            open: (params: PublishChapterModalDispatchParams) => dispatch(publishChapterModalSlice.actions.open(params)),
            close: () => dispatch(publishChapterModalSlice.actions.close()),
        }), [dispatch]);

        return {
            state,
            actions,
        };
    };
}

export default createPublishChapterModalHook();
