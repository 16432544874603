import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import { useNavigate } from 'react-router-dom';

import classnames from 'classnames/bind';

import ROUTES from '@CONSTANTS/ROUTES.constant';

import { ReactComponent as EditIcon } from '@ICONS/edit-icon-2.svg';

import Warning from '@COMPONENTS/SHARED/Warning';
import LatestUpdates from '@MODALS/Previews/components/LatestUpdates';

import styles from './BaseTextInfo.module.scss';

const cx: CX = classnames.bind(styles);

type Section = {
    id: number;

    baseText: BaseText;
};

type BaseText = {
    isPublished: boolean;

    latestVersion: Version;
};

type Version = {
    id: number;

    description: string;

    body: string;

    createdAt: string;

    author: {
        id: number;

        fullName: string;

        email: string;
    };
} | null;

function BaseTextInfo(props: Props) {
    const { top, section } = props;

    const navigate = useNavigate();

    const { baseText: { isPublished, latestVersion } } = section;

    function getWarning() {
        if (latestVersion === null) {
            return 'Base text is not published';
        }

        return 'Base text has unpublished changes';
    }

    return (
        <div className={cx('base-text-info')}>
            <div
                className={cx('info')}
                style={{
                    top,
                }}
            >
                {
                    !isPublished
                    && (
                        <>
                            <div className={cx('warning-wrapper')}>
                                <Warning text={getWarning()} />
                            </div>
                            <div className={cx('delimiter')} />
                        </>
                    )
                }

                <div className={cx('edit-button-wrapper')}>
                    <button
                        type="button"
                        className={cx('clear-button', 'edit-button')}
                        onClick={() => {
                            navigate(ROUTES.admin.sections.id.get(section.id));
                        }}
                    >
                        <EditIcon
                            width={20}
                            height={20}
                        />
                        <div className={cx('edit-button-title')}>
                            Edit section
                        </div>
                    </button>
                </div>

                <div className={cx('title')}>
                    Base text
                </div>

                {
                    latestVersion
                    && (
                        <>
                            <div className={cx('delimiter')} />

                            <LatestUpdates
                                includedToVersionControl={!!latestVersion!.description}
                                version={{
                                    description: latestVersion!.description || '',
                                    createdAt: latestVersion!.createdAt,
                                    author: {
                                        fullName: latestVersion!.author.fullName,
                                        email: latestVersion!.author.email,
                                    },
                                }}
                            />
                        </>
                    )
                }
            </div>
        </div>
    );
}

BaseTextInfo.defaultProps = {
    top: 30,
};

BaseTextInfo.propTypes = {
    top: PropTypes.number,
};

type Props = InferProps<typeof BaseTextInfo.propTypes> & typeof BaseTextInfo.defaultProps & {
    section: Section,
};

export default BaseTextInfo;
