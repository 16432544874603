import React from 'react';

import classnames from 'classnames/bind';

import { AdminUsersData } from '@SERVICES';

import User from '@PAGES/admin/users-permissions/components/User';

import styles from './Content.module.scss';

const cx: CX = classnames.bind(styles);

function Content(props: Props) {
    const { usersData } = props;

    return (
        <div className={cx('content')}>
            <div className={cx('page-name')}>
                Users permissions
            </div>

            {
                usersData.length > 0
                    ? (

                        <div className={cx('users-list')}>
                            {
                                usersData.map((user) => {
                                    const key = user.id;

                                    return (
                                        <div
                                            key={key}
                                            className={cx('user-wrapper')}
                                        >
                                            <User user={user} />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    ) : (
                        <div className={cx('users-not-found')}>
                            No users
                        </div>
                    )
            }
        </div>
    );
}

type Props = {
    usersData: AdminUsersData,
};

export default React.memo(Content, (prevProps, nextProps) => prevProps.usersData === nextProps.usersData);
