import { useMemo } from 'react';
import { EqualityFn } from 'react-redux';

import { useAppDispatch, useAppSelector } from '@STORE/index';

import { previewChapterModalSlice, PreviewChapterModalState, PreviewChapterModalDispatchParams } from '@REDUCERS';

function createPreviewChapterModalHook() {
    return function usePreviewChapterModal(equalityFn?: EqualityFn<PreviewChapterModalState>) {
        const state = useAppSelector((store) => store.previewChapterModal, equalityFn);

        const dispatch = useAppDispatch();

        const actions = useMemo(() => ({
            open: (params: PreviewChapterModalDispatchParams) => dispatch(previewChapterModalSlice.actions.open(params)),
            close: () => dispatch(previewChapterModalSlice.actions.close()),
        }), [dispatch]);

        return {
            state,
            actions,
        };
    };
}

export default createPreviewChapterModalHook();
