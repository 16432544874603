import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';
import StickyBox from 'react-sticky-box';

import styles from './StickySidebar.module.scss';

const cx: CX = classnames.bind(styles);

function StickySidebar(props: Props) {
    const {
        offsetTop, offsetBottom, children,
    } = props;

    return (
        <div>
            <StickyBox
                offsetTop={offsetTop}
                offsetBottom={offsetBottom}
                className={cx('sticky')}
            >
                {children}
            </StickyBox>
        </div>
    );
}

StickySidebar.propTypes = {
    offsetTop: PropTypes.number.isRequired,
    offsetBottom: PropTypes.number.isRequired,
};

type Props = InferProps<typeof StickySidebar.propTypes> & OnlyChildrenProps;

export default StickySidebar;
