import React, { useEffect, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { SingleValue } from 'react-select';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import { AdminSingleSectionMeta } from '@INTERFACES/admin-api/sections';

import RoundedCheckbox from '@COMPONENTS/COMMON/inputs/checkbox/RoundedCheckbox';
import BasicSelect from '@COMPONENTS/COMMON/inputs/select/BasicSelect';
import BasicButton from '@COMPONENTS/COMMON/buttons/BasicButton';

import styles from './VariantForm.module.scss';

const cx: CX = classnames.bind(styles);

const defaultOptions = {
    brands: [],
    regions: [],
    areas: [],
    countries: [],
    propertyTypes: [],
};

export type VariantFormValues = {
    isDefault: boolean;
    brand: number | null;
    region: number | null;
    area: number | null;
    country: number | null;
    propertyType: number | null;
};

type Val = 'brands' | 'regions' | 'areas' | 'countries' | 'propertyTypes';

function getValue(sectionMeta: AdminSingleSectionMeta, id: number | null, val: Val) {
    if (id === null) return null;

    const metaOptions = sectionMeta?.options || defaultOptions;

    const option = metaOptions[val].find((opt) => opt.id === id);

    return {
        ...option,
        value: option!.id,
        label: option!.name,
    };
}

function VariantForm(props: Props) {
    const {
        locked,
        buttonTitle,
        isLoading,
        defaultValues,
        sectionMeta,
        onSave,
        onMenuOpen,
        onMenuClose,
    } = props;

    const metaOptions = sectionMeta.options || defaultOptions;

    const [isDefault, setDefault] = useState<boolean>(defaultValues.isDefault);

    const [options, setOptions] = useState({
        brand: defaultValues.brand,
        region: defaultValues.region,
        area: defaultValues.area,
        country: defaultValues.country,
        propertyType: defaultValues.propertyType,
    });

    useEffect(() => {
        // setOptions({
        //     brand: defaultValues.brand,
        //     region: defaultValues.region,
        //     area: defaultValues.area,
        //     country: defaultValues.country,
        //     propertyType: defaultValues.propertyType,
        // });
    }, [defaultValues]);

    return (
        <div className={cx('variant-form')}>
            <div className={cx('is-default-variant')}>
                <div className={cx('checkbox-title')}>
                    Default variant
                </div>
                <RoundedCheckbox
                    color={COLOR['marriott-orange']}
                    checked={isDefault}
                    onChange={() => {
                        setDefault((val) => !val);
                    }}
                />
            </div>
            <div className={cx('selects')}>
                <div className={cx('select-wrapper')}>
                    <BasicSelect
                        title="Brand"
                        isDisabled={isDefault}
                        placeholder="Choose Brand"
                        options={metaOptions.brands.map((data) => ({
                            ...data,
                            value: data.id,
                            label: data.name,
                        }))}
                        value={getValue(sectionMeta, options.brand, 'brands')}
                        onChange={(val: SingleValue<any>) => {
                            setOptions((opts) => ({
                                ...opts,
                                brand: val?.id || null,
                            }));
                        }}
                        onMenuOpen={() => onMenuOpen()}
                        onMenuClose={() => onMenuClose()}
                    />
                </div>
                <div className={cx('select-wrapper')}>
                    <BasicSelect
                        title="Region"
                        isDisabled={isDefault}
                        placeholder="Choose Region"
                        options={metaOptions.regions.map((data) => ({
                            ...data,
                            value: data.id,
                            label: data.name,
                        }))}
                        value={getValue(sectionMeta, options.region, 'regions')}
                        onChange={(val: SingleValue<any>) => {
                            setOptions((opts) => ({
                                ...opts,
                                region: val?.id || null,
                            }));
                        }}
                        onMenuOpen={() => onMenuOpen()}
                        onMenuClose={() => onMenuClose()}
                    />
                </div>
                <div className={cx('select-wrapper')}>
                    <div className={cx('select-wrapper')}>
                        <BasicSelect
                            title="Area"
                            isDisabled={isDefault}
                            placeholder="Choose Area"
                            options={metaOptions.areas.map((data) => ({
                                ...data,
                                value: data.id,
                                label: data.name,
                            }))}
                            value={getValue(sectionMeta, options.area, 'areas')}
                            onChange={(val: SingleValue<any>) => {
                                setOptions((opts) => ({
                                    ...opts,
                                    area: val?.id || null,
                                }));
                            }}
                            onMenuOpen={() => onMenuOpen()}
                            onMenuClose={() => onMenuClose()}
                        />
                    </div>
                </div>
                <div className={cx('select-wrapper')}>
                    <BasicSelect
                        title="Country"
                        isDisabled={isDefault}
                        placeholder="Choose Country"
                        options={metaOptions.countries.map((data) => ({
                            ...data,
                            value: data.id,
                            label: data.name,
                        }))}
                        isSearchable
                        value={getValue(sectionMeta, options.country, 'countries')}
                        onChange={(val: SingleValue<any>) => {
                            setOptions((opts) => ({
                                ...opts,
                                country: val?.id || null,
                            }));
                        }}
                        onMenuOpen={() => onMenuOpen()}
                        onMenuClose={() => onMenuClose()}
                    />
                </div>
                <div className={cx('select-wrapper')}>
                    <BasicSelect
                        title="Type"
                        isDisabled={isDefault}
                        placeholder="Choose Property Type"
                        options={metaOptions.propertyTypes.map((data) => ({
                            ...data,
                            value: data.id,
                            label: data.name,
                        }))}
                        value={getValue(sectionMeta, options.propertyType, 'propertyTypes')}
                        onChange={(val: SingleValue<any>) => {
                            setOptions((opts) => ({
                                ...opts,
                                propertyType: val?.id || null,
                            }));
                        }}
                        onMenuOpen={() => onMenuOpen()}
                        onMenuClose={() => onMenuClose()}
                    />
                </div>
            </div>
            <BasicButton
                locked={locked}
                title={buttonTitle}
                isProcessing={isLoading}
                style={{
                    height: 40,
                    width: '100%',
                    fontSize: 14,
                    backgroundColor: COLOR['app-green'],
                }}
                onClick={() => {
                    onSave({
                        isDefault,
                        brand: options.brand,
                        region: options.region,
                        area: options.area,
                        country: options.country,
                        propertyType: options.propertyType,
                    });
                }}
            />
        </div>
    );
}

VariantForm.defaultProps = {
    onMenuOpen: () => {
    },
    onMenuClose: () => {
    },
};

VariantForm.propTypes = {
    locked: PropTypes.bool.isRequired,
    buttonTitle: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    onMenuOpen: PropTypes.func,
    onMenuClose: PropTypes.func,
};

type Props = InferProps<typeof VariantForm.propTypes> & typeof VariantForm.defaultProps & {
    defaultValues: {
        isDefault: boolean;
        brand: number | null;
        region: number | null;
        area: number | null;
        country: number | null;
        propertyType: number | null;
    };

    sectionMeta: AdminSingleSectionMeta;
};

export default VariantForm;
