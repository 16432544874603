import { ContentSection } from '@INTERFACES/api/content';

export default function onlyNotMandatorySections(
    sections: ContentSection[],
    isPartial: boolean,
    isChapterMandatory: boolean,
) {
    if (isChapterMandatory) {
        return !isPartial ? [] : sections.map((section) => section.id);
    }

    return sections
        .filter((section) => (!isPartial ? !section.isMandatory : true))
        .map((section) => section.id);
}
